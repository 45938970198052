import React from "react";
import { Link } from "react-router-dom";

import styles from "./Nav.module.css";

const Nav = () => {
  return (
    <div className={styles.NavWrapper}>
      <ul className={styles.Nav}>
        <li>
          <Link to="/admin">Dashboard</Link>
        </li>
        <li>
          <Link to="/admin/quotes">Quotes</Link>
        </li>
        <li>
          <Link to="/admin/users">Users</Link>
        </li>
        <li>
          <Link to="/admin/applications">Applications</Link>
        </li>
      </ul>
    </div>
  );
};

export default Nav;
