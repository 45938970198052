import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import Pagination from "react-paginate";
import { Link } from "react-router-dom";

import { adminListQuotes } from "Core/services/AdminQuote.service";
import useDebounce from "hooks/debounce";

import Breadcrumbs from "Common/Breadcrumbs";
import DateTime from "Common/DateTime";
import Select from "Common/Select";
import Input from "Common/Input";

import { formatCurrency } from "utils/currency";

import styles from "./QuotesList.module.css";

const LIMIT = 30;

export default function UserList() {
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  // filters
  const [product, setProduct] = useState(null);
  const [status, setStatus] = useState(null);
  const [quoteType, setQuoteType] = useState(null);
  const [search, setSearch] = useState(``);
  const searchQuery = useDebounce(search, 500);

  const [quotes, setQuotes] = useState();

  useEffect(() => {
    adminListQuotes({
      page: page + 1,
      limit: LIMIT,
      search: searchQuery,
      product,
      status,
      quote_type: quoteType
    }).then((data) => {
      setPages(Math.ceil(data.count / LIMIT));
      setQuotes(data.results);
    });
  }, [page, product, status, quoteType, searchQuery]);

  return (
    <div className={styles.Container}>
      <Helmet>
        <title>Quotes | Bounc3</title>
      </Helmet>

      <Breadcrumbs
        links={[
          { name: "Admin Home", path: "/admin" },
          { name: "Quotes", path: "/admin/quotes" }
        ]}
        className={styles.Subnav}
      />

      <div className={styles.Filters}>
        <Input
          wrapperClassName={styles.SearchInput}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search quotes"
        />

        <Select
          value={product}
          onChange={(event) => setProduct(event.target.value)}
        >
          <option value="">All Products</option>
          <option value="Critical Illness Insurance">
            Critical Illness Insurance
          </option>
          <option value="Disability Insurance">Disability Insurance</option>
          <option value="Life Insurance">Life Insurance</option>
        </Select>

        <Select
          value={status}
          onChange={(event) => setStatus(event.target.value)}
        >
          <option value="">All Statuses</option>
          <option value="requested">Requested</option>
          <option value="completed">Completed</option>
        </Select>

        <Select
          value={status}
          onChange={(event) => setQuoteType(event.target.value)}
        >
          <option value="">All Types</option>
          <option value="personal">Personal</option>
          <option value="business">Business</option>
        </Select>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>User</th>
            <th>Product</th>
            <th>Type</th>
            <th>Quote</th>
            <th>Coverage Amount</th>
            <th>Premium</th>
            <th>Status</th>
            <th>Date</th>
          </tr>
        </thead>

        <tbody>
          {quotes &&
            quotes.map((q, i) => {
              const requestData = q.request_data;
              return (
                <tr key={i}>
                  <td>
                    <Link to={`/admin/quotes/${q.id}`}>
                      <div>
                        {q.request_data.firstName} {q.request_data.lastName}{" "}
                      </div>
                      <div>{q.request_data.email}</div>
                      {!q.request_data.firstName &&
                        !q.request_data.lastName &&
                        !q.request_data.email && <p>Quote</p>}
                    </Link>
                  </td>
                  <td>{q.product}</td>
                  <td>{q.quote_type}</td>
                  <td>
                    {!q?.company && <>Custom</>}

                    {q?.company && (
                      <>
                        <div>{q.company}</div>
                        <div>
                          <small>{q.product_name}</small>
                        </div>
                      </>
                    )}
                  </td>
                  <td>
                    {!requestData?.coverageAmount &&
                      !requestData?.coverage_amount && <>&mdash;</>}

                    {requestData?.coverageAmount &&
                      formatCurrency(requestData.coverageAmount)}

                    {requestData?.coverage_amount &&
                      formatCurrency(requestData.coverage_amount)}
                  </td>
                  <td>
                    {!q.premium_monthly && <>&mdash;</>}

                    {q.premium_monthly && (
                      <>{formatCurrency(q.premium_monthly)} / mo</>
                    )}
                  </td>
                  <td>{q.status}</td>
                  <td>
                    {
                      <DateTime
                        date={q.createdAt}
                        format="MMMM D, YYYY h:mm A"
                      />
                    }
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <Pagination
        containerClassName="pagination"
        pageCount={pages}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        forcePage={page}
        activeClassName="page-selected"
        onPageChange={(data) => setPage(data.selected)}
      />
    </div>
  );
}
