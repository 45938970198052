import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Textarea from "Common/Textarea";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";

const CompanyInfoSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  street_address: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  province: Yup.string().required("This field is required"),
  postal_code: Yup.string().required("This field is required"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits")
    .required("This field is required"),
  nature_of_business: Yup.string().required("This field is required"),
});

const CompanyInfo = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Company Info</StylizedHeading>

      <Formik
        initialValues={{
          name: data?.name || "",
          street_address: data?.street_address || "",
          city: data?.city || "",
          province: data?.province || "",
          postal_code: data?.postal_code || "",
          phone: data?.phone || "",
          nature_of_business: data?.nature_of_business || "",
        }}
        onSubmit={(values) => {
          save(values);
          next();
        }}
        validateOnMount
        validationSchema={CompanyInfoSchema}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Company Name</Label>
              <Field
                as={Input}
                name="name"
                spellCheck="false"
                placeholder="Example Inc."
              />
              <ErrorMessage
                component="div"
                name="name"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Company Street Address
              </Label>
              <Field
                as={Input}
                name="street_address"
                spellCheck="false"
                placeholder="123 Easy Street, Unit 42"
              />
              <ErrorMessage
                component="div"
                name="street_address"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Company City</Label>
              <Field
                as={Input}
                name="city"
                spellCheck="false"
                placeholder="Toronto"
              />
              <ErrorMessage
                component="div"
                name="city"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Company Province
              </Label>
              <Field
                as={Input}
                name="province"
                spellCheck="false"
                placeholder="ON"
              />
              <ErrorMessage
                component="div"
                name="province"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Company Postal Code
              </Label>
              <Field
                as={Input}
                name="postal_code"
                maxLength="7"
                spellCheck="false"
                placeholder="A1A 2B2"
              />
              <ErrorMessage
                component="div"
                name="postal_code"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Company Phone Number
              </Label>
              <NumberFormat
                customInput={Input}
                name="phone"
                value={values.phone}
                onValueChange={(event) => {
                  setFieldValue("phone", event.value, false);
                }}
                format="(###) ###-####"
                decimalScale={0}
                allowNegative={false}
                allowEmptyFormatting={false}
                type="tel"
                placeholder="(123) 456-7890"
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                name="phone"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Describe the nature of your business.
              </Label>
              <Field as={Textarea} name="nature_of_business" />
              <ErrorMessage
                component="div"
                name="nature_of_business"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button type="submit" className={ApplicationsStyles.NextButton}>
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyInfo;
