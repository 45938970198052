import classNames from "classnames";

import History from "../../../Core/History";

import StylizedHeading from "../../../Common/StylizedHeading";
import P from "Common/P";
import Button from "../../../Common/Button";
import Tooltip from "../../../Common/Tooltip";
import { ReactComponent as EditIcon } from "Icons/edit.svg";
import { ReactComponent as DeleteIcon } from "Icons/delete.svg";

import ApplicationsStyles from "../../Applications.module.css";
// import OtherPolicyListStyles from "./OtherPolicyList.module.css";

const OtherPolicyList = ({ match, data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Other Policies</StylizedHeading>

      <P className={ApplicationsStyles.SmallText}>
        Please list any other policies that have been issued to you, or that you
        have applied for, including life insurance, critical illness insurance,
        disability insurance, and long-term care insurance. Include policies
        that lapsed within the last 90 days, were sold to a third party, or were
        issued in another country. Do not include group insurance policies.
      </P>

      <P className={ApplicationsStyles.SmallText}>
        Click next if you do not have any other policies.
      </P>

      {data && data.length > 0 && (
        <table className={ApplicationsStyles.Table}>
          <tbody>
            {data.map((p, i) => {
              return (
                <tr key={i}>
                  <td>
                    <P>{p.company + " - " + p.type}</P>
                  </td>
                  <td>
                    <Tooltip position="" message="Make changes to this policy.">
                      <EditIcon
                        className={ApplicationsStyles.ActionText}
                        onClick={() => {
                          History.push(`${match.url}/${i}`);
                        }}
                      />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip position="" message="Remove this policy.">
                      <DeleteIcon
                        className={ApplicationsStyles.ActionText}
                        onClick={() => {
                          const updatedData = [...data];
                          updatedData.splice(i, 1);
                          save(updatedData);
                        }}
                      />
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      <div
        onClick={() => {
          History.push(`${match.url}/${data.length}`);
        }}
      >
        <P
          className={classNames(
            ApplicationsStyles.ActionText,
            ApplicationsStyles.AddItem,
          )}
        >
          + Policy
        </P>
      </div>

      <div className={ApplicationsStyles.ButtonWrapper}>
        <Button
          fullWidth
          className={ApplicationsStyles.PreviousButton}
          onClick={(event) => {
            event.preventDefault();
            previous();
          }}
        >
          Previous
        </Button>

        <Button
          fullWidth
          className={ApplicationsStyles.NextButton}
          onClick={(event) => {
            event.preventDefault();
            next();
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default OtherPolicyList;
