import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import History from "Core/History";

import {
  getApplication,
  updateApplication,
} from "store/actions/Applications.actions";
import { selectApplication } from "store/selectors/Applications.selectors";

import styles from "./ApplicationEdit.module.css";

import Breadcrumbs from "Common/Breadcrumbs";
import HSAForm from "Common/HSAForm";

const ApplicationEdit = () => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const application = useSelector(selectApplication);

  useEffect(
    () => dispatch(getApplication(applicationId)).catch((e) => console.log(e)),
    [dispatch, applicationId]
  );

  let ApplicationForm = () => null;

  if (application && application.product && application.submission_data) {
    switch (application.product) {
      case "Health Spending Account": {
        ApplicationForm = HSAForm;
        break;
      }
      default: {
        ApplicationForm = () => null;
        break;
      }
    }
  }

  const back = () => History.goBack();

  const saveApplication = (data) => {
    dispatch(updateApplication(applicationId, data))
      .then(back)
      .catch((e) => console.log(e));
  };

  return (
    <div className={styles.Container}>
      <Helmet>
        <title>Edit Application | Bounc3</title>
      </Helmet>

      <Breadcrumbs
        links={[
          { name: "Home", path: "/dashboard" },
          { name: `Edit ${application.product} Application`, path: "#" },
        ]}
        className={styles.Subnav}
      />

      <ApplicationForm
        data={application.submission_data}
        save={saveApplication}
        cancel={back}
      />
    </div>
  );
};

export default ApplicationEdit;
