import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import Pagination from "react-paginate";
import { Link } from "react-router-dom";

import History from "Core/History";
import {
  adminDeleteApplication,
  adminListApplications
} from "Core/services/AdminApplication.service";

import useDebounce from "hooks/debounce";

import Breadcrumbs from "Common/Breadcrumbs";
import Select from "Common/Select";
import Button from "Common/Button";
import Label from "Common/Label";
import Input from "Common/Input";
import Modal from "Common/Modal";
import P from "Common/P";
import DateTime from "Common/DateTime";

import styles from "./ApplicationList.module.css";

const LIMIT = 30;

const ApplicationList = () => {
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  // filters
  const [product, setProduct] = useState(null);
  const [status, setStatus] = useState(null);
  const [applicationType, setApplicationType] = useState(null);
  const [search, setSearch] = useState(``);
  const searchQuery = useDebounce(search, 500);
  const [reloadTrigger, setReloadTrigger] = useState(0);

  const reload = useCallback(() => setReloadTrigger((t) => t + 1), []);

  const [applications, setApplications] = useState();
  const [applicationToDelete, setApplicationToDelete] = useState();

  const deleteApplication = (applicationId) => {
    setApplicationToDelete(null);
    adminDeleteApplication(applicationId)
      .then(() => {
        // Application deleted.
        setPage(1);
        reload();
      })
      .catch((e) => {
        console.error(e.message);
      });
  };

  useEffect(() => {
    adminListApplications({
      page: page + 1,
      limit: LIMIT,
      search: searchQuery,
      product,
      status,
      application_type: applicationType
    }).then((data) => {
      setPages(Math.ceil(data.count / LIMIT));
      setApplications(data.results);
    });
  }, [page, product, status, applicationType, searchQuery, reloadTrigger]);

  return (
    <div className={styles.Container}>
      <Helmet>
        <title>User Applications | Bounc3</title>
      </Helmet>

      <Breadcrumbs
        links={[
          { name: "Admin Home", path: "/admin" },
          { name: `User Applications`, path: "#" }
        ]}
        className={styles.Subnav}
      />

      <div className={styles.Filters}>
        <Input
          wrapperClassName={styles.SearchInput}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search applications"
        />

        <Select
          value={product}
          onChange={(event) => setProduct(event.target.value)}
        >
          <option value="">All Products</option>
          <option value="Critical Illness Insurance">
            Critical Illness Insurance
          </option>
          <option value="Disability Insurance">Disability Insurance</option>
          <option value="Health Spending Account">
            Health Spending Account
          </option>
          <option value="Life Insurance">Life Insurance</option>
        </Select>

        <Select
          value={status}
          onChange={(event) => setStatus(event.target.value)}
        >
          <option value="">All Statuses</option>
          <option value="pending">Pending</option>
          <option value="submitted">Submitted</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </Select>

        <Select
          value={status}
          onChange={(event) => setApplicationType(event.target.value)}
        >
          <option value="">All Types</option>
          <option value="personal">Personal</option>
          <option value="business">Business</option>
        </Select>

        {/* User filter */}
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>User</th>
            <th>Product</th>
            <th>Type</th>
            <th>Status</th>
            <th>Submitted At</th>
            <th>View</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody>
          {applications &&
            applications.map((a, i) => {
              return (
                <tr key={i}>
                  <td>
                    <Link to={`/admin/users/${a.user.id}`}>{a.user.email}</Link>
                  </td>
                  <td>{a.product}</td>
                  <td>{a.application_type}</td>
                  <td>{a.status}</td>
                  <td>
                    {a.submitted_at ? (
                      <DateTime
                        date={a.submitted_at}
                        format="MMMM D, YYYY h:mm A"
                      />
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td
                    onClick={() => History.push(`/admin/applications/${a.id}`)}
                  >
                    <Button plain>View</Button>
                  </td>
                  <td
                    onClick={() =>
                      History.push(`/admin/applications/${a.id}/edit`)
                    }
                  >
                    <Button plain>Edit</Button>
                  </td>
                  <td onClick={() => setApplicationToDelete(a)}>
                    <Button plain>Delete</Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <Pagination
        containerClassName="pagination"
        pageCount={pages}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        forcePage={page}
        activeClassName="page-selected"
        onPageChange={(data) => setPage(data.selected)}
      />

      {applicationToDelete && (
        <Modal>
          <div className={styles.Modal}>
            <P>
              {applicationToDelete.user.email} - {applicationToDelete.product}{" "}
              Application
            </P>
            <Label>Type "DELETE" to confirm.</Label>
            <Input
              onInput={(event) =>
                setApplicationToDelete({
                  ...applicationToDelete,
                  confirmed: event.target.value === "DELETE"
                })
              }
            />
            <div className={styles.ButtonGroup}>
              <Button onClick={() => setApplicationToDelete(null)} outline>
                Cancel
              </Button>
              <Button
                onClick={() => deleteApplication(applicationToDelete.id)}
                disabled={!applicationToDelete.confirmed}
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ApplicationList;
