import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import History from "../../Core/History";

import StylizedHeading from "../../Common/StylizedHeading";
import Label from "../../Common/Label";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import { register } from "../../store/actions/Auth.actions";

import styles from "./RegisterView.module.css";

const RegistrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  last_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  password: Yup.string()
    .min(8, "This password is too short")
    .max(50, "This password is too long")
    .required("This field is required"),
  terms_agreed: Yup.boolean().oneOf(
    [true],
    "You must agree to the terms of service",
  ),
});

const RegisterView = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const handleSubmit = (values) => {
    setError(null);
    dispatch(
      register(
        values.email,
        values.password,
        values.first_name,
        values.last_name,
      ),
    )
      .then(() => {
        // Redirect to dashboard
        History.push("/");
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return (
    <div className={styles.Container}>
      <div className={styles.FormWrapper}>
        <StylizedHeading>Register</StylizedHeading>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            terms_agreed: false,
          }}
          validationSchema={RegistrationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form className={styles.Form}>
              {error && <p className="danger">{error}</p>}

              <div className={styles.Field}>
                <div className={styles.Label}>
                  <Label>First Name</Label>
                </div>
                <Field as={Input} name="first_name" />
                <div className={styles.Error}>
                  <ErrorMessage name="first_name" />
                </div>
              </div>

              <div className={styles.Field}>
                <div className={styles.Label}>
                  <Label>Last Name</Label>
                </div>
                <Field as={Input} name="last_name" />
                <div className={styles.Error}>
                  <ErrorMessage name="last_name" />
                </div>
              </div>

              <div className={styles.Field}>
                <div className={styles.Label}>
                  <Label>Email</Label>
                </div>
                <Field as={Input} name="email" type="email" />
                <div className={styles.Error}>
                  <ErrorMessage name="email" />
                </div>
              </div>

              <div className={styles.Field}>
                <div className={styles.Label}>
                  <Label>Password</Label>
                </div>
                <Field as={Input} name="password" type="password" />
                <div className={styles.Error}>
                  <ErrorMessage name="password" />
                </div>
              </div>

              <div className={styles.Field}>
                <Field
                  id="termsAgreement"
                  name="terms_agreed"
                  type="checkbox"
                />
                &nbsp;
                <Label htmlFor="termsAgreement">
                  I agree to the{" "}
                  <a
                    href="https://bounc3.io/page/terms-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </Label>
                <div className={styles.Error}>
                  <ErrorMessage name="terms_agreed" />
                </div>
              </div>

              <div className={styles.ButtonWrapper}>
                <Button fullWidth type="submit">
                  Register
                </Button>
              </div>

              <div className={styles.LinkWrapper}>
                <Link className={styles.Link} to="/auth/login">
                  Log In
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RegisterView;
