import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  validatePasswordResetToken,
  confirmPasswordReset,
} from "store/actions/Auth.actions";

import StylizedHeading from "Common/StylizedHeading";
import P from "Common/P";
import Label from "Common/Label";
import Input from "Common/Input";
import Button from "Common/Button";

import styles from "./ResetPassword.module.css";

const ResetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(8, "This password is too short")
    .max(50, "This password is too long")
    .required("This field is required"),
});

const ResetPasswordView = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    dispatch(validatePasswordResetToken(token))
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, [dispatch, token]);

  const handleSubmit = (values) => {
    dispatch(confirmPasswordReset(token, values.new_password))
      .then(() => {
        setSuccess(true);
        setError(false);
      })
      .catch((e) => setError(e.message));
  };

  if (loading) {
    return null;
  } else if (error) {
    return (
      <div className={styles.Container}>
        <p className="danger">{error}</p>
      </div>
    );
  } else if (success) {
    <div className={styles.Container}>
      <P className={styles.Message}>Your password has been reset.</P>
    </div>;
  } else {
    return (
      <div className={styles.Container}>
        <div className={styles.FormWrapper}>
          <StylizedHeading>Reset Password</StylizedHeading>
          <Formik
            initialValues={{
              new_password: "",
            }}
            validationSchema={ResetPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className={styles.Form}>
                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>New Password</Label>
                  </div>
                  <Field as={Input} name="new_password" type="password" />
                  <div className={styles.Error}>
                    <ErrorMessage name="new_password" />
                  </div>
                </div>

                <div className={styles.ButtonWrapper}>
                  <Button fullWidth type="submit">
                    Continue
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
};

export default ResetPasswordView;
