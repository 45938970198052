import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";
// import AddressInfoStyles from "./AddressInfo.module.css";

const AddressInfoSchema = Yup.object().shape({
  street_address: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  province: Yup.string().required("This field is required"),
  postal_code: Yup.string().required("This field is required"),
});

const AddressInfo = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Address Info</StylizedHeading>

      <Formik
        initialValues={{
          street_address: data?.street_address || "",
          city: data?.city || "",
          province: data?.province || "",
          postal_code: data?.postal_code || "",
        }}
        validateOnMount
        validationSchema={AddressInfoSchema}
        onSubmit={(values) => {
          save(values);
          next();
        }}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Street Address</Label>
              <Field
                as={Input}
                name="street_address"
                spellCheck="false"
                placeholder="123 Easy Street, Unit 42"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="street_address"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>City</Label>
              <Field
                as={Input}
                name="city"
                spellCheck="false"
                placeholder="Toronto"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="city"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Province</Label>
              <Field
                as={Input}
                name="province"
                spellCheck="false"
                placeholder="ON"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="province"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Postal Code</Label>
              <Field
                as={Input}
                name="postal_code"
                maxLength="7"
                spellCheck="false"
                placeholder="A1A 2B2"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="postal_code"
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                type="submit"
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddressInfo;
