import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import History from "Core/History";
import { config } from "Config";

import {
  createApplication,
  submitApplication,
  saveDraftApplication
} from "store/actions/Applications.actions";
import { login, register } from "store/actions/Auth.actions";
import { selectCurrentUser } from "store/selectors/Auth.selectors";
import { selectApplicationList } from "store/selectors/Applications.selectors";

import ProgressBar from "Common/ProgressBar";
import GetStarted from "./GetStarted";
import PersonalInfo from "./PersonalInfo";
import CompanyInfo from "./CompanyInfo";
import EmployeeClassList from "./EmployeeClassList";
import EmployeeClassInfo from "./EmployeeClassInfo";
import EmployeeList from "./EmployeeList";
import EmployeeInfo from "./EmployeeInfo";
import EmployeeDependantList from "./EmployeeDependantList";
import EmployeeDependantInfo from "./EmployeeDependantInfo";
import ProgramSettings from "./ProgramSettings";
import ConfirmAndRegister from "./ConfirmAndRegister";

import ApplicationsStyles from "../Applications.module.css";

const HsaApplicationLayout = ({ match }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const applications = useSelector(selectApplicationList);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 6;

  let defaultHSA = {
    eligible: null,
    personal_info: {
      first_name: user?.first_name || null,
      last_name: user?.last_name || null,
      email: user?.email || null,
      province: user?.province || null,
      birth_date: user?.birth_date || null,
      gender: user?.gender || null
    },
    company_info: {},
    has_multiple_classes: undefined,
    employee_class_info: [
      {
        max_annual_claim: 0.0
      }
    ],
    employee_info: [],
    program_settings: {}
  };

  // If we're in dev mode and no user is logged in, use the devHSA config
  if (config.ENVIRONMENT !== "production" && !user) {
    defaultHSA = config.devHSA;
  }

  const [HSA, setHSA] = useState(defaultHSA);

  useEffect(() => {
    const savedApplication = applications?.find(
      (a) => a.product === "Health Spending Account"
    );

    if (savedApplication) {
      setHSA(savedApplication.submission_data);
    }
  }, [applications]);

  const saveDraftHSA = () => {
    if (!user) {
      dispatch(
        saveDraftApplication(
          HSA.personal_info.email,
          "Health Spending Account",
          HSA
        )
      ).catch((e) => console.log(e));
    }
  };

  const submitHSA = async (registrationData = null) => {
    const { email, password, first_name, last_name } = registrationData;

    setLoading(true);

    try {
      if (!user) {
        try {
          await dispatch(register(email, password, first_name, last_name));
        } catch (e) {
          // failed to register, try to login
          await dispatch(login(email, password));
        }
      }

      const application = await dispatch(
        createApplication("Health Spending Account", HSA)
      );

      await dispatch(submitApplication(application.id));

      History.replace("/dashboard");
    } catch (e) {
      setError(e.message);
    }

    setLoading(false);
  };

  return (
    <div className={ApplicationsStyles.Container}>
      <Helmet>
        <title>HSA Application | Bounc3</title>
      </Helmet>

      {currentStep > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "2rem"
          }}
        >
          <ProgressBar percent={currentStep / totalSteps} />
        </div>
      )}

      <Router history={History}>
        <Switch>
          <Route
            exact
            path={`${match.url}/get-started`}
            render={(props) => {
              setCurrentStep(0);
              return (
                <GetStarted
                  {...props}
                  data={HSA.eligible}
                  save={(data) => setHSA({ ...HSA, eligible: data })}
                  next={() => History.push(`${match.url}/personal-info`)}
                />
              );
            }}
          ></Route>

          {!HSA.eligible && (
            <Redirect from={`${match.url}`} to={`${match.url}/get-started`} />
          )}

          <Route
            path={`${match.url}/personal-info`}
            render={(props) => {
              setCurrentStep(0);
              return (
                <PersonalInfo
                  {...props}
                  data={HSA.personal_info}
                  save={(data) => setHSA({ ...HSA, personal_info: data })}
                  previous={() => History.push(`${match.url}/get-started`)}
                  next={() => History.push(`${match.url}/company-info`)}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/company-info`}
            render={(props) => {
              setCurrentStep(1);
              return (
                <CompanyInfo
                  {...props}
                  data={HSA.company_info}
                  save={(data) => {
                    setHSA({ ...HSA, company_info: data });
                    saveDraftHSA();
                  }}
                  previous={() => History.push(`${match.url}/personal-info`)}
                  next={() => History.push(`${match.url}/classes`)}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/classes`}
            render={(props) => {
              setCurrentStep(2);
              return (
                <EmployeeClassList
                  {...props}
                  data={HSA.employee_class_info}
                  save={(data) => {
                    const updatedHSA = { ...HSA };

                    updatedHSA.employee_class_info[0].max_annual_claim =
                      data.max_annual_claim;

                    setHSA(updatedHSA);
                  }}
                  previous={() => History.push(`${match.url}/company-info`)}
                  next={() => History.push(`${match.url}/employees`)}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/classes/:classIndex`}
            render={(props) => {
              setCurrentStep(2);
              return (
                <EmployeeClassInfo
                  {...props}
                  data={HSA.employee_class_info}
                  save={(data) => {
                    setHSA({ ...HSA, employee_class_info: data });
                    saveDraftHSA();
                  }}
                  back={() => History.goBack()}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/employees`}
            render={(props) => {
              setCurrentStep(3);
              return (
                <EmployeeList
                  {...props}
                  data={HSA.employee_info}
                  save={(data) => {
                    setHSA({ ...HSA, employee_info: data });
                    saveDraftHSA();
                  }}
                  previous={() => History.push(`${match.url}/classes`)}
                  next={() => History.push(`${match.url}/settings`)}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/employess/add`}
            render={(props) => {
              setCurrentStep(3);
              return (
                <EmployeeInfo
                  {...props}
                  data={HSA.employee_info}
                  save={(data) => {
                    setHSA({ ...HSA, employee_info: data });
                    saveDraftHSA();
                  }}
                  back={() => History.goBack()}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/employees/:employeeIndex`}
            render={(props) => {
              setCurrentStep(3);
              return (
                <EmployeeInfo
                  {...props}
                  data={HSA.employee_info}
                  save={(data) => {
                    setHSA({ ...HSA, employee_info: data });
                    saveDraftHSA();
                  }}
                  back={() => History.goBack()}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/employees/:employeeIndex/dependants`}
            render={(props) => {
              setCurrentStep(4);
              return (
                <EmployeeDependantList
                  {...props}
                  data={HSA.employee_info}
                  save={(data) => {
                    setHSA({ ...HSA, employee_info: data });
                    saveDraftHSA();
                  }}
                  back={() => History.goBack()}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/employees/:employeeIndex/dependants/add`}
            render={(props) => {
              setCurrentStep(4);
              return (
                <EmployeeDependantInfo
                  {...props}
                  data={HSA.employee_info}
                  save={(data) => {
                    setHSA({ ...HSA, employee_info: data });
                    saveDraftHSA();
                  }}
                  back={() => History.goBack()}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/employees/:employeeIndex/dependants/:dependantIndex`}
            render={(props) => {
              setCurrentStep(4);
              return (
                <EmployeeDependantInfo
                  {...props}
                  data={HSA.employee_info}
                  save={(data) => {
                    setHSA({ ...HSA, employee_info: data });
                    saveDraftHSA();
                  }}
                  back={() => History.goBack()}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/settings`}
            render={(props) => {
              setCurrentStep(5);
              return (
                <ProgramSettings
                  {...props}
                  data={HSA.program_settings}
                  save={(data) => {
                    setHSA({ ...HSA, program_settings: data });
                    saveDraftHSA();
                  }}
                  previous={() => History.push(`${match.url}/employees`)}
                  next={() => History.push(`${match.url}/done`)}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/done`}
            render={(props) => {
              setCurrentStep(6);
              return (
                <ConfirmAndRegister
                  {...props}
                  data={HSA.personal_info}
                  submit={submitHSA}
                  loading={loading}
                  error={error}
                />
              );
            }}
          ></Route>
        </Switch>
      </Router>
    </div>
  );
};

export default HsaApplicationLayout;
