import classNames from "classnames";

import styles from "./Modal.module.css";

export default function Modal({ className, children }) {
  return (
    <div className={classNames(styles.Wrapper, className)}>
      <div className={styles.Modal}>{children}</div>
    </div>
  );
}
