import React, { useState, useEffect } from "react";
import Pagination from "react-paginate";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import useDebounce from "hooks/debounce";

import { adminListUsers } from "Core/services/AdminUser.service";

import Input from "Common/Input";
import Breadcrumbs from "Common/Breadcrumbs";
import DateTime from "Common/DateTime";

import styles from "./UserList.module.css";

const LIMIT = 30;

export default function UserList() {
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  // filters
  const [users, setUsers] = useState();
  const [search, setSearch] = useState("");
  const searchQuery = useDebounce(search, 500);

  useEffect(() => {
    adminListUsers({
      page: page + 1,
      limit: LIMIT,
      search: searchQuery,
    }).then((data) => {
      setPages(Math.ceil(data.count / LIMIT));
      setUsers(data.results);
    });
  }, [page, searchQuery]);

  return (
    <div className={styles.Container}>
      <Helmet>
        <title>Users | Bounc3</title>
      </Helmet>

      <Breadcrumbs
        links={[
          { name: "Admin Home", path: "/admin" },
          { name: `Users`, path: "#" },
        ]}
        className={styles.Subnav}
      />

      <Input
        className={styles.Search}
        placeholder="Search users"
        onChange={(event) => setSearch(event.target.value)}
      />

      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Last Seen</th>
          </tr>
        </thead>

        <tbody>
          {users &&
            users.map((u, i) => {
              return (
                <tr key={i}>
                  <td>
                    <Link to={`/admin/users/${u.id}`}>
                      {[u.profile.first_name, u.profile.last_name].join(" ")}
                    </Link>
                  </td>
                  <td>{u.email}</td>
                  <td>
                    <DateTime
                      date={u.last_login_at}
                      format="MMMM D, YYYY h:mm A"
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <Pagination
        containerClassName="pagination"
        pageCount={pages}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        forcePage={page}
        activeClassName="page-selected"
        onPageChange={(data) => setPage(data.selected)}
      />
    </div>
  );
}
