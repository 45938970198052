import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import History from "Core/History";
import { verifyEmail } from "store/actions/Auth.actions";
import StylizedHeading from "Common/StylizedHeading";
import Button from "Common/Button";

import styles from "./VerifyEmail.module.css";

export default function VerifyEmailView() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(verifyEmail(token))
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => setLoading(false));
  }, [dispatch, token]);

  if (loading) return null;

  return (
    <div className={styles.Container}>
      {error ? (
        <p className="danger">{error}</p>
      ) : (
        <>
          <StylizedHeading>Thanks for verifying your email!</StylizedHeading>

          <div className={styles.ButtonWrapper}>
            <Button onClick={() => History.push("/auth/login")}>
              Continue
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
