import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import P from "Common/P";
import Label from "Common/Label";
import Select from "Common/Select";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";
// import NextStepsStyles from "./NextSteps.module.css";

const NextSteps = ({ data, save, previous, next }) => {
  const NextStepsSchema = Yup.object().shape({
    contact_time: Yup.string().required("This field is required"),
  });

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Next Steps</StylizedHeading>

      <Formik
        initialValues={{
          contact_time: data?.contact_time || "anytime",
        }}
        validationSchema={NextStepsSchema}
        validateOnMount
      >
        {({ values, isValid }) => (
          <Form className={ApplicationsStyles.Form}>
            <P className={ApplicationsStyles.Label}>
              1. A telephone interview will be scheduled with Manulife.
            </P>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                What is the best time to call?
              </Label>
              <Field as={Select} name="contact_time">
                <option value="anytime">Anytime</option>
                <option value="morning">Morning</option>
                <option value="afternoon">Afternoon</option>
                <option value="evening">Evening</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="contact_time"
              />
            </div>

            <P className={ApplicationsStyles.Label}>
              2. An e-application will be sent via email. Review and sign.
            </P>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                className={ApplicationsStyles.NextButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  next();
                }}
                disabled={!isValid}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NextSteps;
