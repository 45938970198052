import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Select from "Common/Select";
import DateSelect from "Common/DateSelect";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";
// import EmployeeDependantInfoStyles from "./EmployeeDependantInfo.module.css";

const DependantInfoSchema = Yup.object().shape({
  full_name: Yup.string()
    .min(2, "This name is too short")
    .max(100, "This name is too long")
    .required("This field is required"),
  relation: Yup.string().required("This field is required"),
  gender: Yup.string()
    .oneOf(["male", "female", "undisclosed"])
    .required("This field is required"),
  birth_date: Yup.date().required("This field is required")
});

const EmployeeDependantInfo = ({ match, data, save, back }) => {
  const employeeIndex = Number(match.params.employeeIndex);
  const dependantIndex = Number(match.params.dependantIndex || -1);

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Dependant Info</StylizedHeading>

      <Formik
        initialValues={{
          full_name:
            data[employeeIndex]?.dependants?.[dependantIndex]?.full_name || "",
          relation:
            data[employeeIndex]?.dependants?.[dependantIndex]?.relation || "",
          gender:
            data[employeeIndex]?.dependants?.[dependantIndex]?.gender || "",
          birth_date:
            data[employeeIndex]?.dependants?.[dependantIndex]?.birth_date || ""
        }}
        validateOnMount
        validationSchema={DependantInfoSchema}
      >
        {({ values, isValid, setFieldValue }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Full Name</Label>
              <Field as={Input} name="full_name" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="full_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Relation</Label>
              <Field as={Input} name="relation" type="text" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="relation"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Gender</Label>
              <Field as={Select} fullWidth name="gender" type="text">
                <option value={null}></option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="undisclosed">Prefer not to say</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="gender"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Date of Birth</Label>
              <Field
                as={DateSelect}
                maxYearCorrection={-16}
                name="birth_date"
                value={values.birth_date}
                onChange={(value) => setFieldValue("birth_date", value, true)}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="birth_date"
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  back();
                }}
              >
                Cancel
              </Button>
              <Button
                className={ApplicationsStyles.NextButton}
                onClick={(event) => {
                  event.preventDefault();
                  const updatedData = [...data];
                  if (!data[employeeIndex].dependants?.[dependantIndex]) {
                    if (!updatedData[employeeIndex].dependants) {
                      updatedData[employeeIndex].dependants = [];
                    }

                    updatedData[employeeIndex].dependants.push(values);
                  } else {
                    updatedData[employeeIndex].dependants.splice(
                      dependantIndex,
                      1,
                      values
                    );
                  }
                  save(updatedData);
                  back();
                }}
                disabled={!isValid}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmployeeDependantInfo;
