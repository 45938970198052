import { Switch, Route } from "react-router-dom";

import HomeView from "./HomeView";
import ApplicationsRouter from "./Applications";

const DashboardRouter = ({ match }) => {
  return (
    <>
      <main>
        <Switch>
          <Route exact path={`/dashboard`} component={HomeView} />

          <Route
            path={`/dashboard/applications`}
            component={ApplicationsRouter}
          />
        </Switch>
      </main>
    </>
  );
};

export default DashboardRouter;
