import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Button from "Common/Button";
import { Card, CardBody } from "Common/Card";
import { ReactComponent as FormIllustration } from "Icons/form-illustration.svg";

import style from "./RiskProfilePrompt.module.css";

const RiskProfilePrompt = ({ data }) => {
  return (
    <Card className={style.Card}>
      <CardBody className={style.CardBody}>
        {!data && (
          <>
            <h2>Fill out your risk profile to get insurance recommendations</h2>

            <p className={classNames(style.Description, "my")}>
              If you are unsure what insurance you really need then take our
              risk diagnostic. It takes 2 minutes, and it's all confidential.
            </p>

            <div className="my">
              <Link to="/risk-profile">
                <Button className={style.Button}>Start</Button>
              </Link>
            </div>
          </>
        )}

        {data && !data.recommendations && (
          <>
            <h2>Complete your risk profile to get insurance recommendations</h2>

            <p className={classNames(style.Description, "my")}>
              If you are unsure what insurance you really need then take our
              risk diagnostic. It takes 2 minutes, and it's all confidential.
            </p>

            <div className="my">
              <Link to="/risk-profile">
                <Button className={style.Button}>Complete</Button>
              </Link>
            </div>
          </>
        )}

        {data && data.recommendations && (
          <>
            <h2>View your risk profile results</h2>

            <p className={classNames(style.Description, "my")}>
              Your risk profile provides you with tailored recommendations on
              what insurance you really need to purchase, and helps you find the
              most affordable insurance options.
            </p>

            <div className="my">
              <Link to="/risk-profile/complete">
                <Button className={style.Button}>View</Button>
              </Link>
            </div>
          </>
        )}

        <FormIllustration className={style.Illustration} />
      </CardBody>
    </Card>
  );
};

export default RiskProfilePrompt;
