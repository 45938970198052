import * as fromApplications from "../types/Applications.types";

const initState = {
  applicationList: [],
  application: false,
  isLoading: false,
  isLoaded: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    // LIST
    case fromApplications.LIST_APPLICATIONS:
      return { ...state, isLoading: true };
    case fromApplications.LIST_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applicationList: action.payload,
        isLoaded: true,
        isLoading: false,
      };
    case fromApplications.LIST_APPLICATIONS_FAIL:
      return { ...state, isLoaded: false, isLoading: false };
    // GET
    case fromApplications.GET_APPLICATION:
      return { ...state, isLoading: true };
    case fromApplications.GET_APPLICATION_SUCCESS:
      return {
        ...state,
        application: action.payload,
        isLoaded: true,
        isLoading: false,
      };
    case fromApplications.GET_APPLICATION_FAIL:
      return { ...state, isLoaded: false, isLoading: false };
    // CREATE
    case fromApplications.CREATE_APPLICATION_SUCCESS:
      return {
        ...state,
        application: action.payload,
        isLoaded: true,
        isLoading: false,
      };
    // UPDATE
    case fromApplications.UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        application: action.payload,
        isLoaded: true,
        isLoading: false,
      };
    // SUBMIT
    case fromApplications.SUBMIT_APPLICATION_SUCCESS:
      return {
        ...state,
        application: action.payload,
        isLoaded: true,
        isLoading: false,
      };
    // RESCIND
    case fromApplications.RESCIND_APPLICATION_SUCCESS:
      return {
        ...state,
        application: action.payload,
        isLoaded: true,
        isLoading: false,
      };
    default:
      return state;
  }
}
