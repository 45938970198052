import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Select from "Common/Select";
import DateSelect from "Common/DateSelect";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";
import { ApplicationType } from "Core/models/Application.model";
import Textarea from "Common/Textarea";
// import PersonalInfoStyles from "./PersonalInfo.module.css";

const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  last_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  gender: Yup.string().required("This field is required"),
  birth_date: Yup.date().required("This field is required"),
  birth_place: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits")
    .required("This field is required"),
  language: Yup.string().required("This field is required"),
  citizen_or_permanent_resident: Yup.boolean().required(
    "This field is required"
  ),
  immigration_status: Yup.string().when("citizen_or_permanent_resident", {
    is: false,
    then: Yup.string().required("This field is required")
  }),
  immigration_status_effective_date: Yup.date().when(
    "citizen_or_permanent_resident",
    {
      is: false,
      then: Yup.date().required("This field is required")
    }
  ),
  applied_for_permanent_residence: Yup.boolean().when(
    "citizen_or_permanent_resident",
    {
      is: false,
      then: Yup.boolean().required("This field is required")
    }
  ),
  is_domestic_worker: Yup.boolean().when("citizen_or_permanent_resident", {
    is: false,
    then: Yup.boolean().required("This field is required")
  }),
  is_foreign_physician: Yup.boolean().when("citizen_or_permanent_resident", {
    is: false,
    then: Yup.boolean().required("This field is required")
  }),
  is_skilled_worker: Yup.boolean().when("citizen_or_permanent_resident", {
    is: false,
    then: Yup.boolean().required("This field is required")
  }),

  business_role: Yup.string(),
  business_purchase_reason: Yup.array(),
  business_signing_authority: Yup.string(),
  business_owned_percentage: Yup.string()
});

const fields = Object.keys(PersonalInfoSchema.fields);

const PersonalInfo = ({ data, applicationType, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Personal Info</StylizedHeading>

      <Formik
        initialValues={fields.reduce((values, field) => {
          values[field] = data?.[field] || "";
          return values;
        }, {})}
        validateOnMount
        validationSchema={PersonalInfoSchema}
        onSubmit={(values) => {
          save(values);
          next();
        }}
      >
        {({ values, isValid, errors, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>First Name</Label>
              <Field as={Input} name="first_name" placeholder="John" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="first_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Last Name</Label>
              <Field as={Input} name="last_name" placeholder="Smith" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="last_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Email</Label>
              <Field
                as={Input}
                name="email"
                type="email"
                placeholder="jsmith@example.ca"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="email"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Phone</Label>
              <NumberFormat
                customInput={Input}
                name="phone"
                value={values.phone}
                onValueChange={(event) => {
                  setFieldValue("phone", event.value, false);
                }}
                format="(###) ###-####"
                decimalScale={0}
                allowNegative={false}
                allowEmptyFormatting={false}
                type="tel"
                placeholder="(123) 456-7890"
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="phone"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Gender</Label>
              <Field as={Select} name="gender" type="text">
                <option value={null}></option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="undisclosed">Prefer not to say</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="gender"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Date of Birth</Label>
              <Field
                as={DateSelect}
                maxYearCorrection={-16}
                name="birth_date"
                value={values.birth_date}
                onChange={(value) => setFieldValue("birth_date", value, true)}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="birth_date"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Place of Birth</Label>
              <Field as={Input} name="birth_place" placeholder="Canada" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="birth_place"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Language Preference
              </Label>
              <Field as={Select} name="language" type="text">
                <option value={null}></option>
                <option value="en">English</option>
                <option value="fr">French</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="language"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Are you a Canadian citizen or do you have a permanent resident
                status?
              </Label>
              <Field
                as={Select}
                name="citizen_or_permanent_resident"
                type="text"
              >
                <option value={null}></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="citizen_or_permanent_resident"
              />
            </div>

            {values.citizen_or_permanent_resident === "false" && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    What is your current immigration status in Canada?
                  </Label>
                  <Field as={Select} name="immigration_status" type="text">
                    <option value={null}></option>
                    <option value="On temporary visa">On temporary visa</option>
                    <option value="Refugee status">Refugee status</option>
                    <option value="Applied for refugee status">
                      Applied for refugee status
                    </option>
                    <option value="Applied for permanent status">
                      Applied for permanent status
                    </option>
                    <option value="On visa - visitor">On visa - visitor</option>
                    <option value="On visa - student">On visa - student</option>
                    <option value="On visa - employee">
                      On visa - employee
                    </option>
                    <option value="Other">Other</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="immigration_status"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    When did this status come into effect?
                  </Label>
                  <Field
                    as={DateSelect}
                    name="immigration_status_effective_date"
                    value={values.immigration_status_effective_date}
                    onChange={(value) =>
                      setFieldValue(
                        "immigration_status_effective_date",
                        value,
                        true
                      )
                    }
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="immigration_status_effective_date"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Have you applied for permanent resident status?
                  </Label>
                  <Field
                    as={Select}
                    name="applied_for_permanent_residence"
                    type="text"
                  >
                    <option value={null}></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="applied_for_permanent_residence"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Are you a domestic worker under a special immigration
                    program to work as a "live-in" care giver or nanny?
                  </Label>
                  <Field as={Select} name="is_domestic_worker" type="text">
                    <option value={null}></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="is_domestic_worker"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Are you a foreign-trained physician under a provincial
                    program?
                  </Label>
                  <Field as={Select} name="is_foreign_physician" type="text">
                    <option value={null}></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="is_foreign_physician"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Are you a skilled worker under either federal or special
                    provincial nominee immigration program?
                  </Label>
                  <Field as={Select} name="is_skilled_worker" type="text">
                    <option value={null}></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="is_skilled_worker"
                  />
                </div>
              </>
            )}

            {applicationType === ApplicationType.BUSINESS && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    What is your role in the business?
                  </Label>
                  <Field as={Textarea} name="business_role" />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Why are you purchasing this policy? (check all that apply)
                  </Label>
                  <Label className={ApplicationsStyles.Label}>
                    <Field
                      type="checkbox"
                      name="business_purchase_reason"
                      value="Collateral for a business loan"
                    />
                    Collateral for a business loan
                  </Label>
                  <Label className={ApplicationsStyles.Label}>
                    <Field
                      type="checkbox"
                      name="business_purchase_reason"
                      value="Estate bond"
                    />
                    Estate bond
                  </Label>
                  <Label className={ApplicationsStyles.Label}>
                    <Field
                      type="checkbox"
                      name="business_purchase_reason"
                      value="Key person insurance"
                    />
                    Key person insurance
                  </Label>
                  <Label className={ApplicationsStyles.Label}>
                    <Field
                      type="checkbox"
                      name="business_purchase_reason"
                      value="Buy-sell agreement"
                    />
                    Buy-sell agreement
                  </Label>
                  <Label className={ApplicationsStyles.Label}>
                    <Field
                      type="checkbox"
                      name="business_purchase_reason"
                      value="Other"
                    />
                    Other
                  </Label>
                  <Label className={ApplicationsStyles.Label}>
                    <Field
                      type="checkbox"
                      name="business_purchase_reason"
                      value="Unsure"
                    />
                    Unsure
                  </Label>
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Do you have signing authority on behalf of your company?
                  </Label>
                  <Field
                    as={Select}
                    name="business_signing_authority"
                    type="text"
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Unsure">Unsure</option>
                  </Field>
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <div className={ApplicationsStyles.Label}>
                    <Label className={ApplicationsStyles.Label}>
                      What percentage of the business do you own?
                    </Label>
                  </div>
                  <Field
                    as={NumberFormat}
                    customInput={Input}
                    name="business_owned_percentage"
                    suffix={"%"}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return floatValue >= 0 && floatValue <= 100;
                    }}
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                type="submit"
                fullWidth
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfo;
