import React, { useState, useMemo, useEffect } from "react";

import { timezoneDate } from "utils/date";

import styles from "./DateSelect.module.css";

import Select from "../../Common/Select";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export default function DateSelect({
  maxYearCorrection = 0,
  className,
  ...props
}) {
  const [year, setYear] = useState(``);
  const [month, setMonth] = useState(``);
  const [day, setDay] = useState(``);

  const years = useMemo(() => {
    const array = generateValues(
      1900,
      new Date().getFullYear() + maxYearCorrection
    );
    return array.sort((a, b) => (a < b ? 1 : -1));
  }, [maxYearCorrection]);
  const months = useMemo(() => generateValues(1, 12), []);
  const days = useMemo(() => generateValues(1, 31), []);

  const setDate = (year, month, day) => {
    setYear(year);
    setMonth(month);
    setDay(day);

    props.onChange(`${year}-${zeroFill(month)}-${zeroFill(day)}`);
  };

  useEffect(() => {
    if (props.value) {
      const date = timezoneDate(props.value);

      if (isValidDate(date)) {
        setYear(date.getFullYear());
        setMonth(date.getMonth() + 1);
        setDay(date.getDate());
      }
    } else {
      setYear("");
      setMonth("");
      setDay("");
    }
  }, [props.value]);

  return (
    <div className={styles.Wrapper}>
      <input {...props} className={styles.Input} />

      <Select
        fullWidth
        name="year"
        value={year}
        onChange={(e) => setDate(e.target.value, month, day)}
      >
        <option value="" disabled hidden>
          Year
        </option>
        {years.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </Select>

      <Select
        fullWidth
        className={styles.MonthSelect}
        name="month"
        value={month}
        onChange={(e) => setDate(year, e.target.value, day)}
      >
        <option value="" disabled hidden>
          Month
        </option>
        {months.map((v) => (
          <option key={v} value={v}>
            {monthNames[v - 1]}
          </option>
        ))}
      </Select>

      <Select
        fullWidth
        name="day"
        value={day}
        onChange={(e) => setDate(year, month, e.target.value)}
      >
        <option value="" disabled hidden>
          Day
        </option>
        {days.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </Select>
    </div>
  );
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function zeroFill(value) {
  return value < 10 ? `0${value}` : value;
}

function generateValues(min, max) {
  const array = [];
  let i = min - 1;
  const d = max;
  while (i < d) array[i++] = i;

  return array;
}
