import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Select from "Common/Select";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";

import ApplicationsStyles from "../../Applications.module.css";
import { ApplicationType } from "Core/models/Application.model";
// import BeneficiaryInfoStyles from "./BeneficiaryInfo.module.css";

const BeneficiaryInfo = ({ match, data, applicationType, save, back }) => {
  const beneficiaryIndex = Number(match.params.beneficiaryIndex);

  const BeneficiaryInfoSchema = Yup.object().shape({
    beneficiary_receiver: Yup.string().required("This field is required"),
    full_name: Yup.string()
      .min(2, "This name is too short")
      .max(50, "This name is too long")
      .required("This field is required"),
    relation: Yup.string().when("beneficiary_receiver", {
      is: "a person",
      then: Yup.string().required("This field is required")
    }),
    percent_allocated: Yup.number().required("This field is required"),
    is_minor: Yup.boolean().when("beneficiary_receiver", {
      is: "a person",
      then: Yup.boolean().required("This field is required")
    }),

    trustee_full_name: Yup.string().when("is_minor", {
      is: true,
      then: Yup.string().required("This field is required")
    }),
    trustee_relation: Yup.string().when("is_minor", {
      is: true,
      then: Yup.string().required("This field is required")
    })
  });

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Beneficiary Info</StylizedHeading>

      <Formik
        initialValues={{
          beneficiary_receiver:
            data[beneficiaryIndex]?.beneficiary_receiver ||
            applicationType === ApplicationType.BUSINESS
              ? "company"
              : "a person",
          full_name: data[beneficiaryIndex]?.full_name || "",
          relation: data[beneficiaryIndex]?.relation || "",
          percent_allocated:
            data[beneficiaryIndex]?.percent_allocated ||
            100 - data.reduce((sum, b) => sum + +b.percent_allocated, 0),
          is_minor:
            typeof data[beneficiaryIndex]?.is_minor === "boolean"
              ? data[beneficiaryIndex]?.is_minor
              : "",

          trustee_full_name: data[beneficiaryIndex]?.trustee_full_name || "",
          trustee_relation: data[beneficiaryIndex]?.trustee_relation || ""
        }}
        validateOnMount
        validationSchema={BeneficiaryInfoSchema}
      >
        {({ values, isValid, errors, setFieldValue }) => (
          <Form className={ApplicationsStyles.Form}>
            {applicationType === ApplicationType.BUSINESS && (
              <div className={ApplicationsStyles.FormGroup}>
                <Label className={ApplicationsStyles.Label}>
                  Who will be the beneficiary of the insurance payout?
                </Label>
                <Field as={Select} name="beneficiary_receiver">
                  <option value="company">My company</option>
                  <option value="a person">A person</option>
                  <option value="unsure">Unsure</option>
                </Field>
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="beneficiary_receiver"
                />
              </div>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Full Name</Label>
              <Field as={Input} name="full_name" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="full_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Percent Allocated
              </Label>
              <Field as={Input} name="percent_allocated" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="percent_allocated"
              />
            </div>

            {values.beneficiary_receiver === "a person" && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>Relation</Label>
                  <Field as={Input} name="relation" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="relation"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <div className={ApplicationsStyles.Label}>
                    <Label className={ApplicationsStyles.Label}>
                      Is the beneficiary a minor?
                    </Label>
                    <Tooltip message=" If the beneficiary is a minor, you will need to assign a trustee. A trustee will hold the insurance payout in trust until the child turns 18.">
                      <div className={ApplicationsStyles.InfoIcon}>i</div>
                    </Tooltip>
                  </div>
                  <Field
                    as={Select}
                    name="is_minor"
                    onChange={(event) =>
                      setFieldValue(
                        "is_minor",
                        JSON.parse(event.target.value),
                        true
                      )
                    }
                  >
                    <option></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="is_minor"
                  />
                </div>

                {values.is_minor && (
                  <>
                    <div className={ApplicationsStyles.FormGroup}>
                      <Label className={ApplicationsStyles.Label}>
                        Trustee Full Name
                      </Label>
                      <Field as={Input} name="trustee_full_name" />
                      <ErrorMessage
                        component="div"
                        className={ApplicationsStyles.Error}
                        name="trustee_full_name"
                      />
                    </div>

                    <div className={ApplicationsStyles.FormGroup}>
                      <Label className={ApplicationsStyles.Label}>
                        Trustee Relation
                      </Label>
                      <Field as={Input} name="trustee_relation" />
                      <ErrorMessage
                        component="div"
                        className={ApplicationsStyles.Error}
                        name="trustee_relation"
                      />
                    </div>
                  </>
                )}
              </>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                className={ApplicationsStyles.PreviousButton}
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  back();
                }}
              >
                Cancel
              </Button>

              <Button
                className={ApplicationsStyles.NextButton}
                onClick={(event) => {
                  event.preventDefault();
                  const updatedData = [...data];
                  if (!data[beneficiaryIndex]) {
                    updatedData.push(values);
                  } else {
                    updatedData.splice(beneficiaryIndex, 1, values);
                  }
                  save(updatedData);
                  back();
                }}
                disabled={!isValid}
              >
                Save
              </Button>
            </div>

            <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{JSON.stringify(isValid, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BeneficiaryInfo;
