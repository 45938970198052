import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Select from "Common/Select";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";
// import OtherPolicyInfoStyles from "./OtherPolicyInfo.module.css";

const OtherPolicyInfo = ({ match, data, save, back }) => {
  const otherPolicyIndex = Number(match.params.otherPolicyIndex);

  const OtherPolicyInfoSchema = Yup.object().shape({
    company: Yup.string()
      .min(2, "This name is too short")
      .max(50, "This name is too long")
      .required("This field is required"),
    type: Yup.string().required("This field is required"),
    amount: Yup.number().required("This field is required"),
    issued: Yup.string().required("This field is required"),
    issue_year: Yup.string().when("issued", {
      is: "Yes",
      then: Yup.string().required("This field is required")
    }),
    replace_policy: Yup.string().when("issued", {
      is: "Yes",
      then: Yup.string().required("This field is required")
    }),
    business_or_personal: Yup.string().required("This field is required")
  });

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Other Policy Info</StylizedHeading>

      <Formik
        initialValues={{
          company: data[otherPolicyIndex]?.company || "",
          type: data[otherPolicyIndex]?.type || "",
          issued: data[otherPolicyIndex]?.issued || "",
          issue_year: data[otherPolicyIndex]?.issue_year || "",
          amount: data[otherPolicyIndex]?.amount || "",
          business_or_personal:
            data[otherPolicyIndex]?.business_or_personal || "",
          replace_policy: data[otherPolicyIndex]?.replace_policy || ""
        }}
        validateOnMount
        validationSchema={OtherPolicyInfoSchema}
        onSubmit={(values) => {
          const updatedData = [...data];
          if (!data[otherPolicyIndex]) {
            updatedData.push(values);
          } else {
            updatedData.splice(otherPolicyIndex, 1, values);
          }
          save(updatedData);
          back();
        }}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Policy Provider
              </Label>
              <Field as={Input} name="company" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="company"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Policy Type</Label>
              <Field as={Select} name="type">
                <option></option>
                <option value="Life Insurance">Life Insurance</option>
                <option value="Critical Illness Insurance">
                  Critical Illness Insurance
                </option>
                <option value="Disability Insurance">
                  Disability Insurance
                </option>
                <option value="Long-term Care Insurance">
                  Long-term Care Insurance
                </option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="type"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Policy Amount</Label>
              <NumberFormat
                customInput={Input}
                name="amount"
                defaultValue={0}
                value={values.amount}
                onValueChange={(event) =>
                  setFieldValue("amount", event.value, false)
                }
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="amount"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Has the policy been issued in the last 12 months?
              </Label>
              <Field as={Select} name="issued">
                <option></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="issued"
              />
            </div>

            {values.issued === "Yes" && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Issue/Application year
                  </Label>
                  <NumberFormat
                    customInput={Input}
                    name="issue_year"
                    defaultValue={0}
                    value={values.issue_year}
                    onValueChange={(event) =>
                      setFieldValue("issue_year", event.value, false)
                    }
                    maxLength={4}
                    allowNegative={false}
                    thousandSeparator={false}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="issue_year"
                  />
                </div>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Is this application intended to replace this policy?
                  </Label>
                  <Field as={Select} name="replace_policy">
                    <option></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="replace_policy"
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Is this a business or personal policy?
              </Label>
              <Field as={Select} name="business_or_personal">
                <option></option>
                <option value="business">Business</option>
                <option value="personal">Personal</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="business_or_personal"
              />
            </div>

            {values.is_minor && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Trustee Full Name
                  </Label>
                  <Field as={Input} name="trustee_full_name" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="trustee_full_name"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Trustee Relation
                  </Label>
                  <Field as={Input} name="trustee_relation" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="trustee_relation"
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  back();
                }}
              >
                Cancel
              </Button>

              <Button type="submit" className={ApplicationsStyles.NextButton}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OtherPolicyInfo;
