import * as API from "./API";

export function adminListQuotes(query = {}) {
  return API.get("/admin/quotes", { params: query });
}

export function adminGetQuote(quoteId) {
  return API.get(`/admin/quotes/${quoteId}`);
}

export function adminCreateQuote(quote) {
  return API.post(`/admin/quotes`, quote);
}

export function adminUpdateQuote(quoteId, quote) {
  return API.put(`/admin/quotes/${quoteId}`, quote);
}

export function adminDeleteQuote(quoteId) {
  return API.del(`/admin/quotes/${quoteId}`);
}
