import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import History from "Core/History";
import { DependentLength, SavingsLength } from "Core/models/RiskProfile.model";
import { InsuranceProducts } from "Core/models/Product.model";
import StylizedHeading from "Common/StylizedHeading";
import { saveRiskProfile } from "store/actions/RiskProfile.actions";
import { selectCurrentUser } from "store/selectors/Auth.selectors";

import StepCounter from "./StepCounter";
import PersonalInfo from "./PersonalInfo";
import DemographicInfo from "./DemographicInfo";
import SmokeInfo from "./SmokeInfo";
import BusinessInfo from "./BusinessInfo";
import InsuranceInfo from "./InsuranceInfo";
import DependentsInfo from "./DependentsInfo";
import FinanceInfo from "./FinanceInfo";

import styles from "./RiskProfile.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectRiskProfile } from "store/selectors/RiskProfile.selectors";
import { generateRiskProfile } from "Core/services/RiskProfile.service";

const defaultRiskProfile = {
  personal_info: {
    first_name: "",
    last_name: "",
    email: ""
  },
  demographic_info: {
    birthday: "",
    location: "",
    gender: ""
  },
  insurance_info: {
    [InsuranceProducts.LIFE_INSURANCE]: false,
    [InsuranceProducts.CRITICAL_ILLNESS_INSURANCE]: false,
    [InsuranceProducts.DISABILITY_INSURANCE]: false,
    [InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE]: false,
    [InsuranceProducts.EXTENDED_HEALTH_INSURANCE]: false,
    [InsuranceProducts.HEALTH_SPENDING_ACCOUNT]: false,
    [InsuranceProducts.UNKNOWN]: false,
    life_insurance_coverage: 0,
    disability_insurance_coverage: 0
  },
  is_smoker: undefined,
  employment_status: "",
  has_business: undefined,
  dependents_info: {
    has_financial_dependents: undefined,
    financial_dependents_length: DependentLength.LESS_THAN_TEN
  },
  finance_info: {
    has_major_debts: undefined,
    has_savings: undefined,
    monthly_income: 0,
    outstanding_debts: 0,
    savings_length: SavingsLength.DAYS
  }
};

const RiskProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const savedRiskProfile = useSelector(selectRiskProfile);
  const [riskProfile, setRiskProfile] = useState({
    ...defaultRiskProfile,
    personal_info: {
      first_name: user?.first_name || null,
      last_name: user?.last_name || null,
      email: user?.email || null
    },
    demographic_info: {
      birthday: user?.birth_date || null,
      postalCode: user?.postal_code || null,
      location: user?.province || null,
      gender: user?.gender || null
    }
  });
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (savedRiskProfile && savedRiskProfile.data) {
      const updated = {
        ...defaultRiskProfile,
        personal_info: {
          first_name: user?.first_name || null,
          last_name: user?.last_name || null,
          email: user?.email || null
        }
      };

      const {
        // demographic_info
        birthday,
        postal_code,
        location,
        gender,
        // insurance_info
        has_life_insurance,
        has_disability_insurance,
        has_critical_illness_insurance,
        has_personal_accident_insurance,
        has_extended_health_insurance,
        has_health_spending_account,
        life_insurance_coverage,
        disability_insurance_coverage,
        //
        is_smoker,
        employment_status,
        has_business,
        // dependents_info
        has_financial_dependents,
        financial_dependents_length,
        // finance_info
        has_major_debts,
        has_savings,
        monthly_income,
        outstanding_debts,
        savings_length
      } = savedRiskProfile.data;

      // demographic_info
      if (birthday) updated.demographic_info.birthday = birthday;
      if (location) updated.demographic_info.location = location;
      if (postal_code) updated.demographic_info.postal_code = postal_code;
      if (gender) updated.demographic_info.gender = gender;
      // insurance_info
      if (has_life_insurance)
        updated.insurance_info.has_life_insurance = has_life_insurance;
      if (has_disability_insurance)
        updated.insurance_info.has_disability_insurance =
          has_disability_insurance;
      if (has_critical_illness_insurance)
        updated.insurance_info.has_critical_illness_insurance =
          has_critical_illness_insurance;
      if (has_personal_accident_insurance)
        updated.insurance_info.has_personal_accident_insurance =
          has_personal_accident_insurance;
      if (has_extended_health_insurance)
        updated.insurance_info.has_extended_health_insurance =
          has_extended_health_insurance;
      if (has_health_spending_account)
        updated.insurance_info.has_health_spending_account =
          has_health_spending_account;
      if (life_insurance_coverage)
        updated.insurance_info.life_insurance_coverage =
          life_insurance_coverage;
      if (disability_insurance_coverage)
        updated.insurance_info.disability_insurance_coverage =
          disability_insurance_coverage;
      //
      if (is_smoker !== undefined) updated.is_smoker = is_smoker;
      if (employment_status) updated.employment_status = employment_status;
      if (has_business !== undefined) updated.has_business = has_business;
      // dependents_info
      if (has_financial_dependents !== undefined)
        updated.dependents_info.has_financial_dependents =
          has_financial_dependents ? "yes" : "no";
      if (financial_dependents_length)
        updated.dependents_info.financial_dependents_length =
          financial_dependents_length;
      // finance_info
      if (has_major_debts !== undefined)
        updated.finance_info.has_major_debts = has_major_debts ? "yes" : "no";
      if (has_savings !== undefined)
        updated.finance_info.has_savings = has_savings ? "yes" : "no";
      if (monthly_income) updated.finance_info.monthly_income = monthly_income;
      if (outstanding_debts)
        updated.finance_info.outstanding_debts = outstanding_debts;
      if (savings_length) updated.finance_info.savings_length = savings_length;

      setRiskProfile(updated);
    }
  }, [savedRiskProfile, user]);

  const saveInfo = (step, values) => {
    setRiskProfile({ ...riskProfile, [step]: values });
  };

  const completeForm = (riskProfile) => {
    // Prepare data for submission
    const data = {
      ...riskProfile.personal_info,
      ...riskProfile.demographic_info,
      ...riskProfile.dependents_info,
      ...riskProfile.finance_info,

      // Convert yes/no
      has_major_debts: riskProfile.finance_info.has_major_debts === "yes",
      has_savings: riskProfile.finance_info.has_savings === "yes",
      has_financial_dependents:
        riskProfile.dependents_info.has_financial_dependents === "yes",

      employment_status: riskProfile.employment_status,
      has_business: riskProfile.has_business,
      is_smoker: riskProfile.is_smoker,

      // Map booleans
      has_life_insurance:
        riskProfile.insurance_info[InsuranceProducts.LIFE_INSURANCE],
      has_disability_insurance:
        riskProfile.insurance_info[InsuranceProducts.DISABILITY_INSURANCE],
      has_critical_illness_insurance:
        riskProfile.insurance_info[
          InsuranceProducts.CRITICAL_ILLNESS_INSURANCE
        ],
      has_personal_accident_insurance:
        riskProfile.insurance_info[
          InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE
        ],
      has_extended_health_insurance:
        riskProfile.insurance_info[InsuranceProducts.EXTENDED_HEALTH_INSURANCE],
      has_health_spending_account:
        riskProfile.insurance_info[InsuranceProducts.HEALTH_SPENDING_ACCOUNT],

      //Get insurance values:
      life_insurance_coverage:
        riskProfile.insurance_info.life_insurance_coverage,
      disability_insurance_coverage:
        riskProfile.insurance_info.disability_insurance_coverage
    };

    if (user) {
      // If user is logged in, save risk profile
      dispatch(saveRiskProfile(data))
        .then(() => {
          History.push("/risk-profile/complete");
        })
        .catch((e) => console.log(e));
    } else {
      const generatedProfile = generateRiskProfile(data);

      History.push("/risk-profile/complete", {
        riskProfileData: data,
        riskProfileRecommendations: generatedProfile
      });
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1: {
        return (
          <SmokeInfo
            data={riskProfile.is_smoker}
            save={(data) => saveInfo("is_smoker", data)}
            next={() => setCurrentStep(2)}
          />
        );
      }
      case 2: {
        return (
          <BusinessInfo
            data={riskProfile.has_business}
            save={(data) => saveInfo("has_business", data)}
            previous={() => setCurrentStep(1)}
            next={() => setCurrentStep(3)}
          />
        );
      }
      case 3: {
        return (
          <InsuranceInfo
            data={riskProfile.insurance_info}
            save={(data) => saveInfo("insurance_info", data)}
            previous={() => setCurrentStep(2)}
            next={() => setCurrentStep(4)}
          />
        );
      }
      case 4: {
        return (
          <DependentsInfo
            data={riskProfile.dependents_info}
            save={(data) => saveInfo("dependents_info", data)}
            previous={() => setCurrentStep(3)}
            next={() => setCurrentStep(5)}
          />
        );
      }
      case 5: {
        return (
          <FinanceInfo
            data={riskProfile.finance_info}
            save={(data) => saveInfo("finance_info", data)}
            previous={() => setCurrentStep(4)}
            next={() => setCurrentStep(6)}
          />
        );
      }
      case 6: {
        return (
          <DemographicInfo
            data={{
              first_name: riskProfile.personal_info.first_name,
              email: riskProfile.personal_info.email,
              ...riskProfile.demographic_info
            }}
            save={(data) => {
              const nowYear = new Date().getFullYear();
              const birthYear = new Date(data.birthday).getFullYear();
              const age = nowYear - birthYear;

              const { first_name, email, ...demographicInfo } = data;

              saveInfo("demographic_info", { ...demographicInfo, age });
              saveInfo("personal_info", { first_name, email });
            }}
            previous={() => setCurrentStep(5)}
            next={(data) => {
              const { first_name, email, ...demographicInfo } = data;
              completeForm({
                ...riskProfile,
                personal_info: {
                  ...riskProfile.personal_info,
                  first_name,
                  email
                },
                demographic_info: demographicInfo
              });
            }}
          />
        );
      }
      case 7: {
        return (
          <PersonalInfo
            data={riskProfile.personal_info}
            save={(data) => saveInfo("personal_info", data)}
            previous={() => setCurrentStep(6)}
            complete={(data) =>
              completeForm({ ...riskProfile, personal_info: data })
            }
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={styles.Container}>
      <Helmet>
        <title>Risk Profile | Bounc3</title>
      </Helmet>

      <div className="text-center">
        <StylizedHeading position="left">Get your risk profile</StylizedHeading>
      </div>

      <StepCounter maxSteps={6} step={currentStep} />

      {renderStep()}
    </div>
  );
};

export default RiskProfile;
