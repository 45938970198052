import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { InsuranceProductDetails } from "Core/models/Product.model";

import Button from "Common/Button";
import { Card, CardBody } from "Common/Card";
import { ReactComponent as FormIllustration } from "Icons/form-illustration.svg";

import style from "./InsuranceUpsell.module.css";

const InsuranceUpsell = ({ code, level }) => {
  const [product, setProduct] = useState();

  useEffect(() => {
    switch (code) {
      case "LI": {
        setProduct("life-insurance");
        break;
      }
      case "CII": {
        setProduct("critical-illness-insurance");
        break;
      }
      case "DI": {
        setProduct("disability-insurance");
        break;
      }
      default: {
        break;
      }
    }
  }, [code]);

  return (
    <Card className={style.Card}>
      <CardBody className={style.CardBody}>
        {/* <div className={style.Level}>{InsuranceLevelLabels[level]}</div> */}

        <h2>{InsuranceProductDetails[code].title}</h2>

        <p className={classNames(style.Description, "my")}>
          {InsuranceProductDetails[code].description}
        </p>

        <div className="my">
          <Link to={`/quote?product=${product}`}>
            <Button className={style.Button}>Get a quote</Button>
          </Link>
        </div>

        <FormIllustration className={style.Illustration} />
      </CardBody>
    </Card>
  );
};

export default InsuranceUpsell;
