import React from "react";
import classNames from "classnames";

import styles from "./StylizedHeading.module.css";

const StylizedHeading = ({ children, position, className }) => {
  const circleStyle = () => {
    return classNames(styles.Circle, styles[position]);
  };

  return (
    <h2 className={classNames(styles.CircleHeading, className)}>
      <span className={styles.Heading}>
        <span className={circleStyle()}></span>
        {children}
      </span>
    </h2>
  );
};

StylizedHeading.defaultProps = {
  position: "right",
};

export default StylizedHeading;
