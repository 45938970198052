import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import History from "../../Core/History";
import { createQuote } from "../../Core/services/Quote.service";

import {
  createApplication,
  submitApplication,
  saveDraftApplication
} from "../../store/actions/Applications.actions";
import { login, register } from "../../store/actions/Auth.actions";
import { selectCurrentUser } from "../../store/selectors/Auth.selectors";
import { selectApplicationList } from "store/selectors/Applications.selectors";
import { track } from "utils/gtag";
import { trackFintelPixel } from "utils/fintel";

import ProgressBar from "Common/ProgressBar";
import GetStarted from "./GetStarted";
import PersonalInfo from "./PersonalInfo";
import AddressInfo from "./AddressInfo";
import FinancialInfo from "./FinancialInfo";
import BusinessInfo from "./BusinessInfo";
import BusinessFinancialInfo from "./BusinessFinancialInfo";
import BeneficiaryList from "./BeneficiaryList";
import BeneficiaryInfo from "./BeneficiaryInfo";
import OtherPolicyList from "./OtherPolicyList";
import OtherPolicyInfo from "./OtherPolicyInfo";
import BusinessOtherPolicyInfo from "./BusinessOtherPolicyInfo";
import PaymentInfo from "./PaymentInfo";
import NextSteps from "./NextSteps";
import ConfirmAndRegister from "./ConfirmAndRegister";

import ApplicationsStyles from "../Applications.module.css";
import { isPostalCodeValidArea } from "utils/postalCode";
import { QuoteType } from "Core/models/Quote.model";

const LiApplicationLayout = ({ match, location }) => {
  const dispatch = useDispatch();
  const [locationState, setLocationState] = useState({});
  const user = useSelector(selectCurrentUser);
  const applications = useSelector(selectApplicationList);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [product, setProduct] = useState();
  const [currentStep, setCurrentStep] = useState(0);

  const { quote } = locationState;
  // Quote type and application type.
  const quoteType = quote?.quote_type || QuoteType.BUSINESS;

  const totalSteps = 6;

  const analyticsTrackQuoteData = {
    insuranceType: product,
    brand: "Manulife",
    productName: quote?.productName,
    price: quote?.premium_monthly,
    quoteType
  };

  const [FormData, setFormData] = useState({
    provider: "MANU",
    started: false,
    personal_info: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      gender: user?.gender,
      birth_date: user?.birth_date,
      birth_place: user?.birth_place,
      email: user?.email,
      phone: user?.phone,
      language: user?.language,
      citizen_or_permanent_resident: user?.citizen_or_permanent_resident
    },
    address_info: {
      street_address: user?.street_address,
      city: user?.city,
      province: user?.province,
      postal_code: user?.postal_code,
      years_at_address: user?.years_at_address
    },
    business_info: {}, // Business application only.
    financial_info: {},
    business_financial_info: {}, // Business application only.
    beneficiary_info: [],
    other_policy_info: [],
    business_other_policy_info: {}, // Business application only.
    payment_info: {}
  });

  useEffect(() => {
    if (location.state) {
      setLocationState(location.state);
    }
  }, [location]);

  useEffect(() => {
    const { product } = match.params;

    if (product === "life-insurance") {
      setProduct("Life Insurance");
    } else if (product === "critical-illness-insurance") {
      setProduct("Critical Illness Insurance");
    }
  }, [match.params]);

  useEffect(() => {
    const savedApplication = applications?.find((a) => a.product === product);

    if (savedApplication) {
      setFormData(savedApplication.submission_data);
    }
  }, [applications, product]);

  const saveDraft = () => {
    if (!user) {
      dispatch(
        saveDraftApplication(FormData.personal_info.email, product, FormData)
      ).catch((e) => console.log(e));
    }
  };

  const submitFormData = async (data) => {
    const { contact_time, password } = data;
    const { first_name, last_name, email } = FormData.personal_info;
    const { quote, data: quoteFormData } = locationState;

    setFormData({ ...FormData, contact_time });

    setLoading(true);

    try {
      if (!user) {
        try {
          await dispatch(register(email, password, first_name, last_name));
          track({
            event: "insurance_createaccount",
            ...analyticsTrackQuoteData
          });
        } catch (e) {
          if (e.message === "Account with that email already exists.") {
            await dispatch(login(email, password));
          }
        }
      }

      const application = await dispatch(
        createApplication(product, FormData, quoteType)
      );

      if (quote) {
        await createQuote({
          product: product,
          request_data: {
            ...quoteFormData,
            firstName: first_name,
            lastName: last_name,
            email: email
          },
          ...quote
        });
      }

      if (isPostalCodeValidArea(quoteFormData.postalCode)) {
        trackFintelPixel(product, application.id, user?.id);
      }

      await dispatch(submitApplication(application.id));

      History.replace("/dashboard");
    } catch (e) {
      setError(e.message);
    }

    setLoading(false);
  };

  return (
    <div className={ApplicationsStyles.Container}>
      <Helmet>
        <title>{`${quoteType} | ${product} Application | Bounc3`}</title>
      </Helmet>

      {currentStep > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "2rem"
          }}
        >
          <ProgressBar percent={currentStep / totalSteps} />
        </div>
      )}

      <Router history={History}>
        <Switch>
          <Route
            exact
            path={`${match.url}/get-started`}
            render={(props) => {
              setCurrentStep(0);
              return (
                <GetStarted
                  {...props}
                  product={product}
                  applicationType={quoteType}
                  save={(data) => setFormData({ ...FormData, started: data })}
                  next={() => {
                    track({
                      event: "insurance_startapplication",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/personal-info`);
                  }}
                />
              );
            }}
          ></Route>

          {!FormData.started && (
            <Redirect from={`${match.url}`} to={`${match.url}/get-started`} />
          )}

          <Route
            path={`${match.url}/personal-info`}
            render={(props) => {
              setCurrentStep(0);
              return (
                <PersonalInfo
                  {...props}
                  applicationType={quoteType}
                  data={FormData.personal_info}
                  save={(data) =>
                    setFormData({ ...FormData, personal_info: data })
                  }
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/get-started`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/address-info`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/address-info`}
            render={(props) => {
              setCurrentStep(1);
              return (
                <AddressInfo
                  {...props}
                  data={FormData.address_info}
                  save={(data) =>
                    setFormData({ ...FormData, address_info: data })
                  }
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });

                    History.push(`${match.url}/personal-info`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    if (quoteType === QuoteType.BUSINESS) {
                      History.push(`${match.url}/business-info`);
                    } else {
                      History.push(`${match.url}/financial-info`);
                    }
                  }}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/business-info`}
            render={(props) => {
              setCurrentStep(2);
              return (
                <BusinessInfo
                  {...props}
                  data={FormData.business_info}
                  save={(data) =>
                    setFormData({ ...FormData, business_info: data })
                  }
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/address-info`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/business-financial-info`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/financial-info`}
            render={(props) => {
              setCurrentStep(2);
              return (
                <FinancialInfo
                  {...props}
                  data={FormData.financial_info}
                  save={(data) => {
                    setFormData({ ...FormData, financial_info: data });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/address-info`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/beneficiaries`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/business-financial-info`}
            render={(props) => {
              setCurrentStep(2);
              return (
                <BusinessFinancialInfo
                  {...props}
                  data={FormData.business_financial_info}
                  save={(data) =>
                    setFormData({ ...FormData, business_financial_info: data })
                  }
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/business-info`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/beneficiaries`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/beneficiaries`}
            render={(props) => {
              setCurrentStep(3);
              return (
                <BeneficiaryList
                  {...props}
                  applicationType={quoteType}
                  data={FormData.beneficiary_info}
                  save={(data) => {
                    setFormData({ ...FormData, beneficiary_info: data });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    if (quoteType === QuoteType.BUSINESS) {
                      History.push(`${match.url}/business-financial-info`);
                    } else {
                      History.push(`${match.url}/financial-info`);
                    }
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    if (quoteType === QuoteType.BUSINESS) {
                      History.push(`${match.url}/other-business-policies`);
                    } else {
                      History.push(`${match.url}/other-policies`);
                    }
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/beneficiaries/:beneficiaryIndex`}
            render={(props) => {
              setCurrentStep(3);
              return (
                <BeneficiaryInfo
                  {...props}
                  applicationType={quoteType}
                  data={FormData.beneficiary_info}
                  save={(data) => {
                    setFormData({ ...FormData, beneficiary_info: data });
                    saveDraft();
                  }}
                  back={() => History.goBack()}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/other-policies`}
            render={(props) => {
              setCurrentStep(4);
              return (
                <OtherPolicyList
                  {...props}
                  data={FormData.other_policy_info}
                  save={(data) => {
                    setFormData({ ...FormData, other_policy_info: data });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/beneficiaries`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/payment-info`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/other-policies/:otherPolicyIndex`}
            render={(props) => {
              setCurrentStep(4);
              return (
                <OtherPolicyInfo
                  {...props}
                  data={FormData.other_policy_info}
                  save={(data) => {
                    setFormData({ ...FormData, other_policy_info: data });
                    saveDraft();
                  }}
                  back={() => History.goBack()}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/other-business-policies`}
            render={(props) => {
              setCurrentStep(4);
              return (
                <BusinessOtherPolicyInfo
                  {...props}
                  data={FormData.other_policy_info}
                  save={(data) => {
                    setFormData({
                      ...FormData,
                      business_other_policy_info: data
                    });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/beneficiaries`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/payment-info`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/payment-info`}
            render={(props) => {
              setCurrentStep(5);
              return (
                <PaymentInfo
                  {...props}
                  applicationType={quoteType}
                  data={FormData.payment_info}
                  save={(data) => {
                    setFormData({ ...FormData, payment_info: data });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/other-policies`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/next-steps`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/next-steps`}
            render={(props) => {
              setCurrentStep(6);
              return (
                <NextSteps
                  {...props}
                  data={FormData.next_steps}
                  save={(data) => {
                    setFormData({ ...FormData, next_steps: data });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/payment-info`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/done`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/done`}
            render={(props) => (
              <ConfirmAndRegister
                {...props}
                data={FormData.personal_info}
                submit={submitFormData}
                loading={loading}
                error={error}
              />
            )}
          ></Route>
        </Switch>
      </Router>
    </div>
  );
};

export default LiApplicationLayout;
