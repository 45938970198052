import classNames from "classnames";

import styles from "./Textarea.module.css";

export default function Textarea({ className, ...props }) {
  return (
    <textarea
      className={classNames(styles.Textarea, className)}
      rows="4"
      {...props}
    ></textarea>
  );
}
