import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { selectCurrentUser } from "store/selectors/Auth.selectors";
import History from "Core/History";
import RecommendedProduct from "./RecommendedProduct";
import { selectRiskProfile } from "store/selectors/RiskProfile.selectors";
import { getRiskProfile } from "store/actions/RiskProfile.actions";
import * as RiskProfileService from "Core/services/RiskProfile.service";
import { InsuranceLevelLabels } from "Core/models/RiskProfile.model";
import {
  InsuranceProductDetails,
  InsuranceProducts,
} from "Core/models/Product.model";

import styles from "./RiskProfileOverview.module.css";

const RiskProfileOverview = ({ location }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const riskProfile = useSelector(selectRiskProfile);
  const [isLoading, setIsLoading] = useState(true);

  const riskProfileData = riskProfile?.data || location.state?.riskProfileData;
  const recommendations =
    riskProfile?.recommendations || location.state?.riskProfileRecommendations;

  const descriptions = useMemo(
    () =>
      riskProfileData &&
      RiskProfileService.generateTextDescriptions(
        riskProfileData,
        recommendations,
      ),
    [riskProfileData, recommendations],
  );

  useEffect(() => {
    if (!user && !riskProfile && !location.state) {
      History.replace("/risk-profile");
    } else if (user && !riskProfile) {
      dispatch(getRiskProfile())
        .catch((e) => {
          console.log(e);
          History.replace("/risk-profile");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [user, riskProfile, location.state, dispatch]);

  if (isLoading) return null;

  const allRecommendations = Object.keys(recommendations)
    .filter(
      (key) =>
        recommendations[key] &&
        // Temporarily removed
        key !== InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE,
    )
    .sort((a, b) => recommendations[b] - recommendations[a]);

  const topRecommendation = allRecommendations.splice(0, 1)[0];

  return (
    <div className={styles.RiskProfileOverview}>
      <Helmet>
        <title>Risk Profile | Bounc3</title>
      </Helmet>

      <div className={styles.RiskProfileSummary}>
        <div className={styles.RiskProfileDescription}>
          {!user && <h2>Your risk profile</h2>}

          {user && (
            <h2>
              Your risk profile, {user.first_name} {user.last_name}
            </h2>
          )}

          {!Object.keys(allRecommendations).length && (
            <p>
              It looks like you're already fully covered! Please contact us at
              <a href="mailto:info@bounc3.io">info@bounc3.io</a> if you want to
              review your coverage options.
            </p>
          )}
        </div>
      </div>

      {topRecommendation && (
        <RecommendedProduct
          key={topRecommendation}
          productKey={topRecommendation}
          riskProfileData={riskProfileData}
          icon={InsuranceProductDetails[topRecommendation].icon}
          level={InsuranceLevelLabels[recommendations[topRecommendation]]}
          title={InsuranceProductDetails[topRecommendation].title}
          description={InsuranceProductDetails[topRecommendation].description}
          reasons={descriptions[topRecommendation]}
          dark
        />
      )}

      {allRecommendations.length && (
        <>
          <div className={styles.Divider}></div>
          <h2 className="text-center">We also recommend</h2>
        </>
      )}

      {allRecommendations.map((key, index) => (
        <RecommendedProduct
          key={key}
          productKey={key}
          riskProfileData={riskProfileData}
          icon={InsuranceProductDetails[key].icon}
          level={InsuranceLevelLabels[recommendations[key]]}
          title={InsuranceProductDetails[key].title}
          description={InsuranceProductDetails[key].description}
          reasons={descriptions[key]}
          dark={false}
        />
      ))}
    </div>
  );
};

export default RiskProfileOverview;
