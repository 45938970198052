import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Helmet from "react-helmet";
import NumberFormat from "react-number-format";

import History from "Core/History";
import { InsuranceLevelLabels } from "Core/models/RiskProfile.model";
import {
  getIP,
  createQuote,
  requestCrtiticalInsuranceQuote
} from "Core/services/Quote.service";

import { event, track } from "utils/gtag";
import { trackFintelPixel } from "utils/fintel";
import { isPostalCodeValidArea } from "utils/postalCode";

import { selectRiskProfile } from "store/selectors/RiskProfile.selectors";

import useDebounce from "hooks/debounce";

import StylizedHeading from "Common/StylizedHeading";
import P from "Common/P";
import Label from "Common/Label";
import Input from "Common/Input";
import Select from "Common/Select";
import DateSelect from "Common/DateSelect";
import Button from "Common/Button";
import Textarea from "Common/Textarea";
import Tooltip from "Common/Tooltip";

import styles from "./QuoteForm.module.css";
import QuoteItem from "./QuoteItem";
import HelpBanner from "./HelpBanner/HelpBanner";
import Loader from "Common/Loader";
import { QuoteType } from "Core/models/Quote.model";

const defaultCoverageAmount = "1000000";
const crticialIllnessCoverageAmount = "25000";

const Products = {
  LifeInsurance: "Life Insurance",
  CriticalIllness: "Critical Illness Insurance",
  DisabilityInsurance: "Disability Insurance"
};

const validationSchema = Yup.object().shape({
  // Shared
  productName: Yup.string()
    .required("This field is required")
    .oneOf(Object.values(Products)),
  productLevel: Yup.string()
    .required("This field is required")
    .oneOf([
      "Vital",
      "Important",
      "Consider",
      "Check Coverage",
      "Not Recommended"
    ]),

  // Shared
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string(),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  gender: Yup.string().required("This field is required").oneOf(["M", "F"]),
  birthDate: Yup.date().required("This field is required"),
  isSmoker: Yup.boolean().required("This field is required"),
  quoteType: Yup.string(),

  // DI
  coverageType: Yup.string().when("productName", {
    is: Products.DisabilityInsurance,
    then: Yup.string()
      .required("This field is required")
      .oneOf(["injury and illness", "injury only"])
  }),
  occupation: Yup.string().when("productName", {
    is: Products.DisabilityInsurance,
    then: Yup.string().required("This field is required")
  }),
  grossAnnualIncome: Yup.number().when("productName", {
    is: Products.DisabilityInsurance,
    then: Yup.number().required("This field is required")
  }),

  // CII
  purchaseConsiderationPriority: Yup.string().when("productName", {
    is: Products.CriticalIllness,
    then: Yup.string().required("This field is requried")
  }),

  // LI + CII
  postalCode: Yup.string().when("productName", {
    is: (value) =>
      [Products.LifeInsurance, Products.CriticalIllness].includes(value),
    then: Yup.string()
      .matches(
        /[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]\d[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z][ ]?\d[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z]\d/,
        "Invalid postal code"
      )
      .required("This field is required")
  }),
  coverageTerm: Yup.string().when("productName", {
    is: (value) =>
      [Products.LifeInsurance, Products.CriticalIllness].includes(value),
    then: Yup.string()
      .required("This field is required")
      .oneOf([
        "10 years",
        "15 years",
        "20 years",
        "25 years",
        "30 years",
        "35 years",
        "40 years",
        "to age 65"
      ])
  }),
  coverageAmount: Yup.number().when("productName", {
    is: (value) =>
      [Products.LifeInsurance, Products.CriticalIllness].includes(value),
    then: Yup.number().required("This field is required")
  }),

  // CI + DI
  phoneNumber: Yup.string().when("productName", {
    is: (value) =>
      [Products.CriticalIllness, Products.DisabilityInsurance].includes(value),
    then: Yup.string().required("This field is required")
  })
});

export default function QuoteForm() {
  const queryStr = useLocation().search;
  const riskProfile = useSelector(selectRiskProfile);
  const [minQuote, setMinQuote] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(``);
  const [ipAddress, setIPAddress] = useState(null);
  const [quotes, setQuotes] = useState([]);
  const [quotesSearched, setQuotesSearched] = useState(false);
  const [isLoadingQuotes, setIsLoadingQuotes] = useState(false);
  const [quoteRequested, setQuoteRequested] = useState(false);

  const initialValues = useMemo(() => {
    const query = new URLSearchParams(queryStr);
    let productName = "";
    let productLevel = "";
    let coverageAmount = defaultCoverageAmount;

    switch (query.get("product")) {
      case "life-insurance": {
        productName = Products.LifeInsurance;
        productLevel = InsuranceLevelLabels[riskProfile?.recommendations?.LI];
        break;
      }
      case "critical-illness-insurance": {
        productName = Products.CriticalIllness;
        productLevel = InsuranceLevelLabels[riskProfile?.recommendations?.CI];
        coverageAmount = crticialIllnessCoverageAmount;
        break;
      }
      case "disability-insurance": {
        productName = Products.DisabilityInsurance;
        productLevel = InsuranceLevelLabels[riskProfile?.recommendations?.DI];
        break;
      }
      default: {
        break;
      }
    }

    if (!productLevel) {
      productLevel = "Check Coverage";
    }

    return {
      // Shared
      productName,
      productLevel,

      // Shared
      email: riskProfile?.email || ``,
      firstName: riskProfile?.firstName || ``,
      lastName: riskProfile?.lastName || ``,
      gender: riskProfile?.gender || ``,
      birthDate: riskProfile?.birthDate || ``,
      isSmoker: riskProfile?.isSmoker || ``,
      quoteType: riskProfile?.quoteType || QuoteType.PERSONAL,

      // DI
      coverageType: ``,
      occupation: riskProfile?.occupation || ``,
      occupation_description: riskProfile?.occupation_description || ``,
      // industry: riskProfile?.industry || ``,
      // hasRegisteredBusiness: riskProfile?.hasRegisteredBusiness || ``,
      // businessDuration: riskProfile?.businessDuration || ``,
      grossAnnualIncome: riskProfile?.grossAnnualIncome || ``,
      // hadHealthIssues: riskProfile?.hadHealthIssues || ``,

      // CII
      purchaseConsiderationPriority: ``,

      // LI + CII
      coverageTerm: ``,
      coverageAmount,
      postalCode: riskProfile?.postalCode || ``,

      // CI + DI
      phoneNumber: riskProfile?.phoneNumber || ``
    };
  }, [queryStr, riskProfile]);

  const handleReset = () => {
    formik.resetForm(initialValues);
    setQuotesSearched(false);
    setQuoteRequested(false);
    setSubmitted(false);
    setError(false);
  };

  const handleSubmit = async (quote = quotes[0]) => {
    setError(null);

    track({
      event: "fastquote_submit",
      insuranceType: formik.values.productName,
      quoteType: formik.values.quoteType
    });

    // if (formik.values.productName === Products.LifeInsurance && quote) {
    // History.push(`/applications/life-insurance/${quote.provider}`, {
    //   data: formik.values,
    //   quote
    // });
    // } else if (
    if (formik.values.productName === Products.CriticalIllness && quote) {
      History.push(
        `/applications/critical-illness-insurance/${quote.provider}`,
        {
          data: formik.values,
          quote
        }
      );
    } else {
      try {
        const quoteResponse = await createQuote({
          product: formik.values.productName,
          quote_type: formik.values.quoteType,
          request_data: formik.values
        });

        if (isPostalCodeValidArea(formik.values.postalCode)) {
          trackFintelPixel(
            `${formik.values.productName} Quote`,
            quoteResponse.id,
            quoteResponse.id
          );
        }

        setQuoteRequested(true);
        setSubmitted(true);
        setError(false);
      } catch (error) {
        console.error(error);

        setError("Oops! Something went wrong.");
      }
    }
  };

  const sortQuotes = (quotes) => {
    let sortedData = quotes.sort((a, b) => {
      return parseFloat(a.premium_monthly) > parseFloat(b.premium_monthly)
        ? 1
        : -1;
    });

    if (quotes.length > 0) {
      let minQuote = parseFloat(quotes[0].premium_monthly);

      for (let quote of quotes) {
        minQuote = Math.min(minQuote, parseFloat(quote.premium_monthly));
      }

      setMinQuote(minQuote);
    } else {
      setMinQuote(null);
    }

    setQuotes(sortedData);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    isInitialValid: false,
    enableReinitialize: true
  });

  const debouncedFormikValues = useDebounce(formik.values, 500);

  useEffect(() => {
    const quoteRequestPayload = {
      postal_code: debouncedFormikValues.postalCode.toUpperCase(),
      birth_date: debouncedFormikValues.birthDate,
      sex: debouncedFormikValues.gender,
      is_smoker: debouncedFormikValues.isSmoker,
      coverage_amount: debouncedFormikValues.coverageAmount,
      policy_length: "monthly",
      coverage_term: debouncedFormikValues.coverageTerm,
      remote_ip: ipAddress,
      quote_type: debouncedFormikValues.quoteType,
      first_name: debouncedFormikValues.firstName,
      last_name: debouncedFormikValues.lastName,
      email: debouncedFormikValues.email,
      phone_number: debouncedFormikValues.phoneNumber
    };

    if (
      debouncedFormikValues.productName === Products.CriticalIllness &&
      formik.isValid
    ) {
      setQuotesSearched(false);
      setIsLoadingQuotes(true);

      requestCrtiticalInsuranceQuote(quoteRequestPayload)
        .then(sortQuotes)
        .finally(() => {
          setIsLoadingQuotes(false);
          setQuotesSearched(true);
        });
    } else {
      setMinQuote();
    }
  }, [debouncedFormikValues, formik.isValid, ipAddress]);

  useEffect(() => {
    getIP().then(({ IPADDRESS }) => {
      setIPAddress(IPADDRESS);
    });
  }, []);

  useEffect(() => {
    // Track navigation to this page.
    event("fastquote_click");
  }, []);

  return (
    <div className={styles.Container}>
      <Helmet>
        <title>Insurance Quote | Bounc3</title>
      </Helmet>

      <div className={styles.Wrapper}>
        <StylizedHeading>Insurance Quote</StylizedHeading>

        <form className={styles.Form}>
          <div className={styles.FormGroupWrapper}>
            {!submitted && (
              <>
                <div className={styles.FormGroup}>
                  <Label className={styles.Label}>
                    What do you want a quote for? (select a product below)
                  </Label>
                  <Select
                    fullWidth
                    className={styles.Select}
                    name="productName"
                    value={formik.values.productName}
                    onChange={(e) => {
                      let newCoverageAmount = defaultCoverageAmount;

                      if (e.target.value === Products.CriticalIllness) {
                        newCoverageAmount = crticialIllnessCoverageAmount;
                      }

                      formik.setFieldValue("coverageAmount", newCoverageAmount);

                      formik.handleChange(e);

                      track({
                        event: "fastquote_choosetype",
                        insuranceType: e.target.value
                      });
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" disabled hidden></option>
                    {Object.values(Products).map((prod) => (
                      <option value={prod}>{prod}</option>
                    ))}
                  </Select>
                </div>

                {formik.values.productName && (
                  <>
                    {formik.values.productName === Products.LifeInsurance && (
                      <div className={styles.FormGroup}>
                        <Label className={styles.Label}>
                          Who will be the owner of this insurance policy?
                        </Label>
                        <Select
                          fullWidth
                          className={styles.Select}
                          name="quoteType"
                          value={formik.values.quoteType}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option value={QuoteType.PERSONAL}>
                            I will own the insurance policy
                          </option>
                          <option value={QuoteType.BUSINESS}>
                            My business will own the insurance policy
                          </option>
                          <option value="">Unsure</option>
                        </Select>
                      </div>
                    )}

                    <div className={styles.FormGroup}>
                      <Label className={styles.Label}>First Name</Label>
                      <Input
                        className={styles.Input}
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <span className={styles.Error}>
                          {formik.errors.firstName}
                        </span>
                      )}
                    </div>

                    {formik.values.productName === Products.LifeInsurance && (
                      <div className={styles.FormGroup}>
                        <Label className={styles.Label}>Last Name</Label>
                        <Input
                          className={styles.Input}
                          name="lastName"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                          <span className={styles.Error}>
                            {formik.errors.lastName}
                          </span>
                        )}
                      </div>
                    )}

                    <div className={styles.FormGroup}>
                      <Label className={styles.Label}>Email</Label>
                      <Input
                        className={styles.Input}
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <span className={styles.Error}>
                          {formik.errors.email}
                        </span>
                      )}
                    </div>

                    {[
                      Products.CriticalIllness,
                      Products.DisabilityInsurance
                    ].includes(formik.values.productName) && (
                      <div className={styles.FormGroup}>
                        <Label className={styles.Label}>Phone Number</Label>
                        <NumberFormat
                          className={styles.Input}
                          customInput={Input}
                          name="phoneNumber"
                          value={formik.values.phoneNumber}
                          onValueChange={(event) => {
                            formik.setFieldValue(
                              "phoneNumber",
                              event.formattedValue
                            );
                          }}
                          format="+1 (###) ###-####"
                          mask="_"
                          onBlur={formik.handleBlur}
                        />
                        {/* <Input
                          className={styles.Input}
                          name="phoneNumber"
                          value={formik.values.phoneNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        /> */}
                        {formik.touched.phoneNumber &&
                          formik.errors.phoneNumber && (
                            <span className={styles.Error}>
                              {formik.errors.phoneNumber}
                            </span>
                          )}
                      </div>
                    )}

                    <div className={styles.FormGroup}>
                      <Label className={styles.Label}>Gender</Label>
                      <Select
                        fullWidth
                        className={styles.Select}
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="" disabled hidden></option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </Select>
                    </div>

                    <div className={styles.FormGroup}>
                      <Label className={styles.Label}>Date of Birth</Label>
                      <DateSelect
                        className={styles.Select}
                        maxYearCorrection={-16}
                        name="birthDate"
                        value={formik.values.birthDate}
                        onChange={(value) =>
                          formik.setFieldValue("birthDate", value)
                        }
                        onBlur={formik.handleBlur}
                        onReset={() => formik.setFieldValue("birthDate", ``)}
                      />
                    </div>

                    <div className={styles.FormGroup}>
                      <Label className={styles.Label}>Do you smoke?</Label>
                      <Select
                        fullWidth
                        className={styles.Select}
                        name="isSmoker"
                        value={formik.values.isSmoker}
                        onChange={(event) =>
                          formik.setFieldValue(
                            "isSmoker",
                            JSON.parse(event.target.value)
                          )
                        }
                        onBlur={formik.handleBlur}
                      >
                        <option value="" disabled hidden></option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Select>
                    </div>
                  </>
                )}

                {(formik.values.productName === Products.LifeInsurance ||
                  formik.values.productName === Products.CriticalIllness) && (
                  <div className={styles.FormGroup}>
                    <Label className={styles.Label}>Postal Code</Label>
                    <Input
                      className={styles.Input}
                      name="postalCode"
                      value={formik.values.postalCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxLength="7"
                    />
                    {formik.touched.postalCode && formik.errors.postalCode && (
                      <span className={styles.Error}>
                        {formik.errors.postalCode}
                      </span>
                    )}
                  </div>
                )}

                {formik.values.productName === Products.DisabilityInsurance && (
                  <>
                    <div className={styles.FormGroup}>
                      <Label className={styles.Label}>
                        What type of coverage are you looking for?
                      </Label>
                      <Select
                        fullWidth
                        className={styles.Select}
                        name="coverageType"
                        value={formik.values.coverageType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="" disabled hidden></option>
                        <option value="injury and illness">
                          Injury and illness
                        </option>
                        <option value="injury only">Injury only</option>
                      </Select>
                    </div>

                    <div className={styles.FormGroup}>
                      <Label className={styles.Label}>Occupation</Label>
                      <Input
                        className={styles.Input}
                        name="occupation"
                        value={formik.values.occupation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>

                    <div className={styles.FormGroup}>
                      <div className={styles.Label}>
                        <Label className={styles.Label}>
                          Describe the main duties of your occupation
                        </Label>
                        <Tooltip message="Tell us what you do in you day to day job. This will help us match you with the right disability insurance plan.">
                          <div className={styles.InfoIcon}>i</div>
                        </Tooltip>
                      </div>
                      <Textarea
                        rows="5"
                        name="occupation_description"
                        value={formik.values.occupation_description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>

                    <div className={styles.FormGroup}>
                      <div className={styles.Label}>
                        <Label className={styles.Label}>
                          What is your gross annual income?
                        </Label>
                        <Tooltip message="This is the total income from all sources including employment and self-employment income, interest, dividends, capital gains, rental income, and so on. Check your T1 or T4 for this number.">
                          <div className={styles.InfoIcon}>i</div>
                        </Tooltip>
                      </div>
                      <NumberFormat
                        className={styles.Input}
                        customInput={Input}
                        name="grossAnnualIncome"
                        value={formik.values.grossAnnualIncome}
                        onValueChange={(event) => {
                          formik.setFieldValue(
                            "grossAnnualIncome",
                            event.value
                          );
                        }}
                        prefix={"$ "}
                        decimalScale={0}
                        allowNegative={false}
                        thousandSeparator={true}
                        allowLeadingZeros={false}
                        allowEmptyFormatting={true}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </>
                )}

                {formik.values.productName === Products.CriticalIllness && (
                  <div className={styles.FormGroup}>
                    <Label className={styles.Label}>
                      What is most important to you?
                    </Label>
                    <Select
                      fullWidth
                      className={styles.Select}
                      name="purchaseConsiderationPriority"
                      value={formik.values.purchaseConsiderationPriority}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" disabled hidden></option>
                      <option value="comprehensive coverage">
                        Having comprehesive insurance coverage
                      </option>
                      <option value="managing cost">
                        Managing the cost of insurance
                      </option>
                      <option value="both">Both</option>
                      <option value="unsure">Unsure</option>
                    </Select>
                  </div>
                )}

                {formik.values.productName === Products.LifeInsurance && (
                  <>
                    <div className={styles.FormGroup}>
                      <Label className={styles.Label}>Coverage Term</Label>
                      <Select
                        fullWidth
                        className={styles.Select}
                        name="coverageTerm"
                        value={formik.values.coverageTerm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="" disabled hidden></option>
                        <option value="10 years">10 Years</option>
                        {formik.values.quoteType === QuoteType.PERSONAL && (
                          <option value="15 years">15 Years</option>
                        )}
                        <option value="20 years">20 Years</option>
                        {formik.values.quoteType === QuoteType.PERSONAL && (
                          <option value="to age 65">To Age 65</option>
                        )}
                      </Select>
                    </div>

                    <div className={styles.FormGroup}>
                      <Label className={styles.Label}>Coverage Amount: </Label>
                      <P className={styles.P}>
                        $
                        {formik.values.coverageAmount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </P>
                      <input
                        className={styles.Input}
                        name="coverageAmount"
                        type="range"
                        min="100000"
                        max="5000000"
                        step="100000"
                        value={formik.values.coverageAmount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </>
                )}

                {formik.values.productName === Products.CriticalIllness && (
                  <>
                    <div className={styles.FormGroup}>
                      <Label className={styles.Label}>Coverage Term</Label>
                      <Select
                        fullWidth
                        className={styles.Select}
                        name="coverageTerm"
                        value={formik.values.coverageTerm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="" disabled hidden></option>
                        <option value="10 years">10 Years</option>
                        <option value="20 years">20 Years</option>
                        <option value="permanent">Permanent</option>
                      </Select>
                    </div>

                    <div className={styles.FormGroup}>
                      <Label className={styles.Label}>Coverage Amount: </Label>
                      <P className={styles.P}>
                        $
                        {formik.values.coverageAmount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </P>
                      <input
                        className={styles.Input}
                        name="coverageAmount"
                        type="range"
                        min="25000"
                        max="2000000"
                        step="25000"
                        value={formik.values.coverageAmount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          {minQuote && (
            <>
              <div className={styles.InstantQuoteWrapper}>
                <div style={{ textAlign: "right" }}>
                  <Tooltip message="This estimated rate might may change during the insurance application process but once your application is approved, the premium you pay for your insurance will be locked in for the duration of the policy.">
                    <div className={styles.InfoIcon}>i</div>
                  </Tooltip>
                </div>
                <Label>Your estimated rate is</Label>
                <Label className={styles.InstantQuote}>
                  ${minQuote.toFixed(2)} / month
                </Label>
              </div>

              <div className={styles.InstantQuoteList}>
                {quotes.map((quote, index) => (
                  <QuoteItem
                    key={index}
                    company={quote.company}
                    premium={quote.premium_monthly}
                    productName={quote.product_name}
                    productDescription={quote.product_description}
                    logo={quote.logos.medium}
                    onClick={(event) => {
                      event.preventDefault();
                      handleSubmit(quote);
                    }}
                  />
                ))}
              </div>
            </>
          )}

          {isLoadingQuotes && <Loader isLoading={isLoadingQuotes} />}

          {!minQuote && quotesSearched && !quoteRequested && (
            <>
              <p>
                Please get in touch with one of our advisors to discuss your
                insurance options.{" "}
                <a href="mailto:info@bounc3.io">info@bounc3.io</a>
              </p>
            </>
          )}

          {submitted && quoteRequested && (
            <>
              <P>
                Your quote request has been submitted. We will email you a quote
                shortly.
              </P>

              <Button
                onClick={(event) => {
                  event.preventDefault();
                  handleReset();
                }}
              >
                get another quote
              </Button>
            </>
          )}

          {error && <P className={styles.Error}>{error}</P>}

          {!submitted && (
            <div className={styles.ButtonWrapper}>
              {/* <Button
                fullWidth
                className={styles.ResetButton}
                onClick={(event) => {
                  event.preventDefault();
                  handleReset();
                }}
              >
                reset
              </Button> */}

              {!(
                (formik.values.productName === Products.LifeInsurance ||
                  formik.values.productName === Products.CriticalIllness) &&
                minQuote
              ) && (
                <Button
                  className={styles.SubmitButton}
                  onClick={(event) => {
                    event.preventDefault();
                    handleSubmit();
                  }}
                  disabled={!formik.isValid || isLoadingQuotes}
                >
                  get a quote
                </Button>
              )}
            </div>
          )}
        </form>
      </div>
      <HelpBanner className={styles.helpBanner} />
    </div>
  );
}
