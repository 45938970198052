import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import { getRiskProfile } from "store/actions/RiskProfile.actions";
import { listApplications } from "store/actions/Applications.actions";

import { selectCurrentUser } from "store/selectors/Auth.selectors";
import { selectRiskProfile } from "store/selectors/RiskProfile.selectors";
import { selectApplicationList } from "store/selectors/Applications.selectors";

import styles from "./HomeView.module.css";

import Breadcrumbs from "Common/Breadcrumbs";
import StylizedHeading from "Common/StylizedHeading";

import HSAUpsell from "./HSAUpsell";
import RiskProfilePrompt from "./RiskProfilePrompt";
import ApplicationStatus from "./ApplicationStatus";
import InsuranceUpsell from "./InsuranceUpsell";
import {
  InsuranceProducts,
  InsuranceProductDetails
} from "Core/models/Product.model";

const HomeView = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const riskProfile = useSelector(selectRiskProfile);
  const applicationList = useSelector(selectApplicationList);
  const applicationProducts = applicationList.map((a) => a.product);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(async (dispatch) => {
      await dispatch(listApplications());
      await dispatch(getRiskProfile());
    })
      .catch((e) => console.log(e))
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>Home | Bounc3</title>
      </Helmet>

      <Breadcrumbs
        links={[{ name: "Home", path: "/dashboard" }]}
        className={styles.Subnav}
      />

      <StylizedHeading position="right">
        Welcome, {user.first_name}
      </StylizedHeading>

      {!isLoading && (
        <div className="mt">
          {applicationList.map((a, i) => (
            <ApplicationStatus application={a} key={i}></ApplicationStatus>
          ))}

          {!applicationList.some(
            (a) => a.product === "Health Spending Account"
          ) && <HSAUpsell></HSAUpsell>}

          {riskProfile &&
            riskProfile.recommendations &&
            Object.entries(riskProfile.recommendations)
              .filter(
                ([code, level]) =>
                  riskProfile.recommendations[code] &&
                  code !== InsuranceProducts.HEALTH_SPENDING_ACCOUNT &&
                  // Temporarily removed
                  code !== InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE &&
                  !applicationProducts.includes(
                    InsuranceProductDetails[code].title
                  )
              )
              .map(([code, level], i) => {
                return (
                  <InsuranceUpsell
                    code={code}
                    level={level}
                    key={i}
                  ></InsuranceUpsell>
                );
              })}

          {<RiskProfilePrompt data={riskProfile} />}
        </div>
      )}
    </div>
  );
};

export default HomeView;
