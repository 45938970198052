import { useState } from "react";
import NumberFormat from "react-number-format";

import Button from "Common/Button";
import Input from "Common/Input";
import Label from "Common/Label";
import Modal from "Common/Modal";

import styles from "./NetWorthCalculatorModal.module.css";

const NetWorthCalculatorModal = ({ onSubmit, onClose }) => {
  const [netWorthCalc, setNetWorthCalc] = useState({
    savings_and_investments: 0,
    home_value: 0,
    mortgage_balance: 0,
    other_debts: 0,
  });

  const netWorth = (
    Number(netWorthCalc.savings_and_investments) +
    Number(netWorthCalc.home_value) -
    Number(netWorthCalc.mortgage_balance) -
    Number(netWorthCalc.other_debts)
  ).toFixed(0);

  return (
    <Modal>
      <div className={styles.FormGroup}>
        <Label className={styles.Label}>Savings and Investments</Label>
        <NumberFormat
          customInput={Input}
          defaultValue={0}
          value={netWorthCalc.savings_and_investments}
          onValueChange={(event) => {
            setNetWorthCalc({
              ...netWorthCalc,
              savings_and_investments: event.value,
            });
          }}
          prefix={"$ "}
          decimalScale={0}
          allowNegative={false}
          thousandSeparator={true}
          allowLeadingZeros={false}
          allowEmptyFormatting={true}
        />
      </div>

      <div className={styles.FormGroup}>
        <Label className={styles.Label}>Home Value</Label>
        <NumberFormat
          customInput={Input}
          defaultValue={0}
          value={netWorthCalc.home_value}
          onValueChange={(event) => {
            setNetWorthCalc({
              ...netWorthCalc,
              home_value: event.value,
            });
          }}
          prefix={"$ "}
          decimalScale={0}
          allowNegative={false}
          thousandSeparator={true}
          allowLeadingZeros={false}
          allowEmptyFormatting={true}
        />
      </div>

      <div className={styles.FormGroup}>
        <Label className={styles.Label}>Mortgage Balance</Label>
        <NumberFormat
          customInput={Input}
          defaultValue={0}
          value={netWorthCalc.mortgage_balance}
          onValueChange={(event) => {
            setNetWorthCalc({
              ...netWorthCalc,
              mortgage_balance: event.value,
            });
          }}
          prefix={"$ "}
          decimalScale={0}
          allowNegative={false}
          thousandSeparator={true}
          allowLeadingZeros={false}
          allowEmptyFormatting={true}
        />
      </div>

      <div className={styles.FormGroup}>
        <Label className={styles.Label}>Other Debts</Label>
        <NumberFormat
          customInput={Input}
          defaultValue={0}
          value={netWorthCalc.other_debts}
          onValueChange={(event) => {
            setNetWorthCalc({
              ...netWorthCalc,
              other_debts: event.value,
            });
          }}
          prefix={"$ "}
          decimalScale={0}
          allowNegative={false}
          thousandSeparator={true}
          allowLeadingZeros={false}
          allowEmptyFormatting={true}
        />
      </div>

      <div className={styles.NetWorthCalc}>
        <Label className={styles.Label}>Personal Net Worth: </Label>
        <NumberFormat
          customInput={Input}
          defaultValue={0}
          value={netWorth}
          prefix={"$ "}
          decimalScale={0}
          allowNegative={false}
          thousandSeparator={true}
          allowLeadingZeros={false}
          allowEmptyFormatting={true}
          disabled={true}
        />
      </div>

      <div className={styles.ButtonWrapper}>
        <Button
          fullWidth
          className={styles.PreviousButton}
          onClick={(event) => {
            event.preventDefault();
            onClose();
          }}
        >
          Cancel
        </Button>

        <Button
          fullWidth
          className={styles.NextButton}
          onClick={(event) => {
            event.preventDefault();
            onSubmit(netWorth);
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default NetWorthCalculatorModal;
