import React from "react";
import classnames from "classnames";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "../StylizedHeading";
import { Card, CardBody } from "Common/Card";
import P from "../P";
import Tooltip from "../Tooltip";
import Label from "../Label";
import Input from "../Input";
import Select from "../Select";
import DateSelect from "../DateSelect";
import Button from "../Button";

import styles from "./HSAForm.module.css";

const expenseCategories = [
  { id: "Extended Health Care", name: "Extended Health Care" },
  { id: "Dental Care", name: "Dental Care" },
  { id: "Vision Care", name: "Vision Care" },
];

const newEmployeeClass = {
  name: "",
  // claim_start_date: Date.now(),
  max_annual_claim: 0.0,
};

const newEmployee = {
  first_name: "",
  last_name: "",
  email: "",
  is_admin: false,
  is_claimant: false,

  phone: "",
  street_address: "",
  city: "",
  province: "",
  postal_code: "",
  birth_date: "",
  hire_date: "",
  gender: "",
  employee_class: 0,
  bank_transit_number: "",
  bank_institution_number: "",
  bank_account_number: "",

  dependants: [],
};

const newEmployeeDependant = {
  full_name: "",
  relation: "",
  gender: "",
  birth_date: "",
};

const HSAForm = ({ data, save, cancel }) => {
  const HSA = {
    eligible: true,
    personal_info: {
      first_name: "",
      last_name: "",
      email: "",
      province: "",
      birth_date: "",
      gender: "",
      ...((data && data.personal_info) || {}),
    },
    company_info: {
      name: "",
      street_address: "",
      city: "",
      province: "",
      postal_code: "",
      phone: "",
      bank_transit_number: "",
      bank_institution_number: "",
      bank_account_number: "",
      ...((data && data.company_info) || {}),
    },
    has_multiple_classes: undefined,
    employee_class_info:
      (data &&
        data.employee_class_info &&
        data.employee_class_info.map((c) => {
          return {
            ...newEmployeeClass,
            ...c,
          };
        })) ||
      [],
    employee_info:
      (data &&
        data.employee_info &&
        data.employee_info.map((e) => {
          return {
            ...newEmployee,
            ...e,
            dependants:
              (e.dependants &&
                e.dependants.map((d) => {
                  return {
                    ...newEmployeeDependant,
                    ...d,
                  };
                })) ||
              [],
          };
        })) ||
      [],
    program_settings: {
      coverage_ratio: 1.0,
      annual_allocations: 1,
      benefits_expire: false,
      covered_expenses: expenseCategories.map((c) => c.id),
      renewal: "January 1",
      ...((data && data.program_settings) || {}),
    },
  };

  const HSASchema = Yup.object().shape({
    personal_info: Yup.object().shape({
      first_name: Yup.string()
        .min(2, "This name is too short")
        .max(50, "This name is too long")
        .required("This field is required"),
      last_name: Yup.string()
        .min(2, "This name is too short")
        .max(50, "This name is too long")
        .required("This field is required"),
      email: Yup.string()
        .email("Please enter a valid email")
        .required("This field is required"),
      province: Yup.string().required("This field is required"),
      birth_date: Yup.date().required("This field is required"),
      gender: Yup.string().required("This field is required"),
    }),
    company_info: Yup.object().shape({
      name: Yup.string()
        .min(2, "This name is too short")
        .max(50, "This name is too long")
        .required("This field is required"),
      street_address: Yup.string().required("This field is required"),
      city: Yup.string().required("This field is required"),
      province: Yup.string().required("This field is required"),
      postal_code: Yup.string().required("This field is required"),
      phone: Yup.string()
        .matches(/^[0-9]+$/, "Must contain only numbers")
        .min(10, "Must be exactly 10 digits")
        .max(10, "Must be exactly 10 digits")
        .required("This field is required"),
      bank_transit_number: Yup.string()
        .matches(/^[0-9]+$/, "Must contain only numbers")
        .min(5, "Must be exactly 5 digits")
        .max(5, "Must be exactly 5 digits")
        .required("This field is required"),
      bank_institution_number: Yup.string()
        .matches(/^[0-9]+$/, "Must contain only numbers")
        .min(3, "Must be exactly 3 digits")
        .max(3, "Must be exactly 3 digits")
        .required("This field is required"),
      bank_account_number: Yup.string()
        .matches(/^[0-9]+$/, "Must contain only numbers")
        .min(7, "Must be at least 7 digits")
        .max(12, "Must be at most 12 digits")
        .required("This field is required"),
    }),
    employee_class_info: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .min(2, "This name is too short")
          .max(50, "This name is too long")
          .required("This field is required"),
        // .notOneOf(
        //   HSA.employee_class_info.map((c) => c.name),
        //   "This name is already being used"
        // ),
        // claim_start_date: Yup.date().required("This field is required"),
        max_annual_claim: Yup.number().required("This field is required"),
      })
    ),
    employee_info: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string()
          .min(2, "This name is too short")
          .max(50, "This name is too long")
          .required("This field is required"),
        last_name: Yup.string()
          .min(2, "This name is too short")
          .max(50, "This name is too long")
          .required("This field is required"),
        email: Yup.string()
          .email("Please enter a valid email")
          .required("This field is required"),
        phone: Yup.string().required("This field is required"),
        street_address: Yup.string().required("This field is required"),
        city: Yup.string().required("This field is required"),
        province: Yup.string().required("This field is required"),
        postal_code: Yup.string().required("This field is required"),
        is_admin: Yup.boolean().required("This field is required"),
        is_claimant: Yup.boolean().required("This field is required"),
        gender: Yup.string().when("is_claimant", {
          is: true,
          then: Yup.string().required("This field is required"),
        }),
        birth_date: Yup.date().when("is_claimant", {
          is: true,
          then: Yup.date().required("This field is required"),
        }),
        hire_date: Yup.date().when("is_claimant", {
          is: true,
          then: Yup.date().required("This field is required"),
        }),
        bank_transit_number: Yup.string().when("is_claimant", {
          is: true,
          then: Yup.string()
            .matches(/^[0-9]+$/, "Must contain only numbers")
            .min(5, "Must be exactly 5 digits")
            .max(5, "Must be exactly 5 digits")
            .required("This field is required"),
        }),
        bank_institution_number: Yup.string().when("is_claimant", {
          is: true,
          then: Yup.string()
            .matches(/^[0-9]+$/, "Must contain only numbers")
            .min(3, "Must be exactly 3 digits")
            .max(3, "Must be exactly 3 digits")
            .required("This field is required"),
        }),
        bank_account_number: Yup.string().when("is_claimant", {
          is: true,
          then: Yup.string()
            .matches(/^[0-9]+$/, "Must contain only numbers")
            .min(7, "Must be at least 7 digits")
            .max(12, "Must be at most 12 digits")
            .required("This field is required"),
        }),
        employee_class: Yup.string().when("is_claimant", {
          is: true,
          then: Yup.string().required("This field is required"),
        }),
        dependants: Yup.array().of(
          Yup.object({
            full_name: Yup.string()
              .min(2, "This name is too short")
              .max(100, "This name is too long")
              .required("This field is required"),
            relation: Yup.string().required("This field is required"),
            gender: Yup.string()
              .oneOf(["male", "female", "undisclosed"])
              .required("This field is required"),
            birth_date: Yup.date().required("This field is required"),
          })
        ),
      })
    ),
    program_settings: Yup.object({
      coverage_ratio: Yup.number()
        .oneOf([0.5, 0.8, 1.0])
        .required("This field is required"),
      annual_allocations: Yup.number()
        .oneOf([1, 2, 4, 12])
        .required("This field is required"),
      benefits_expire: Yup.boolean().required("This field is required"),
      covered_expenses: Yup.array()
        .min(1, "You must choose at least one coverage option")
        .of(Yup.string().oneOf(expenseCategories.map((c) => c.id))),
      renewal: Yup.string().required("This field is required"),
    }),
  });

  return (
    <div className={styles.Container}>
      <Formik
        initialValues={HSA}
        validateOnMount
        validationSchema={HSASchema}
        onSubmit={(values) => {
          save(values);
        }}
      >
        {({ values, isValid, setFieldTouched, setFieldValue, handleBlur }) => (
          <Form className={styles.Form}>
            <div className={styles.SectionHeading}>
              <StylizedHeading>Personal Info</StylizedHeading>
            </div>

            <Card>
              <CardBody>
                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>First Name</Label>
                  </div>
                  <Field
                    as={Input}
                    name="personal_info.first_name"
                    placeholder="John"
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="personal_info.first_name" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Last Name</Label>
                  </div>
                  <Field
                    as={Input}
                    name="personal_info.last_name"
                    placeholder="Smith"
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="personal_info.last_name" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Email</Label>
                  </div>
                  <Field
                    as={Input}
                    name="personal_info.email"
                    type="email"
                    placeholder="jsmith@example.ca"
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="personal_info.email" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Province</Label>
                  </div>
                  <Field
                    as={Input}
                    name="personal_info.province"
                    spellCheck="false"
                    placeholder="ON"
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="personal_info.province" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Date of Birth</Label>
                  </div>
                  <Field
                    as={DateSelect}
                    maxYearCorrection={-16}
                    name="personal_info.birth_date"
                    value={values.personal_info.birth_date}
                    onChange={(value) =>
                      setFieldValue("personal_info.birth_date", value, true)
                    }
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="personal_info.birth_date" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Gender</Label>
                  </div>
                  <Field as={Select} name="personal_info.gender" type="text">
                    <option value={null}></option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="undisclosed">Prefer not to say</option>
                  </Field>
                  <div className={styles.Error}>
                    <ErrorMessage name="personal_info.gender" />
                  </div>
                </div>
              </CardBody>
            </Card>

            <div className={styles.SectionHeading}>
              <StylizedHeading>Company Info</StylizedHeading>
            </div>

            <Card>
              <CardBody>
                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Company Name</Label>
                  </div>
                  <Field
                    as={Input}
                    name="company_info.name"
                    spellCheck="false"
                    placeholder="Example Inc."
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="company_info.name" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Company Street Address</Label>
                  </div>
                  <Field
                    as={Input}
                    name="company_info.street_address"
                    spellCheck="false"
                    placeholder="123 Easy Street, Unit 42"
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="company_info.street_address" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Company City</Label>
                  </div>
                  <Field
                    as={Input}
                    name="company_info.city"
                    spellCheck="false"
                    placeholder="Toronto"
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="company_info.city" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Company Province</Label>
                  </div>
                  <Field
                    as={Input}
                    name="company_info.province"
                    spellCheck="false"
                    placeholder="ON"
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="company_info.province" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Company Postal Code</Label>
                  </div>
                  <Field
                    as={Input}
                    name="company_info.postal_code"
                    maxLength="7"
                    spellCheck="false"
                    placeholder="A1A 2B2"
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="company_info.postal_code" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Company Phone Number</Label>
                  </div>
                  <NumberFormat
                    customInput={Input}
                    name="phone"
                    value={values.phone}
                    onValueChange={(event) => {
                      setFieldValue("company_info.phone", event.value, false);
                    }}
                    format="(###) ###-####"
                    decimalScale={0}
                    allowNegative={false}
                    allowEmptyFormatting={false}
                    type="tel"
                    placeholder="(123) 456-7890"
                    onBlur={handleBlur}
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="company_info.phone" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Corporate Bank Transit Number</Label>
                  </div>
                  <Field
                    as={Input}
                    maxLength="5"
                    name="company_info.bank_transit_number"
                    placeholder="12345"
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="company_info.bank_transit_number" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Corporate Bank Institution Number</Label>
                  </div>
                  <Field
                    as={Input}
                    maxLength="3"
                    name="company_info.bank_institution_number"
                    placeholder="123"
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="company_info.bank_institution_number" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Corporate Bank Account Number</Label>
                  </div>
                  <Field
                    as={Input}
                    maxLength="12"
                    name="company_info.bank_account_number"
                    placeholder="1234567"
                  />
                  <div className={styles.Error}>
                    <ErrorMessage name="company_info.bank_account_number" />
                  </div>
                </div>
              </CardBody>
            </Card>

            <div className={styles.SectionHeading}>
              <StylizedHeading>Claim Amounts</StylizedHeading>
            </div>

            <FieldArray
              name="employee_class_info"
              render={(arrayHelpers) => (
                <div>
                  {values.employee_class_info.map((c, i) => {
                    return (
                      <Card key={i}>
                        <CardBody>
                          <div className={styles.Field}>
                            <div className={styles.Label}>
                              <Label>Class Name</Label>
                            </div>
                            <Field
                              as={Input}
                              name={`employee_class_info[${i}].name`}
                            />
                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_class_info[${i}].name`}
                              />
                            </div>
                          </div>
                          {/* <div className={styles.Field}>
                            <div className={styles.Label}>
                              <Label>Claim Start Date</Label>
                            </div>
                            <Field
                              as={Input}
                              name={`employee_class_info[${i}].claim_start_date`}
                              type="date"
                            />
                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_class_info[${i}].claim_start_date`}
                              />
                            </div>
                          </div> */}
                          <div className={styles.Field}>
                            <div className={styles.Label}>
                              <Label>Maximum Annual Claim</Label>
                            </div>
                            <Tooltip
                              position="bottom"
                              message="Annual claim amounts shouldn't excede 15% of an employee's salary."
                            >
                              <NumberFormat
                                customInput={Input}
                                name={`employee_class_info[${i}].max_annual_claim`}
                                defaultValue={0}
                                value={c.max_annual_claim}
                                onValueChange={(event) => {
                                  setFieldValue(
                                    `employee_class_info[${i}].max_annual_claim`,
                                    event.value,
                                    false
                                  );
                                }}
                                prefix={"$ "}
                                decimalScale={0}
                                allowNegative={false}
                                thousandSeparator={true}
                                allowLeadingZeros={false}
                                allowEmptyFormatting={true}
                                onBlur={handleBlur}
                              />
                            </Tooltip>

                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_class_info[${i}].max_annual_claim`}
                              />
                            </div>
                          </div>

                          {i > 0 && (
                            <div className={styles.RemoveItem}>
                              <Button
                                plain
                                type="button"
                                onClick={() => {
                                  const updatedEmployeeInfo = values.employee_info.map(
                                    (employee) => {
                                      if (i === employee.employee_class) {
                                        return {
                                          ...employee,
                                          employee_class: 0,
                                        };
                                      } else if (i < employee.employee_class) {
                                        return {
                                          ...employee,
                                          employee_class:
                                            employee.employee_class - 1,
                                        };
                                      } else {
                                        return employee;
                                      }
                                    }
                                  );

                                  setFieldValue(
                                    `employee_info`,
                                    updatedEmployeeInfo
                                  );

                                  arrayHelpers.remove(i);
                                }}
                              >
                                Remove
                              </Button>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    );
                  })}

                  <Button
                    plain
                    className={styles.AddItem}
                    type="button"
                    onClick={() => arrayHelpers.push(newEmployeeClass)}
                  >
                    + Employee Class
                  </Button>
                </div>
              )}
            />

            <div className={styles.SectionHeading}>
              <StylizedHeading>Employees and Dependants</StylizedHeading>
            </div>

            <FieldArray
              name="employee_info"
              render={(employeeArrayHelpers) => (
                <div>
                  {values.employee_info.map((e, i) => {
                    return (
                      <Card key={i}>
                        <CardBody>
                          <div className={styles.Field}>
                            <div className={styles.Label}>
                              <Label>First Name</Label>
                            </div>
                            <Field
                              as={Input}
                              name={`employee_info[${i}].first_name`}
                            />
                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_info[${i}].first_name`}
                              />
                            </div>
                          </div>

                          <div className={styles.Field}>
                            <div className={styles.Label}>
                              <Label>Last Name</Label>
                            </div>
                            <Field
                              as={Input}
                              name={`employee_info[${i}].last_name`}
                            />
                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_info[${i}].last_name`}
                              />
                            </div>
                          </div>

                          <div className={styles.Field}>
                            <div className={styles.Label}>
                              <Label>Email</Label>
                            </div>
                            <Field
                              as={Input}
                              name={`employee_info[${i}].email`}
                              type="email"
                            />
                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_info[${i}].email`}
                              />
                            </div>
                          </div>

                          <div className={styles.Field}>
                            <div className={styles.Label}>
                              <Label>Phone</Label>
                            </div>
                            <Field>
                              {() => (
                                <NumberFormat
                                  customInput={Input}
                                  name="phone"
                                  value={values.phone}
                                  onValueChange={(event) => {
                                    setFieldValue(
                                      `employee_info[${i}].phone`,
                                      event.value,
                                      false
                                    );
                                  }}
                                  format="(###) ###-####"
                                  decimalScale={0}
                                  allowNegative={false}
                                  allowEmptyFormatting={false}
                                  type="tel"
                                  placeholder="(123) 456-7890"
                                  onBlur={handleBlur}
                                />
                              )}
                            </Field>
                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_info[${i}].phone`}
                              />
                            </div>
                          </div>

                          <div className={styles.Field}>
                            <div className={styles.Label}>
                              <Label>Street Address</Label>
                            </div>
                            <Field
                              as={Input}
                              name={`employee_info[${i}].street_address`}
                              spellCheck="false"
                            />
                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_info[${i}].street_address`}
                              />
                            </div>
                          </div>

                          <div className={styles.Field}>
                            <div className={styles.Label}>
                              <Label>City</Label>
                            </div>
                            <Field
                              as={Input}
                              name={`employee_info[${i}].city`}
                              spellCheck="false"
                            />
                            <div className={styles.Error}>
                              <ErrorMessage name={`employee_info[${i}].city`} />
                            </div>
                          </div>

                          <div className={styles.Field}>
                            <div className={styles.Label}>
                              <Label>Province</Label>
                            </div>
                            <Field
                              as={Input}
                              name={`employee_info[${i}].province`}
                              spellCheck="false"
                            />
                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_info[${i}].province`}
                              />
                            </div>
                          </div>

                          <div className={styles.Field}>
                            <div className={styles.Label}>
                              <Label>Postal Code</Label>
                            </div>
                            <Field
                              as={Input}
                              name={`employee_info[${i}].postal_code`}
                              maxLength="7"
                              spellCheck="false"
                            />
                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_info[${i}].postal_code`}
                              />
                            </div>
                          </div>

                          <div className={styles.CheckboxGroup}>
                            <Tooltip
                              position="bottom"
                              message="This employee will be able to make changes to the Health Spending Account."
                              className={styles.Checkbox}
                            >
                              <Label
                                htmlFor={`employee_info[${i}].is_admin`}
                                className={classnames(
                                  styles.Label,
                                  styles.CheckboxLabel
                                )}
                              >
                                Administrator
                              </Label>
                              <Field
                                id={`employee_info[${i}].is_admin`}
                                name={`employee_info[${i}].is_admin`}
                                type="checkbox"
                                component={Input}
                                checked={values.employee_info[i].is_admin}
                                onChange={() =>
                                  setFieldValue(
                                    `employee_info[${i}].is_admin`,
                                    !values.employee_info[i].is_admin
                                  )
                                }
                              />
                            </Tooltip>
                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_info[${i}].is_admin`}
                              />
                            </div>

                            <Tooltip
                              position="bottom"
                              message="This employee and their eligible dependants will be able to claim health expenses."
                              className={styles.Checkbox}
                            >
                              <Label
                                htmlFor={`employee_info[${i}].is_claimant`}
                                className={classnames(
                                  styles.Label,
                                  styles.CheckboxLabel
                                )}
                              >
                                Claiming
                              </Label>
                              <Field
                                id={`employee_info[${i}].is_claimant`}
                                name={`employee_info[${i}].is_claimant`}
                                type="checkbox"
                                component={Input}
                                checked={values.employee_info[i].is_claimant}
                                onChange={() =>
                                  setFieldValue(
                                    `employee_info[${i}].is_claimant`,
                                    !values.employee_info[i].is_claimant
                                  )
                                }
                              />
                            </Tooltip>
                            <div className={styles.Error}>
                              <ErrorMessage
                                name={`employee_info[${i}].is_claimant`}
                              />
                            </div>
                          </div>

                          {values.employee_info[i].is_claimant && (
                            <div className={styles.ClaimantInfo}>
                              <div className={styles.Field}>
                                <div className={styles.Label}>
                                  <Label>Gender</Label>
                                </div>
                                <Field
                                  as={Select}
                                  name={`employee_info[${i}].gender`}
                                  type="text"
                                >
                                  <option value={null}></option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="undisclosed">
                                    Prefer not to say
                                  </option>
                                </Field>
                                <div className={styles.Error}>
                                  <ErrorMessage
                                    name={`employee_info[${i}].gender`}
                                  />
                                </div>
                              </div>

                              <div className={styles.Field}>
                                <div className={styles.Label}>
                                  <Label>Date of Birth</Label>
                                </div>
                                <Field
                                  as={DateSelect}
                                  maxYearCorrection={-16}
                                  name={`employee_info[${i}].birth_date`}
                                  value={values.employee_info[i].birth_date}
                                  onChange={(value) =>
                                    setFieldValue(
                                      `employee_info[${i}].birth_date`,
                                      value,
                                      true
                                    )
                                  }
                                />
                                <div className={styles.Error}>
                                  <ErrorMessage
                                    name={`employee_info[${i}].birth_date`}
                                  />
                                </div>
                              </div>

                              <div className={styles.Field}>
                                <div className={styles.Label}>
                                  <Label>Date of Hire</Label>
                                </div>
                                <Field
                                  as={DateSelect}
                                  name={`employee_info[${i}].hire_date`}
                                  value={values.employee_info[i].hire_date}
                                  onChange={(value) =>
                                    setFieldValue(
                                      `employee_info[${i}].hire_date`,
                                      value,
                                      true
                                    )
                                  }
                                />
                                <div className={styles.Error}>
                                  <ErrorMessage
                                    name={`employee_info[${i}].hire_date`}
                                  />
                                </div>
                              </div>

                              <div className={styles.Field}>
                                <div className={styles.Label}>
                                  <Label>Employee Class</Label>
                                </div>
                                <Field
                                  as={Select}
                                  name={`employee_info[${i}].employee_class`}
                                  type="text"
                                >
                                  {values.employee_class_info.map((c, i) => {
                                    return (
                                      <option key={i} value={i}>
                                        {c.name} (${c.max_annual_claim} / year)
                                      </option>
                                    );
                                  })}
                                </Field>
                                <div className={styles.Error}>
                                  <ErrorMessage
                                    name={`employee_info[${i}].employee_class`}
                                  />
                                </div>
                              </div>

                              <div className={styles.Field}>
                                <div className={styles.Label}>
                                  <Label>Bank Transit Number</Label>
                                </div>
                                <Field
                                  as={Input}
                                  name={`employee_info[${i}].bank_transit_number`}
                                  type="text"
                                  maxLength="5"
                                />
                                <div className={styles.Error}>
                                  <ErrorMessage
                                    name={`employee_info[${i}].bank_transit_number`}
                                  />
                                </div>
                              </div>

                              <div className={styles.Field}>
                                <div className={styles.Label}>
                                  <Label>Bank Institution Number</Label>
                                </div>
                                <Field
                                  as={Input}
                                  name={`employee_info[${i}].bank_institution_number`}
                                  type="text"
                                  maxLength="3"
                                />
                                <div className={styles.Error}>
                                  <ErrorMessage
                                    name={`employee_info[${i}].bank_institution_number`}
                                  />
                                </div>
                              </div>

                              <div className={styles.Field}>
                                <div className={styles.Label}>
                                  <Label>Bank Account Number</Label>
                                </div>
                                <Field
                                  as={Input}
                                  name={`employee_info[${i}].bank_account_number`}
                                  type="text"
                                  maxLength="12"
                                />
                                <div className={styles.Error}>
                                  <ErrorMessage
                                    name={`employee_info[${i}].bank_account_number`}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          <div className={styles.Label}>
                            <Label>Dependants</Label>
                          </div>

                          <FieldArray
                            name={`employee_info[${i}].dependants`}
                            render={(dependantArrayHelpers) => (
                              <div>
                                {values.employee_info[i].dependants.map(
                                  (d, j) => {
                                    return (
                                      <Card key={j}>
                                        <CardBody>
                                          <div className={styles.Field}>
                                            <div className={styles.Label}>
                                              <Label>Full Name</Label>
                                            </div>
                                            <Field
                                              as={Input}
                                              name={`employee_info[${i}].dependants[${j}].full_name`}
                                            />
                                            <div className={styles.Error}>
                                              <ErrorMessage
                                                name={`employee_info[${i}].dependants[${j}].full_name`}
                                              />
                                            </div>
                                          </div>

                                          <div className={styles.Field}>
                                            <div className={styles.Label}>
                                              <Label>Relation</Label>
                                            </div>
                                            <Field
                                              as={Input}
                                              name={`employee_info[${i}].dependants[${j}].relation`}
                                              type="text"
                                            />
                                            <div className={styles.Error}>
                                              <ErrorMessage
                                                name={`employee_info[${i}].dependants[${j}].relation`}
                                              />
                                            </div>
                                          </div>

                                          <div className={styles.Field}>
                                            <div className={styles.Label}>
                                              <Label>Gender</Label>
                                            </div>
                                            <Field
                                              as={Select}
                                              name={`employee_info[${i}].dependants[${j}].gender`}
                                              type="text"
                                            >
                                              <option value={null}></option>
                                              <option value="male">Male</option>
                                              <option value="female">
                                                Female
                                              </option>
                                              <option value="undisclosed">
                                                Prefer not to say
                                              </option>
                                            </Field>
                                            <div className={styles.Error}>
                                              <ErrorMessage
                                                name={`employee_info[${i}].dependants[${j}].gender`}
                                              />
                                            </div>
                                          </div>

                                          <div className={styles.Field}>
                                            <div className={styles.Label}>
                                              <Label>Date of Birth</Label>
                                            </div>
                                            <Field
                                              as={Input}
                                              name={`employee_info[${i}].dependants[${j}].birth_date`}
                                              type="date"
                                            />
                                            <Field
                                              as={DateSelect}
                                              maxYearCorrection={-16}
                                              name={`employee_info[${i}].dependants[${j}].birth_date`}
                                              value={
                                                values.employee_info[i]
                                                  .dependants[j].birth_date
                                              }
                                              onChange={(value) =>
                                                setFieldValue(
                                                  `employee_info[${i}].dependants[${j}].birth_date`,
                                                  value,
                                                  true
                                                )
                                              }
                                            />
                                            <div className={styles.Error}>
                                              <ErrorMessage
                                                name={`employee_info[${i}].dependants[${j}].birth_date`}
                                              />
                                            </div>
                                          </div>

                                          <div className={styles.RemoveItem}>
                                            <Button
                                              plain
                                              type="button"
                                              onClick={() =>
                                                dependantArrayHelpers.remove(j)
                                              }
                                            >
                                              Remove Dependant
                                            </Button>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    );
                                  }
                                )}

                                <Button
                                  plain
                                  className={styles.AddItem}
                                  type="button"
                                  onClick={() =>
                                    dependantArrayHelpers.push(
                                      newEmployeeDependant
                                    )
                                  }
                                >
                                  + Dependant
                                </Button>
                              </div>
                            )}
                          />
                          <div className={styles.RemoveItem}>
                            <Button
                              plain
                              type="button"
                              onClick={() => employeeArrayHelpers.remove(i)}
                            >
                              Remove Employee
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    );
                  })}

                  <Button
                    plain
                    className={styles.AddItem}
                    type="button"
                    onClick={() => employeeArrayHelpers.push(newEmployee)}
                  >
                    + Employee
                  </Button>
                </div>
              )}
            />

            <div className={styles.SectionHeading}>
              <StylizedHeading>HSA Details</StylizedHeading>
            </div>

            <Card>
              <CardBody>
                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>
                      What percentage of health expenses do you want to cover?
                    </Label>
                  </div>
                  <Field as={Select} name="program_settings.coverage_ratio">
                    <option value={0.5}>50%</option>
                    <option value={0.8}>80%</option>
                    <option value={1.0}>100% (Recommended)</option>
                  </Field>
                  <div className={styles.Error}>
                    <ErrorMessage name="program_settings.coverage_ratio" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>
                      How often will you allocate the funds in the HSA?
                    </Label>
                  </div>
                  <Field as={Select} name="program_settings.annual_allocations">
                    <option value={1}>Annually (Recommended)</option>
                    <option value={2}>Semi-annually</option>
                    <option value={4}>Quarterly</option>
                    <option value={12}>Monthly</option>
                  </Field>
                  <div className={styles.Error}>
                    <ErrorMessage name="program_settings.annual_allocations" />
                  </div>
                </div>

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>
                      Do you want the HSA benefits to expire at the end of the
                      year?
                    </Label>
                  </div>
                  <Field as={Select} name="program_settings.benefits_expire">
                    <option value={false}>No (Recommended)</option>
                    <option value={true}>Yes</option>
                  </Field>
                  <div className={styles.Error}>
                    <ErrorMessage name="program_settings.benefits_expire" />
                  </div>
                </div>

                <FieldArray
                  name="program_settings.covered_expenses"
                  render={(arrayHelpers) => (
                    <div className={styles.CheckboxGroup}>
                      <Label className={styles.Label}>
                        What would you like the HSA to cover?
                      </Label>
                      {expenseCategories.map((category) => (
                        <div className={styles.Checkbox} key={category.id}>
                          <Field
                            name="program_settings.covered_expenses"
                            type="checkbox"
                            component={Input}
                            value={category.id}
                            checked={values.program_settings.covered_expenses.includes(
                              category.id
                            )}
                            onChange={(e) => {
                              if (e.target.checked)
                                arrayHelpers.push(category.id);
                              else {
                                const index = values.program_settings.covered_expenses.indexOf(
                                  category.id
                                );
                                arrayHelpers.remove(index);
                              }
                              setFieldTouched(
                                "program_settings.covered_expenses",
                                true
                              );
                            }}
                          />
                          <P className={styles.P}>{category.name}</P>
                        </div>
                      ))}
                      <div className={styles.Error}>
                        <ErrorMessage name="program_settings.covered_expenses" />
                      </div>
                    </div>
                  )}
                />

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>When would you like your HSA to renew?</Label>
                  </div>
                  <Field as={Select} name="renewal">
                    <option value="January 1">January 1 (Recommended)</option>
                    <option value="February 1">February 1</option>
                    <option value="March 1">March 1</option>
                    <option value="April 1">April 1</option>
                    <option value="May 1">May 1</option>
                    <option value="June 1">June 1</option>
                    <option value="July 1">July 1</option>
                    <option value="August 1">August 1</option>
                    <option value="September 1">September 1</option>
                    <option value="October 1">October 1</option>
                    <option value="November 1">November 1</option>
                    <option value="December 1">December 1</option>
                  </Field>
                  <div className={styles.Error}>
                    <ErrorMessage name="renewal" />
                  </div>
                </div>
              </CardBody>
            </Card>

            <div className={styles.ButtonWrapper}>
              <Button
                fullWidth
                className={styles.CancelButton}
                type="button"
                onClick={cancel}
              >
                Cancel
              </Button>

              <Button
                fullWidth
                className={styles.SaveButton}
                type="submit"
                disabled={!isValid}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default HSAForm;
