import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Select from "Common/Select";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";

import ApplicationsStyles from "../../Applications.module.css";
// import OwnershipInfoStyles from "./OwnershipInfo.module.css";

const OwnershipInfoSchema = Yup.object().shape({
  is_policy_owner: Yup.boolean().required("This field is required"),

  owner_first_name: Yup.string().when("is_policy_owner", {
    is: false,
    then: Yup.string()
      .min(2, "This name is too short")
      .max(50, "This name is too long")
      .required("This field is required"),
  }),
  owner_last_name: Yup.string().when("is_policy_owner", {
    is: false,
    then: Yup.string()
      .min(2, "This name is too short")
      .max(50, "This name is too long")
      .required("This field is required"),
  }),
  owner_email: Yup.string().when("is_policy_owner", {
    is: false,
    then: Yup.string()
      .email("Please enter a valid email")
      .required("This field is required"),
  }),
  owner_relation: Yup.string().when("is_policy_owner", {
    is: false,
    then: Yup.string().required("This field is required"),
  }),
  owner_street_address: Yup.string().when("is_policy_owner", {
    is: false,
    then: Yup.string().required("This field is required"),
  }),
  owner_city: Yup.string().when("is_policy_owner", {
    is: false,
    then: Yup.string().required("This field is required"),
  }),
  owner_province: Yup.string().when("is_policy_owner", {
    is: false,
    then: Yup.string().required("This field is required"),
  }),
  owner_postal_code: Yup.string().when("is_policy_owner", {
    is: false,
    then: Yup.string().required("This field is required"),
  }),

  has_joint_owner: Yup.boolean().required("This field is required"),

  joint_owner_first_name: Yup.string().when("has_joint_owner", {
    is: true,
    then: Yup.string()
      .min(2, "This name is too short")
      .max(50, "This name is too long")
      .required("This field is required"),
  }),
  joint_owner_last_name: Yup.string().when("has_joint_owner", {
    is: true,
    then: Yup.string()
      .min(2, "This name is too short")
      .max(50, "This name is too long")
      .required("This field is required"),
  }),
  joint_owner_email: Yup.string().when("has_joint_owner", {
    is: true,
    then: Yup.string()
      .email("Please enter a valid email")
      .required("This field is required"),
  }),
  joint_owner_relation: Yup.string().when("has_joint_owner", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  joint_owner_street_address: Yup.string().when("has_joint_owner", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  joint_owner_city: Yup.string().when("has_joint_owner", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  joint_owner_province: Yup.string().when("has_joint_owner", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  joint_owner_postal_code: Yup.string().when("has_joint_owner", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
});

const PersonalInfo = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Policy Ownership Info</StylizedHeading>

      <Formik
        initialValues={{
          is_policy_owner:
            typeof data?.is_policy_owner === "boolean"
              ? data?.is_policy_owner
              : "",

          owner_first_name: data?.owner_first_name || "",
          owner_last_name: data?.owner_last_name || "",
          owner_email: data?.owner_email || "",
          owner_relation: data?.owner_relation || "",
          owner_street_address: data?.owner_street_address || "",
          owner_city: data?.owner_city || "",
          owner_province: data?.owner_province || "",
          owner_postal_code: data?.owner_postal_code || "",

          has_joint_owner:
            typeof data?.has_joint_owner === "boolean"
              ? data?.has_joint_owner
              : "",

          joint_owner_first_name: data?.joint_owner_first_name || "",
          joint_owner_last_name: data?.joint_owner_last_name || "",
          joint_owner_email: data?.joint_owner_email || "",
          joint_owner_relation: data?.joint_owner_relation || "",
          joint_owner_street_address: data?.joint_owner_street_address || "",
          joint_owner_city: data?.joint_owner_city || "",
          joint_owner_province: data?.joint_owner_province || "",
          joint_owner_postal_code: data?.joint_owner_postal_code || "",
        }}
        validateOnMount
        validationSchema={OwnershipInfoSchema}
        onSubmit={(values) => {
          save(values);
          next();
        }}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  Will you be the owner of the policy?
                </Label>
                <Tooltip message="The policy owner is the individual purchasing the insurance coverage. The policy owner can cancel or change the policy.">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <Field
                as={Select}
                name="is_policy_owner"
                onChange={(event) =>
                  setFieldValue(
                    "is_policy_owner",
                    JSON.parse(event.target.value),
                  )
                }
              >
                <option value="" disabled hidden></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="is_policy_owner"
              />
            </div>

            {values.is_policy_owner === false && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Owner First Name
                  </Label>
                  <Field as={Input} name="owner_first_name" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="owner_first_name"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Owner Last Name
                  </Label>
                  <Field as={Input} name="owner_last_name" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="owner_last_name"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Owner Email
                  </Label>
                  <Field as={Input} name="owner_email" type="email" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="owner_email"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Owner Relation to the Insured
                  </Label>
                  <Field as={Input} name="owner_relation" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="owner_relation"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Owner Street Address
                  </Label>
                  <Field
                    as={Input}
                    name="owner_street_address"
                    spellCheck="false"
                  />
                  <ErrorMessage
                    component="div"
                    name="owner_street_address"
                    className={ApplicationsStyles.Error}
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>Owner City</Label>
                  <Field as={Input} name="owner_city" spellCheck="false" />
                  <ErrorMessage
                    component="div"
                    name="owner_city"
                    className={ApplicationsStyles.Error}
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Owner Province
                  </Label>
                  <Field as={Input} name="owner_province" spellCheck="false" />
                  <ErrorMessage
                    component="div"
                    name="owner_province"
                    className={ApplicationsStyles.Error}
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Owner Postal Code
                  </Label>
                  <Field
                    as={Input}
                    name="owner_postal_code"
                    maxLength="7"
                    spellCheck="false"
                  />
                  <ErrorMessage
                    component="div"
                    name="owner_postal_code"
                    className={ApplicationsStyles.Error}
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Will this policy be jointly owned?
              </Label>
              <Field
                as={Select}
                name="has_joint_owner"
                onChange={(event) =>
                  setFieldValue(
                    "has_joint_owner",
                    JSON.parse(event.target.value),
                  )
                }
              >
                <option value="" disabled hidden></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="has_joint_owner"
              />
            </div>

            {values.has_joint_owner && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Joint Owner First Name
                  </Label>
                  <Field as={Input} name="joint_owner_first_name" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="joint_owner_first_name"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Joint Owner Last Name
                  </Label>
                  <Field as={Input} name="joint_owner_last_name" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="joint_owner_last_name"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Joint Owner Email
                  </Label>
                  <Field as={Input} name="joint_owner_email" type="email" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="joint_owner_email"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Joint Owner Relation to the Insured
                  </Label>
                  <Field as={Input} name="joint_owner_relation" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="joint_owner_relation"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Joint Owner Street Address
                  </Label>
                  <Field
                    as={Input}
                    name="joint_owner_street_address"
                    spellCheck="false"
                  />
                  <ErrorMessage
                    component="div"
                    name="joint_owner_street_address"
                    className={ApplicationsStyles.Error}
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Joint Owner City
                  </Label>
                  <Field
                    as={Input}
                    name="joint_owner_city"
                    spellCheck="false"
                  />
                  <ErrorMessage
                    component="div"
                    name="joint_owner_city"
                    className={ApplicationsStyles.Error}
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Joint Owner Province
                  </Label>
                  <Field
                    as={Input}
                    name="joint_owner_province"
                    spellCheck="false"
                  />
                  <ErrorMessage
                    component="div"
                    name="joint_owner_province"
                    className={ApplicationsStyles.Error}
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Joint Owner Postal Code
                  </Label>
                  <Field
                    as={Input}
                    name="joint_owner_postal_code"
                    maxLength="7"
                    spellCheck="false"
                  />
                  <ErrorMessage
                    component="div"
                    name="joint_owner_postal_code"
                    className={ApplicationsStyles.Error}
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                type="submit"
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfo;
