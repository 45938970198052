import { Route } from "react-router-dom";

import QuotesList from "./QuotesList";
import QuoteDetails from "./QuoteDetails";

const QuotesRouter = ({ match }) => {
  return (
    <>
      <Route exact path={`${match.url}/`} component={QuotesList} />

      <Route exact path={`${match.url}/:quoteId`} component={QuoteDetails} />
    </>
  );
};

export default QuotesRouter;
