/**
 * Phone number utility functions.
 */

export function formatPhoneNumber(
  numberString = "",
  includeCountryCode = false
) {
  const numberParts = phoneNumberParts(numberString);

  if (!numberParts) {
    return "-";
  }

  const countryCode = numberParts.countryCode
    ? `+${numberParts.countryCode}`
    : "";

  let countryCodeString = "";

  if (includeCountryCode) {
    countryCodeString = countryCode;
  }

  return `${countryCodeString}(${numberParts.areaCode}) ${numberParts.prefix}-${numberParts.lineNumber}`;
}

export function cleanPhoneNumber(numberString = "") {
  return numberString.replace(/\D/g, "");
}

export function phoneNumberParts(numberString = "") {
  const cleaned = cleanPhoneNumber(numberString);

  if (!cleaned || cleaned.length < 10) {
    throw new Error("Invalid phone number");
  }

  const [, countryCode, phoneNumber] =
    cleaned.match(/^(\d{0,3})?(\d{10})$/) || [];

  if (!phoneNumber) {
    throw new Error("Invalid phone number");
  }

  const [, areaCode, prefix, lineNumber] = matchPhoneNumber(phoneNumber);

  return {
    countryCode,
    areaCode,
    prefix,
    lineNumber
  };
}

export function matchPhoneNumber(numberString = "") {
  return numberString.match(/^(\d{3})(\d{3})(\d{4})$/);
}
