/**
 * Risk Profile service for parsing form data and generating a recommendation.
 */
import {
  InsuranceLevels,
  SavingsLength,
  EmploymentStatus,
  DependentLength,
} from "Core/models/RiskProfile.model";
import { InsuranceProducts } from "Core/models/Product.model";
import * as RiskProfile from "Core/models/RiskProfile.model";
import * as API from "./API";

export function getRiskProfile() {
  return API.get("/risk-profile", {}, true);
}

export function saveRiskProfile(data) {
  return API.put("/risk-profile", data, {}, true);
}

export function saveDraftRiskProfile(email, firstName, lastName, data) {
  return API.put("/risk-profile/draft", {
    email,
    first_name: firstName,
    last_name: lastName,
    data,
  });
}

export function newRecommendation() {
  return {
    [InsuranceProducts.LIFE_INSURANCE]: 0,
    [InsuranceProducts.DISABILITY_INSURANCE]: 0,
    [InsuranceProducts.CRITICAL_ILLNESS_INSURANCE]: 0,
    [InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE]: 0,
  };
}

export function generateRiskProfile(data) {
  let recommendation = newRecommendation();

  if (data.has_financial_dependents) {
    recommendation = setRecommendation(
      recommendation,
      InsuranceProducts.LIFE_INSURANCE,
      InsuranceLevels.VITAL,
    );
  } else {
    recommendation = setRecommendation(
      recommendation,
      InsuranceProducts.DISABILITY_INSURANCE,
      InsuranceLevels.VITAL,
    );
  }

  if (data.has_major_debts) {
    recommendation = setRecommendation(
      recommendation,
      InsuranceProducts.LIFE_INSURANCE,
      InsuranceLevels.VITAL,
    );
    recommendation = setRecommendation(
      recommendation,
      InsuranceProducts.CRITICAL_ILLNESS_INSURANCE,
      InsuranceLevels.IMPORTANT,
    );
    recommendation = setRecommendation(
      recommendation,
      InsuranceProducts.DISABILITY_INSURANCE,
      InsuranceLevels.IMPORTANT,
    );
  }

  if (data.has_business) {
    recommendation = setRecommendation(
      recommendation,
      InsuranceProducts.HEALTH_SPENDING_ACCOUNT,
      InsuranceLevels.IMPORTANT,
    );
  }

  if (data.has_savings) {
    if (data.savings_length === SavingsLength.YEARS) {
      recommendation = setRecommendation(
        recommendation,
        InsuranceProducts.DISABILITY_INSURANCE,
        InsuranceLevels.IMPORTANT,
      );
    } else if (data.savings_length === SavingsLength.MONTHS) {
      recommendation = setRecommendation(
        recommendation,
        InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE,
        InsuranceLevels.IMPORTANT,
      );
      recommendation = setRecommendation(
        recommendation,
        InsuranceProducts.CRITICAL_ILLNESS_INSURANCE,
        InsuranceLevels.IMPORTANT,
      );
      recommendation = setRecommendation(
        recommendation,
        InsuranceProducts.DISABILITY_INSURANCE,
        InsuranceLevels.IMPORTANT,
      );
    } else if (data.savings_length === SavingsLength.DAYS) {
      recommendation = setRecommendation(
        recommendation,
        InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE,
        InsuranceLevels.IMPORTANT,
      );
      recommendation = setRecommendation(
        recommendation,
        InsuranceProducts.CRITICAL_ILLNESS_INSURANCE,
        InsuranceLevels.IMPORTANT,
      );
    }
  } else {
    recommendation = setRecommendation(
      recommendation,
      InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE,
      InsuranceLevels.IMPORTANT,
    );
    recommendation = setRecommendation(
      recommendation,
      InsuranceProducts.CRITICAL_ILLNESS_INSURANCE,
      InsuranceLevels.IMPORTANT,
    );
  }

  if (data.has_life_insurance) {
    delete recommendation[InsuranceProducts.LIFE_INSURANCE];
  }
  if (data.has_disability_insurance) {
    delete recommendation[InsuranceProducts.DISABILITY_INSURANCE];
  }
  if (data.has_critical_illness_insurance) {
    delete recommendation[InsuranceProducts.CRITICAL_ILLNESS_INSURANCE];
  }
  if (data.has_personal_accident_insurance) {
    delete recommendation[InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE];
  }
  if (data.has_extended_health_insurance) {
    delete recommendation[InsuranceProducts.HEALTH_SPENDING_ACCOUNT];
  }
  if (data.has_health_spending_account) {
    delete recommendation[InsuranceProducts.HEALTH_SPENDING_ACCOUNT];
  }

  return recommendation;
}

export function generateTextDescriptions(data, recommendation) {
  const descriptions = {
    [InsuranceProducts.LIFE_INSURANCE]: [],
    [InsuranceProducts.DISABILITY_INSURANCE]: [],
    [InsuranceProducts.CRITICAL_ILLNESS_INSURANCE]: [],
    [InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE]: [],
    [InsuranceProducts.HEALTH_SPENDING_ACCOUNT]: [],
  };

  if (recommendation[InsuranceProducts.LIFE_INSURANCE]) {
    if (data.has_financial_dependents) {
      descriptions[InsuranceProducts.LIFE_INSURANCE].push(
        `You should purchase life insurance because you have dependents.`,
      );
    } else if (data.has_major_debts) {
      descriptions[InsuranceProducts.LIFE_INSURANCE].push(
        `You should purchase life insurance because you have debt.`,
      );

      if (data.outstanding_debts > 0) {
        const debtAmount = `$${data.outstanding_debts
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        descriptions[InsuranceProducts.LIFE_INSURANCE].push(
          `We recommend at least ${debtAmount} to cover your debt.`,
        );
      }
    }
  }

  const disabilityStats = [
    { age: 25, risk: 0.58, duration: 1 },
    { age: 30, risk: 0.54, duration: 3 },
    { age: 35, risk: 0.5, duration: 3 },
    { age: 40, risk: 0.45, duration: 3 },
    { age: 45, risk: 0.4, duration: 3 },
    { age: 50, risk: 0.3, duration: 3 },
    { age: 55, risk: 0.25, duration: 3 },
    { age: 60, risk: 0.14, duration: 2 },
  ];

  const matchedDisabilityStats = disabilityStats.find((x) => data.age >= x.age);

  if (recommendation[InsuranceProducts.DISABILITY_INSURANCE]) {
    if (matchedDisabilityStats) {
      descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
        `${(matchedDisabilityStats.risk * 100).toFixed(
          1,
        )}% of individuals your age have an injury or illness that prevents them from working. They are usually out of work for ${
          matchedDisabilityStats.duration
        } years or more.`,
      );
    }
    if (!data.has_savings) {
      descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
        ` You don’t have an emergency fund to replace your income in case of injury or illness.`,
      );
    }
    if (data.has_major_debts) {
      descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
        `With disability coverage, you can continue to pay off your debt in case you are unable to work.`,
      );
    }
  }

  if (
    data.gender === "female" &&
    (recommendation[InsuranceProducts.DISABILITY_INSURANCE] ||
      recommendation[InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE])
  ) {
    descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
      `Women are statistically more likely to be affected by a disability
      than men`,
    );
    descriptions[InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE].push(
      `Women are statistically more likely to be affected by a disability
      than men.`,
    );
  }

  if (
    data.employment_status === EmploymentStatus.FULL_TIME &&
    (recommendation[InsuranceProducts.LIFE_INSURANCE] ||
      recommendation[InsuranceProducts.DISABILITY_INSURANCE])
  ) {
    descriptions[InsuranceProducts.LIFE_INSURANCE].push(
      `Because you are employed full-time, you might
      have some existing insurance coverage as part of your benefits but it might not be enough to
      cover your needs.`,
    );
    descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
      `Because you are employed full-time, you might
      have some existing insurance coverage as part of your benefits but it might not be enough to
      cover your needs.`,
    );
  }

  const dependentLengthMap = {
    [RiskProfile.DependentLength.LESS_THAN_TEN]: 10,
    [RiskProfile.DependentLength.TEN_TO_TWENTY]: 20,
    [RiskProfile.DependentLength.TWENTY_TO_THIRTY]: 30,
    [RiskProfile.DependentLength.MORE_THAN_THIRTY]: 30,
  };

  if (
    data.has_financial_dependents &&
    recommendation[InsuranceProducts.LIFE_INSURANCE]
  ) {
    descriptions[InsuranceProducts.LIFE_INSURANCE].push(
      `You should consider purchasing at least $ ${(
        parseInt(data.monthly_income) *
        12 *
        dependentLengthMap[data.financial_dependents_length]
      )
        .toString()
        .replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ",",
        )} of coverage to supplement your income and support your dependents. You can adjust this level of coverage based on how much you want to spend on insurance each month.`,
    );

    if (data.financial_dependents_length === DependentLength.LESS_THAN_TEN) {
      descriptions[InsuranceProducts.LIFE_INSURANCE].push(
        `You should consider a 10 year term for insurance, given that you are
        supporting your dependents for up to 10 years.`,
      );
    } else if (
      data.financial_dependents_length === DependentLength.TEN_TO_TWENTY
    ) {
      descriptions[InsuranceProducts.LIFE_INSURANCE].push(
        `You should consider a 20 year term for insurance, given that you are
        supporting your dependents for up to 20 years.`,
      );
    } else {
      descriptions[InsuranceProducts.LIFE_INSURANCE].push(
        `You should consider a 30 year term for insurance, given that you are
        supporting your dependents for the foreseeable future.`,
      );
    }
  }

  if (data.has_major_debts) {
    if (recommendation[InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE]) {
      descriptions[InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE].push(
        `Since you have debt, you should consider a product to replace your income and allow you to pay you debt in case you could not work due to an injury or accident.`,
      );
    }
    if (recommendation[InsuranceProducts.CRITICAL_ILLNESS_INSURANCE]) {
      descriptions[InsuranceProducts.CRITICAL_ILLNESS_INSURANCE].push(
        `Since you have debt, you should consider a critical illness product so
        that you don’t fall behind on your payments if you were to get sick.`,
      );
    }
  }

  if (!data.has_savings) {
    descriptions[InsuranceProducts.CRITICAL_ILLNESS_INSURANCE].push(
      `You do not have an emergency fund to cover your expenses in case you were diagnosed with a critical illness and unable to work. A lump sum critical illness payment can help you cover your expenses when you are unable to work.`,
    );
    descriptions[InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE].push(
      `Since you don’t have an emergency fund, you should consider an insurance product to replace a portion of your income in case of an unexpected accident.`,
    );
    descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
      `You need disability insurance because you do not have an emergency fund to help support you financially if you were unable to work.`,
    );
  } else {
    if (data.savings_length === SavingsLength.DAYS) {
      descriptions[InsuranceProducts.CRITICAL_ILLNESS_INSURANCE].push(
        `Your emergency fund will only cover your expenses for a few days or weeks but a critical illness can put you out of work for months, or even years. A lump sum critical illness payment can help you cover your expenses when you are unable to work.`,
      );
      descriptions[InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE].push(
        `Since you don’t have a large emergency fund, you should consider an insurance product to replace a portion of your income, in case of an unexpected accident.`,
      );
      descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
        `You need disability insurance because your emergency fund can only support you for a limited period of time if you were unable to work.`,
      );
    } else if (data.savings_length === SavingsLength.MONTHS) {
      descriptions[InsuranceProducts.CRITICAL_ILLNESS_INSURANCE].push(
        `Your emergency fund will only cover your expenses for a few months but a critical illness can put you out of work for years. A lump sum critical illness payment can help you cover your expenses when you are unable to work.`,
      );
      descriptions[InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE]
        .push(`You don’t want an accident to deplete your savings so consider
        an insurance product to replace a portion of your income, in case of an
        unexpected accident.`);
      descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
        `You need disability insurance because your emergency fund can only support you for a limited period of time if you were unable to work.`,
      );
      if (matchedDisabilityStats) {
        descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
          `This is important as ${(matchedDisabilityStats.risk * 100).toFixed(
            1,
          )}% of ${
            data.age
          } year olds will suffer from a disability during their working life. On average, they are out of work for ${
            matchedDisabilityStats.duration
          } years.`,
        );
        descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
          `Your estimated income lost if you were to become disable would be $${
            parseInt(data.monthly_income) * 12 * matchedDisabilityStats.duration
          }.`,
        );
      }
    } else if (data.savings_length === SavingsLength.YEARS) {
      descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
        `You might want to consider a long-term disability product to replace your income if you were unable to work for several months, or years.`,
      );
      if (matchedDisabilityStats) {
        descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
          `This is important as ${(matchedDisabilityStats.risk * 100).toFixed(
            1,
          )}% of ${
            data.age
          } year olds will suffer from a disability during their working life. On average, they are out of work for ${
            matchedDisabilityStats.duration
          } years.`,
        );
        descriptions[InsuranceProducts.DISABILITY_INSURANCE].push(
          `Your estimated income lost if you were to become disable would be $${
            parseInt(data.monthly_income) * 12 * matchedDisabilityStats.duration
          }.`,
        );
      }
    }
  }

  // Must be last line of life insurance section
  if (recommendation[InsuranceProducts.LIFE_INSURANCE] && data.age >= 59) {
    descriptions[InsuranceProducts.LIFE_INSURANCE].push(
      `After 60, the cost of life insurance drastically increases. But you can still get coverage and there are providers that tailor their products specifically to customers over 60.`,
    );
  }

  if (recommendation[InsuranceProducts.HEALTH_SPENDING_ACCOUNT]) {
    descriptions[InsuranceProducts.HEALTH_SPENDING_ACCOUNT].push(
      `Because you are a business owner, and don’t have other health insurance,
      you should consider an HSA. You can write off your own health expenses, and provide cost-effective benefits to your employees.`,
    );
  }

  if (data.is_smoker && recommendation[InsuranceProducts.LIFE_INSURANCE]) {
    descriptions[InsuranceProducts.LIFE_INSURANCE].push(
      `As a smoker, your cost of life insurance might be higher than a
      non-smoker, but you can still get coverage.`,
    );
  }

  return descriptions;
}

export function setRecommendation(recommendation, product, level) {
  if (recommendation[product] < level) {
    recommendation[product] = level;
  }
  return recommendation;
}
