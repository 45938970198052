import { ApplicationType } from "Core/models/Application.model";
import { getLatestQuote } from "Core/services/Quote.service";
import { useEffect, useState } from "react";

export const useLatestQuote = (
  product,
  applicationType = ApplicationType.PERSONAL
) => {
  const [latestQuote, setLatestQuote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (product) {
      getLatestQuote(product, applicationType)
        .then((data) => {
          setLoading(false);
          setError(null);
          setLatestQuote(data);
        })
        .catch((e) => {
          setLatestQuote(null);
          setLoading(false);
          setError(e.message);
        });
    }
  }, [product, applicationType]);

  return {
    latestQuote,
    loading,
    error
  };
};
