import { combineReducers } from "redux";

import { AUTH_LOGOUT } from "../types/Auth.types";

import AppReducer from "./App.reducer";
import AuthReducer from "./Auth.reducer";
import ApplicationsReducer from "./Applications.reducer";
import AdminUsersReducer from "./AdminUsers.reducer";
import AdminApplicationsReducer from "./AdminApplications.reducer";
import RiskProfileReducer from "./RiskProfile.reducer";

const appRedcuer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  adminUsers: AdminUsersReducer,
  applications: ApplicationsReducer,
  adminApplications: AdminApplicationsReducer,
  riskProfile: RiskProfileReducer,
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    state = {
      app: {
        appReady: true,
      },
    };
  }

  return appRedcuer(state, action);
};

export default rootReducer;
