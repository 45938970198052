import { Route } from "react-router-dom";

import ApplicationList from "./ApplicationList";
import ApplicationEdit from "./ApplicationEdit";
import ApplicationView from "./ApplicationView";

const ApplicationsRouter = ({ match }) => {
  return (
    <>
      <Route exact path={`${match.url}/`} component={ApplicationList} />

      <Route
        exact
        path={`${match.url}/:applicationId`}
        component={ApplicationView}
      />

      <Route
        exact
        path={`${match.url}/:applicationId/edit`}
        component={ApplicationEdit}
      />
    </>
  );
};

export default ApplicationsRouter;
