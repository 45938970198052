import { config } from "Config";

async function fetchGraphQL(query, preview = false) {
  return fetch(
    `https://graphql.contentful.com/content/v1/spaces/${config.REACT_APP_PUBLIC_CONTENTFUL_SPACE_ID}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${config.REACT_APP_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`
      },
      body: JSON.stringify({ query })
    }
  ).then((response) => response.json());
}

function getMenuQuery(name) {
  return fetchGraphQL(
    `query {
    menuCollection(where: { name : "${name}" }, limit: 1) {
      items {
        name,
        menuItemsCollection {
          items {
            ... on MenuItem {
              url
              label
              target
              slug
              submenu {
                menuItemsCollection {
                  items {
                    ... on MenuItem {
                      url
                      label
                      target
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`
  );
}

function buildMenuItems(menuData) {
  return menuData.data.menuCollection.items[0];
}

export async function getMainMenu() {
  try {
    const menus = await getMenuQuery("Main Menu");

    return buildMenuItems(menus);
  } catch (e) {
    return [];
  }
}
