import React from "react";
import { useFormik } from "formik";
import classNames from "classnames";

import { DependentLength } from "Core/models/RiskProfile.model";
import Label from "Common/Label";
import Select from "Common/Select";
import Button from "Common/Button";

import riskStyles from "../RiskProfile.module.css";
import styles from "./DependentsInfo.module.css";

const DependentsInfo = ({ data, save, previous, next }) => {
  const formik = useFormik({
    initialValues: {
      has_financial_dependents: data?.has_financial_dependents || "",
      financial_dependents_length:
        data?.financial_dependents_length || DependentLength.LESS_THAN_TEN,
    },
    validateOnMount: true,
  });

  return (
    <form>
      <div
        className={classNames(riskStyles.FormContainer, styles.FormContainer)}
      >
        <div className={classNames(riskStyles.Field, riskStyles.Fluid)}>
          <div className={riskStyles.Label}>
            <Label>Does anyone depend on you financially?</Label>
          </div>

          <div className={riskStyles.RadioGroup}>
            <div className={riskStyles.RadioInput}>
              <label
                htmlFor="yes"
                className={classNames({
                  [riskStyles.RadioSelected]:
                    formik.values.has_financial_dependents === "yes",
                })}
              >
                Yes
              </label>
              <input
                id="yes"
                type="radio"
                name="has_financial_dependents"
                value="yes"
                onChange={formik.handleChange}
                checked={formik.values.has_financial_dependents === "yes"}
              />
            </div>

            <div className={riskStyles.RadioInput}>
              <label
                htmlFor="no"
                className={classNames({
                  [riskStyles.RadioSelected]:
                    formik.values.has_financial_dependents === "no",
                })}
              >
                No
              </label>
              <input
                id="no"
                type="radio"
                name="has_financial_dependents"
                value="no"
                onChange={formik.handleChange}
                checked={formik.values.has_financial_dependents === "no"}
              />
            </div>
          </div>
        </div>

        <div>
          {formik.values.has_financial_dependents === "yes" && (
            <div className={classNames(riskStyles.Field, riskStyles.Fluid)}>
              <div className={riskStyles.Label}>
                <Label>
                  How much longer do you expect to financially support your
                  dependents?
                </Label>
              </div>
              <Select
                name="financial_dependents_length"
                value={formik.values.financial_dependents_length}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value={DependentLength.LESS_THAN_TEN}>
                  0 - 10 years
                </option>
                <option value={DependentLength.TEN_TO_TWENTY}>
                  10 - 20 years
                </option>
                <option value={DependentLength.TWENTY_TO_THIRTY}>
                  20 - 30 years
                </option>
                <option value={DependentLength.MORE_THAN_THIRTY}>
                  More than 30 years
                </option>
              </Select>
            </div>
          )}
        </div>

        <div className={riskStyles.ButtonWrapper}>
          <Button
            className={riskStyles.PreviousButton}
            onClick={(event) => {
              event.preventDefault();
              save(formik.values);
              previous();
            }}
          >
            Previous
          </Button>

          <Button
            className={riskStyles.NextButton}
            onClick={(event) => {
              event.preventDefault();
              save(formik.values);
              next();
            }}
            disabled={!formik.values.has_financial_dependents}
          >
            Next
          </Button>
        </div>
      </div>
    </form>
  );
};

export default DependentsInfo;
