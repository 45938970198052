import { LocalStorage } from "../../Core/services/Storage";
import * as API from "../../Core/services/API";
import * as AuthService from "../../Core/services/Auth.service";
import * as fromApp from "../types/App.types";
import * as fromAuth from "../types/Auth.types";
import { listApplications } from "store/actions/Applications.actions";
import { getRiskProfile } from "store/actions/RiskProfile.actions";

export function initApp() {
  return async function (dispatch) {
    dispatch({ type: fromApp.INIT_APP });

    // Check local storage for token
    const authToken = LocalStorage.getItem("auth_token");

    if (!authToken) {
      return dispatch({ type: fromApp.APP_READY });
    }

    // Set up API service.
    API.setToken(authToken);

    // Fetch user data.
    try {
      const user = await AuthService.getCurrentUser();

      dispatch({ type: fromAuth.AUTH_LOGIN_SUCCESS, payload: user });
    } catch (e) {
      LocalStorage.removeItem("auth_token");
      return dispatch({ type: fromApp.APP_READY });
    }

    // Fetch user applications and risk profile
    try {
      await Promise.all([
        dispatch(listApplications()),
        dispatch(getRiskProfile()),
      ]);
    } catch (e) {
      console.log(e);
    }

    return dispatch({ type: fromApp.APP_READY });
  };
}
