import React from "react";
import classNames from "classnames";

import styles from "./Container.module.css";

const Container = ({ children, className, ...props }) => {
  return (
    <div className={classNames(styles.Container, className)} {...props}>
      {children}
    </div>
  );
};

export default Container;
