import { useState } from "react";
import classNames from "classnames";

import styles from "./Tooltip.module.css";

export default function Tooltip({
  message,
  position,
  className,
  children,
  ...props
}) {
  const [visible, setVisible] = useState(false);

  const tooltipPositionStyle = () => {
    switch (position) {
      case "top":
        return styles.TooltipTop;
      case "left":
        return styles.TooltipLeft;
      case "right":
        return styles.TooltipRight;
      default:
        return styles.TooltipBottom;
    }
  };

  return (
    <div
      className={classNames(styles.TooltipWrapper, className)}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      {...props}
    >
      {children}
      {visible && (
        <div className={classNames(styles.Tooltip, tooltipPositionStyle())}>
          {message}
        </div>
      )}
    </div>
  );
}
