import { Switch, Route } from "react-router-dom";

import HealthSpendingAccount from "./HealthSpendingAccount";
import RbcDisabilityInsurance from "./RbcDisabilityInsurance";
import RbcLifeInsurance from "./RbcLifeInsurance";
import ManulifeLifeAndIllnessInsurance from "./ManulifeLifeAndIllnessInsurance";

const ApplicationsRouter = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.url}/health-spending-account`}
        component={HealthSpendingAccount}
      ></Route>

      <Route
        path={`${match.url}/disability-insurance/rbc`}
        component={RbcDisabilityInsurance}
      ></Route>

      <Route
        path={`${match.url}/life-insurance/rbc`}
        component={RbcLifeInsurance}
      ></Route>

      <Route
        path={`${match.url}/:product/manulife`}
        component={ManulifeLifeAndIllnessInsurance}
      ></Route>
    </Switch>
  );
};

export default ApplicationsRouter;
