import * as fromRiskProfile from "../types/RiskProfile.types";

const initState = {
  riskProfile: null,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case fromRiskProfile.GET_RISK_PROFILE_FAIL: {
      return { riskProfile: null };
    }
    case fromRiskProfile.GET_RISK_PROFILE_SUCCESS: {
      return { riskProfile: action.payload };
    }
    case fromRiskProfile.SAVE_RISK_PROFILE_SUCCESS: {
      return { riskProfile: action.payload };
    }
    default:
      return state;
  }
}
