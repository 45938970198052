export const adminSelectUserState = (state) => state.adminUsers;

export const adminSelectUserList = (state) =>
  adminSelectUserState(state).adminUserList;

export const adminSelectUser = (state) =>
  adminSelectUserState(state).adminUser;

export const adminSelectUserIsLoading = (state) =>
  adminSelectUserState(state).isLoading;

export const adminSelectUserIsLoaded = (state) =>
  adminSelectUserState(state).isLoaded;
