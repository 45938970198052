export function trackFintelStart() {
  if (window.fcpixel) {
    window.fcpixel.attribution(
      "finteltag",
      30,
      "first",
      "bounc3FC",
      ".bounc3.io"
    );
  }
}

export function trackFintelPixel(productId, orderId, customerId) {
  if (window.fcpixel) {
    window.fcpixel.pxl(
      25269,
      orderId,
      productId,
      customerId,
      0,
      0,
      "",
      "bounc3FC"
    );
  }
}

export function loadFintelAnalytics(callback) {
  const id = "fintelAnalytics";
  const existingScript = document.getElementById(id);

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://app.fintelconnect.com/assets/scripts/fcanalytics.js";
    script.id = id;

    document.body.append(script);

    script.onload = () => {
      if (callback) callback();
    };
  } else if (existingScript && callback) {
    callback();
  }
}
