import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";

import ApplicationsStyles from "../../Applications.module.css";
// import EmployeeInfoStyles from "./EmployeeInfo.module.css";

const EmployeeInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  last_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  phone: Yup.string().required("This field is required"),
  province: Yup.string().required("This field is required"),
  is_admin: Yup.boolean().required("This field is required"),
  is_claimant: Yup.boolean().required("This field is required")
});

const EmployeeInfo = ({ data, save, back }) => {
  const employeeIndex = Number(useParams().employeeIndex);

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <div>
        <StylizedHeading>Employee Info</StylizedHeading>
        <Tooltip message="We collect the personal information of your employees to set up their HSA account.">
          <div className={ApplicationsStyles.InfoIcon}>i</div>
        </Tooltip>
      </div>

      <Formik
        initialValues={{
          first_name: data[employeeIndex]?.first_name || "",
          last_name: data[employeeIndex]?.last_name || "",
          email: data[employeeIndex]?.email || "",
          phone: data[employeeIndex]?.phone || "",
          province: data[employeeIndex]?.province || "",
          is_admin: data[employeeIndex]?.is_admin || false,
          is_claimant: data[employeeIndex]?.is_claimant || false
        }}
        validateOnMount
        validationSchema={EmployeeInfoSchema}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>First Name</Label>
              <Field as={Input} name="first_name" placeholder="John" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="first_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Last Name</Label>
              <Field as={Input} name="last_name" placeholder="Smith" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="last_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Email</Label>
              <Field
                as={Input}
                name="email"
                type="email"
                placeholder="jsmith@example.ca"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="email"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Phone</Label>
              <NumberFormat
                customInput={Input}
                name="phone"
                value={values.phone}
                onValueChange={(event) => {
                  setFieldValue("phone", event.value, false);
                }}
                format="(###) ###-####"
                decimalScale={0}
                allowNegative={false}
                allowEmptyFormatting={false}
                type="tel"
                placeholder="(123) 456-7890"
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="phone"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Province</Label>
              <Field
                as={Input}
                name="province"
                spellCheck="false"
                placeholder="ON"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="province"
              />
            </div>

            <div className={ApplicationsStyles.CheckboxGroup}>
              <Tooltip
                position="right"
                message="This employee will be able to make changes to the Health Spending Account."
                className={ApplicationsStyles.Checkbox}
              >
                <Label
                  htmlFor="is_admin"
                  className={classNames(
                    ApplicationsStyles.Label,
                    ApplicationsStyles.CheckboxLabel
                  )}
                >
                  Administrator
                </Label>
                <Field
                  id="is_admin"
                  name="is_admin"
                  type="checkbox"
                  component={Input}
                  checked={values.is_admin}
                  onChange={() => setFieldValue("is_admin", !values.is_admin)}
                />
              </Tooltip>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="is_admin"
              />

              <Tooltip
                position="right"
                message="This employee and their eligible dependants will be able to claim health expenses."
                className={ApplicationsStyles.Checkbox}
              >
                <Label
                  htmlFor="is_claimant"
                  className={classNames(
                    ApplicationsStyles.Label,
                    ApplicationsStyles.CheckboxLabel
                  )}
                >
                  Claiming
                </Label>
                <Field
                  id="is_claimant"
                  name="is_claimaint"
                  type="checkbox"
                  component={Input}
                  checked={values.is_claimant}
                  onChange={() =>
                    setFieldValue("is_claimant", !values.is_claimant)
                  }
                />
              </Tooltip>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="is_claimant"
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  back();
                }}
              >
                Cancel
              </Button>

              <Button
                className={ApplicationsStyles.NextButton}
                onClick={(event) => {
                  event.preventDefault();
                  const updatedData = [...data];
                  if (!data[employeeIndex]) {
                    updatedData.push(values);
                  } else {
                    updatedData.splice(employeeIndex, 1, values);
                  }
                  save(updatedData);
                  back();
                }}
                disabled={!isValid}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmployeeInfo;
