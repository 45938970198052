import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";

import ApplicationsStyles from "../../Applications.module.css";

const CompanyInfoSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  province: Yup.string().required("This field is required"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits")
    .required("This field is required"),
  bank_transit_number: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits")
    .required("This field is required"),
  bank_institution_number: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(3, "Must be exactly 3 digits")
    .max(3, "Must be exactly 3 digits")
    .required("This field is required"),
  bank_account_number: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(7, "Must be at least 7 digits")
    .max(12, "Must be at most 12 digits")
    .required("This field is required")
});

const CompanyInfo = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <div>
        <StylizedHeading>Company Info</StylizedHeading>
        <Tooltip message="Every HSA account is linked to an incorporated business, which is why we collect information about your business. We need your corporate banking information because HSA claims are withdrawn from your corporate account. You will receive an email notification before the withdrawal is made to ensure that you deposit the necessary funds.">
          <div className={ApplicationsStyles.InfoIcon}>i</div>
        </Tooltip>
      </div>

      <Formik
        initialValues={{
          name: data?.name || "",
          province: data?.province || "",
          phone: data?.phone || "",
          bank_transit_number: data?.bank_transit_number || "",
          bank_institution_number: data?.bank_institution_number || "",
          bank_account_number: data?.bank_account_number || ""
        }}
        onSubmit={(values) => {
          save(values);
          next();
        }}
        validateOnMount
        validationSchema={CompanyInfoSchema}
      >
        {({ values, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Company Name</Label>
              <Field
                as={Input}
                name="name"
                spellCheck="false"
                placeholder="Example Inc."
              />
              <ErrorMessage
                component="div"
                name="name"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Company Province
              </Label>
              <Field
                as={Input}
                name="province"
                spellCheck="false"
                placeholder="ON"
              />
              <ErrorMessage
                component="div"
                name="province"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Company Phone Number
              </Label>
              <NumberFormat
                customInput={Input}
                name="phone"
                value={values.phone}
                onValueChange={(event) => {
                  setFieldValue("phone", event.value, false);
                }}
                format="(###) ###-####"
                decimalScale={0}
                allowNegative={false}
                allowEmptyFormatting={false}
                type="tel"
                placeholder="(123) 456-7890"
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                name="phone"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Corporate Bank Transit Number
              </Label>
              <Field
                as={Input}
                maxLength="5"
                name="bank_transit_number"
                placeholder="12345"
              />
              <ErrorMessage
                component="div"
                name="bank_transit_number"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Corporate Bank Institution Number
              </Label>
              <Field
                as={Input}
                maxLength="3"
                name="bank_institution_number"
                placeholder="123"
              />
              <ErrorMessage
                component="div"
                name="bank_institution_number"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Corporate Bank Account Number
              </Label>
              <Field
                as={Input}
                maxLength="12"
                name="bank_account_number"
                placeholder="1234567"
              />
              <ErrorMessage
                component="div"
                name="bank_account_number"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button type="submit" className={ApplicationsStyles.NextButton}>
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyInfo;
