import * as API from "../../Core/services/API";
import * as AuthService from "../../Core/services/Auth.service";
import { LocalStorage } from "Core/services/Storage";

import * as fromAuth from "../types/Auth.types";
import { event } from "utils/gtag";

export function login(email, password) {
  return async function (dispatch) {
    dispatch({ type: fromAuth.AUTH_LOGIN });

    // Attempt login
    try {
      const { auth_token, user } = await AuthService.login(email, password);

      API.setToken(auth_token);

      return dispatch({
        type: fromAuth.AUTH_LOGIN_SUCCESS,
        payload: user
      });
    } catch (e) {
      dispatch({ type: fromAuth.AUTH_LOGIN_FAIL });
      throw e;
    }
  };
}

export function register(email, password, firstName, lastName) {
  return async function (dispatch) {
    dispatch({ type: fromAuth.AUTH_REGISTER });

    // Attempt register
    try {
      const { auth_token, user } = await AuthService.register(
        email,
        password,
        firstName,
        lastName
      );

      API.setToken(auth_token);

      // Track sign up event.
      event("sign_up", { method: "Email" });

      return dispatch({
        type: fromAuth.AUTH_REGISTER_SUCCESS,
        payload: user
      });
    } catch (e) {
      dispatch({ type: fromAuth.AUTH_REGISTER_FAIL });
      throw e;
    }
  };
}

export function verifyEmail(token) {
  return async function (dispatch) {
    dispatch({ type: fromAuth.AUTH_VERIFY_EMAIL });

    try {
      await AuthService.verifyEmail(token);

      return dispatch({ type: fromAuth.AUTH_VERIFY_EMAIL_SUCCESS });
    } catch (e) {
      dispatch({ type: fromAuth.AUTH_VERIFY_EMAIL_FAIL });
      throw e;
    }
  };
}

export function getCurrentUser() {
  return async function (dispatch) {
    dispatch({ type: fromAuth.GET_CURRENT_USER });

    // Attempt register
    try {
      const user = await AuthService.getCurrentUser();
      dispatch({ type: fromAuth.GET_CURRENT_USER_SUCCESS, payload: user });
    } catch (e) {
      dispatch({ type: fromAuth.GET_CURRENT_USER_FAIL });
      throw e;
    }
  };
}

export function logout() {
  return function (dispatch) {
    LocalStorage.removeItem("auth_token");
    dispatch({ type: fromAuth.AUTH_LOGOUT });
  };
}

export function forgotPassword(email) {
  return async function (dispatch) {
    dispatch({ type: fromAuth.AUTH_FORGOT_PASSWORD });

    try {
      await AuthService.forgotPassword(email);

      return dispatch({ type: fromAuth.AUTH_FORGOT_PASSWORD_SUCCESS });
    } catch (e) {
      dispatch({ type: fromAuth.AUTH_FORGOT_PASSWORD_FAIL });
      throw e;
    }
  };
}

export function validatePasswordResetToken(token) {
  return async function (dispatch) {
    dispatch({ type: fromAuth.VALIDATE_PASSWORD_RESET_TOKEN });

    try {
      await AuthService.validatePasswordResetToken(token);

      return dispatch({ type: fromAuth.VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS });
    } catch (e) {
      dispatch({ type: fromAuth.VALIDATE_PASSWORD_RESET_TOKEN_FAIL });
      throw e;
    }
  };
}

export function confirmPasswordReset(token, password) {
  return async function (dispatch) {
    dispatch({ type: fromAuth.CONFIRM_PASSWORD_RESET });

    try {
      await AuthService.confirmPasswordReset(token, password);

      dispatch({ type: fromAuth.CONFIRM_PASSWORD_RESET_SUCCESS });
    } catch (e) {
      dispatch({ type: fromAuth.CONFIRM_PASSWORD_RESET_FAIL });
      throw e;
    }
  };
}
