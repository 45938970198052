import classNames from "classnames";
import { Link } from "react-router-dom";

import styles from "./Breadcrumbs.module.css";

import Button from "Common/Button";

export default function Breadcrumbs({ links, className, ...props }) {
  const classes = classNames(styles.Button, className);

  return (
    <div className={classes}>
      {links.map((l, i) => {
        return (
          <span key={i}>
            <Link to={l.path}>
              <Button plain>{l.name}</Button>
            </Link>
            {links[i + 1] ? <span> / </span> : null}
          </span>
        );
      })}
    </div>
  );
}
