import classNames from "classnames";

import styles from "./Label.module.css";

export default function Label({ className, children, ...props }) {
  return (
    <label className={classNames(styles.Label, className)} {...props}>
      {children}
    </label>
  );
}
