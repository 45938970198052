/**
 * RiskProfile model and enums.
 */

export const InsuranceLevels = {
  VITAL: 4,
  IMPORTANT: 3,
  CONSIDER: 2,
  CHECK_COVERAGE: 1,
  NOT_RECOMMENDED: 0
};

export const InsuranceLevelLabels = {
  [InsuranceLevels.VITAL]: `Vital`,
  [InsuranceLevels.IMPORTANT]: `Important`,
  [InsuranceLevels.CONSIDER]: `Consider`,
  [InsuranceLevels.CHECK_COVERAGE]: `Check Coverage`
};

export const EmploymentStatus = {
  FULL_TIME: "full_time",
  PART_TIME: "part_time",
  SELF_EMPLOYED: "self_employed",
  STAY_AT_HOME: "stay_at_home",
  RETIRED: "retired",
  UNEMPLOYED: "unemployed",
  STUDENT: "student"
};

export const Gender = {
  MALE: "male",
  FEMALE: "female",
  UNDISCLOSED: "undisclosed"
};

export const DependentLength = {
  LESS_THAN_TEN: "0-10 years",
  TEN_TO_TWENTY: "10-20 years",
  TWENTY_TO_THIRTY: "20-30 years",
  MORE_THAN_THIRTY: "More than 30",
};

export const SavingsLength = {
  DAYS: "days",
  MONTHS: "months",
  YEARS: "years"
};
