import React from "react";
import classNames from "classnames";

import styles from "./QuoteDescriptor.module.css";

export const QuoteDescriptorTypes = {
  LONGER_APPLICATION: "Longer Application",
  FAST_APPROVAL: "Fast Approval",
  NO_MEDICAL_REQUIRED: "No Medical Required"
};

const TypeStyle = {
  [QuoteDescriptorTypes.LONGER_APPLICATION]: null,
  [QuoteDescriptorTypes.FAST_APPROVAL]: styles.Fast,
  [QuoteDescriptorTypes.NO_MEDICAL_REQUIRED]: styles.NoMedical
};

const QuoteDescriptor = ({
  type = QuoteDescriptorTypes.LONGER_APPLICATION
}) => {
  return (
    <div className={classNames(styles.QuoteDescriptor, TypeStyle[type])}>
      {type}
    </div>
  );
};

export default QuoteDescriptor;
