import React, { useMemo, useState } from "react";
import classNames from "classnames";

import Button from "Common/Button";
import { formatCurrency } from "utils/currency";

import styles from "./QuoteForm.module.css";
import QuoteDescriptor, { QuoteDescriptorTypes } from "./QuoteDescriptor";

const QuoteItem = ({
  company,
  logo,
  expandable = true,
  premium,
  productName,
  productDescription,
  small = false,
  onClick
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((v) => !v);
  };

  const descriptor = useMemo(() => {
    const companyDescriptors = {
      RBC: QuoteDescriptorTypes.LONGER_APPLICATION,
      Manulife: QuoteDescriptorTypes.FAST_APPROVAL,
      "Canada Protection Plan": QuoteDescriptorTypes.NO_MEDICAL_REQUIRED
    };

    if (companyDescriptors[company]) {
      return companyDescriptors[company];
    }

    return QuoteDescriptorTypes.LONGER_APPLICATION;
  }, [company]);

  return (
    <div className={styles.QuoteItemWrapper}>
      <div
        className={classNames(styles.QuoteItem, small && styles.QuoteItemSmall)}
      >
        <div className={styles.QuoteProduct}>
          <div className={styles.QuoteItemLogo}>
            <img src={logo} alt={company} />
          </div>
          <div className={styles.QuoteItemProductName}>{productName}</div>
        </div>

        <div className={styles.QuoteDetails}>
          <div className={styles.QuoteAmount}>
            <h3>{formatCurrency(premium)} </h3>
            <span>per month</span>
          </div>

          {!small && (
            <div className={styles.QuoteDescriptor}>
              {descriptor && <QuoteDescriptor type={descriptor} />}
            </div>
          )}

          <Button
            className={styles.SmallButton}
            onClick={onClick || ((e) => e.preventDefault())}
            aria-label="Apply now"
          >
            Apply now
          </Button>
        </div>
      </div>

      {expandable && productDescription && (
        <>
          <button
            type="button"
            className={styles.ExpandToggle}
            onClick={toggleExpanded}
          >
            Read Details {expanded ? "-" : "+"}
          </button>
          {expanded && (
            <div className={styles.QuoteDescription}>
              <p>{productDescription}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default QuoteItem;
