import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Select from "Common/Select";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";

import ApplicationsStyles from "../../Applications.module.css";
// import BeneficiaryInfoStyles from "./BeneficiaryInfo.module.css";

const BeneficiaryInfo = ({ match, data, save, back }) => {
  const beneficiaryIndex = Number(match.params.beneficiaryIndex);

  const BeneficiaryInfoSchema = Yup.object().shape({
    full_name: Yup.string()
      .min(2, "This name is too short")
      .max(50, "This name is too long")
      .required("This field is required"),
    relation: Yup.string()
      .min(2, "This name is too short")
      .max(50, "This name is too long")
      .required("This field is required"),
    percent_allocated: Yup.number().required("This field is required"),
    is_minor: Yup.string().required("This field is required"),

    trustee_full_name: Yup.string().when("is_minor", {
      is: true,
      then: Yup.string().required("This field is required"),
    }),
    trustee_relation: Yup.string().when("is_minor", {
      is: true,
      then: Yup.string().required("This field is required"),
    }),
  });

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Beneficiary Info</StylizedHeading>

      <Formik
        initialValues={{
          full_name: data[beneficiaryIndex]?.full_name || "",
          relation: data[beneficiaryIndex]?.relation || "",
          percent_allocated:
            data[beneficiaryIndex]?.percent_allocated ||
            100 - data.reduce((sum, b) => sum + +b.percent_allocated, 0),
          is_minor:
            typeof data[beneficiaryIndex]?.is_minor === "boolean"
              ? data[beneficiaryIndex]?.is_minor
              : "",

          trustee_full_name: data[beneficiaryIndex]?.trustee_full_name || "",
          trustee_relation: data[beneficiaryIndex]?.trustee_relation || "",
        }}
        validateOnMount
        validationSchema={BeneficiaryInfoSchema}
        onSubmit={(values) => {
          const updatedData = [...data];
          if (!data[beneficiaryIndex]) {
            updatedData.push(values);
          } else {
            updatedData.splice(beneficiaryIndex, 1, values);
          }
          save(updatedData);
          back();
        }}
      >
        {({ values, isValid, setFieldValue }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Full Name</Label>
              <Field as={Input} name="full_name" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="full_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Relation</Label>
              <Field as={Input} name="relation" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="relation"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Percent Allocated
              </Label>
              <Field as={Input} name="percent_allocated" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="percent_allocated"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  Is the beneficiary a minor?
                </Label>
                <Tooltip message="If the beneficiary is a minor, you will need to assign a trustee. A trustee will hold the insurance payout in trust until the child turns 18.">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <Field
                as={Select}
                name="is_minor"
                onChange={(event) =>
                  setFieldValue(
                    "is_minor",
                    JSON.parse(event.target.value),
                    true,
                  )
                }
              >
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="is_minor"
              />
            </div>

            {values.is_minor && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Trustee Full Name
                  </Label>
                  <Field as={Input} name="trustee_full_name" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="trustee_full_name"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Trustee Relation
                  </Label>
                  <Field as={Input} name="trustee_relation" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="trustee_relation"
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                className={ApplicationsStyles.PreviousButton}
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  back();
                }}
              >
                Cancel
              </Button>

              <Button type="submit" className={ApplicationsStyles.NextButton}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BeneficiaryInfo;
