import * as AdminApplicationService from "Core/services/AdminApplication.service";
import * as AdminNoteService from "Core/services/AdminNote.service";

import * as fromAdminApplications from "../types/AdminApplications.types";

export function adminListApplications(query = {}) {
  return async function (dispatch) {
    dispatch({ type: fromAdminApplications.ADMIN_LIST_APPLICATIONS });

    try {
      const applications = await AdminApplicationService.adminListApplications(
        query
      );

      dispatch({
        type: fromAdminApplications.ADMIN_LIST_APPLICATIONS_SUCCESS,
        payload: applications,
      });

      return applications;
    } catch (e) {
      dispatch({ type: fromAdminApplications.ADMIN_LIST_APPLICATIONS_FAIL });
      throw e;
    }
  };
}

export function adminGetApplication(applicationId) {
  return async function (dispatch) {
    dispatch({ type: fromAdminApplications.ADMIN_GET_APPLICATION });

    try {
      const application = await AdminApplicationService.adminGetApplication(
        applicationId
      );

      dispatch({
        type: fromAdminApplications.ADMIN_GET_APPLICATION_SUCCESS,
        payload: application,
      });

      return application;
    } catch (e) {
      dispatch({ type: fromAdminApplications.ADMIN_GET_APPLICATION_FAIL });
      throw e;
    }
  };
}

export function adminUpdateApplication(applicationId, data) {
  return async function (dispatch) {
    dispatch({ type: fromAdminApplications.ADMIN_UPDATE_APPLICATION });

    try {
      const application = await AdminApplicationService.adminUpdateApplication(
        applicationId,
        data
      );

      dispatch({
        type: fromAdminApplications.ADMIN_UPDATE_APPLICATION_SUCCESS,
        payload: application,
      });

      return application;
    } catch (e) {
      dispatch({ type: fromAdminApplications.ADMIN_UPDATE_APPLICATION_FAIL });
      throw e;
    }
  };
}

export function adminDeleteApplication(applicationId) {
  return async function (dispatch) {
    dispatch({ type: fromAdminApplications.ADMIN_DELETE_APPLICATION });

    try {
      await AdminApplicationService.adminDeleteApplication(applicationId);

      dispatch({
        type: fromAdminApplications.ADMIN_DELETE_APPLICATION_SUCCESS,
      });
    } catch (e) {
      dispatch({ type: fromAdminApplications.ADMIN_DELETE_APPLICATION_FAIL });
      throw e;
    }
  };
}

export function adminCreateApplicationNote(userId, applicationId, content) {
  return async function (dispatch) {
    dispatch({ type: fromAdminApplications.ADMIN_CREATE_APPLICATION_NOTE });

    try {
      const note = await AdminNoteService.adminCreateNote(
        content,
        userId,
        applicationId
      );

      dispatch({
        type: fromAdminApplications.ADMIN_CREATE_APPLICATION_NOTE_SUCCESS,
        payload: note,
      });

      return note;
    } catch (e) {
      dispatch({
        type: fromAdminApplications.ADMIN_CREATE_APPLICATION_NOTE_FAIL,
      });
      throw e;
    }
  };
}
