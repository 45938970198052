import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Textarea from "Common/Textarea";
import Select from "Common/Select";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";
import NetWorthCalculatorModal from "Common/NetWorthCalculatorModal";

import ApplicationsStyles from "../../Applications.module.css";
import FinancialInfoStyles from "./FinancialInfo.module.css";

const FinancialInfoSchema = Yup.object().shape({
  gross_annual_income: Yup.number().required("This field is required"),
  gross_household_income: Yup.number().required("This field is required"),
  other_income: Yup.number().required("This field is required"),
  personal_net_worth: Yup.number().required("This field is required"),

  birth_date: Yup.date().when("is_claimant", {
    is: true,
    then: Yup.date().required("This field is required")
  }),

  had_financial_difficulties: Yup.boolean().required("This field is required"),
  financial_difficulties_explanation: Yup.string().when(
    "had_financial_difficulties",
    {
      is: true,
      then: Yup.string().required("This field is required")
    }
  ),

  has_licenced_or_permitted_business: Yup.boolean().required(
    "This field is required"
  ),
  business_licence_or_permit_explanation: Yup.string().when(
    "has_licenced_or_permitted_business",
    {
      is: true,
      then: Yup.string().required("This field is required")
    }
  ),

  will_borrow_for_premiums: Yup.boolean().required("This field is required"),
  premiums_payment_lender: Yup.string().when("will_borrow_for_premiums", {
    is: true,
    then: Yup.string().required("This field is required")
  })
});

const FinancialInfo = ({ data, save, previous, next }) => {
  const [netWorthCalcVisible, setNetWorthCalcVisible] = useState(false);

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Financial Info</StylizedHeading>

      <Formik
        initialValues={{
          gross_annual_income: data?.gross_annual_income || "",
          gross_household_income: data?.gross_household_income || "",
          other_income: data?.other_income || "",
          personal_net_worth: data?.personal_net_worth || "",

          had_financial_difficulties:
            typeof data?.had_financial_difficulties === "boolean"
              ? data?.had_financial_difficulties
              : "",
          financial_difficulties_explanation:
            data?.financial_difficulties_explanation || "",

          has_licenced_or_permitted_business:
            typeof data?.has_licenced_or_permitted_business === "boolean"
              ? data?.has_licenced_or_permitted_business
              : "",
          business_licence_or_permit_explanation:
            data?.business_licence_or_permit_explanation || "",

          will_borrow_for_premiums:
            typeof data?.will_borrow_for_premiums === "boolean"
              ? data?.will_borrow_for_premiums
              : "",
          premiums_payment_lender: data?.premiums_payment_lender || ""
        }}
        validateOnMount
        validationSchema={FinancialInfoSchema}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What is your gross annual income?
                </Label>
                <Tooltip message="Gross annual income refers to the total amount of money you earn in one year before taxes. Include your salary, commissions, dividends, bonuses and pension. If you are self-employed, your annual income is your share of the income or loss of your business after business expenses but before income taxes. If you are an incorporated business, you may also include any wages, salary, fees or commissions that are paid to you as an employee of the business.">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <NumberFormat
                customInput={Input}
                name="gross_annual_income"
                defaultValue={0}
                value={values.gross_annual_income}
                onValueChange={(event) => {
                  setFieldValue("gross_annual_income", event.value, false);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="gross_annual_income"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What is your gross household income?
                </Label>
                <Tooltip message="Household income includes the combined income of all the people sharing your household. It includes all forms of income such as salaries and wages, commissions, dividends, bonuses and pensions.">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <NumberFormat
                customInput={Input}
                name="gross_household_income"
                defaultValue={0}
                value={values.gross_household_income}
                onValueChange={(event) => {
                  setFieldValue("gross_household_income", event.value, false);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="gross_household_income"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What is your income from other sources?
                </Label>
                <Tooltip message="Include interest and income from real estate.">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <NumberFormat
                customInput={Input}
                name="other_income"
                defaultValue={0}
                value={values.other_income}
                onValueChange={(event) => {
                  setFieldValue("other_income", event.value, false);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="other_income"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={FinancialInfoStyles.LabelWrapper}>
                <Label className={ApplicationsStyles.Label}>
                  What is your personal net worth?
                </Label>
                <div
                  className={FinancialInfoStyles.ActionText}
                  onClick={() => setNetWorthCalcVisible(true)}
                >
                  Calculate Net Worth
                </div>
              </div>
              <NumberFormat
                customInput={Input}
                name="personal_net_worth"
                defaultValue={0}
                value={values.personal_net_worth}
                onValueChange={(event) => {
                  setFieldValue("personal_net_worth", event.value, false);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="personal_net_worth"
              />
            </div>

            {netWorthCalcVisible && (
              <NetWorthCalculatorModal
                onSubmit={(netWorth) => {
                  setFieldValue("personal_net_worth", netWorth, true);
                  setNetWorthCalcVisible(false);
                }}
                onClose={() => setNetWorthCalcVisible(false)}
              />
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  In the past five years, have you had any major financial
                  difficulties?
                </Label>
                <Tooltip message="This could include having pay garnished, petitioning for bankruptcy or declaring bankruptcy.">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <Field
                as={Select}
                name="had_financial_difficulties"
                onChange={(event) =>
                  setFieldValue(
                    "had_financial_difficulties",
                    JSON.parse(event.target.value),
                    true
                  )
                }
              >
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="had_financial_difficulties"
              />
            </div>

            {values.had_financial_difficulties && (
              <div className={ApplicationsStyles.FormGroup}>
                <Label className={ApplicationsStyles.Label}>
                  Explain what type of financial difficulty was faced, when it
                  happened, and if it was resolved.
                </Label>
                <Field
                  as={Textarea}
                  name="financial_difficulties_explanation"
                />
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="financial_difficulties_explanation"
                />
              </div>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Do you have a business that requires a licence or permit to
                operate?
              </Label>
              <Field
                as={Select}
                name="has_licenced_or_permitted_business"
                onChange={(event) =>
                  setFieldValue(
                    "has_licenced_or_permitted_business",
                    JSON.parse(event.target.value),
                    true
                  )
                }
              >
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="has_licenced_or_permitted_business"
              />
            </div>

            {values.has_licenced_or_permitted_business && (
              <div className={ApplicationsStyles.FormGroup}>
                <Label className={ApplicationsStyles.Label}>
                  Explain what type of licence or permit is required to operate
                  your business.
                </Label>
                <Field
                  as={Textarea}
                  name="business_licence_or_permit_explanation"
                />
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="business_licence_or_permit_explanation"
                />
              </div>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Will the money to pay the premiums for this policy be borrowed
                from an individual, a bank or other institution?
              </Label>
              <Field
                as={Select}
                name="will_borrow_for_premiums"
                onChange={(event) =>
                  setFieldValue(
                    "will_borrow_for_premiums",
                    JSON.parse(event.target.value),
                    true
                  )
                }
              >
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="will_borrow_for_premiums"
              />
            </div>

            {values.will_borrow_for_premiums && (
              <div className={ApplicationsStyles.FormGroup}>
                <Label className={ApplicationsStyles.Label}>
                  Who will you be borrowing the money to pay the premiums for
                  this policy from?
                </Label>
                <Field as={Select} name="premiums_payment_lender">
                  <option></option>
                  <option value="bank">Bank</option>
                  <option value="relative">Relative</option>
                  <option value="friend">Friend</option>
                  <option value="credit union">Credit Union</option>
                  <option value="mortgage lender">Mortgage Lender</option>
                  <option value="other">Other</option>
                </Field>
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="premiums_payment_lender"
                />
              </div>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                className={ApplicationsStyles.NextButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  next();
                }}
                disabled={!isValid}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FinancialInfo;
