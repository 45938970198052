import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  adminGetApplication,
  adminCreateApplicationNote
} from "store/actions/AdminApplications.actions";
import { adminSelectApplication } from "store/selectors/AdminApplications.selectors";

import styles from "./ApplicationView.module.css";

import Breadcrumbs from "Common/Breadcrumbs";
import { Card, CardBody } from "Common/Card";
import Input from "Common/Input";
import Textarea from "Common/Textarea";
import Label from "Common/Label";
import ObjectViewer from "Common/ObjectViewer";
import { adminUpdateApplication } from "store/actions/AdminApplications.actions";

const ApplicationView = () => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const application = useSelector(adminSelectApplication);

  useEffect(
    () =>
      dispatch(adminGetApplication(applicationId)).catch((e) => console.log(e)),
    [dispatch, applicationId]
  );

  const handleStatusBlur = (event) => {
    dispatch(
      adminUpdateApplication(applicationId, {
        status: event.target.value
      })
    ).catch((e) => console.log(e));
  };

  const handleNoteBlur = (event) => {
    dispatch(
      adminCreateApplicationNote(
        application.user_id,
        application.id,
        event.target.value
      )
    ).catch((e) => console.log(e));
  };

  if (!application) return null;

  return (
    <div className={styles.Container}>
      <Helmet>
        <title>View Application | Bounc3</title>
      </Helmet>

      <Breadcrumbs
        links={[
          { name: "Admin Home", path: "/admin" },
          { name: "User Applications", path: "/admin/applications" },
          { name: `View ${application.product} Application`, path: "#" }
        ]}
        className={styles.Subnav}
      />

      <Card className={styles.Card}>
        <CardBody>
          <div className={styles.LabelWrapper}>
            <Label>Status</Label>
          </div>
          <Input defaultValue={application.status} onBlur={handleStatusBlur} />
          <br />
          <div className={styles.LabelWrapper}>
            <Label>Notes</Label>
          </div>
          <Textarea
            rows="5"
            defaultValue={application.note?.content}
            onBlur={handleNoteBlur}
          />
        </CardBody>
      </Card>

      {application?.submission_data && (
        <ObjectViewer object={application.submission_data} />
      )}
    </div>
  );
};

export default ApplicationView;
