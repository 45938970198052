import * as RiskProfileService from "../../Core/services/RiskProfile.service";
import * as fromRiskProfile from "../types/RiskProfile.types";

export function getRiskProfile() {
  return async function (dispatch) {
    dispatch({ type: fromRiskProfile.GET_RISK_PROFILE });
    try {
      const riskProfile = await RiskProfileService.getRiskProfile();
      dispatch({
        type: fromRiskProfile.GET_RISK_PROFILE_SUCCESS,
        payload: riskProfile
      });
      return riskProfile;
    } catch (e) {
      dispatch({ type: fromRiskProfile.GET_RISK_PROFILE_FAIL });
      throw e;
    }
  };
}

export function saveRiskProfile(data) {
  return async function (dispatch) {
    dispatch({ type: fromRiskProfile.SAVE_RISK_PROFILE });
    try {
      const riskProfile = await RiskProfileService.saveRiskProfile(data);
      dispatch({
        type: fromRiskProfile.SAVE_RISK_PROFILE_SUCCESS,
        payload: riskProfile
      });
      return riskProfile;
    } catch (e) {
      dispatch({ type: fromRiskProfile.SAVE_RISK_PROFILE_FAIL });
      throw e;
    }
  };
}

export function saveDraftRiskProfile(email, firstName, lastName, data) {
  return async function (dispatch) {
    dispatch({ type: fromRiskProfile.SAVE_RISK_PROFILE });

    try {
      const riskProfile = await RiskProfileService.saveDraftRiskProfile(
        email,
        firstName,
        lastName,
        data
      );
      dispatch({
        type: fromRiskProfile.SAVE_RISK_PROFILE_SUCCESS,
        payload: riskProfile
      });
      return riskProfile;
    } catch (e) {
      dispatch({ type: fromRiskProfile.SAVE_RISK_PROFILE_FAIL });
      throw e;
    }
  };
}
