import * as fromAdminApplications from "../types/AdminApplications.types";

const initState = {
  adminApplicationList: [],
  adminApplication: false,
  isLoading: false,
  isLoaded: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    // LIST
    case fromAdminApplications.ADMIN_LIST_APPLICATIONS:
      return { ...state, isLoading: true };
    case fromAdminApplications.ADMIN_LIST_APPLICATIONS_SUCCESS:
      return {
        ...state,
        adminApplicationList: action.payload,
        isLoaded: true,
        isLoading: false,
      };
    case fromAdminApplications.ADMIN_LIST_APPLICATIONS_FAIL:
      return { ...state, isLoaded: false, isLoading: false };
    // GET
    case fromAdminApplications.ADMIN_GET_APPLICATION:
      return { ...state, isLoading: true };
    case fromAdminApplications.ADMIN_GET_APPLICATION_SUCCESS:
      return {
        ...state,
        adminApplication: action.payload,
        isLoaded: true,
        isLoading: false,
      };
    case fromAdminApplications.ADMIN_GET_APPLICATION_FAIL:
      return { ...state, isLoaded: false, isLoading: false };
    // UPDATE
    case fromAdminApplications.ADMIN_UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        adminApplication: action.payload,
        isLoaded: true,
        isLoading: false,
      };
    // NOTE
    case fromAdminApplications.ADMIN_CREATE_APPLICATION_NOTE_SUCCESS:
      return {
        ...state,
        adminApplication: { ...state.adminApplication, note: action.payload },
        isLoaded: true,
        isLoading: false,
      };
    default:
      return state;
  }
}
