import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import { listApplications } from "store/actions/Applications.actions";
import { selectApplicationList } from "store/selectors/Applications.selectors";

import styles from "./ApplicationList.module.css";

const ApplicationList = () => {
  const dispatch = useDispatch();
  const applications = useSelector(selectApplicationList);
  const [productFilter] = useState("");
  const [statusFilter] = useState("");

  useEffect(() => {
    const query = {};

    if (productFilter) query.type = productFilter;
    if (statusFilter) query.status = statusFilter;

    dispatch(listApplications()).catch((e) => console.log(e));
  }, [productFilter, statusFilter, dispatch]);

  return (
    <div className={styles.Container}>
      <Helmet>
        <title>Applications | Bounc3</title>
      </Helmet>

      {applications &&
        applications.map((a, i) => {
          return <div>{a.product}</div>;
        })}
    </div>
  );
};

export default ApplicationList;
