import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Select from "Common/Select";
import DateSelect from "Common/DateSelect";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";

const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  last_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  gender: Yup.string().required("This field is required"),
  birth_date: Yup.date().required("This field is required"),
  birth_place: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits")
    .required("This field is required"),
  language: Yup.string().required("This field is required"),
  citizen_or_permanent_resident: Yup.boolean().required(
    "This field is required",
  ),
  years_in_country: Yup.number().when("citizen_or_permanent_resident", {
    is: true,
    then: Yup.number().required("This field is required"),
  }),
  is_policy_owner: Yup.boolean().required("This field is required"),
});

const PersonalInfo = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Personal Info</StylizedHeading>

      <Formik
        initialValues={{
          first_name: data?.first_name || "",
          last_name: data?.last_name || "",
          gender: data?.gender || "",
          birth_date: data?.birth_date || "",
          birth_place: data?.birth_place || "",
          email: data?.email || "",
          phone: data?.phone || "",
          language: data?.language || "",
          citizen_or_permanent_resident:
            data?.citizen_or_permanent_resident || "",
          years_in_country: data?.years_in_country || "",
          is_policy_owner: data?.is_policy_owner || "",
        }}
        onSubmit={(values) => {
          save(values);
          next();
        }}
        validateOnMount
        validationSchema={PersonalInfoSchema}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>First Name</Label>
              <Field as={Input} name="first_name" placeholder="John" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="first_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Last Name</Label>
              <Field as={Input} name="last_name" placeholder="Smith" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="last_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Email</Label>
              <Field
                as={Input}
                name="email"
                type="email"
                placeholder="jsmith@example.ca"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="email"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Phone</Label>
              <NumberFormat
                customInput={Input}
                name="phone"
                value={values.phone}
                onValueChange={(event) => {
                  setFieldValue("phone", event.value, false);
                }}
                format="(###) ###-####"
                decimalScale={0}
                allowNegative={false}
                allowEmptyFormatting={false}
                type="tel"
                placeholder="(123) 456-7890"
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="phone"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Gender</Label>
              <Field as={Select} name="gender" type="text">
                <option value={null}></option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="undisclosed">Prefer not to say</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="gender"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Date of Birth</Label>
              <Field
                as={DateSelect}
                maxYearCorrection={-16}
                name="birth_date"
                value={values.birth_date}
                onChange={(value) => setFieldValue("birth_date", value, true)}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="birth_date"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Place of Birth</Label>
              <Field as={Input} name="birth_place" placeholder="Canada" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="birth_place"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Language Preference
              </Label>
              <Field as={Select} name="language" type="text">
                <option value={null}></option>
                <option value="en">English</option>
                <option value="fr">French</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="language"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Are you a Canadian citizen or do you have a permanent resident
                status?
              </Label>
              <Field
                as={Select}
                name="citizen_or_permanent_resident"
                type="text"
              >
                <option value={null}></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="citizen_or_permanent_resident"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                How many years have you lived in Canada?
              </Label>
              <NumberFormat
                customInput={Input}
                name="years_in_country"
                defaultValue={0}
                value={values.years_in_country}
                onValueChange={(event) => {
                  setFieldValue("years_in_country", event.value);
                }}
                decimalScale={0}
                allowNegative={false}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="years_in_country"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Will you be the owner of the proposed policy?
              </Label>
              <Field as={Select} name="is_policy_owner" type="text">
                <option value={null}></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="is_policy_owner"
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                type="submit"
                fullWidth
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfo;
