import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import Button from "Common/Button";
import Input from "Common/Input";
import Label from "Common/Label";
import StylizedHeading from "Common/StylizedHeading";
import Tooltip from "Common/Tooltip";

import ApplicationsStyles from "../../Applications.module.css";
import Select from "Common/Select";

const BusinessInfoSchema = Yup.object().shape({
  business_legal_name: Yup.string().required("This field is required"),
  business_number: Yup.string()
    .required("This field is required")
    .min(9, "Business number should be exactly 9 digits. E.g 123456789"),
  business_address_as_personal_address: Yup.boolean().required(
    "This field is required"
  ),
  street_address: Yup.string().when("business_address_as_personal_address", {
    is: false,
    then: Yup.string().required("This field is required")
  }),
  city: Yup.string().when("business_address_as_personal_address", {
    is: false,
    then: Yup.string().required("This field is required")
  }),
  province: Yup.string().when("business_address_as_personal_address", {
    is: false,
    then: Yup.string().required("This field is required")
  }),
  postal_code: Yup.string().when("business_address_as_personal_address", {
    is: false,
    then: Yup.string().required("This field is required")
  }),
  department_policy: Yup.string().required("This field is required"),
  had_financial_difficulties: Yup.boolean().required("This field is required"),
  has_licenced_or_permitted_business: Yup.boolean().required(
    "This field is required"
  ),
  will_borrow_for_premiums: Yup.boolean().required("This field is required"),
  has_existing_agreement_for_other: Yup.boolean().required(
    "This field is required"
  )
});

const BusinessInfo = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Business Info</StylizedHeading>

      <Formik
        initialValues={{
          business_legal_name: data?.business_legal_name || "",
          business_number: data?.business_number || "",
          business_address_as_personal_address: "false",
          street_address: data?.street_address || "",
          city: data?.city || "",
          province: data?.province || "",
          postal_code: data?.postal_code || "",
          department_policy: data?.department_policy || "",
          had_financial_difficulties:
            typeof data?.had_financial_difficulties === "boolean"
              ? data?.had_financial_difficulties
              : "",
          has_licenced_or_permitted_business:
            typeof data?.has_licenced_or_permitted_business === "boolean"
              ? data?.has_licenced_or_permitted_business
              : "",
          will_borrow_for_premiums:
            typeof data?.will_borrow_for_premiums === "boolean"
              ? data?.will_borrow_for_premiums
              : "",
          has_existing_agreement_for_other:
            typeof data?.had_financial_difficulties === "boolean"
              ? data?.had_financial_difficulties
              : ""
        }}
        validateOnMount
        validationSchema={BusinessInfoSchema}
      >
        {({ values, isValid, errors, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  Full legal name of your company or trust (including Company,
                  Limited, Inc., etc.)
                </Label>
              </div>
              <Field as={Input} name="business_legal_name" />

              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="business_legal_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  Business Number
                </Label>
                <Tooltip message="Your business number is a 9-digit identification number you use for tax purposes. Under the Income Tax Act, we are required to record a business number if the policy is owned by an entity. Your business number should consist of exactly 9 digits. E.g.: 123456789">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <Field as={Input} name="business_number" maxLength={9} />

              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="business_number"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Is the business address the same as your personal address?
              </Label>
              <Field
                as={Select}
                name="business_address_as_personal_address"
                onChange={(event) =>
                  setFieldValue(
                    "business_address_as_personal_address",
                    JSON.parse(event.target.value),
                    true
                  )
                }
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="business_address_as_personal_address"
              />
            </div>

            {!values.business_address_as_personal_address && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Street Address
                  </Label>
                  <Field
                    as={Input}
                    name="street_address"
                    spellCheck="false"
                    placeholder="123 Easy Street, Unit 42"
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="street_address"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>City</Label>
                  <Field
                    as={Input}
                    name="city"
                    spellCheck="false"
                    placeholder="Toronto"
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="city"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>Province</Label>
                  <Field
                    as={Input}
                    name="province"
                    spellCheck="false"
                    placeholder="ON"
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="province"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Postal Code
                  </Label>
                  <Field
                    as={Input}
                    name="postal_code"
                    maxLength="7"
                    spellCheck="false"
                    placeholder="A1A 2B2"
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="postal_code"
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  Which department in your company will receive information
                  about this policy? (Example: Accounts payable)
                </Label>
              </div>
              <Field as={Input} name="department_policy" />

              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="department_policy"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                In the past five years, have you, or the business, had any major
                financial difficulties, such as having pay garnished,
                petitioning for bankruptcy or declaring bankruptcy?
              </Label>
              <Field
                as={Select}
                name="had_financial_difficulties"
                onChange={(event) =>
                  setFieldValue(
                    "had_financial_difficulties",
                    JSON.parse(event.target.value),
                    true
                  )
                }
              >
                <option value=""></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="had_financial_difficulties"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Is a licence or permit required to operate your business?
              </Label>
              <Field
                as={Select}
                name="has_licenced_or_permitted_business"
                onChange={(event) =>
                  setFieldValue(
                    "has_licenced_or_permitted_business",
                    JSON.parse(event.target.value),
                    true
                  )
                }
              >
                <option value=""></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="has_licenced_or_permitted_business"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Will the money to pay the premiums for this policy be borrowed
                from an individual, a bank or other institution?
              </Label>
              <Field
                as={Select}
                name="will_borrow_for_premiums"
                onChange={(event) =>
                  setFieldValue(
                    "will_borrow_for_premiums",
                    JSON.parse(event.target.value),
                    true
                  )
                }
              >
                <option value=""></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="will_borrow_for_premiums"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Is there an existing or planned agreement that provides for
                anyone other than an owner identified in this application to
                obtain any legal interest in any policy resulting from this
                application?
              </Label>
              <Field
                as={Select}
                name="has_existing_agreement_for_other"
                onChange={(event) =>
                  setFieldValue(
                    "has_existing_agreement_for_other",
                    JSON.parse(event.target.value),
                    true
                  )
                }
              >
                <option value=""></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="has_existing_agreement_for_other"
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                className={ApplicationsStyles.NextButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  next();
                }}
                disabled={!isValid}
              >
                Next
              </Button>
            </div>

            <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{JSON.stringify(isValid, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BusinessInfo;
