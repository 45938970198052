import * as fromApp from "../types/App.types";

const initState = {
  appReady: false
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case fromApp.APP_READY:
      return { ...state, appReady: true };
    default:
      return state;
  }
}
