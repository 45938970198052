import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Textarea from "Common/Textarea";
import Select from "Common/Select";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";

import ApplicationsStyles from "../../Applications.module.css";

const FinancialInfoSchema = Yup.object().shape({
  net_annual_income_current_year: Yup.number().required(
    "This field is required",
  ),
  net_annual_income_previous_year: Yup.number().required(
    "This field is required",
  ),
  declared_bankruptcy: Yup.boolean().required("This field is required"),
  bankruptcy_details: Yup.string().when("declared_bankruptcy", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
});

const FinancialInfo = ({ data, save, previous, next }) => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Financial Info</StylizedHeading>

      <Formik
        initialValues={{
          net_annual_income_current_year:
            data?.net_annual_income_current_year || "",
          net_annual_income_previous_year:
            data?.net_annual_income_previous_year || "",
          declared_bankruptcy:
            typeof data?.declared_bankruptcy === "boolean"
              ? data?.declared_bankruptcy
              : "",
          bankruptcy_details: data?.bankruptcy_details || "",
        }}
        onSubmit={(values) => {
          save(values);
          next();
        }}
        validateOnMount
        validationSchema={FinancialInfoSchema}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What was your net annual income earned in {year - 1}?
                </Label>
                <Tooltip message="Check your income tax return for the exact number.">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <NumberFormat
                customInput={Input}
                name="net_annual_income_current_year"
                value={values.gross_annual_income}
                onValueChange={(event) => {
                  setFieldValue("net_annual_income_current_year", event.value);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="net_annual_income_current_year"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What was your net annual income earned in {year - 2}?
                </Label>
                <Tooltip message="Check your income tax return for the exact number.">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <NumberFormat
                customInput={Input}
                name="net_annual_income_previous_year"
                value={values.gross_annual_income}
                onValueChange={(event) => {
                  setFieldValue("net_annual_income_previous_year", event.value);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="net_annual_income_previous_year"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Have you ever declared personal or corporate bankruptcy?
              </Label>
              <Field
                as={Select}
                name="declared_bankruptcy"
                onChange={(event) =>
                  setFieldValue(
                    "declared_bankruptcy",
                    JSON.parse(event.target.value),
                  )
                }
              >
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="declared_bankruptcy"
              />
            </div>

            {values.declared_bankruptcy && (
              <div className={ApplicationsStyles.FormGroup}>
                <Label className={ApplicationsStyles.Label}>
                  Please provide details about any bankruptcies.
                </Label>
                <Field as={Textarea} name="bankruptcy_details" />
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="bankruptcy_details"
                />
              </div>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                type="submit"
                fullWidth
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FinancialInfo;
