import { ApplicationType } from "Core/models/Application.model";
import * as API from "./API";

export function listApplications() {
  return API.get("/applications");
}

export function getApplication(applicationId) {
  return API.get(`/applications/${applicationId}`);
}

export function createApplication(
  product,
  data,
  applicationType = ApplicationType.PERSONAL
) {
  return API.post("/applications", {
    product,
    submission_data: data,
    application_type: applicationType
  });
}

export function updateApplication(applicationId, data) {
  return API.put(`/applications/${applicationId}`, { submission_data: data });
}

export function submitUserApplication(applicationId) {
  return API.put(`/applications/${applicationId}/submit`);
}

export function rescindUserApplication(applicationId) {
  return API.put(`/applications/${applicationId}/rescind`);
}

export function saveDraftApplication(email, product, data) {
  return API.put("/applications/draft", { email, product, data });
}
