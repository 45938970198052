import { useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

import { selectIsAuthenticated } from "store/selectors/Auth.selectors";

import StylizedHeading from "Common/StylizedHeading";
import P from "Common/P";
import Label from "Common/Label";
import Input from "Common/Input";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";
import ConfirmAndRegisterStyles from "./ConfirmAndRegister.module.css";

const ConfirmAndRegister = ({ data, submit, loading, error }) => {
  const authed = useSelector(selectIsAuthenticated);

  const RegistrationSchema = Yup.object().shape({
    password: Yup.string().when("contact_time", {
      is: () => !authed,
      then: Yup.string()
        .min(8, "This password is too short")
        .max(50, "This password is too long")
        .required("This field is required"),
    }),
    terms_agreed: Yup.boolean().when("contact_time", {
      is: () => !authed,
      then: Yup.boolean().oneOf(
        [true],
        "You must agree to the terms of service",
      ),
    }),
  });

  if (authed && !loading) {
    return (
      <div className={ApplicationsStyles.Wrapper}>
        <StylizedHeading>You're all done!</StylizedHeading>

        <P className={ApplicationsStyles.SmallText}>
          {`Thanks for applying for insurance with Bounc3! We've
          received your application and will reach out to you soon.`}
        </P>

        <div className={ApplicationsStyles.ButtonWrapper}>
          <Button fullWidth onClick={submit} disabled={loading}>
            Continue
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>You're all done!</StylizedHeading>

      <P>
        To submit your application, please enter a password. You may use the
        email <strong>{data.email}</strong> to log in.
      </P>

      <Formik
        initialValues={{
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: "",
          terms_agreed: false,
        }}
        validationSchema={RegistrationSchema}
        validateOnMount
      >
        {({ values, isValid }) => (
          <Form className={ConfirmAndRegisterStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Field name="password">
                {({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    className={ConfirmAndRegisterStyles.Password}
                  />
                )}
              </Field>
              <ErrorMessage
                component="div"
                name="password"
                className={ConfirmAndRegisterStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ConfirmAndRegisterStyles.Label}>
                <Field
                  as={Input}
                  id="termsAgreement"
                  name="terms_agreed"
                  type="checkbox"
                />
                <Label htmlFor="termsAgreement">
                  &nbsp; I agree to the{" "}
                  <a
                    href="https://bounc3.io/page/terms-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </Label>
              </div>
              <ErrorMessage
                component="div"
                name="terms_agreed"
                className={ConfirmAndRegisterStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                onClick={(event) => {
                  event.preventDefault();
                  submit(values);
                }}
                disabled={!isValid || loading}
              >
                Continue
              </Button>
            </div>

            {error && (
              <p className={classNames("danger", ApplicationsStyles.Error)}>
                {error}
              </p>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConfirmAndRegister;
