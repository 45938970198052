import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import History from "../../Core/History";
import { createQuote } from "../../Core/services/Quote.service";

import {
  createApplication,
  submitApplication,
  saveDraftApplication
} from "../../store/actions/Applications.actions";
import { login, register } from "../../store/actions/Auth.actions";
import { selectCurrentUser } from "../../store/selectors/Auth.selectors";
import { selectApplicationList } from "store/selectors/Applications.selectors";
import { track } from "utils/gtag";
import { trackFintelPixel } from "utils/fintel";

import ProgressBar from "Common/ProgressBar";
import GetStarted from "./GetStarted";
import PersonalInfo from "./PersonalInfo";
import OwnershipInfo from "./OwnershipInfo";
import AddressInfo from "./AddressInfo";
import FinancialInfo from "./FinancialInfo";
import BeneficiaryList from "./BeneficiaryList";
import BeneficiaryInfo from "./BeneficiaryInfo";
import OtherPolicyList from "./OtherPolicyList";
import OtherPolicyInfo from "./OtherPolicyInfo";
import TempCoverageInfo from "./TempCoverageInfo";
import PaymentInfo from "./PaymentInfo";
import NextSteps from "./NextSteps";
import ConfirmAndRegister from "./ConfirmAndRegister";

import ApplicationsStyles from "../Applications.module.css";
import { isPostalCodeValidArea } from "utils/postalCode";

export default function RbcLifeInsurance({ match, location }) {
  const dispatch = useDispatch();
  const [locationState, setLocationState] = useState({});
  const user = useSelector(selectCurrentUser);
  const applications = useSelector(selectApplicationList);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 8;
  const product = "Life Insurance";

  const { quote } = locationState;

  const analyticsTrackQuoteData = {
    insuranceType: "Life Insurance",
    brand: "RBC",
    productName: quote?.product_name,
    price: quote?.premium_monthly
  };

  const [FormData, setFormData] = useState({
    provider: "rbc",
    started: false,
    personal_info: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email, // Needed for saving, contact, and registration
      phone: user?.phone, // Needed for saving and contact
      birth_date: user?.birth_date,
      birth_place: user?.birth_place,
      gender: user?.gender,
      is_smoker: user?.is_smoker,
      language: user?.language,
      residence_status: user?.residence_status,
      purchase_reason: user?.purchase_reason,
      other_purchase_reason: user?.other_purchase_reason
    },
    ownership_info: {
      // is_policy_owner: user?.is_policy_owner,
      // owner_first_name,
      // owner_last_name,
      // owner_email,
      // owner_relation,
      // owner_street_address,
      // owner_city,
      // owner_province,
      // owner_postal_code,
      // has_joint_owner: user?.has_joint_owner,
      // joint_owner_first_name,
      // joint_owner_last_name,
      // joint_owner_email,
      // joint_owner_relation,
      // joint_owner_street_address,
      // joint_owner_city,
      // joint_owner_province,
      // joint_owner_postal_code,
    },
    address_info: {
      street_address: user?.street_address,
      city: user?.city,
      province: user?.province,
      postal_code: user?.postal_code
    },
    financial_info: {
      is_employed: user?.is_employed,

      // Yes
      employer_name: user?.employer_name,
      employer_street_address: user?.employer_street_address,
      employer_city: user?.employer_city,
      employer_province: user?.employer_province,
      employer_postal_code: user?.employer_postal_code,
      employer_phone: user?.employer_phone,
      nature_of_business: user?.nature_of_business,
      occupation: user?.occupation,
      occupation_description: user?.occupation_description,
      duties_description: user?.duties_description,
      current_employment_duration: user?.current_employment_duration,
      earned_annual_income: user?.earned_annual_income,
      personal_net_worth: user?.personal_net_worth,

      // No
      // occupation: user?.occupation,
      // earned_annual_income: user?.earned_annual_income,
      // personal_net_worth: user?.personal_net_worth,

      has_outstanding_mortgage: user?.has_outstanding_mortgage,
      outstanding_mortgage_amount: user?.outstanding_mortgage_amount,

      is_self_supporting: user?.is_self_supporting,
      family_earned_annual_income: user?.family_earned_annual_income,

      has_other_income: user?.has_other_income,
      other_annual_income: user?.other_annual_income,
      other_income_description: user?.other_income_description,

      declared_bankruptcy: user?.declared_bankruptcy,
      bankruptcy_type: user?.bankruptcy_type,
      bankruptcy_details: user?.bankruptcy_details
    },
    beneficiary_info: [],
    other_policy_info: [],
    temp_coverage_info: {},
    payment_info: {}
  });

  useEffect(() => {
    if (location.state) {
      setLocationState(location.state);
    }
  }, [location]);

  useEffect(() => {
    const savedApplication = applications?.find((a) => a.product === product);

    if (savedApplication) {
      setFormData(savedApplication.submission_data);
    }
  }, [applications, product]);

  const saveDraft = () => {
    if (!user) {
      dispatch(
        saveDraftApplication(FormData.personal_info.email, product, FormData)
      ).catch((e) => console.log(e));
    }
  };

  const submitFormData = async (data) => {
    const { contact_time, password } = data;
    const { first_name, last_name, email } = FormData.personal_info;
    const { data: quoteFormData } = locationState;

    setFormData({ ...FormData, contact_time });

    setLoading(true);

    try {
      if (!user) {
        try {
          await dispatch(register(email, password, first_name, last_name));
          track({
            event: "insurance_createaccount",
            ...analyticsTrackQuoteData
          });
        } catch (e) {
          await dispatch(login(email, password));
        }
      }

      const application = await dispatch(createApplication(product, FormData));

      if (quote) {
        await createQuote({
          product: product,
          request_data: {
            ...quoteFormData,
            firstName: first_name,
            lastName: last_name,
            email: email
          },
          ...quote
        });
      }

      if (isPostalCodeValidArea(quoteFormData.postalCode)) {
        trackFintelPixel("Life Insurance", application.id, user?.id);
      }

      await dispatch(submitApplication(application.id));

      History.replace("/dashboard");
    } catch (e) {
      setError(e.message);
    }

    setLoading(false);
  };

  return (
    <div className={ApplicationsStyles.Container}>
      <Helmet>
        <title>{`${product} Application | Bounc3`}</title>
      </Helmet>

      {currentStep > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "2rem"
          }}
        >
          <ProgressBar percent={currentStep / totalSteps} />
        </div>
      )}

      <Router history={History}>
        <Switch>
          <Route
            exact
            path={`${match.url}/get-started`}
            render={(props) => {
              setCurrentStep(0);
              return (
                <GetStarted
                  {...props}
                  product={product}
                  save={(data) => setFormData({ ...FormData, started: data })}
                  next={() => {
                    track({
                      event: "insurance_startapplication",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/personal-info`);
                  }}
                />
              );
            }}
          ></Route>

          {!FormData.started && (
            <Redirect from={`${match.url}`} to={`${match.url}/get-started`} />
          )}

          <Route
            path={`${match.url}/personal-info`}
            render={(props) => {
              setCurrentStep(0);
              return (
                <PersonalInfo
                  {...props}
                  data={FormData.personal_info}
                  save={(data) =>
                    setFormData({ ...FormData, personal_info: data })
                  }
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/get-started`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/ownership-info`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/ownership-info`}
            render={(props) => {
              setCurrentStep(1);
              return (
                <OwnershipInfo
                  {...props}
                  data={FormData.ownership_info}
                  save={(data) =>
                    setFormData({ ...FormData, ownership_info: data })
                  }
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/personal-info`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/address-info`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/address-info`}
            render={(props) => {
              setCurrentStep(2);
              return (
                <AddressInfo
                  {...props}
                  data={FormData.address_info}
                  save={(data) =>
                    setFormData({ ...FormData, address_info: data })
                  }
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/ownership-info`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/financial-info`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/financial-info`}
            render={(props) => {
              setCurrentStep(3);
              return (
                <FinancialInfo
                  {...props}
                  data={FormData.financial_info}
                  save={(data) => {
                    setFormData({ ...FormData, financial_info: data });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/address-info`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/beneficiaries`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/beneficiaries`}
            render={(props) => {
              setCurrentStep(4);
              return (
                <BeneficiaryList
                  {...props}
                  data={FormData.beneficiary_info}
                  save={(data) => {
                    setFormData({ ...FormData, beneficiary_info: data });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/financial-info`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/other-policies`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/beneficiaries/:beneficiaryIndex`}
            render={(props) => {
              setCurrentStep(4);
              return (
                <BeneficiaryInfo
                  {...props}
                  data={FormData.beneficiary_info}
                  save={(data) => {
                    setFormData({ ...FormData, beneficiary_info: data });
                    saveDraft();
                  }}
                  back={() => History.goBack()}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/other-policies`}
            render={(props) => {
              setCurrentStep(5);
              return (
                <OtherPolicyList
                  {...props}
                  data={FormData.other_policy_info}
                  save={(data) => {
                    setFormData({ ...FormData, other_policy_info: data });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/beneficiaries`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/temporary-coverage`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/other-policies/:otherPolicyIndex`}
            render={(props) => {
              setCurrentStep(5);
              return (
                <OtherPolicyInfo
                  {...props}
                  data={FormData.other_policy_info}
                  save={(data) => {
                    setFormData({ ...FormData, other_policy_info: data });
                    saveDraft();
                  }}
                  back={() => History.goBack()}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/temporary-coverage`}
            render={(props) => {
              setCurrentStep(6);
              return (
                <TempCoverageInfo
                  {...props}
                  data={FormData.temp_coverage_info}
                  save={(data) => {
                    setFormData({ ...FormData, temp_coverage_info: data });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/other-policies`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/payment-info`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/payment-info`}
            render={(props) => {
              setCurrentStep(7);
              return (
                <PaymentInfo
                  {...props}
                  data={FormData.payment_info}
                  save={(data) => {
                    setFormData({ ...FormData, payment_info: data });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/temporary-coverage`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/next-steps`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/next-steps`}
            render={(props) => {
              setCurrentStep(8);
              return (
                <NextSteps
                  {...props}
                  data={FormData.next_steps}
                  save={(data) => {
                    setFormData({ ...FormData, next_steps: data });
                    saveDraft();
                  }}
                  previous={() => {
                    track({
                      event: "insurance_previousstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/payment-info`);
                  }}
                  next={() => {
                    track({
                      event: "insurance_nextstep",
                      ...analyticsTrackQuoteData
                    });
                    History.push(`${match.url}/done`);
                  }}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/done`}
            render={(props) => (
              <ConfirmAndRegister
                {...props}
                data={FormData.personal_info}
                submit={submitFormData}
                loading={loading}
                error={error}
              />
            )}
          ></Route>
        </Switch>
      </Router>
    </div>
  );
}
