import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Select from "Common/Select";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";

const expenseCategories = [
  { id: "Extended Health Care", name: "Extended Health Care" },
  { id: "Dental Care", name: "Dental Care" },
  { id: "Vision Care", name: "Vision Care" }
];

const ProgramSettingsSchema = Yup.object().shape({
  coverage_ratio: Yup.number()
    .oneOf([0.5, 0.8, 1.0])
    .required("This field is required"),
  annual_allocations: Yup.number()
    .oneOf([1, 2, 4, 12])
    .required("This field is required"),
  benefits_expire: Yup.boolean().required("This field is required"),
  covered_expenses: Yup.array()
    .min(1, "You must choose at least one coverage option")
    .of(Yup.string().oneOf(expenseCategories.map((c) => c.id))),
  renewal: Yup.string().required("This field is required")
});

const ProgramSettings = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>HSA Details</StylizedHeading>

      <Formik
        initialValues={{
          coverage_ratio: data?.coverage_ratio || 1.0,
          annual_allocations: data?.annual_allocations || 1,
          benefits_expire: data?.benefits_expire || false,
          covered_expenses:
            data?.covered_expenses || expenseCategories.map((c) => c.id),
          renewal: data?.renewal || "January 1"
        }}
        validateOnMount
        validationSchema={ProgramSettingsSchema}
      >
        {({ values, isValid, setFieldTouched }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                What percentage of health expenses do you want to cover?
              </Label>
              <Field as={Select} fullWidth name="coverage_ratio">
                <option value={0.5}>50%</option>
                <option value={0.8}>80%</option>
                <option value={1.0}>100% (Recommended)</option>
              </Field>
              <ErrorMessage
                component="div"
                name="coverage_ratio"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                How often will you allocate the funds in the HSA?
              </Label>
              <Field as={Select} fullWidth name="annual_allocations">
                <option value={1}>Annually (Recommended)</option>
                <option value={2}>Semi-annually</option>
                <option value={4}>Quarterly</option>
                <option value={12}>Monthly</option>
              </Field>
              <ErrorMessage
                component="div"
                name="annual_allocations"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Do you want the HSA benefits to expire at the end of the year?
              </Label>
              <Field as={Select} fullWidth name="benefits_expire">
                <option value={false}>No (Recommended)</option>
                <option value={true}>Yes</option>
              </Field>
              <ErrorMessage
                component="div"
                name="benefits_expire"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                When would you like your HSA to renew?
              </Label>
              <Field as={Select} fullWidth name="renewal">
                <option value="January 1">January 1 (Recommended)</option>
                <option value="February 1">February 1</option>
                <option value="March 1">March 1</option>
                <option value="April 1">April 1</option>
                <option value="May 1">May 1</option>
                <option value="June 1">June 1</option>
                <option value="July 1">July 1</option>
                <option value="August 1">August 1</option>
                <option value="September 1">September 1</option>
                <option value="October 1">October 1</option>
                <option value="November 1">November 1</option>
                <option value="December 1">December 1</option>
              </Field>
              <ErrorMessage
                component="div"
                name="renewal"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                className={ApplicationsStyles.NextButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  next();
                }}
                disabled={!isValid}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProgramSettings;
