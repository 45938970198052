import style from "./ProgressBar.module.css";

const ProgressBar = ({ percent }) => {
  const percentText = `${Math.ceil(percent * 100)}%`;

  return (
    <div className={style.ProgressBarWrapper}>
      <div className={style.ProgressBarText}>{percentText}</div>
      <div className={style.ProgressBar}>
        <div
          className={style.ProgressBarComplete}
          style={{ width: percentText }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
