import { ApplicationType } from "Core/models/Application.model";
import * as API from "./API";

export function getIP() {
  return fetch(`https://compulifeapi.com/api/ip/`, {
    method: "GET"
  }).then((response) => response.json());
}

export function createQuote({
  request_data,
  product,
  provider,
  source = null,
  status = null,
  company = null,
  product_name = null,
  premium_annually = null,
  premium_monthly = null,
  quote_type = null
}) {
  return API.post("/quote", {
    request_data,
    product,
    provider,
    source,
    status,
    company,
    product_name,
    premium_annually,
    premium_monthly,
    quote_type
  });
}

export function requestLifeInsuranceQuote({
  postal_code,
  birth_date,
  sex,
  is_smoker,
  coverage_amount,
  policy_length,
  coverage_term,
  remote_ip,
  quote_type,
  first_name,
  last_name,
  email
}) {
  return API.post("/quote/life-insurance", {
    postal_code,
    birth_date,
    sex,
    is_smoker,
    coverage_amount,
    policy_length,
    coverage_term,
    remote_ip,
    quote_type,
    first_name,
    last_name,
    email
  });
}

export function requestCrtiticalInsuranceQuote({
  postal_code,
  birth_date,
  sex,
  is_smoker,
  coverage_amount,
  policy_length,
  coverage_term,
  remote_ip,
  first_name,
  last_name,
  email
}) {
  return API.post("/quote/critical-illness-insurance", {
    postal_code,
    birth_date,
    sex,
    is_smoker,
    coverage_amount,
    policy_length,
    coverage_term,
    remote_ip,
    first_name,
    last_name,
    email
  });
}

export function getLatestQuote(
  product,
  applicationType = ApplicationType.PERSONAL
) {
  return API.get(
    `/quote/latest?product=${product}&application_type=${applicationType}`,
    {},
    true
  );
}
