/**
 *  Data about products.
 */
export const InsuranceProducts = {
  LIFE_INSURANCE: "LI",
  CRITICAL_ILLNESS_INSURANCE: "CI",
  DISABILITY_INSURANCE: "DI",
  PERSONAL_ACCIDENT_INSURANCE: "PA",
  EXTENDED_HEALTH_INSURANCE: "EH",
  HEALTH_SPENDING_ACCOUNT: "HSA",
  UNKNOWN: "UNKNOWN"
};

export const InsuranceProductDetails = {
  [InsuranceProducts.LIFE_INSURANCE]: {
    icon: `/images/life-icon.svg`,
    description:
      "Term life insurance provides a tax-free and lump sum amount that can be used to cover your outstanding debts, and help your family maintain their standard of living in case of your premature death.",
    title: "Life Insurance"
  },
  [InsuranceProducts.CRITICAL_ILLNESS_INSURANCE]: {
    icon: `/images/ci-icon.svg`,
    description:
      "This insurance pays a lump sum if you were to be diagnosed with an illness. This lump sum can be used to supplement you income, cover any out of pocket costs of treatment, or to go on a groovy vacation when you conquer your illness.",
    title: "Critical Illness Insurance"
  },
  [InsuranceProducts.DISABILITY_INSURANCE]: {
    icon: `/images/ci-icon.svg`,
    description:
      "This insurance product replaces your income if you experience a disability. A disability is any condition that prevents you from working such as an injury resulting from an accident, major depression, cancer and cardiovascular issues.",
    title: "Disability Insurance"
  },
  [InsuranceProducts.PERSONAL_ACCIDENT_INSURANCE]: {
    icon: `/images/ci-icon.svg`,
    description:
      "Personal Accident Insurance description Personal Accident Insurance description Personal Accident Insurance description Personal Accident Insurance description Personal Accident Insurance description.",
    title: "Personal Accident Insurance"
  },
  [InsuranceProducts.HEALTH_SPENDING_ACCOUNT]: {
    icon: `/images/ci-icon.svg`,
    description:
      "An HSA lets you to run your personal medical expenses through your business, giving you a huge tax advantage. You can also write off expenses that are usually not covered under standard health insurance plans, like mental health or orthodontics. If you have employees, HSAs are also great option to manage the cost of health benefits.",
    title: "Health Spending Account"
  }
};

export const BusinessInsuranceProductDetails = {
  [InsuranceProducts.LIFE_INSURANCE]: {
    type: "Life Insurance",
    icon: `/images/life-icon.svg`,
    description:
      "Business owned life insurance is a policy that is owned, and paid for by a business. It provides a tax-free and lump sum amount to help your business continue to operate in case of your premature death.",
    title: "Business Owned Life Insurance"
  }
};
