import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import P from "Common/P";
import Button from "Common/Button";
import Select from "Common/Select";
import Label from "Common/Label";
import Input from "Common/Input";

import ApplicationsStyles from "../../Applications.module.css";
import NumberFormat from "react-number-format";

const BusinessOtherPolicyInfoSchema = Yup.object().shape({
  are_people_insured: Yup.boolean().required("This field is required"),
  people_policy_provider: Yup.string().when("are_people_insured", {
    is: true,
    then: Yup.string().required("This field is required")
  }),
  people_policy_type: Yup.string().when("are_people_insured", {
    is: true,
    then: Yup.string().required("This field is required")
  }),
  people_policy_amount: Yup.number().when("are_people_insured", {
    is: true,
    then: Yup.number().required("This field is required")
  }),
  people_policy_issued_past_year: Yup.boolean().when("are_people_insured", {
    is: true,
    then: Yup.boolean().required("This field is required")
  }),
  people_policy_issue_year: Yup.string().when(
    "people_policy_issued_past_year",
    {
      is: true,
      then: Yup.string().required("This field is required")
    }
  ),
  people_replace_policy: Yup.boolean().when("people_policy_issued_past_year", {
    is: true,
    then: Yup.boolean().required("This field is required")
  }),
  people_business_or_personal_policy: Yup.string().when("are_people_insured", {
    is: true,
    then: Yup.string().required("This field is required")
  }),

  has_applied_not_issued: Yup.boolean().required("This field is required"),
  applied_policy_provider: Yup.string().when("has_applied_not_issued", {
    is: true,
    then: Yup.string().required("This field is required")
  }),
  applied_policy_type: Yup.string().when("has_applied_not_issued", {
    is: true,
    then: Yup.string().required("This field is required")
  }),
  applied_policy_amount: Yup.number().when("has_applied_not_issued", {
    is: true,
    then: Yup.number().required("This field is required")
  }),
  applied_business_or_personal_policy: Yup.string().when(
    "has_applied_not_issued",
    {
      is: true,
      then: Yup.string().required("This field is required")
    }
  )
});

const BusinessOtherPolicyInfo = ({ match, data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Other Policies</StylizedHeading>

      <P className={ApplicationsStyles.SmallText}>
        Other than group insurance, are any people to be insured covered under
        other life, critical illness, disability, or long term care insurance
        policies? Also include policies that: lapsed within the past 90 days,
        were sold to a third party, or were issued in another country.
      </P>

      <Formik
        initialValues={{
          are_people_insured: data?.are_people_insured || "",
          people_policy_provider: data?.people_policy_provider || "",
          people_policy_type: data?.people_policy_type || "",
          people_policy_amount: data?.people_policy_amount || "",
          people_policy_issued_past_year:
            data?.people_policy_issued_past_year || "",
          people_policy_issue_year: data?.people_policy_issue_year || "",
          people_replace_policy: data?.people_replace_policy || "",
          people_business_or_personal_policy:
            data?.people_business_or_personal_policy || "",

          has_applied_not_issued: data?.has_applied_not_issued || "",
          applied_policy_provider: data?.applied_policy_provider || "",
          applied_policy_type: data?.applied_policy_type || "",
          applied_policy_amount: data?.applied_policy_amount || "",
          applied_business_or_personal_policy:
            data?.applied_business_or_personal_policy || ""
        }}
        validateOnMount
        validationSchema={BusinessOtherPolicyInfoSchema}
      >
        {({ values, isValid, errors, setFieldValue, handleBlur }) => (
          <>
            <Form className={ApplicationsStyles.Form}>
              <div className={ApplicationsStyles.FormGroup}>
                <Field
                  as={Select}
                  name="are_people_insured"
                  onChange={(event) =>
                    setFieldValue(
                      "are_people_insured",
                      JSON.parse(event.target.value),
                      true
                    )
                  }
                >
                  <option></option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Field>
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="are_people_insured"
                />
              </div>

              {values.are_people_insured && (
                <>
                  <div className={ApplicationsStyles.FormGroup}>
                    <Label className={ApplicationsStyles.Label}>
                      Policy Provider
                    </Label>
                    <Field as={Input} name="people_policy_provider" />
                    <ErrorMessage
                      component="div"
                      className={ApplicationsStyles.Error}
                      name="people_policy_provider"
                    />
                  </div>

                  <div className={ApplicationsStyles.FormGroup}>
                    <Label className={ApplicationsStyles.Label}>
                      Policy Type
                    </Label>
                    <Field as={Select} name="people_policy_type">
                      <option></option>
                      <option value="Life Insurance">Life Insurance</option>
                      <option value="Critical Illness Insurance">
                        Critical Illness Insurance
                      </option>
                      <option value="Disability Insurance">
                        Disability Insurance
                      </option>
                      <option value="Long-term Care Insurance">
                        Long-term Care Insurance
                      </option>
                    </Field>
                    <ErrorMessage
                      component="div"
                      className={ApplicationsStyles.Error}
                      name="people_policy_type"
                    />
                  </div>

                  <div className={ApplicationsStyles.FormGroup}>
                    <Label className={ApplicationsStyles.Label}>
                      Policy Amount
                    </Label>
                    <NumberFormat
                      customInput={Input}
                      name="people_policy_amount"
                      defaultValue={0}
                      value={values.amount}
                      onValueChange={(event) =>
                        setFieldValue(
                          "people_policy_amount",
                          event.value,
                          false
                        )
                      }
                      prefix={"$ "}
                      decimalScale={0}
                      allowNegative={false}
                      thousandSeparator={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={true}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      component="div"
                      className={ApplicationsStyles.Error}
                      name="people_policy_amount"
                    />
                  </div>

                  <div className={ApplicationsStyles.FormGroup}>
                    <Label className={ApplicationsStyles.Label}>
                      Has the policy been issued in the last 12 months?
                    </Label>
                    <Field
                      as={Select}
                      name="people_policy_issued_past_year"
                      onChange={(event) =>
                        setFieldValue(
                          "people_policy_issued_past_year",
                          JSON.parse(event.target.value),
                          true
                        )
                      }
                    >
                      <option></option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </Field>
                    <ErrorMessage
                      component="div"
                      className={ApplicationsStyles.Error}
                      name="people_policy_issued_past_year"
                    />
                  </div>

                  {values.people_policy_issued_past_year && (
                    <>
                      <div className={ApplicationsStyles.FormGroup}>
                        <Label className={ApplicationsStyles.Label}>
                          Issue/Application year
                        </Label>
                        <NumberFormat
                          customInput={Input}
                          name="people_policy_issue_year"
                          defaultValue={0}
                          value={values.people_policy_issue_year}
                          onValueChange={(event) =>
                            setFieldValue(
                              "people_policy_issue_year",
                              event.value,
                              false
                            )
                          }
                          maxLength={4}
                          allowNegative={false}
                          thousandSeparator={false}
                          allowLeadingZeros={false}
                          allowEmptyFormatting={true}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          component="div"
                          className={ApplicationsStyles.Error}
                          name="people_policy_issue_year"
                        />
                      </div>

                      <div className={ApplicationsStyles.FormGroup}>
                        <Label className={ApplicationsStyles.Label}>
                          Is this application intended to replace this policy?
                        </Label>
                        <Field
                          as={Select}
                          name="people_replace_policy"
                          onChange={(event) =>
                            setFieldValue(
                              "people_replace_policy",
                              JSON.parse(event.target.value),
                              true
                            )
                          }
                        >
                          <option></option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          className={ApplicationsStyles.Error}
                          name="people_replace_policy"
                        />
                      </div>
                    </>
                  )}

                  <div className={ApplicationsStyles.FormGroup}>
                    <Label className={ApplicationsStyles.Label}>
                      Is this a business or personal policy?
                    </Label>
                    <Field
                      as={Select}
                      name="people_business_or_personal_policy"
                    >
                      <option></option>
                      <option value="business">Business</option>
                      <option value="personal">Personal</option>
                    </Field>
                    <ErrorMessage
                      component="div"
                      className={ApplicationsStyles.Error}
                      name="people_business_or_personal_policy"
                    />
                  </div>
                </>
              )}

              {/* Start of second section */}

              <div className={ApplicationsStyles.FormGroup}>
                <P className={ApplicationsStyles.SmallText}>
                  Have you applied for any other insurance that has not yet been
                  issued? Include life, critical illness, disability, or long
                  term care insurance.
                </P>
              </div>

              <div className={ApplicationsStyles.FormGroup}>
                <Field
                  as={Select}
                  name="has_applied_not_issued"
                  onChange={(event) =>
                    setFieldValue(
                      "has_applied_not_issued",
                      JSON.parse(event.target.value),
                      true
                    )
                  }
                >
                  <option></option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Field>
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="has_applied_not_issued"
                />
              </div>

              {values.has_applied_not_issued && (
                <>
                  <div className={ApplicationsStyles.FormGroup}>
                    <Label className={ApplicationsStyles.Label}>
                      Policy Provider
                    </Label>
                    <Field as={Input} name="applied_policy_provider" />
                    <ErrorMessage
                      component="div"
                      className={ApplicationsStyles.Error}
                      name="applied_policy_provider"
                    />
                  </div>

                  <div className={ApplicationsStyles.FormGroup}>
                    <Label className={ApplicationsStyles.Label}>
                      Policy Type
                    </Label>
                    <Field as={Select} name="applied_policy_type">
                      <option></option>
                      <option value="Life Insurance">Life Insurance</option>
                      <option value="Critical Illness Insurance">
                        Critical Illness Insurance
                      </option>
                      <option value="Disability Insurance">
                        Disability Insurance
                      </option>
                      <option value="Long-term Care Insurance">
                        Long-term Care Insurance
                      </option>
                    </Field>
                    <ErrorMessage
                      component="div"
                      className={ApplicationsStyles.Error}
                      name="applied_policy_type"
                    />
                  </div>

                  <div className={ApplicationsStyles.FormGroup}>
                    <Label className={ApplicationsStyles.Label}>
                      Policy Amount
                    </Label>
                    <NumberFormat
                      customInput={Input}
                      name="applied_policy_amount"
                      defaultValue={0}
                      value={values.amount}
                      onValueChange={(event) =>
                        setFieldValue(
                          "applied_policy_amount",
                          event.value,
                          false
                        )
                      }
                      prefix={"$ "}
                      decimalScale={0}
                      allowNegative={false}
                      thousandSeparator={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={true}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      component="div"
                      className={ApplicationsStyles.Error}
                      name="applied_policy_amount"
                    />
                  </div>

                  <div className={ApplicationsStyles.FormGroup}>
                    <Label className={ApplicationsStyles.Label}>
                      Is this a business or personal policy?
                    </Label>
                    <Field
                      as={Select}
                      name="applied_business_or_personal_policy"
                    >
                      <option></option>
                      <option value="business">Business</option>
                      <option value="personal">Personal</option>
                    </Field>
                    <ErrorMessage
                      component="div"
                      className={ApplicationsStyles.Error}
                      name="applied_business_or_personal_policy"
                    />
                  </div>
                </>
              )}

              <pre>{JSON.stringify(values, null, 2)}</pre>
              <pre>{JSON.stringify(isValid, null, 2)}</pre>
              <pre>{JSON.stringify(errors, null, 2)}</pre>
            </Form>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                className={ApplicationsStyles.NextButton}
                onClick={(event) => {
                  event.preventDefault();
                  next();
                }}
                disabled={!isValid}
              >
                Next
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default BusinessOtherPolicyInfo;
