import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "../../Common/StylizedHeading";
import Label from "../../Common/Label";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import P from "Common/P";

import { forgotPassword } from "store/actions/Auth.actions";

import styles from "./ForgotPassword.module.css";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
});

const ForgotPasswordView = () => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(forgotPassword(values.email))
      .catch((e) => {
        setError(e.message);
      })
      .then(() => setSuccess(true))
      .catch((e) => setError(e.message));
  };

  return (
    <div className={styles.Container}>
      <div className={styles.FormWrapper}>
        <StylizedHeading>Forgot Password</StylizedHeading>

        {!success && (
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className={styles.Form}>
                {error && <p className="danger">{error}</p>}

                <div className={styles.Field}>
                  <div className={styles.Label}>
                    <Label>Email</Label>
                  </div>
                  <Field as={Input} name="email" type="email" />
                  <div className={styles.Error}>
                    <ErrorMessage name="email" />
                  </div>
                </div>

                <div className={styles.ButtonWrapper}>
                  <Button fullWidth type="submit">
                    Continue
                  </Button>
                </div>

                <div className={styles.LinkWrapper}>
                  <Link className={styles.Link} to="/auth/login">
                    Log In
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        )}

        {success && (
          <P className={styles.Message}>
            If you have an account with us, you will receive an email to reset
            your password.
          </P>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordView;
