import classNames from "classnames";

import ApplicationsStyles from "../../Applications.module.css";
import GetStartedStyles from "./GetStarted.module.css";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Button from "Common/Button";
import P from "Common/P";

const GetStarted = ({ data, save, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Health Spending Account Application</StylizedHeading>

      <P>
        To be eligible for a Health Spending Account, you should have an
        incorporated and active business.
      </P>

      <div className={GetStartedStyles.Checkbox}>
        <Label htmlFor="eligibility" className={GetStartedStyles.Label}>
          I have an incorporated and active business
        </Label>
        <Input
          id="eligibility"
          name="eligibility"
          type="checkbox"
          checked={data || false}
          onChange={() => save(!data)}
        />
      </div>

      <P
        className={classNames(
          ApplicationsStyles.SmallText,
          GetStartedStyles.Agreement
        )}
      >
        Bounc3 collects personal information to assess product needs and
        facilitate insurance and other product applications. By clicking “start
        application”, you agree to our{" "}
        <a
          href="https://bounc3.io/page/terms-conditions"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href="https://bounc3.io/page/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        .
      </P>

      <Button
        disabled={!data}
        onClick={() => {
          next();
        }}
      >
        Start application
      </Button>
    </div>
  );
};

export default GetStarted;
