import classNames from "classnames";

import { InsuranceProductDetails } from "Core/models/Product.model";

import ApplicationsStyles from "../../Applications.module.css";
import GetStartedStyles from "./GetStarted.module.css";

import StylizedHeading from "Common/StylizedHeading";
import Button from "Common/Button";
import P from "Common/P";

const GetStarted = ({ product, save, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>{product}</StylizedHeading>

      <P>
        {
          Object.values(InsuranceProductDetails).find(
            (v) => v.title === product
          )?.description
        }
      </P>

      <P
        className={classNames(
          ApplicationsStyles.SmallText,
          GetStartedStyles.Agreement
        )}
      >
        Bounc3 collects personal information to assess product needs and
        facilitate insurance and other product applications. By clicking “start
        application”, you agree to our{" "}
        <a
          href="https://bounc3.io/page/terms-conditions"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href="https://bounc3.io/page/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        .
      </P>

      <Button
        className={ApplicationsStyles.Button}
        onClick={() => {
          save(true);
          next();
        }}
      >
        Start application
      </Button>
    </div>
  );
};

export default GetStarted;
