import React, { useEffect } from "react";
import { Router } from "react-router";

import { config } from "Config";
import History from "../Core/History";
// Views
import { AppRouter } from "./";

import "./index.css";

const App = () => {
  useEffect(() => {
    console.log(
      "%cBounc3",
      "background: blue; color: white;",
      `v${config.VERSION} - ${config.ENVIRONMENT}`
    );
  }, []);

  return (
    <Router history={History}>
      <AppRouter />;
    </Router>
  );
};

export default App;
