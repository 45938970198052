import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Select from "Common/Select";
import DateSelect from "Common/DateSelect";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";

const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  last_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  province: Yup.string().required("This field is required"),
  birth_date: Yup.date().required("This field is required"),
  gender: Yup.string().required("This field is required"),
});

const PersonalInfo = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Personal Info</StylizedHeading>

      <Formik
        initialValues={{
          first_name: data?.first_name || "",
          last_name: data?.last_name || "",
          email: data?.email || "",
          province: data?.province || "",
          birth_date: data?.birth_date || "",
          gender: data?.gender || "",
        }}
        onSubmit={(values) => {
          save(values);
          next();
        }}
        validateOnMount
        validationSchema={PersonalInfoSchema}
      >
        {({ values, setFieldValue }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>First Name</Label>
              <Field as={Input} name="first_name" placeholder="John" />
              <ErrorMessage
                component="div"
                name="first_name"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Last Name</Label>
              <Field as={Input} name="last_name" placeholder="Smith" />
              <ErrorMessage
                component="div"
                name="last_name"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Email</Label>
              <Field
                as={Input}
                name="email"
                type="email"
                placeholder="jsmith@example.ca"
              />
              <ErrorMessage
                component="div"
                name="email"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Province</Label>
              <Field
                as={Input}
                name="province"
                spellCheck="false"
                placeholder="ON"
              />
              <ErrorMessage
                component="div"
                name="province"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Date of Birth</Label>
              <Field
                as={DateSelect}
                maxYearCorrection={-16}
                name="birth_date"
                value={values.birth_date}
                onChange={(value) => setFieldValue("birth_date", value, true)}
              />
              <ErrorMessage
                component="div"
                name="birth_date"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Gender</Label>
              <Field as={Select} fullWidth name="gender" type="text">
                <option value={null}></option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="undisclosed">Prefer not to say</option>
              </Field>
              <ErrorMessage
                component="div"
                name="gender"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button type="submit" className={ApplicationsStyles.NextButton}>
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfo;
