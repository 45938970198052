import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import P from "Common/P";
import Label from "Common/Label";
import Input from "Common/Input";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";
// import EmployeeClassInfoStyles from "./EmployeeClassInfo.module.css";

const EmployeeClassInfo = ({ data, save, back }) => {
  const classIndex = Number(useParams().classIndex);

  const EmployeeClassInfoSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "This name is too short")
      .max(50, "This name is too long")
      .required("This field is required")
      .notOneOf(
        data
          .filter((c) => c.name !== data[classIndex]?.name)
          .map((c) => c.name),
        "This name is already being used"
      ),
    max_annual_claim: Yup.number().required("This field is required"),
  });

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Employee Category</StylizedHeading>

      <Formik
        initialValues={{
          name: data[classIndex]?.name || "",
          max_annual_claim: data[classIndex]?.max_annual_claim || 0,
        }}
        validateOnMount
        validationSchema={EmployeeClassInfoSchema}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Class Name</Label>
              <Field as={Input} name="name" />
              <ErrorMessage
                component="div"
                name="name"
                className={ApplicationsStyles.Error}
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Maximum Annual Claim
              </Label>
              <NumberFormat
                customInput={Input}
                name="max_annual_claim"
                defaultValue={0}
                value={values.max_annual_claim}
                onValueChange={(event) => {
                  setFieldValue("max_annual_claim", event.value, false);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                name="max_annual_claim"
                className={ApplicationsStyles.Error}
              />
            </div>

            <P className={ApplicationsStyles.SmallText}>
              It is highly recommended that claim limits be set at 10-15% of an
              employee’s annual income.{" "}
              <a
                href="https://bounc3.io/page/claim-amounts"
                target="_blank"
                rel="noreferrer"
              >
                Learn more here.
              </a>
            </P>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  back();
                }}
              >
                Cancel
              </Button>

              <Button
                className={ApplicationsStyles.NextButton}
                onClick={(event) => {
                  event.preventDefault();
                  const updatedData = [...data];
                  if (!data[classIndex]) {
                    updatedData.push(values);
                  } else {
                    updatedData.splice(classIndex, 1, values);
                  }
                  save(updatedData);
                  back();
                }}
                disabled={!isValid}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmployeeClassInfo;
