import classNames from "classnames";

import History from "../../../Core/History";

import StylizedHeading from "../../../Common/StylizedHeading";
import P from "Common/P";
import Button from "../../../Common/Button";
import Tooltip from "../../../Common/Tooltip";
import { ReactComponent as EditIcon } from "Icons/edit.svg";
import { ReactComponent as DeleteIcon } from "Icons/delete.svg";

import ApplicationsStyles from "../../Applications.module.css";
// import EmployeeDependantListStyles from "./EmployeeDependantList.module.css";

const EmployeeDependantList = ({ match, data, save, back }) => {
  const employeeIndex = Number(match.params.employeeIndex);

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>
        {data[employeeIndex]?.first_name +
          " " +
          data[employeeIndex]?.last_name +
          "'s Dependants"}
      </StylizedHeading>

      {data[employeeIndex]?.dependants &&
        data[employeeIndex]?.dependants.length > 0 && (
          <table className={ApplicationsStyles.Table}>
            <tbody>
              {data[employeeIndex].dependants.map((d, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <P>{d.full_name}</P>
                    </td>
                    <td>
                      <Tooltip
                        position=""
                        message="Make changes to this dependant."
                      >
                        <EditIcon
                          className={ApplicationsStyles.ActionText}
                          onClick={() => History.push(`${match.url}/${i}`)}
                        />
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip position="" message="Remove this dependant.">
                        <DeleteIcon
                          className={ApplicationsStyles.ActionText}
                          onClick={() => {
                            const updatedData = [...data];
                            updatedData[employeeIndex].dependants.splice(i, 1);
                            save(updatedData);
                          }}
                        />
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

      <div
        onClick={() => {
          History.push(`${match.url}/add`);
        }}
      >
        <P
          className={classNames(
            ApplicationsStyles.ActionText,
            ApplicationsStyles.AddItem
          )}
        >
          + Dependant
        </P>
      </div>

      <div className={ApplicationsStyles.ButtonWrapper}>
        <Button onClick={back}>Back</Button>
      </div>
    </div>
  );
};

export default EmployeeDependantList;
