import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import LoginView from "./LoginView";
import RegisterView from "./RegisterView";
import ForgotPasswordView from "./ForgotPasswordView";
import ResetPasswordView from "./ResetPasswordView";
import VerifyEmailView from "./VerifyEmailView";

const AuthRouter = ({ match }) => {
  return (
    <>
      <main>
        <Switch>
          <Route path={`${match.url}/login`} exact component={LoginView} />
          <Route
            path={`${match.url}/register`}
            exact
            component={RegisterView}
          />
          <Route
            path={`${match.url}/verify-email/:token`}
            exact
            component={VerifyEmailView}
          />
          <Route
            path={`${match.url}/forgot-password`}
            exact
            component={ForgotPasswordView}
          />
          <Route
            path={`${match.url}/reset-password`}
            exact
            component={ResetPasswordView}
          />
          <Redirect to={`${match.url}/login`} />
        </Switch>
      </main>
    </>
  );
};

export default AuthRouter;
