export const adminSelectApplicationState = (state) => state.adminApplications;

export const adminSelectApplicationList = (state) =>
  adminSelectApplicationState(state).adminApplicationList;

export const adminSelectApplication = (state) =>
  adminSelectApplicationState(state).adminApplication;

export const adminSelectApplicationIsLoading = (state) =>
  adminSelectApplicationState(state).isLoading;

export const adminSelectApplicationIsLoaded = (state) =>
  adminSelectApplicationState(state).isLoaded;
