import { version } from "../package.json";

const {
  REACT_APP_PUBLIC_CONTENTFUL_SPACE_ID,
  REACT_APP_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
  REACT_APP_GOOGLE_ANALYTICS,
  REACT_APP_GOOGLE_ADWORDS,
  REACT_APP_GOOGLE_ADWORDS_LABEL,
  REACT_APP_GOOGLE_TAG_MANAGER
} = process.env;

const envConfig = {
  REACT_APP_PUBLIC_CONTENTFUL_SPACE_ID,
  REACT_APP_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
  REACT_APP_GOOGLE_ANALYTICS,
  REACT_APP_GOOGLE_ADWORDS,
  REACT_APP_GOOGLE_ADWORDS_LABEL,
  REACT_APP_GOOGLE_TAG_MANAGER
};

const envs = {
  local: {
    VERSION: version,
    ENVIRONMENT: "local",
    // API_URL: "https://dev.api.bounc3.io/api",
    API_URL: "http://localhost:3000/api",

    devHSA: {
      eligible: true,
      personal_info: {
        first_name: "John",
        last_name: "Doe",
        email: "john@doe.com",
        province: "Ontario",
        birth_date: "1990-01-01",
        gender: "male"
      },
      company_info: {
        name: "John Doe Company",
        address: "123 Street",
        city: "Toronto",
        province: "Ontario",
        postal_code: "M1M1M1",
        phone: "4161234567",
        bank_transit_number: "12345",
        bank_institution_number: "123",
        bank_account_number: "1234567"
      },
      employee_class_info: [
        {
          max_annual_claim: 10
        }
      ],
      employee_info: [],
      program_settings: {}
    },

    ...envConfig
  },

  development: {
    VERSION: version,
    ENVIRONMENT: "development",
    API_URL: "https://dev.api.bounc3.io/api",
    devHSA: {
      eligible: true,
      personal_info: {
        first_name: "John",
        last_name: "Doe",
        email: "john@doe.com",
        province: "Ontario",
        birth_date: "1990-01-01",
        gender: "male"
      },
      company_info: {
        name: "John Doe Company",
        address: "123 Street",
        city: "Toronto",
        province: "Ontario",
        postal_code: "M1M1M1",
        phone: "4161234567",
        bank_transit_number: "12345",
        bank_institution_number: "123",
        bank_account_number: "1234567"
      },
      employee_class_info: [
        {
          max_annual_claim: 10
        }
      ],
      employee_info: [],
      program_settings: {}
    },
    ...envConfig
  },

  production: {
    VERSION: version,
    ENVIRONMENT: "production",
    API_URL: "https://api.bounc3.io/api",
    ...envConfig
  }
};

export const config = { ...envs[process.env.REACT_APP_ENV || "local"] };
