import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Button from "Common/Button";
import { Card, CardBody } from "Common/Card";
import { ReactComponent as FormIllustration } from "Icons/form-illustration.svg";

import style from "./HSAUpsell.module.css";

const HSAUpsell = () => {
  return (
    <Card className={style.Card}>
      <CardBody className={style.CardBody}>
        <h2>Apply for an HSA account today</h2>

        <p className={classNames(style.Description, "my")}>
          A Health Savings Account lets you run your personal medical expenses
          through your business, giving you a huge tax advantage. If you have
          employees, HSAs are also great option to manage the cost of health
          benefits.
        </p>

        <div className="my">
          <Link to="/applications/health-spending-account">
            <Button className={style.Button}>Apply now</Button>
          </Link>
        </div>

        <FormIllustration className={style.Illustration} />
      </CardBody>
    </Card>
  );
};

export default HSAUpsell;
