import * as fromAdminUsers from "../types/AdminUsers.types";

const initState = {
  adminUserList: [],
  adminUser: null,
  isLoading: false,
  isLoaded: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    // LIST
    case fromAdminUsers.ADMIN_LIST_USERS:
      return { ...state, isLoading: true };
    case fromAdminUsers.ADMIN_LIST_USERS_SUCCESS:
      return {
        ...state,
        adminUserList: action.payload,
        isLoaded: true,
        isLoading: false,
      };
    case fromAdminUsers.ADMIN_LIST_USERS_FAIL:
      return { ...state, isLoaded: false, isLoading: false };
    // GET
    case fromAdminUsers.ADMIN_GET_USER:
      return { ...state, isLoading: true };
    case fromAdminUsers.ADMIN_GET_USER_SUCCESS:
      return {
        ...state,
        adminUser: action.payload,
        isLoaded: true,
        isLoading: false,
      };
    case fromAdminUsers.ADMIN_GET_USER_FAIL:
      return { ...state, isLoaded: false, isLoading: false };
    // NOTE
    case fromAdminUsers.ADMIN_CREATE_USER_NOTE_SUCCESS:
      return {
        ...state,
        adminUser: { ...state.adminUser, note: action.payload },
        isLoaded: true,
        isLoading: false,
      };
    default:
      return state;
  }
}
