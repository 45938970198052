import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { getApplication } from "store/actions/Applications.actions";
import { selectApplication } from "store/selectors/Applications.selectors";

import styles from "./ApplicationView.module.css";

import Breadcrumbs from "Common/Breadcrumbs";
import ObjectViewer from "Common/ObjectViewer";
import { ApplicationType } from "Core/models/Application.model";

const ApplicationView = () => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const application = useSelector(selectApplication);

  const isBusiness = application.application_type === ApplicationType.BUSINESS;

  useEffect(
    () => dispatch(getApplication(applicationId)).catch((e) => console.log(e)),
    [dispatch, applicationId]
  );

  return (
    <div className={styles.Container}>
      <Helmet>
        <title>View Application | Bounc3</title>
      </Helmet>

      <Breadcrumbs
        links={[
          { name: "Home", path: "/dashboard" },
          {
            name: `View ${isBusiness && "Business"} ${
              application.product
            } Application`,
            path: "#"
          }
        ]}
        className={styles.Subnav}
      />

      <h2>
        {isBusiness && "Business"} {application.product} Application
      </h2>

      {application && <ObjectViewer object={application.submission_data} />}
    </div>
  );
};

export default ApplicationView;
