import * as API from "./API";

export function getCurrentUser() {
  return API.get(`/auth/me`);
}

export function login(email, password) {
  return API.post(`/auth/login`, { email, password }, {}, false);
}

export function register(email, password, firstName, lastName) {
  return API.post(
    `/auth/register`,
    { email, password, first_name: firstName, last_name: lastName },
    {},
    false
  );
}

export function verifyEmail(token) {
  return API.post(`/auth/email-confirmation`, { token }, {}, false);
}

export function forgotPassword(email) {
  return API.post(`/auth/forgot-password`, { email }, {}, false);
}

export function validatePasswordResetToken(token) {
  return API.post(`/auth/reset-password/validate`, { token }, {}, false);
}

export function confirmPasswordReset(token, password) {
  return API.post(
    `/auth/reset-password/confirm`,
    { token, password },
    {},
    false
  );
}
