export const BaseStorage = (storageStrategy) => ({
  setItem: (key, value) => {
    storageStrategy.setItem(key, JSON.stringify(value));
  },

  getItem: (key) => {
    let stringData = storageStrategy.getItem(key) || null;

    try {
      return JSON.parse(stringData);
    } catch (e) {
      return stringData;
    }
  },

  removeItem: (key) => {
    storageStrategy.removeItem(key);
  },

  clear: () => {
    storageStrategy.clear();
  }
});

export const SessionStorage = BaseStorage(sessionStorage);
export const LocalStorage = BaseStorage(localStorage);
