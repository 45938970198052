import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import History from "Core/History";
import { InsuranceProducts } from "Core/models/Product.model";

import Button from "Common/Button";
import QuickQuotes from "Common/QuickQuotes";

import styles from "./RecommendedProduct.module.css";

const RecommendedProduct = ({
  productKey,
  riskProfileData,
  icon,
  level,
  title,
  description,
  reasons,
  dark,
}) => {
  const [product, setProduct] = useState();

  useEffect(() => {
    switch (productKey) {
      case "LI": {
        setProduct("life-insurance");
        break;
      }
      case "CI": {
        setProduct("critical-illness-insurance");
        break;
      }
      case "DI": {
        setProduct("disability-insurance");
        break;
      }
      default: {
        break;
      }
    }
  }, [productKey]);

  return (
    <div
      className={classNames(styles.RecommendedProduct, { [styles.Dark]: dark })}
    >
      <div className={styles.RecommendedProductGraphic}>
        <img src={icon} alt="Recommended product" />
        <div className={styles.RecommendedProductLevel}>{level}</div>
        <div className={styles.RecommendedProductTitle}>{title}</div>

        <p>{description}</p>
      </div>
      <div className={styles.RecommendedProductReason}>
        <h3>Why you need it</h3>
        {reasons.map((reason) => (
          <p>{reason}</p>
        ))}

        {productKey !== InsuranceProducts.HEALTH_SPENDING_ACCOUNT &&
          productKey !== InsuranceProducts.LIFE_INSURANCE && (
            <Link to={`/quote?product=${product}`}>
              <Button>Get a quote</Button>
            </Link>
          )}

        {productKey === InsuranceProducts.HEALTH_SPENDING_ACCOUNT && (
          <div>
            <Button
              onClick={() =>
                History.push("/applications/health-spending-account")
              }
            >
              Apply
            </Button>
          </div>
        )}

        {productKey === InsuranceProducts.LIFE_INSURANCE && (
          <QuickQuotes
            birthday={riskProfileData.birthday}
            gender={riskProfileData.gender}
            isSmoker={riskProfileData.is_smoker}
            coverageAmount={riskProfileData.outstanding_debts}
          />
        )}
      </div>
    </div>
  );
};

export default RecommendedProduct;
