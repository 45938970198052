/**
 * Checks if the postal code is in a valid area
 * for Insurance tracking conversion:
 *
 *  K	Eastern Ontario
 *  L	Central Ontario
 *  M	Metropolitan Toronto
 *  N	Southwestern Ontario
 *  P	Northern Ontario
 *  T	Alberta
 *  V	British Columbia
 *
 *  https://www150.statcan.gc.ca/n1/pub/92-195-x/2011001/other-autre/pc-cp/tbl/tbl9-eng.htm
 *
 * @param {*} postalCode
 */

export function isPostalCodeValidArea(postalCode) {
  if (!postalCode) return false;

  const validAreas = ["k", "l", "m", "n", "p", "t", "v"];

  const firstLetter = postalCode[0];

  return validAreas.includes(firstLetter.toLowerCase());
}
