import React from "react";
import classNames from "classnames";

import { InsuranceProducts } from "Core/models/Product.model";
import Label from "Common/Label";
import Button from "Common/Button";
import Input from "Common/Input";

import riskStyles from "../RiskProfile.module.css";

import styles from "./InsuranceInfo.module.css";
import { useFormik } from "formik";

const InsuranceInfo = ({ data, save, previous, next }) => {
  const formik = useFormik({
    initialValues: {
      [InsuranceProducts.LIFE_INSURANCE]:
        data?.[InsuranceProducts.LIFE_INSURANCE] || false,
      [InsuranceProducts.DISABILITY_INSURANCE]:
        data?.[InsuranceProducts.DISABILITY_INSURANCE] || false,
      [InsuranceProducts.CRITICAL_ILLNESS_INSURANCE]:
        data?.[InsuranceProducts.CRITICAL_ILLNESS_INSURANCE] || false,
      [InsuranceProducts.EXTENDED_HEALTH_INSURANCE]:
        data?.[InsuranceProducts.EXTENDED_HEALTH_INSURANCE] || false,
      [InsuranceProducts.HEALTH_SPENDING_ACCOUNT]:
        data?.[InsuranceProducts.HEALTH_SPENDING_ACCOUNT] || false,

      life_insurance_coverage: data?.life_insurance_coverage || 0,
      disability_insurance_coverage: data?.disability_insurance_coverage || 0,
    },
    validateOnChange: true,
  });

  return (
    <div className={classNames(riskStyles.FormContainer, styles.FormContainer)}>
      <div className={classNames(riskStyles.Field, riskStyles.Fluid)}>
        <div className={riskStyles.Label}>
          <Label>Do you currently have any insurance products?</Label>
        </div>

        <div className={riskStyles.RadioGroup}>
          <div className={riskStyles.RadioInput}>
            <label
              htmlFor={InsuranceProducts.LIFE_INSURANCE}
              className={classNames({
                [riskStyles.RadioSelected]:
                  formik.values[InsuranceProducts.LIFE_INSURANCE],
              })}
            >
              Life insurance
            </label>
            <input
              id={InsuranceProducts.LIFE_INSURANCE}
              type="checkbox"
              name={InsuranceProducts.LIFE_INSURANCE}
              value={formik.values[InsuranceProducts.LIFE_INSURANCE]}
              onChange={formik.handleChange}
              checked={formik.values[InsuranceProducts.LIFE_INSURANCE]}
            />
          </div>
          <div className={riskStyles.RadioInput}>
            <label
              htmlFor={InsuranceProducts.DISABILITY_INSURANCE}
              className={classNames({
                [riskStyles.RadioSelected]:
                  formik.values[InsuranceProducts.DISABILITY_INSURANCE],
              })}
            >
              Disability insurance
            </label>
            <input
              id={InsuranceProducts.DISABILITY_INSURANCE}
              type="checkbox"
              name={InsuranceProducts.DISABILITY_INSURANCE}
              value={formik.values[InsuranceProducts.DISABILITY_INSURANCE]}
              onChange={formik.handleChange}
              checked={formik.values[InsuranceProducts.DISABILITY_INSURANCE]}
            />
          </div>
          <div className={riskStyles.RadioInput}>
            <label
              htmlFor={InsuranceProducts.CRITICAL_ILLNESS_INSURANCE}
              className={classNames({
                [riskStyles.RadioSelected]:
                  formik.values[InsuranceProducts.CRITICAL_ILLNESS_INSURANCE],
              })}
            >
              Critical illness insurance
            </label>
            <input
              id={InsuranceProducts.CRITICAL_ILLNESS_INSURANCE}
              type="checkbox"
              name={InsuranceProducts.CRITICAL_ILLNESS_INSURANCE}
              value={
                formik.values[InsuranceProducts.CRITICAL_ILLNESS_INSURANCE]
              }
              onChange={formik.handleChange}
              checked={
                formik.values[InsuranceProducts.CRITICAL_ILLNESS_INSURANCE]
              }
            />
          </div>

          <div className={riskStyles.RadioInput}>
            <label
              htmlFor={InsuranceProducts.EXTENDED_HEALTH_INSURANCE}
              className={classNames({
                [riskStyles.RadioSelected]:
                  formik.values[InsuranceProducts.EXTENDED_HEALTH_INSURANCE],
              })}
            >
              Extended Health Insurance
            </label>
            <input
              id={InsuranceProducts.EXTENDED_HEALTH_INSURANCE}
              type="checkbox"
              name={InsuranceProducts.EXTENDED_HEALTH_INSURANCE}
              value={formik.values[InsuranceProducts.EXTENDED_HEALTH_INSURANCE]}
              onChange={formik.handleChange}
              checked={
                formik.values[InsuranceProducts.EXTENDED_HEALTH_INSURANCE]
              }
            />
          </div>

          <div className={riskStyles.RadioInput}>
            <label
              htmlFor={InsuranceProducts.HEALTH_SPENDING_ACCOUNT}
              className={classNames({
                [riskStyles.RadioSelected]:
                  formik.values[InsuranceProducts.HEALTH_SPENDING_ACCOUNT],
              })}
            >
              Health Spending Account
            </label>
            <input
              id={InsuranceProducts.HEALTH_SPENDING_ACCOUNT}
              type="checkbox"
              name={InsuranceProducts.HEALTH_SPENDING_ACCOUNT}
              value={formik.values[InsuranceProducts.HEALTH_SPENDING_ACCOUNT]}
              onChange={formik.handleChange}
              checked={formik.values[InsuranceProducts.HEALTH_SPENDING_ACCOUNT]}
            />
          </div>
        </div>
      </div>

      <div className={styles.CoverageContainer}>
        {formik.values[InsuranceProducts.LIFE_INSURANCE] && (
          <div className={riskStyles.Field}>
            <div className={riskStyles.Label}>
              <Label>
                How much coverage do you currently have under your life policy?
              </Label>
            </div>
            <Input
              name="life_insurance_coverage"
              placeholder="0"
              prefix="$"
              value={formik.values.life_insurance_coverage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        )}
      </div>

      <div className={riskStyles.ButtonWrapper}>
        <Button
          type="button"
          className={riskStyles.PreviousButton}
          onClick={(event) => {
            event.preventDefault();
            save(formik.values);
            previous();
          }}
        >
          Previous
        </Button>

        <Button
          type="submit"
          className={riskStyles.NextButton}
          onClick={(event) => {
            event.preventDefault();
            save(formik.values);
            next();
          }}
          disabled={
            formik.values[InsuranceProducts.LIFE_INSURANCE] &&
            !formik.values.life_insurance_coverage
          }
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default InsuranceInfo;
