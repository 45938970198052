import ky from "ky";

import { config } from "../../Config";

import { LocalStorage } from "./Storage";

let authToken = LocalStorage.getItem("auth_token");

export async function get(url, options = {}, includeAuthHeader = true) {
  let headers = {};

  if (options && options.params) {
    Object.keys(options.params).forEach((key) => {
      if (
        options.params[key] === null ||
        options.params[key] === undefined ||
        options.params[key] === ""
      ) {
        delete options.params[key];
      }
    });

    options.searchParams = { ...options.params };
    delete options.params;
  }

  if (includeAuthHeader) {
    headers = createAuthorizationHeader(headers);
  }

  options = mergeOptionsAndHeaders(options, headers);

  try {
    return await ky.get(buildUrl(url), options).json();
  } catch (e) {
    return handleAPIError(e);
  }
}

export async function post(
  url,
  data = {},
  options = {},
  includeAuthHeader = true,
) {
  let headers = {};

  if (includeAuthHeader) {
    headers = createAuthorizationHeader(headers);
  }

  options = mergeOptionsAndHeaders(options, headers);

  try {
    return await ky.post(buildUrl(url), { ...options, json: data }).json();
  } catch (e) {
    return handleAPIError(e);
  }
}

export async function put(
  url,
  data = {},
  options = {},
  includeAuthHeader = true,
) {
  let headers = {};

  if (includeAuthHeader) {
    headers = createAuthorizationHeader(headers);
  }

  options = mergeOptionsAndHeaders(options, headers);

  try {
    return await ky.put(buildUrl(url), { ...options, json: data }).json();
  } catch (e) {
    return handleAPIError(e);
  }
}

export async function patch(
  url,
  data = {},
  options = {},
  includeAuthHeader = true,
) {
  let headers = {};

  if (includeAuthHeader) {
    headers = createAuthorizationHeader(headers);
  }

  options = mergeOptionsAndHeaders(options, headers);

  try {
    return await ky.patch(buildUrl(url), { ...options, json: data }).json();
  } catch (e) {
    return handleAPIError(e);
  }
}

export async function del(
  url,
  data = {},
  options = {},
  includeAuthHeader = true,
) {
  let headers = {};

  if (includeAuthHeader) {
    headers = createAuthorizationHeader(headers);
  }

  options = mergeOptionsAndHeaders(options, headers);

  try {
    return await ky.delete(buildUrl(url), { ...options, json: data }).json();
  } catch (e) {
    return handleAPIError(e);
  }
}

export function buildUrl(str) {
  return config.API_URL + str;
}

export function getToken() {
  return authToken;
}

export function setToken(token) {
  authToken = token;
  LocalStorage.setItem("auth_token", token);
}

function createAuthorizationHeader(headers) {
  const token = authToken;

  if (token) {
    headers[`Authorization`] = `Bearer ${token}`;
  }

  return headers;
}

function mergeOptionsAndHeaders(options, headers) {
  if (options) {
    options = Object.assign(options, { headers });
  } else {
    options = Object.assign({}, { headers });
  }

  return options;
}

async function handleAPIError(err) {
  console.log(err);
  if (err.response) {
    const { status } = err.response;

    if (status === 500) {
      const newError = {
        message: "Something has gone wrong",
      };

      throw newError;
    }
    const errors = await err.response.json();
    throw errors;
  }

  throw err;
}
