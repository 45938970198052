import classNames from "classnames";

import History from "Core/History";

import StylizedHeading from "../../../Common/StylizedHeading";
import P from "Common/P";
import Button from "../../../Common/Button";
import Tooltip from "../../../Common/Tooltip";
import { ReactComponent as EditIcon } from "Icons/edit.svg";
import { ReactComponent as DeleteIcon } from "Icons/delete.svg";
import { ReactComponent as DependantsIcon } from "Icons/dependants.svg";

import ApplicationsStyles from "../../Applications.module.css";

const EmployeeList = ({ match, data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Employees</StylizedHeading>

      <P className={ApplicationsStyles.SmallText}>
        If you have employees, you can add them to your HSA plan now, or you can
        save this for later. Even if you are the only one using the account, you
        can add yourself as an employee.
      </P>

      {data && data.length > 0 && (
        <table className={ApplicationsStyles.Table}>
          <tbody>
            {data.map((e, i) => {
              return (
                <tr key={i}>
                  <td>
                    <P>{e.first_name + " " + e.last_name}</P>
                  </td>
                  <td>
                    <Tooltip
                      position=""
                      message="Manage this employee's dependants."
                      className={ApplicationsStyles.ActionWrapper}
                    >
                      <DependantsIcon
                        onClick={() => {
                          History.push(`${match.url}/${i}/dependants`);
                        }}
                        className={ApplicationsStyles.ActionText}
                      />
                      <div className={ApplicationsStyles.Badge}>
                        {e.dependants?.length || "0"}
                      </div>
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip
                      position=""
                      message="Make changes to this employee."
                    >
                      <EditIcon
                        className={ApplicationsStyles.ActionText}
                        onClick={() => {
                          History.push(`${match.url}/${i}`);
                        }}
                      />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip position="" message="Remove this employee.">
                      <DeleteIcon
                        className={ApplicationsStyles.ActionText}
                        onClick={() => {
                          const updatedData = [...data];
                          updatedData.splice(i, 1);
                          save(updatedData);
                        }}
                      />
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      <div
        onClick={() => {
          History.push(`${match.url}/add`);
        }}
      >
        <P
          className={classNames(
            ApplicationsStyles.ActionText,
            ApplicationsStyles.AddItem
          )}
        >
          + Employee
        </P>
      </div>

      <div className={ApplicationsStyles.ButtonWrapper}>
        <Button
          className={ApplicationsStyles.PreviousButton}
          onClick={() => {
            previous();
          }}
        >
          Previous
        </Button>

        <Button
          className={ApplicationsStyles.NextButton}
          onClick={() => {
            next();
          }}
          disabled={false}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default EmployeeList;
