import classNames from "classnames";

import styles from "./Button.module.css";

export default function Button({
  className,
  plain,
  outline,
  fullWidth,
  linkTo,
  target,
  children,
  ...props
}) {
  const classes = classNames(
    outline && styles.outline,
    plain && styles.plain,
    fullWidth && styles.fullWidth,
    className,
    styles.Button
  );

  if (linkTo) {
    return (
      <a href={linkTo} target={target} className={classes} {...props}>
        {children}
      </a>
    );
  }

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
}
