import classNames from "classnames";

import Label from "Common/Label";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";

import riskStyles from "../RiskProfile.module.css";

const SmokeInfo = ({ data, save, next }) => {
  return (
    <div className={riskStyles.FormContainer}>
      <div className={classNames(riskStyles.Field, riskStyles.Fluid)}>
        <div className={riskStyles.Label}>
          <Label>Have you smoked in the last 12 months?</Label>{" "}
          <Tooltip message="Most insurance companies will categorize you as a smoker if your regularly use tobacco or nicotine products such as cigarettes, e-cigarettes or vapes, cigars, chewing tobacco, nicotine gum, or patches. But the status is not permanent. If you quit smoking, you may be eligible to be re-classified as a non-smoker after 12 months. If you smoke marijuana, it is possible to be classified as a non-smoker under certain conditions.">
            <div className={riskStyles.InfoIcon}>i</div>
          </Tooltip>
        </div>

        <div className={riskStyles.RadioGroup}>
          <div className={riskStyles.RadioInput}>
            <label
              htmlFor="yes"
              className={classNames({
                [riskStyles.RadioSelected]: data === true,
              })}
            >
              Yes
            </label>
            <input
              id="yes"
              type="radio"
              name="is_smoker"
              value={data}
              onChange={() => save(true)}
              checked={data === true}
            />
          </div>

          <div className={riskStyles.RadioInput}>
            <label
              htmlFor="no"
              className={classNames({
                [riskStyles.RadioSelected]: data === false,
              })}
            >
              No
            </label>
            <input
              id="no"
              type="radio"
              name="is_smoker"
              value={data}
              onChange={() => save(false)}
              checked={data === false}
            />
          </div>
        </div>
      </div>

      <div className={riskStyles.ButtonWrapper}>
        <Button
          type="submit"
          className={riskStyles.NextButton}
          onClick={next}
          disabled={typeof data !== "boolean"}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default SmokeInfo;
