import { useEffect, useState } from "react";

import History from "Core/History";
import { getIP, requestLifeInsuranceQuote } from "Core/services/Quote.service";
import QuoteItem from "QuoteForm/QuoteItem";
import { event, track } from "utils/gtag";
import Loader from "Common/Loader";
import Label from "Common/Label";
import Select from "Common/Select";
import P from "Common/P";

let debounce = null;

const QuickQuotes = ({
  postalCode,
  birthday,
  gender,
  isSmoker,
  coverageAmount = "1000000",
  policyLength = "monthly",
  coverageTerm = "10 years"
}) => {
  const [quotes, setQuotes] = useState([]);
  const [term, setTerm] = useState(coverageTerm);
  const [amount, setAmount] = useState(coverageAmount);
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = (quote) => {
    event("insurance_apply");
    track({
      insuranceType: "Life Insurance",
      brand: quote.company,
      productName: quote.product_name,
      price: quote.premium_monthly
    });

    History.push(`/applications/life-insurance/${quote.provider}`, {
      data: {
        postalCode,
        birthday,
        gender,
        isSmoker,
        coverageAmount: amount,
        policyLength,
        coverageTerm: term
      },
      quote
    });
  };

  useEffect(() => {
    if (debounce) {
      clearTimeout(debounce);
    }

    debounce = setTimeout(() => {
      setIsLoading(true);
      getIP().then(({ IPADDRESS }) => {
        // Defaults
        requestLifeInsuranceQuote({
          postal_code: postalCode,
          birth_date: birthday,
          sex: gender,
          is_smoker: isSmoker,
          coverage_amount: amount,
          policy_length: policyLength,
          coverage_term: term,
          remote_ip: IPADDRESS
        })
          .then((data) => {
            let sortedData = data.sort((a, b) => {
              return parseFloat(a.premium_monthly) >
                parseFloat(b.premium_monthly)
                ? 1
                : -1;
            });

            setQuotes(sortedData);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }, 400);

    return () => {
      clearTimeout(debounce);
    };
  }, [postalCode, birthday, term, amount, gender, isSmoker, policyLength]);

  return (
    <div>
      <div className="my">
        <Label>Coverage Term</Label>
        <Select
          fullWidth
          name="coverageTerm"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
        >
          <option value="1 year">1 Year</option>
          <option value="10 years">10 Years</option>
          <option value="15 years">15 Years</option>
          <option value="20 years">20 Years</option>
          <option value="to age 65">To Age 65</option>
        </Select>
      </div>

      <div className="my">
        <Label>Coverage Amount: </Label>
        <P>${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</P>
        <input
          name="coverageAmount"
          type="range"
          min="100000"
          max="5000000"
          step="25000"
          value={amount}
          width="100%"
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>

      {isLoading && <Loader />}

      {!isLoading &&
        quotes &&
        quotes.map((quote, index) => (
          <QuoteItem
            small
            key={index}
            expandable={false}
            company={quote.company}
            premium={quote.premium_monthly}
            productName={quote.product_name}
            logo={quote.logos.medium}
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(quote);
            }}
          />
        ))}
    </div>
  );
};

export default QuickQuotes;
