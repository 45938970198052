import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { getMainMenu } from "Core/services/Contentful.service";
import { logout } from "store/actions/Auth.actions";
import { selectCurrentUser } from "store/selectors/Auth.selectors";
import useViewport from "hooks/viewport";
import { isAdmin } from "utils/permissions";

import { ReactComponent as Chevron } from "../../Icons/chevron.svg";

import styles from "./Nav.module.css";

const quoteLink = "https://app.bounc3.io/quote";

const DesktopMenu = ({ mainMenu, authed, toggleMenu, handleLogout }) => {
  const user = useSelector(selectCurrentUser);

  return (
    <div className={styles.MenuFlex}>
      <ul className={styles.DesktopMenu}>
        {mainMenu?.menuItemsCollection?.items?.map((menuItem) => {
          if (authed && menuItem.label === "Log In") return null;

          const hasSubMenu =
            menuItem?.submenu?.menuItemsCollection?.items?.length > 0;
          return (
            <li className={styles.MenuItem}>
              <a
                href={menuItem.url}
                key={menuItem.label}
                className={classnames(styles.DesktopMenuItem)}
              >
                {menuItem.label}

                {hasSubMenu && <Chevron className={styles.Chevron} />}
              </a>

              {hasSubMenu && (
                <div className={styles.NavDropdown}>
                  <ul className={styles.NavDropdownList}>
                    {menuItem?.submenu?.menuItemsCollection?.items?.map(
                      (subMenuItem) => (
                        <li className={styles.NavDropdownListItem}>
                          <a
                            href={subMenuItem.url}
                            key={subMenuItem.label}
                            className={classnames(styles.DesktopMenuItem)}
                          >
                            {subMenuItem.label}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </li>
          );
        })}

        {authed && (
          <>
            <li className={styles.MenuItem}>
              <Link to="/" key="My Account" className={styles.DesktopMenuItem}>
                My Account
              </Link>
            </li>

            {isAdmin(user) && (
              <li className={styles.MenuItem}>
                <Link
                  to="/admin"
                  key="Admin"
                  className={styles.DesktopMenuItem}
                >
                  Admin
                </Link>
              </li>
            )}

            <li className={styles.MenuItem}>
              <Link
                to="/"
                key="Log Out"
                className={styles.DesktopMenuItem}
                onClick={handleLogout}
              >
                Log Out
              </Link>
            </li>
          </>
        )}
      </ul>
      <div className={styles.FastQuote}>
        <a href={quoteLink}>Get Quote</a>
      </div>
    </div>
  );
};

const MobileMenu = ({
  menuOpen,
  mainMenu,
  authed,
  toggleMenu,
  handleLogout
}) => {
  const user = useSelector(selectCurrentUser);

  return (
    <>
      {menuOpen && (
        <div className={styles.MobileMenu}>
          <ul
            className={classnames(
              styles.MobileMenuItems,
              styles.MobileNavDropdownList
            )}
          >
            {mainMenu.menuItemsCollection.items.map((menuItem) => {
              if (authed && menuItem.label === "Log In") return null;

              const hasSubMenu =
                menuItem?.submenu?.menuItemsCollection?.items?.length > 0;

              return (
                <li className={styles.MobileMenuItem}>
                  <a
                    href={menuItem.url}
                    key={menuItem.label}
                    className={classnames(styles.MobileMenuItemLink)}
                  >
                    {menuItem.label}
                  </a>

                  {hasSubMenu && (
                    <div className={styles.MobileNavDropdown}>
                      <ul className={styles.MobileNavDropdownList}>
                        {menuItem?.submenu?.menuItemsCollection?.items?.map(
                          (subMenuItem) => (
                            <li className={styles.MobileNavDropdownListItem}>
                              <a
                                href={subMenuItem.url}
                                key={subMenuItem.label}
                                onClick={() => {
                                  toggleMenu();
                                  return true;
                                }}
                                className={classnames(
                                  styles.MobileMenuItemLink
                                )}
                              >
                                {subMenuItem.label}
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                </li>
              );
            })}

            {authed && (
              <>
                <li className={styles.MobileMenuItem}>
                  <Link
                    to="/"
                    key="My Account"
                    className={classnames(styles.MobileMenuItemLink)}
                    onClick={() => {
                      toggleMenu();
                      return true;
                    }}
                  >
                    My Account
                  </Link>
                </li>

                {isAdmin(user) && (
                  <li className={styles.MobileMenuItem}>
                    <Link
                      to="/admin"
                      key="Admin"
                      className={classnames(styles.MobileMenuItemLink)}
                      onClick={() => {
                        toggleMenu();
                        return true;
                      }}
                    >
                      Admin
                    </Link>
                  </li>
                )}

                <li className={styles.MobileMenuItem}>
                  <Link
                    to="/"
                    key="Log Out"
                    className={classnames(styles.MobileMenuItemLink)}
                    onClick={handleLogout}
                  >
                    Log Out
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      )}

      <div className={styles.FastQuote}>
        <a href={quoteLink}>Get Quote</a>
      </div>
    </>
  );
};

const Nav = ({ authed }) => {
  const breakpoint = 1024;
  const { width } = useViewport();
  const dispatch = useDispatch();
  const [mainMenu, setMainMenu] = useState([]);
  const [menuOpen, setMenuOpen] = useState();

  const isMobile = width <= breakpoint;

  useEffect(() => {
    getMainMenu().then((data) => {
      setMainMenu(data);
    });
  }, []);

  const toggleMenu = () => {
    setMenuOpen((v) => !v);
  };

  const handleLogout = () => {
    toggleMenu();
    dispatch(logout());
  };

  const renderMobileMenuButton = () => {
    if (!isMobile) return null;

    if (menuOpen) {
      return (
        <div className={styles.MobileMenuButton} onClick={toggleMenu}>
          <img src="/images/close-menu.svg" width={20} alt="Close menu" />
        </div>
      );
    } else {
      return (
        <button
          className={styles.MobileMenuButton}
          onClick={toggleMenu}
          type="button"
        >
          <img src="/images/open-menu.svg" width={20} alt="Open menu" />
        </button>
      );
    }
  };

  const renderMenu = () => {
    let Menu = DesktopMenu;

    if (isMobile) {
      Menu = MobileMenu;
    }

    return (
      <Menu
        menuOpen={menuOpen}
        mainMenu={mainMenu}
        authed={authed}
        toggleMenu={toggleMenu}
        handleLogout={handleLogout}
      />
    );
  };

  return (
    <nav className={styles.Nav}>
      <a href="https://bounc3.io" className={styles.LogoAnchor}>
        <img src="/images/logo.svg" width={140} height={30} alt="Bounc3 Logo" />
      </a>

      {renderMenu()}

      {renderMobileMenuButton()}
    </nav>
  );
};

export default Nav;
