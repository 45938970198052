export const ADMIN_LIST_USERS = "[Admin Users] Admin List Users";
export const ADMIN_LIST_USERS_SUCCESS =
  "[Admin Users] Admin List Users Success";
export const ADMIN_LIST_USERS_FAIL = "[Admin Users] Admin List Users Fail";

export const ADMIN_GET_USER = "[Admin Users] Admin Get User";
export const ADMIN_GET_USER_SUCCESS = "[Admin Users] Admin Get User Success";
export const ADMIN_GET_USER_FAIL = "[Admin Users] Admin Get User Fail";

export const ADMIN_UPDATE_USER = "[Admin Users] Admin Update User";
export const ADMIN_UPDATE_USER_SUCCESS =
  "[Admin Users] Admin Update User Success";
export const ADMIN_UPDATE_USER_FAIL = "[Admin Users] Admin Update User Fail";

export const ADMIN_CREATE_USER_NOTE = "[Admin Users] Admin Create User Note";
export const ADMIN_CREATE_USER_NOTE_SUCCESS =
  "[Admin Users] Admin Create User Note Success";
export const ADMIN_CREATE_USER_NOTE_FAIL =
  "[Admin Users] Admin Create User Note Fail";
