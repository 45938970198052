import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Textarea from "Common/Textarea";
import Select from "Common/Select";
import DateSelect from "Common/DateSelect";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";

const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  last_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits")
    .required("This field is required"),
  birth_date: Yup.date().required("This field is required"),
  birth_place: Yup.string().required("This field is required"),
  gender: Yup.string().required("This field is required"),
  is_smoker: Yup.boolean().required("This field is required"),
  language: Yup.string().required("This field is required"),
  residence_status: Yup.string()
    .required("This field is required")
    .oneOf(["Canadian citizen", "permanent resident", "other"]),
  purchase_reason: Yup.string()
    .required("This field is required")
    .oneOf(["replace income", "preserve estate value", "unsure", "other"]),
  other_purchase_reason: Yup.string().when("purchase_reason", {
    is: "other",
    then: Yup.string().required("This field is required"),
  }),
});

const PersonalInfo = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Personal Info</StylizedHeading>

      <Formik
        initialValues={{
          first_name: data?.first_name || "",
          last_name: data?.last_name || "",
          email: data?.email || "",
          phone: data?.phone || "",
          birth_date: data?.birth_date || "",
          birth_place: data?.birth_place || "",
          gender: data?.gender || "",
          is_smoker:
            typeof data?.is_smoker === "boolean" ? data?.is_smoker : "",
          language: data?.language || "",
          residence_status: data?.residence_status || "",
          purchase_reason: data?.purchase_reason || "",
          other_purchase_reason: data?.other_purchase_reason || "",
        }}
        onSubmit={(values) => {
          save(values);
          next();
        }}
        validateOnMount
        validationSchema={PersonalInfoSchema}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>First Name</Label>
              <Field as={Input} name="first_name" placeholder="John" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="first_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Last Name</Label>
              <Field as={Input} name="last_name" placeholder="Smith" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="last_name"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Email</Label>
              <Field
                as={Input}
                name="email"
                type="email"
                placeholder="jsmith@example.ca"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="email"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Phone</Label>
              <NumberFormat
                customInput={Input}
                name="phone"
                value={values.phone}
                onValueChange={(event) => {
                  setFieldValue("phone", event.value, false);
                }}
                format="(###) ###-####"
                decimalScale={0}
                allowNegative={false}
                allowEmptyFormatting={false}
                type="tel"
                placeholder="(123) 456-7890"
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="phone"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Date of Birth</Label>
              <Field
                as={DateSelect}
                maxYearCorrection={-16}
                name="birth_date"
                value={values.birth_date}
                onChange={(value) => setFieldValue("birth_date", value, true)}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="birth_date"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Place of Birth</Label>
              <Field as={Input} name="birth_place" placeholder="Canada" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="birth_place"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Gender</Label>
              <Field as={Select} name="gender" type="text">
                <option value={null}></option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="undisclosed">Prefer not to say</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="gender"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Do you smoke?</Label>
              <Field
                as={Select}
                name="is_smoker"
                onChange={(event) =>
                  setFieldValue("is_smoker", JSON.parse(event.target.value))
                }
              >
                <option value="" disabled hidden></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="is_smoker"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Language Preference
              </Label>
              <Field as={Select} name="language" type="text">
                <option value={null}></option>
                <option value="en">English</option>
                <option value="fr">French</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="language"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Residence Status
              </Label>
              <Field as={Select} name="residence_status">
                <option value="" disabled hidden></option>
                <option value="Canadian citizen">Canadian Citizen</option>
                <option value="permanent resident">Permanent Resident</option>
                <option value="other">Other</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="residence_status"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Purchase Reason
              </Label>
              <Field as={Select} name="purchase_reason">
                <option value="" disabled hidden></option>
                <option value="replace income">Replace your income</option>
                <option value="preserve estate value">
                  Preserve the value of your estate
                </option>
                <option value="unsure">Unsure</option>
                <option value="other">Other</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="purchase_reason"
              />
            </div>

            {values.purchase_reason === "other" && (
              <div className={ApplicationsStyles.FormGroup}>
                <Label className={ApplicationsStyles.Label}>
                  Please specify the reason for your purchase.
                </Label>
                <Field as={Textarea} name="other_purchase_reason" />
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="other_purchase_reason"
                />
              </div>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                type="submit"
                fullWidth
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfo;
