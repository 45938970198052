import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import NumberFormat from "react-number-format";

import StylizedHeading from "../../../Common/StylizedHeading";
import P from "Common/P";
import Label from "Common/Label";
import Input from "Common/Input";
import Button from "../../../Common/Button";

import ApplicationsStyles from "../../Applications.module.css";

const EmployeeClassInfoSchema = Yup.object().shape({
  max_annual_claim: Yup.number().required("This field is required")
});

const EmployeeClassList = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <div className={ApplicationsStyles.HeadingWrapper}>
        <StylizedHeading>Claim Amounts</StylizedHeading>
      </div>

      <Label className={ApplicationsStyles.Label}>
        What claim amount would you like to allocate to your health spending
        account?
      </Label>

      <Formik
        initialValues={{
          max_annual_claim: (data && data[0].max_annual_claim) || 0.0
        }}
        validationSchema={EmployeeClassInfoSchema}
      >
        {({ values, setFieldValue, handleBlur }) => (
          <Form>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Claim amount (annual)
              </Label>
              <NumberFormat
                customInput={Input}
                name="max_annual_claim"
                defaultValue={0}
                value={values.max_annual_claim}
                onValueChange={(event) => {
                  save({ max_annual_claim: event.value });
                  setFieldValue("max_annual_claim", event.value, false);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                name="max_annual_claim"
                className={ApplicationsStyles.Error}
              />
            </div>

            <P className={ApplicationsStyles.SmallText}>
              It is highly recommended that claim limits be set at 10-15% of an
              employee’s annual income.{" "}
              <a
                href="https://bounc3.io/page/claim-amounts"
                target="_blank"
                rel="noreferrer"
              >
                Learn more here.
              </a>
            </P>
          </Form>
        )}
      </Formik>

      <div className={ApplicationsStyles.ButtonWrapper}>
        <Button
          className={ApplicationsStyles.PreviousButton}
          onClick={() => {
            previous();
          }}
        >
          Previous
        </Button>

        <Button
          className={ApplicationsStyles.NextButton}
          onClick={() => {
            next();
          }}
          disabled={!data || !data.length || !data[0].max_annual_claim}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default EmployeeClassList;
