import * as API from "./API";

export function adminListApplications(query = {}) {
  return API.get("/admin/applications", { params: query });
}

export function adminGetApplication(applicationId) {
  return API.get(`/admin/applications/${applicationId}`);
}

export function adminUpdateApplication(applicationId, data) {
  return API.put(`/admin/applications/${applicationId}`, data);
}

export function adminDeleteApplication(applicationId) {
  return API.del(`/admin/applications/${applicationId}`);
}
