import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router";

import {
  adminGetQuote,
  adminUpdateQuote
} from "Core/services/AdminQuote.service";
import ObjectViewer from "Common/ObjectViewer";
import Breadcrumbs from "Common/Breadcrumbs";
import Container from "Common/Container";

import styles from "./QuoteDetails.module.css";
import Button from "Common/Button";

export default function UserList() {
  const [quote, setQuote] = useState(null);
  const { quoteId } = useParams();

  const handleArchive = () => {
    adminUpdateQuote(quoteId, {
      is_archived: true
    }).then((data) => {
      setQuote(data);
    });
  };

  useEffect(() => {
    adminGetQuote(quoteId)
      .then((data) => {
        setQuote(data);
      })
      .catch((e) => console.error(e));
  }, [quoteId]);

  return (
    <>
      <Helmet>
        <title>Quote | Bounc3</title>
      </Helmet>

      <Container>
        <Breadcrumbs
          links={[
            { name: "Admin Home", path: "/admin" },
            { name: "Quotes", path: "/admin/quotes" }
          ]}
        />
        <div className={styles.Actions}>
          {!quote?.is_archived && (
            <Button plain className={styles.Danger} onClick={handleArchive}>
              Archive
            </Button>
          )}
          {quote?.is_archived && <p>Archived</p>}
        </div>

        {quote?.company && (
          <>
            <strong>Company</strong>
            <div>{quote.company}</div>
            <div>
              <small>{quote.product_name}</small>
            </div>
          </>
        )}

        {quote?.premium_monthly && (
          <ObjectViewer
            object={{ premium_monthly_amount: quote?.premium_monthly }}
          />
        )}

        {quote?.premium_annually && (
          <ObjectViewer
            object={{ premium_annually_amount: quote?.premium_annually }}
          />
        )}

        {quote?.request_data && <ObjectViewer object={quote.request_data} />}
      </Container>
    </>
  );
}
