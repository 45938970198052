import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import Input from "Common/Input";
import Label from "Common/Label";
import Select from "Common/Select";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";

import ApplicationsStyles from "../../Applications.module.css";
import PaymentInfoStyles from "./PaymentInfo.module.css";

const PaymentInfoSchema = Yup.object().shape({
  has_preferred_payment_day: Yup.boolean().required("This field is required"),
  preferred_payment_day: Yup.number().when("has_preferred_payment_day", {
    is: true,
    then: Yup.number().required("This field is required"),
  }),
  preferred_payment_option: Yup.string().required("This field is required"),
  payment_frequency: Yup.string().required("This field is required"),

  bank_name: Yup.string().when("preferred_payment_option", {
    is: "bank account",
    then: Yup.string().required("This field is required"),
  }),
  bank_transit_number: Yup.string().when("preferred_payment_option", {
    is: "bank account",
    then: Yup.string()
      .matches(/^[0-9]+$/, "Must contain only numbers")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits")
      .required("This field is required"),
  }),
  bank_account_number: Yup.string().when("preferred_payment_option", {
    is: "bank account",
    then: Yup.string()
      .matches(/^[0-9]+$/, "Must contain only numbers")
      .min(7, "Must be at least 7 digits")
      .max(12, "Must be at most 12 digits")
      .required("This field is required"),
  }),

  pad_agreement: Yup.boolean().when("preferred_payment_option", {
    is: "bank account",
    then: Yup.boolean().required("This field is required").oneOf([true]),
  }),
});

const PaymentInfo = ({ data, save, previous, next }) => {
  const mapDayOptions = () => {
    const mappedOptions = [];
    for (let i = 1; i <= 28; i++) {
      mappedOptions.push(<option value={i}>{i}</option>);
    }
    return mappedOptions;
  };

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Payment Info</StylizedHeading>

      <Formik
        initialValues={{
          has_preferred_payment_day:
            typeof data?.has_preferred_payment_day === "boolean"
              ? data?.has_preferred_payment_day
              : "",
          preferred_payment_day: data?.preferred_payment_day || "",
          preferred_payment_option: data?.preferred_payment_option || "",
          payment_frequency: data?.payment_frequency || "",

          bank_name: data?.bank_name || "",
          bank_transit_number: data?.bank_transit_number || "",
          bank_account_number: data?.bank_account_number || "",

          pad_agreement:
            typeof data?.pad_agreement === "boolean" ? data?.pad_agreement : "",
        }}
        validateOnMount
        validationSchema={PaymentInfoSchema}
        onSubmit={(values) => {
          save(values);
          next();
        }}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Is there a preferred day to make the monthly premium payments?
              </Label>
              <Field
                as={Select}
                name="has_preferred_payment_day"
                onChange={(event) =>
                  setFieldValue(
                    "has_preferred_payment_day",
                    JSON.parse(event.target.value),
                  )
                }
              >
                <option value="" disabled hidden></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="has_preferred_payment_day"
              />
            </div>

            {values.has_preferred_payment_day && (
              <div className={ApplicationsStyles.FormGroup}>
                <Label className={ApplicationsStyles.Label}>
                  Preferred Payment Day
                </Label>
                <Field
                  as={Select}
                  name="preferred_payment_day"
                  onChange={(event) =>
                    setFieldValue(
                      "preferred_payment_day",
                      JSON.parse(event.target.value),
                    )
                  }
                >
                  <option value="" disabled hidden></option>
                  {mapDayOptions()}
                </Field>
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="preferred_payment_day"
                />
              </div>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What is your preferred payment option?
                </Label>
              </div>
              <Field
                as={Select}
                name="preferred_payment_option"
                onChange={(event) => {
                  let paymentFrequency = "";
                  switch (event.target.value) {
                    case "bank account": {
                      paymentFrequency = "monthly";
                      break;
                    }
                    case "direct bill": {
                      paymentFrequency = "annually";
                      break;
                    }
                    default: {
                      break;
                    }
                  }
                  setFieldValue("preferred_payment_option", event.target.value);
                  setFieldValue("payment_frequency", paymentFrequency);
                }}
              >
                <option value="" disabled hidden></option>
                <option value="bank account">Bank Account</option>
                <option value="direct bill">Direct Bill</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="preferred_payment_option"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  Frequency of Payments
                </Label>
                <Tooltip message="For monthly payments, the only payment option is pre-authorized withdrawals from a Bank Account. For yearly payments, the only payment option is Direct Bill.">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <Field as={Select} name="payment_frequency" disabled>
                <option value="" disabled hidden></option>
                <option value="monthly">Monthly</option>
                <option value="annually">Annually</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="payment_frequency"
              />
            </div>

            {values.preferred_payment_option === "bank account" && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <div className={ApplicationsStyles.Label}>
                    <Label className={ApplicationsStyles.Label}>
                      Bank Name
                    </Label>
                    <Tooltip message="We require your banking information because your monthly premiums will be deducted directly from your account.">
                      <div className={ApplicationsStyles.InfoIcon}>i</div>
                    </Tooltip>
                  </div>
                  <Field as={Input} name="bank_name" />
                  <ErrorMessage
                    component="div"
                    name="bank_name"
                    className={ApplicationsStyles.Error}
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <div className={ApplicationsStyles.Label}>
                    <Label className={ApplicationsStyles.Label}>
                      Bank Transit Number
                    </Label>
                    <Tooltip message="We require your banking information because your monthly premiums will be deducted directly from your account.">
                      <div className={ApplicationsStyles.InfoIcon}>i</div>
                    </Tooltip>
                  </div>
                  <Field as={Input} maxLength="5" name="bank_transit_number" />
                  <ErrorMessage
                    component="div"
                    name="bank_transit_number"
                    className={ApplicationsStyles.Error}
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <div className={ApplicationsStyles.Label}>
                    <Label className={ApplicationsStyles.Label}>
                      Bank Account Number
                    </Label>
                    <Tooltip message="We require your banking information because your monthly premiums will be deducted directly from your account.">
                      <div className={ApplicationsStyles.InfoIcon}>i</div>
                    </Tooltip>
                  </div>
                  <Field as={Input} maxLength="12" name="bank_account_number" />
                  <ErrorMessage
                    component="div"
                    name="bank_account_number"
                    className={ApplicationsStyles.Error}
                  />
                </div>
              </>
            )}

            {values.preferred_payment_option === "bank account" && (
              <div className={PaymentInfoStyles.Checkbox}>
                <Label
                  htmlFor="pad_agreement"
                  className={PaymentInfoStyles.Label}
                >
                  I/We, the Payor(s), acknowledge and agree to be bound by the
                  terms and conditions of the{" "}
                  <a
                    href="https://assets.ctfassets.net/sj7ksahqrtn7/RkVCqDbIDcAZbjByauOf9/c0295022a4ddce1aa8edb3735495ec66/PAD_agreement.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pre-Authorized Debit (PAD) Agreement
                  </a>
                  .
                </Label>
                <Input
                  id="pad_agreement"
                  name="pad_agreement"
                  type="checkbox"
                  onChange={() =>
                    setFieldValue("pad_agreement", !values.pad_agreement)
                  }
                />
              </div>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                type="submit"
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentInfo;
