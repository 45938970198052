import { useFormik } from "formik";
import classNames from "classnames";
import NumberFormat from "react-number-format";

import Label from "Common/Label";
import Button from "Common/Button";
import Input from "Common/Input";
import Tooltip from "Common/Tooltip";

import riskStyles from "../RiskProfile.module.css";

import styles from "./FinanceInfo.module.css";
import Select from "Common/Select";
import { SavingsLength } from "Core/models/RiskProfile.model";

const FinanceTooltip = () => {
  return (
    <Tooltip message="We ask about your financial information to determine which insurance products are most suitable, and how much coverage you should consider.">
      <div className={riskStyles.InfoIcon}>i</div>
    </Tooltip>
  );
};

const FinanceInfo = ({ data, save, previous, next }) => {
  const formik = useFormik({
    initialValues: {
      monthly_income: data?.monthly_income || 0,

      has_savings: data?.has_savings || "",
      savings_length: data?.savings_length || "",

      has_major_debts: data?.has_major_debts || "",
      outstanding_debts: data?.outstanding_debts || 0,
    },
    validateOnMount: true,
  });

  return (
    <div className={classNames(riskStyles.FormContainer, styles.FormContainer)}>
      <div className={classNames(riskStyles.Field, riskStyles.Fluid)}>
        <div className={riskStyles.Label}>
          <Label>
            Approximately, how much <u>monthly</u> income do you need to
            maintain your standard of living, and to meet your major household
            expenses?
          </Label>{" "}
          <FinanceTooltip />
        </div>
        <NumberFormat
          customInput={Input}
          name="monthly_income"
          defaultValue={0}
          value={formik.values.monthly_income}
          onValueChange={(event) => {
            formik.setFieldValue("monthly_income", event.value, false);
          }}
          prefix={"$ "}
          decimalScale={0}
          allowNegative={false}
          thousandSeparator={true}
          allowLeadingZeros={false}
          allowEmptyFormatting={true}
          onBlur={formik.handleBlur}
        />
      </div>

      <div className={classNames(riskStyles.Field, riskStyles.Fluid)}>
        <div className={riskStyles.Label}>
          <Label>Do you have any savings or an emergency fund?</Label>
        </div>
        <div className={riskStyles.RadioGroup}>
          <div className={riskStyles.RadioInput}>
            <label
              htmlFor="yes"
              className={classNames({
                [riskStyles.RadioSelected]: formik.values.has_savings === "yes",
              })}
            >
              Yes
            </label>
            <input
              id="yes"
              type="radio"
              name="has_savings"
              value="yes"
              onChange={formik.handleChange}
              checked={formik.values.has_savings === "yes"}
            />
          </div>

          <div className={riskStyles.RadioInput}>
            <label
              htmlFor="no"
              className={classNames({
                [riskStyles.RadioSelected]: formik.values.has_savings === "no",
              })}
            >
              No
            </label>
            <input
              id="no"
              type="radio"
              name="has_savings"
              value="no"
              onChange={formik.handleChange}
              checked={formik.values.has_savings === "no"}
            />
          </div>
        </div>
      </div>

      <div>
        {formik.values.has_savings === "yes" && (
          <div className={classNames(riskStyles.Field, riskStyles.Fluid)}>
            <div className={riskStyles.Label}>
              <Label>
                How long could your emergency fund last if you were unable to
                work and earn income?
              </Label>{" "}
              <FinanceTooltip />
            </div>
            <Select
              name="savings_length"
              value={formik.values.savings_length}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value={SavingsLength.DAYS}>A few days or weeks</option>
              <option value={SavingsLength.MONTHS}>A few months</option>
              <option value={SavingsLength.YEARS}>A few years</option>
            </Select>
          </div>
        )}
      </div>

      <div className={classNames(riskStyles.Field, riskStyles.Fluid)}>
        <div className={riskStyles.Label}>
          <Label>Do you have a mortgage, or any other major debt?</Label>
        </div>
        <div className={riskStyles.RadioGroup}>
          <div className={riskStyles.RadioInput}>
            <label
              htmlFor="has_major_debts_yes"
              className={classNames({
                [riskStyles.RadioSelected]:
                  formik.values.has_major_debts === "yes",
              })}
            >
              Yes
            </label>
            <input
              id="has_major_debts_yes"
              type="radio"
              name="has_major_debts"
              value="yes"
              onChange={formik.handleChange}
              checked={formik.values.has_major_debts === "yes"}
            />
          </div>

          <div className={riskStyles.RadioInput}>
            <label
              htmlFor="has_major_debts_no"
              className={classNames({
                [riskStyles.RadioSelected]:
                  formik.values.has_major_debts === "no",
              })}
            >
              No
            </label>
            <input
              id="has_major_debts_no"
              type="radio"
              name="has_major_debts"
              value="no"
              onChange={formik.handleChange}
              checked={formik.values.has_major_debts === "no"}
            />
          </div>
        </div>
      </div>

      {formik.values.has_major_debts === "yes" && (
        <div className={classNames(riskStyles.Field, riskStyles.Fluid)}>
          <div className={riskStyles.Label}>
            <Label>How much outstanding debt do you currently hold?</Label>{" "}
            <FinanceTooltip />
          </div>
          <NumberFormat
            customInput={Input}
            name="outstanding_debts"
            defaultValue={0}
            value={formik.values.outstanding_debts}
            onValueChange={(event) => {
              formik.setFieldValue("outstanding_debts", event.value, false);
            }}
            prefix={"$ "}
            decimalScale={0}
            allowNegative={false}
            thousandSeparator={true}
            allowLeadingZeros={false}
            allowEmptyFormatting={true}
            onBlur={formik.handleBlur}
          />
        </div>
      )}

      <div className={riskStyles.ButtonWrapper}>
        <Button
          type="button"
          className={riskStyles.PreviousButton}
          onClick={(event) => {
            event.preventDefault();
            save(formik.values);
            previous();
          }}
        >
          Previous
        </Button>

        <Button
          type="submit"
          className={riskStyles.NextButton}
          onClick={(event) => {
            event.preventDefault();
            save(formik.values);
            next();
          }}
          disabled={
            !formik.values.has_savings ||
            (formik.values.has_savings === "yes" &&
              !formik.values.savings_length) ||
            !formik.values.has_major_debts ||
            (formik.values.has_major_debts === "yes" &&
              !formik.values.outstanding_debts)
          }
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default FinanceInfo;
