import { useFormik } from "formik";
import * as Yup from "yup";

import Label from "../../Common/Label";
import Button from "../../Common/Button";
import Select from "../../Common/Select";
import DateSelect from "Common/DateSelect";

// import styles from "./DemographicInfo.module.css";
import riskStyles from "../RiskProfile.module.css";
import Input from "Common/Input";

const DemographicInfoSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  birthday: Yup.date().required("This field is required"),
  postalCode: Yup.string().required("This field is required"),
  gender: Yup.string().required("This field is required"),
});

const DemographicInfo = ({ data, save, previous, next }) => {
  const formik = useFormik({
    initialValues: {
      first_name: data?.first_name || "",
      email: data?.email || "",
      birthday: data?.birthday || "",
      postalCode: data?.postalCode || "",
      gender: data?.gender || "",
    },
    validateOnMount: true,
    validationSchema: DemographicInfoSchema,
  });

  return (
    <div className={riskStyles.FormContainer}>
      <form className={riskStyles.Form}>
        <div className={riskStyles.Field}>
          <div className={riskStyles.Label}>
            <Label>First Name</Label>
          </div>
          <Input
            name="first_name"
            placeholder="John"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className={riskStyles.Error}>
            {formik.errors.first_name && formik.touched.first_name && (
              <span>{formik.errors.first_name}</span>
            )}
          </div>
        </div>

        <div className={riskStyles.Field}>
          <div className={riskStyles.Label}>
            <Label>Email</Label>
          </div>
          <Input
            name="email"
            type="email"
            placeholder="jsmith@example.ca"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className={riskStyles.Error}>
            {formik.errors.email && formik.touched.email && (
              <span>{formik.errors.email}</span>
            )}
          </div>
        </div>

        <div className={riskStyles.Field}>
          <div className={riskStyles.Label}>
            <Label>When were you born?</Label>
          </div>
          <DateSelect
            maxYearCorrection={-16}
            value={formik.values.birthday}
            onChange={(value) => formik.setFieldValue("birthday", value, true)}
          />
        </div>

        <div className={riskStyles.Field}>
          <div className={riskStyles.Label}>
            <Label>What is your postal code?</Label>
          </div>
          <Input
            name="postalCode"
            value={formik.values.postalCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {/* <Select
            name="location"
            value={formik.values.location}
            onChange={formik.handleChange}
          >
            <option value="">Province</option>
            <option value="Alberta">Alberta</option>
            <option value="British Columbia">British Columbia</option>
            <option value="Manitoba">Manitoba</option>
            <option value="New Brunswick">New Brunswick</option>
            <option value="Newfoundland">Newfoundland</option>
            <option value="Nova Scotia">Nova Scotia</option>
            <option value="Northwest Territories">Northwest Territories</option>
            <option value="Nunavut">Nunavut</option>
            <option value="Ontario">Ontario</option>
            <option value="Prince Edward Island">Prince Edward Island</option>
            <option value="Quebec">Quebec</option>
            <option value="Saskatchewan">Saskatchewan</option>
            <option value="Yukon">Yukon</option>
          </Select> */}
        </div>

        <div className={riskStyles.Field}>
          <div className={riskStyles.Label}>
            <Label>What is your gender?</Label>
          </div>
          <Select
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
          >
            <option value="">Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="undisclosed">Prefer not to say</option>
          </Select>
        </div>

        <div className={riskStyles.ButtonWrapper}>
          <Button
            type="button"
            className={riskStyles.PreviousButton}
            onClick={(event) => {
              event.preventDefault();
              save(formik.values);
              previous();
            }}
          >
            Previous
          </Button>

          <Button
            type="submit"
            className={riskStyles.NextButton}
            onClick={(event) => {
              event.preventDefault();
              next(formik.values);
            }}
            disabled={!formik.isValid}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DemographicInfo;
