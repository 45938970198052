import React from "react";
import classNames from "classnames";

import History from "Core/History";

import StylizedHeading from "Common/StylizedHeading";
import P from "Common/P";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";
import { ReactComponent as EditIcon } from "Icons/edit.svg";
import { ReactComponent as DeleteIcon } from "Icons/delete.svg";

import ApplicationsStyles from "../../Applications.module.css";
import { ApplicationType } from "Core/models/Application.model";
// import BeneficiaryListStyles from "./BeneficiaryList.module.css";

const BeneficiaryList = ({
  match,
  data,
  applicationType,
  save,
  previous,
  next
}) => {
  const hasBeneficiary = data?.length > 0;

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Beneficiaries</StylizedHeading>

      <P className={ApplicationsStyles.SmallText}>
        A beneficiary is a person
        {applicationType === ApplicationType.BUSINESS ? ", or a business," : ""}{" "}
        that will receive all or part of the insurance payout. The money that is
        paid to beneficiaries is based on how much is allocated. If you have
        only one beneficiary, allocate 100%. Beneficiary allocations must add up
        to 100%.
      </P>

      {data && data.length > 0 && (
        <table className={ApplicationsStyles.Table}>
          <tbody>
            {data.map((b, i) => {
              return (
                <tr key={i}>
                  <td>
                    <P>{b.full_name + " - " + b.percent_allocated + "%"}</P>
                  </td>
                  <td>
                    <Tooltip
                      position=""
                      message="Make changes to this beneficiary."
                    >
                      <EditIcon
                        className={ApplicationsStyles.ActionText}
                        onClick={() => {
                          History.push(`${match.url}/${i}`);
                        }}
                      />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip position="" message="Remove this beneficiary.">
                      <DeleteIcon
                        className={ApplicationsStyles.ActionText}
                        onClick={() => {
                          const updatedData = [...data];
                          updatedData.splice(i, 1);
                          save(updatedData);
                        }}
                      />
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      <div
        onClick={() => {
          History.push(`${match.url}/${data.length}`);
        }}
      >
        <P
          className={classNames(
            ApplicationsStyles.ActionText,
            ApplicationsStyles.AddItem
          )}
        >
          + Beneficiary
        </P>
      </div>

      <div className={ApplicationsStyles.ButtonWrapper}>
        <Button
          fullWidth
          className={ApplicationsStyles.PreviousButton}
          onClick={() => {
            previous();
          }}
        >
          Previous
        </Button>

        <Button
          fullWidth
          className={ApplicationsStyles.NextButton}
          onClick={() => {
            next();
          }}
          disabled={!hasBeneficiary}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default BeneficiaryList;
