export const selectApplicationsState = (state) => state.applications;

export const selectApplicationList = (state) =>
  selectApplicationsState(state).applicationList;

export const selectApplication = (state) =>
  selectApplicationsState(state).application;

export const selectIsLoading = (state) =>
  selectApplicationsState(state).isLoading;

export const selectIsLoaded = (state) =>
  selectApplicationsState(state).isLoaded;
