export const AUTH_LOGIN = "[Auth] Login";
export const AUTH_LOGIN_SUCCESS = "[Auth] Login Success";
export const AUTH_LOGIN_FAIL = "[Auth] Login Fail";

export const AUTH_REGISTER = "[Auth] Register";
export const AUTH_REGISTER_SUCCESS = "[Auth] Register Success";
export const AUTH_REGISTER_FAIL = "[Auth] Register Fail";

export const AUTH_VERIFY_EMAIL = "[Auth] Verify Email";
export const AUTH_VERIFY_EMAIL_SUCCESS = "[Auth] Verify Email Success";
export const AUTH_VERIFY_EMAIL_FAIL = "[Auth] Verify Email Fail";

export const GET_CURRENT_USER = "[Auth] Get current user";
export const GET_CURRENT_USER_SUCCESS = "[Auth] Get current user Success";
export const GET_CURRENT_USER_FAIL = "[Auth] Get current user Fail";

export const AUTH_LOGOUT = "[Auth] Logout";

export const AUTH_FORGOT_PASSWORD = "[Auth] Forgot Password";
export const AUTH_FORGOT_PASSWORD_SUCCESS = "[Auth] Forgot Password Success";
export const AUTH_FORGOT_PASSWORD_FAIL = "[Auth] Forgot Password Fail";

export const VALIDATE_PASSWORD_RESET_TOKEN =
  "[Auth] Validate Password Reset Token";
export const VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS =
  "[Auth] Validate Password Reset Token Success";
export const VALIDATE_PASSWORD_RESET_TOKEN_FAIL =
  "[Auth] Validate Password Reset Token Fail";

export const CONFIRM_PASSWORD_RESET = "[Auth] Confirm Password Reset";
export const CONFIRM_PASSWORD_RESET_SUCCESS =
  "[Auth] Confirm Password Reset Success";
export const CONFIRM_PASSWORD_RESET_FAIL = "[Auth] Confirm Password Reset Fail";
