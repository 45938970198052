import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import History from "../../Core/History";

import {
  createApplication,
  submitApplication,
  saveDraftApplication
} from "../../store/actions/Applications.actions";
import { login, register } from "../../store/actions/Auth.actions";
import { selectCurrentUser } from "../../store/selectors/Auth.selectors";
import { selectApplicationList } from "store/selectors/Applications.selectors";
import { event } from "utils/gtag";
import { trackFintelPixel } from "utils/fintel";
import { isPostalCodeValidArea } from "utils/postalCode";

import ProgressBar from "Common/ProgressBar";
import GetStarted from "./GetStarted";
import PersonalInfo from "./PersonalInfo";
import AddressInfo from "./AddressInfo";
import EmploymentInfo from "./EmploymentInfo";
import CompanyInfo from "./CompanyInfo";
import FinancialInfo from "./FinancialInfo";
import OtherPolicyList from "./OtherPolicyList";
import OtherPolicyInfo from "./OtherPolicyInfo";
import NextSteps from "./NextSteps";
import ConfirmAndRegister from "./ConfirmAndRegister";

import ApplicationsStyles from "../Applications.module.css";

export default function RbcDisabilityInsurance({ match }) {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const applications = useSelector(selectApplicationList);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 6;
  const product = "Disability Insurance";

  const [FormData, setFormData] = useState({
    provider: "rbc",
    started: false,
    personal_info: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      gender: user?.gender,
      birth_date: user?.birth_date,
      birth_place: user?.birth_place,
      email: user?.email,
      phone: user?.phone,
      language: user?.language,
      citizen_or_permanent_resident: user?.citizen_or_permanent_resident,
      years_in_country: user?.years_in_country,
      is_policy_owner: user?.is_policy_owner
    },
    address_info: {
      street_address: user?.street_address,
      city: user?.city,
      province: user?.province,
      postal_code: user?.postal_code
    },
    employment_info: {
      occupation: user?.occupation,
      professional_designations_or_degress:
        user?.professional_designations_or_degress,
      weekly_hours_worked: user?.weekly_hours_worked,
      work_from_home_percent: user?.work_from_home_percent,
      is_self_employed: user?.is_self_employed,
      self_employed_duration: user?.self_employed_duration,
      non_owner_full_time_employees: user?.non_owner_full_time_employees,
      business_type: user?.business_type,
      ownership_percent: user?.ownership_percent,
      splits_income_for_taxes: user?.splits_income_for_taxes,
      split_income_amount: user?.split_income_amount,
      years_with_current_employer: user?.years_with_current_employer,
      years_in_business_type: user?.years_in_business_type,
      is_commissioned_sales_person: user?.is_commissioned_sales_person
    },
    company_info: {
      name: user?.company_name,
      street_address: user?.company_street_address,
      city: user?.company_city,
      province: user?.company_province,
      postal_code: user?.company_postal_code,
      phone: user?.company_phone,
      nature_of_business: user?.nature_of_business
    },
    financial_info: {
      net_annual_income_current_year: user?.net_annual_income_current_year,
      net_annual_income_previous_year: user?.net_annual_income_previous_year,
      declared_bankruptcy: user?.declared_bankruptcy,
      bankruptcy_details: user?.bankruptcy_details
    },
    other_policy_info: []
  });

  useEffect(() => {
    const savedApplication = applications?.find((a) => a.product === product);

    if (savedApplication) {
      setFormData(savedApplication.submission_data);
    }
  }, [applications, product]);

  const saveDraft = () => {
    if (!user) {
      dispatch(
        saveDraftApplication(FormData.personal_info.email, product, FormData)
      ).catch((e) => console.log(e));
    }
  };

  const submitFormData = async (data) => {
    const { contact_time, password } = data;
    const { first_name, last_name, email } = FormData.personal_info;
    const { postal_code } = FormData.address_info;

    setFormData({ ...FormData, contact_time });

    setLoading(true);

    try {
      if (!user) {
        try {
          await dispatch(register(email, password, first_name, last_name));
        } catch (e) {
          await dispatch(login(email, password));
        }
      }

      const application = await dispatch(createApplication(product, FormData));

      event("application_submission", {
        category: "Disability Insurance",
        label: "RBC",
        value: 1440.0
      });

      if (isPostalCodeValidArea(postal_code)) {
        trackFintelPixel("Disability Insurance", application.id, user?.id);
      }

      await dispatch(submitApplication(application.id));

      History.replace("/dashboard");
    } catch (e) {
      setError(e.message);
    }

    setLoading(false);
  };

  return (
    <div className={ApplicationsStyles.Container}>
      <Helmet>
        <title>{`${product} Application | Bounc3`}</title>
      </Helmet>

      {currentStep > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "2rem"
          }}
        >
          <ProgressBar percent={currentStep / totalSteps} />
        </div>
      )}

      <Router history={History}>
        <Switch>
          <Route
            exact
            path={`${match.url}/get-started`}
            render={(props) => {
              setCurrentStep(0);
              return (
                <GetStarted
                  {...props}
                  product={product}
                  save={(data) => setFormData({ ...FormData, started: data })}
                  next={() => History.push(`${match.url}/personal-info`)}
                />
              );
            }}
          ></Route>

          {!FormData.started && (
            <Redirect from={`${match.url}`} to={`${match.url}/get-started`} />
          )}

          <Route
            path={`${match.url}/personal-info`}
            render={(props) => {
              setCurrentStep(0);
              return (
                <PersonalInfo
                  {...props}
                  data={FormData.personal_info}
                  save={(data) =>
                    setFormData({ ...FormData, personal_info: data })
                  }
                  previous={() => History.push(`${match.url}/get-started`)}
                  next={() => History.push(`${match.url}/address-info`)}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/address-info`}
            render={(props) => {
              setCurrentStep(1);
              return (
                <AddressInfo
                  {...props}
                  data={FormData.address_info}
                  save={(data) =>
                    setFormData({ ...FormData, address_info: data })
                  }
                  previous={() => History.push(`${match.url}/personal-info`)}
                  next={() => History.push(`${match.url}/employment-info`)}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/employment-info`}
            render={(props) => {
              setCurrentStep(2);
              return (
                <EmploymentInfo
                  {...props}
                  data={FormData.employment_info}
                  save={(data) => {
                    setFormData({ ...FormData, employment_info: data });
                    saveDraft();
                  }}
                  previous={() => History.push(`${match.url}/address-info`)}
                  next={() => History.push(`${match.url}/company-info`)}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/company-info`}
            render={(props) => {
              setCurrentStep(3);
              return (
                <CompanyInfo
                  {...props}
                  data={FormData.company_info}
                  save={(data) => {
                    setFormData({ ...FormData, company_info: data });
                    saveDraft();
                  }}
                  previous={() => History.push(`${match.url}/employment-info`)}
                  next={() => History.push(`${match.url}/financial-info`)}
                />
              );
            }}
          ></Route>

          <Route
            path={`${match.url}/financial-info`}
            render={(props) => {
              setCurrentStep(4);
              return (
                <FinancialInfo
                  {...props}
                  data={FormData.financial_info}
                  save={(data) => {
                    setFormData({ ...FormData, financial_info: data });
                    saveDraft();
                  }}
                  previous={() => History.push(`${match.url}/company-info`)}
                  next={() => History.push(`${match.url}/other-policies`)}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/other-policies`}
            render={(props) => {
              setCurrentStep(5);
              return (
                <OtherPolicyList
                  {...props}
                  data={FormData.other_policy_info}
                  save={(data) => {
                    setFormData({ ...FormData, other_policy_info: data });
                    saveDraft();
                  }}
                  previous={() => History.push(`${match.url}/beneficiaries`)}
                  next={() => History.push(`${match.url}/next-steps`)}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/other-policies/:otherPolicyIndex`}
            render={(props) => {
              setCurrentStep(5);
              return (
                <OtherPolicyInfo
                  {...props}
                  data={FormData.other_policy_info}
                  save={(data) => {
                    setFormData({ ...FormData, other_policy_info: data });
                    saveDraft();
                  }}
                  back={() => History.goBack()}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/next-steps`}
            render={(props) => {
              setCurrentStep(6);
              return (
                <NextSteps
                  {...props}
                  data={FormData.next_steps}
                  save={(data) => {
                    setFormData({ ...FormData, next_steps: data });
                    saveDraft();
                  }}
                  previous={() => History.push(`${match.url}/other-policies`)}
                  next={() => History.push(`${match.url}/done`)}
                />
              );
            }}
          ></Route>

          <Route
            exact
            path={`${match.url}/done`}
            render={(props) => (
              <ConfirmAndRegister
                {...props}
                data={FormData.personal_info}
                submit={submitFormData}
                loading={loading}
                error={error}
              />
            )}
          ></Route>
        </Switch>
      </Router>
    </div>
  );
}
