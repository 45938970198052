import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import History from "../../Core/History";

import StylizedHeading from "../../Common/StylizedHeading";
import Label from "../../Common/Label";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import { login } from "../../store/actions/Auth.actions";

import styles from "./LoginView.module.css";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  password: Yup.string()
    .min(8, "This password is too short")
    .max(50, "This password is too long")
    .required("This field is required"),
});

const LoginView = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const handleSubmit = ({ email, password }) => {
    dispatch(login(email, password))
      .then(() => {
        // Redirect to dashboard
        History.push("/");
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return (
    <div className={styles.Container}>
      <div className={styles.FormWrapper}>
        <StylizedHeading position="right">Log In</StylizedHeading>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleSubmit }) => (
            <Form className={styles.Form} onSubmit={handleSubmit}>
              {error && <p className="danger">{error}</p>}

              <div className={styles.Field}>
                <div className={styles.Label}>
                  <Label>Email</Label>
                </div>
                <Field as={Input} name="email" type="email" />
                <div className={styles.Error}>
                  <ErrorMessage name="email" />
                </div>
              </div>

              <div className={styles.Field}>
                <div className={styles.Label}>
                  <Label>Password</Label>
                </div>
                <Field as={Input} name="password" type="password" />
                <div className={styles.Error}>
                  <ErrorMessage name="password" />
                </div>
              </div>

              <div className={styles.ButtonWrapper}>
                <Button fullWidth type="submit">
                  Log In
                </Button>
              </div>

              <div className={styles.LinkWrapper}>
                <Link className={styles.Link} to="/auth/forgot-password">
                  Forgot Password
                </Link>
              </div>

              <div className={styles.LinkWrapper}>
                <Link className={styles.Link} to="/auth/register">
                  Register
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginView;
