import classNames from "classnames";

import styles from "./Select.module.css";

export default function Select({ fullWidth, className, children, ...props }) {
  return (
    <select
      className={classNames(
        styles.Select,
        fullWidth && styles.FullWidth,
        className
      )}
      {...props}
    >
      {children}
    </select>
  );
}
