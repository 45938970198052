import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Select from "Common/Select";
import DateSelect from "Common/DateSelect";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";
// import OtherPolicyInfoStyles from "./OtherPolicyInfo.module.css";

const OtherPolicyInfo = ({ match, data, save, back }) => {
  const otherPolicyIndex = Number(match.params.otherPolicyIndex);

  const OtherPolicyInfoSchema = Yup.object().shape({
    company: Yup.string()
      .min(2, "This name is too short")
      .max(50, "This name is too long")
      .required("This field is required"),
    type: Yup.string().required("This field is required"),
    status: Yup.string().required("This field is required"),

    amount: Yup.number().when("status", {
      is: "issued",
      then: Yup.number().required("This field is required"),
    }),
    issue_date: Yup.date().when("status", {
      is: "issued",
      then: Yup.date().required("This field is required"),
    }),
  });

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Other Policy Info</StylizedHeading>

      <Formik
        initialValues={{
          company: data[otherPolicyIndex]?.company || "",
          type: data[otherPolicyIndex]?.type || "",
          status: data[otherPolicyIndex]?.status || "",

          amount: data[otherPolicyIndex]?.amount || "",
          issue_date: data[otherPolicyIndex]?.issue_date || "",
        }}
        validateOnMount
        validationSchema={OtherPolicyInfoSchema}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Policy Provider
              </Label>
              <Field as={Input} name="company" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="company"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Policy Type</Label>
              <Field as={Select} name="type">
                <option></option>
                <option value="Life Insurance">Life Insurance</option>
                <option value="Critical Illness Insurance">
                  Critical Illness Insurance
                </option>
                <option value="Disability Insurance">
                  Disability Insurance
                </option>
                <option value="Long-term Care Insurance">
                  Long-term Care Insurance
                </option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="type"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Policy Status</Label>
              <Field as={Select} name="status">
                <option></option>
                <option value="applied">Applied</option>
                <option value="issued">Issued</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="status"
              />
            </div>

            {values.status === "issued" && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Policy Amount
                  </Label>
                  <NumberFormat
                    customInput={Input}
                    name="amount"
                    value={values.amount}
                    onValueChange={(event) =>
                      setFieldValue("amount", event.value)
                    }
                    prefix={"$ "}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="amount"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Date Issued
                  </Label>
                  <Field
                    as={DateSelect}
                    name="issue_date"
                    value={values.issue_date}
                    onChange={(value) => setFieldValue("issue_date", value)}
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="issue_date"
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  back();
                }}
              >
                Cancel
              </Button>

              <Button
                className={ApplicationsStyles.NextButton}
                onClick={(event) => {
                  event.preventDefault();
                  const updatedData = [...data];
                  if (!data[otherPolicyIndex]) {
                    updatedData.push(values);
                  } else {
                    updatedData.splice(otherPolicyIndex, 1, values);
                  }
                  save(updatedData);
                  back();
                }}
                disabled={!isValid}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OtherPolicyInfo;
