import styles from "./Loader.module.css";

const Loader = (isLoading = true) => {
  if (!isLoading) return null;

  return (
    <div className={styles.Roller}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
