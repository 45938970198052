import * as fromAuth from "../types/Auth.types";

const initState = {
  isAuthenticated: false,
  user: null
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case fromAuth.AUTH_LOGIN_SUCCESS:
      return { isAuthenticated: true, user: action.payload };
    case fromAuth.AUTH_REGISTER_SUCCESS:
      return { isAuthenticated: true, user: action.payload };
    case fromAuth.AUTH_LOGOUT:
      return { isAuthenticated: false, user: null };
    default:
      return state;
  }
}
