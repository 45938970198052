export const LIST_APPLICATIONS = "[Applications] List Applications";
export const LIST_APPLICATIONS_SUCCESS =
  "[Applications] List Applications Success";
export const LIST_APPLICATIONS_FAIL = "[Applications] List Applications Fail";

export const GET_APPLICATION = "[Applications] Get Application";
export const GET_APPLICATION_SUCCESS = "[Applications] Get Application Success";
export const GET_APPLICATION_FAIL = "[Applications] Get Application Fail";

export const CREATE_APPLICATION = "[Applications] Create Application";
export const CREATE_APPLICATION_SUCCESS =
  "[Applications] Create Application Success";
export const CREATE_APPLICATION_FAIL = "[Applications] Create Application Fail";

export const UPDATE_APPLICATION = "[Applications] Update Application";
export const UPDATE_APPLICATION_SUCCESS =
  "[Applications] Update Application Success";
export const UPDATE_APPLICATION_FAIL = "[Applications] Update Application Fail";

export const SUBMIT_APPLICATION = "[Applications] Submit Application";
export const SUBMIT_APPLICATION_SUCCESS =
  "[Applications] Submit Application Success";
export const SUBMIT_APPLICATION_FAIL = "[Applications] Submit Application Fail";

export const RESCIND_APPLICATION = "[Applications] Rescind Application";
export const RESCIND_APPLICATION_SUCCESS =
  "[Applications] Rescind Application Success";
export const RESCIND_APPLICATION_FAIL =
  "[Applications] Rescind Application Fail";

export const SAVE_DRAFT_APPLICATION = "[Application] Save Draft Application";
export const SAVE_DRAFT_APPLICATION_SUCCESS =
  "[Application] Save Draft Application Success";
export const SAVE_DRAFT_APPLICATION_FAIL =
  "[Application] Save Draft Application Fail";
