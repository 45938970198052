import classNames from "classnames";

import Label from "Common/Label";
import Button from "Common/Button";

import riskStyles from "../RiskProfile.module.css";
import styles from "./BusinessInfo.module.css";

const BusinessInfo = ({ data, save, previous, next }) => {
  return (
    <div className={classNames(riskStyles.FormContainer, styles.FormContainer)}>
      <div className={classNames(riskStyles.Field, riskStyles.Fluid)}>
        <div className={riskStyles.Label}>
          <Label>Do you own a business that is incorporated?</Label>
        </div>

        <div className={riskStyles.RadioGroup}>
          <div className={riskStyles.RadioInput}>
            <label
              htmlFor="yes"
              className={classNames({
                [riskStyles.RadioSelected]: data === true,
              })}
            >
              Yes
            </label>
            <input
              id="yes"
              type="radio"
              name="has_business"
              value={data}
              onChange={() => save(true)}
              checked={data === true}
            />
          </div>

          <div className={riskStyles.RadioInput}>
            <label
              htmlFor="no"
              className={classNames({
                [riskStyles.RadioSelected]: data === false,
              })}
            >
              No
            </label>
            <input
              id="no"
              type="radio"
              name="has_business"
              value={data}
              onChange={() => save(false)}
              checked={data === false}
            />
          </div>
        </div>
      </div>

      <div className={riskStyles.ButtonWrapper}>
        <Button
          type="button"
          className={riskStyles.PreviousButton}
          onClick={previous}
        >
          Previous
        </Button>

        <Button
          type="submit"
          className={riskStyles.NextButton}
          onClick={next}
          disabled={typeof data !== "boolean"}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default BusinessInfo;
