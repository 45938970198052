import React from "react";
import { Link } from "react-router-dom";

import { Card, CardBody } from "Common/Card";
import { ApplicationType } from "Core/models/Application.model";

import style from "./ApplicationStatus.module.css";
import { useLatestQuote } from "./useLatestQuoteHook";
import Button from "Common/Button";

const ApplicationStatus = ({ application }) => {
  const { latestQuote } = useLatestQuote(
    application.product,
    application.application_type
  );

  const isBusiness = application.application_type === ApplicationType.BUSINESS;

  return (
    <Card className={style.Card}>
      <CardBody className={style.CardBody}>
        <h2>
          Your {isBusiness && "Business"} {application.product} Application
        </h2>

        {isBusiness && (
          <p>
            <strong>Business</strong> <br />
            {application?.submission_data?.business_info?.business_legal_name}
          </p>
        )}

        {latestQuote && (
          <p>
            <strong>Latest Quote</strong> <br />
            {latestQuote.company}
            <br />
            {latestQuote.product_name}
            <br />${latestQuote.premium_monthly} per month
          </p>
        )}

        <p>
          <strong>Application Status</strong> <br />
          <span className="text-capitalize">{application.status}</span>
        </p>

        <div className="my">
          {application.status === "pending" &&
            application.product === "Health Spending Account" && (
              <Link to={`/applications/health-spending-account`}>
                <Button plain>Complete</Button>
              </Link>
            )}
          <Link to={`/dashboard/applications/${application.id}`}>
            <Button plain>View application</Button>
          </Link>
          {latestQuote && (
            <Link to="/quote" className="ml">
              <Button plain>Get a new quote</Button>
            </Link>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default ApplicationStatus;
