import { ApplicationType } from "Core/models/Application.model";
import * as ApplicationService from "../../Core/services/Application.service";

import * as fromApplications from "../types/Applications.types";

export function listApplications() {
  return async function (dispatch) {
    dispatch({ type: fromApplications.LIST_APPLICATIONS });

    try {
      const applications = await ApplicationService.listApplications();

      dispatch({
        type: fromApplications.LIST_APPLICATIONS_SUCCESS,
        payload: applications
      });

      return applications;
    } catch (e) {
      dispatch({ type: fromApplications.LIST_APPLICATIONS_FAIL });
      throw e;
    }
  };
}

export function getApplication(applicationId) {
  return async function (dispatch) {
    dispatch({ type: fromApplications.GET_APPLICATION });

    try {
      const application = await ApplicationService.getApplication(
        applicationId
      );

      dispatch({
        type: fromApplications.GET_APPLICATION_SUCCESS,
        payload: application
      });

      return application;
    } catch (e) {
      dispatch({ type: fromApplications.GET_APPLICATION_FAIL });
      throw e;
    }
  };
}

export function createApplication(
  product,
  data,
  applicationType = ApplicationType.PERSONAL
) {
  return async function (dispatch) {
    dispatch({ type: fromApplications.CREATE_APPLICATION });

    try {
      const application = await ApplicationService.createApplication(
        product,
        data,
        applicationType
      );

      dispatch({
        type: fromApplications.CREATE_APPLICATION_SUCCESS,
        payload: application
      });

      return application;
    } catch (e) {
      dispatch({ type: fromApplications.CREATE_APPLICATION_FAIL });
      throw e;
    }
  };
}

export function updateApplication(applicationId, data) {
  return async function (dispatch) {
    dispatch({ type: fromApplications.UPDATE_APPLICATION });

    try {
      const application = await ApplicationService.updateApplication(
        applicationId,
        data
      );

      dispatch({
        type: fromApplications.UPDATE_APPLICATION_SUCCESS,
        payload: application
      });

      return application;
    } catch (e) {
      dispatch({ type: fromApplications.UPDATE_APPLICATION_FAIL });
      throw e;
    }
  };
}

export function submitApplication(applicationId) {
  return async function (dispatch) {
    dispatch({ type: fromApplications.SUBMIT_APPLICATION });

    try {
      const application = await ApplicationService.submitUserApplication(
        applicationId
      );

      dispatch({
        type: fromApplications.SUBMIT_APPLICATION_SUCCESS,
        payload: application
      });

      return application;
    } catch (e) {
      dispatch({ type: fromApplications.SUBMIT_APPLICATION_FAIL });
      throw e;
    }
  };
}

export function rescindApplication(applicationId) {
  return async function (dispatch) {
    dispatch({ type: fromApplications.RESCIND_APPLICATION });

    try {
      const application = await ApplicationService.rescindUserApplication(
        applicationId
      );

      dispatch({
        type: fromApplications.RESCIND_APPLICATION_SUCCESS,
        payload: application
      });

      return application;
    } catch (e) {
      dispatch({ type: fromApplications.RESCIND_APPLICATION_FAIL });
      throw e;
    }
  };
}

export function submitAndListApplication(applicationId) {
  return async function (dispatch) {
    await dispatch(submitApplication(applicationId));
    dispatch(listApplications());
  };
}

export function rescindAndListApplication(applicationId) {
  return async function (dispatch) {
    await dispatch(rescindApplication(applicationId));
    dispatch(listApplications());
  };
}

export function saveDraftApplication(email, product, data) {
  return async function (dispatch) {
    dispatch({ type: fromApplications.SAVE_DRAFT_APPLICATION });

    try {
      const draftApplication = await ApplicationService.saveDraftApplication(
        email,
        product,
        data
      );
      dispatch({
        type: fromApplications.SAVE_DRAFT_APPLICATION_SUCCESS,
        payload: draftApplication
      });
      return draftApplication;
    } catch (e) {
      dispatch({ type: fromApplications.SAVE_DRAFT_APPLICATION_FAIL });
      throw e;
    }
  };
}
