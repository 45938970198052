import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import styles from "./Dashboard.module.css";

import Breadcrumbs from "Common/Breadcrumbs";
import Button from "Common/Button";

const Dashboard = () => {
  return (
    <div className={styles.Container}>
      <Helmet>
        <title>Admin Home | Bounc3</title>
      </Helmet>

      <Breadcrumbs
        links={[{ name: "Admin Home", path: "/admin" }]}
        className={styles.Subnav}
      />

      <div className={styles.ButtonWrapper}>
        <Link to="/admin/quotes">
          <Button>Quotes</Button>
        </Link>
      </div>

      <div className={styles.ButtonWrapper}>
        <Link to="/admin/applications">
          <Button>Applications</Button>
        </Link>
      </div>

      <div className={styles.ButtonWrapper}>
        <Link to="/admin/users">
          <Button>Users</Button>
        </Link>
      </div>

      {/* Searchable user list - look up users by email and like name search */}
    </div>
  );
};

export default Dashboard;
