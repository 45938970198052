import { useFormik } from "formik";
import * as Yup from "yup";

import Label from "../../Common/Label";
import Input from "../../Common/Input";
import Button from "../../Common/Button";

// import styles from "./PersonalInfo.module.css";
import riskStyles from "../RiskProfile.module.css";

const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  last_name: Yup.string()
    .min(2, "This name is too short")
    .max(50, "This name is too long")
    .required("This field is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
});

const PersonalInfo = ({ data, save, previous, complete }) => {
  const formik = useFormik({
    initialValues: {
      first_name: data?.first_name || "",
      last_name: data?.last_name || "",
      email: data?.email || "",
    },
    validateOnMount: true,
    validationSchema: PersonalInfoSchema,
  });

  return (
    <div className={riskStyles.FormContainer}>
      <form className={riskStyles.Form}>
        <div className={riskStyles.Field}>
          <div className={riskStyles.Label}>
            <Label>First Name</Label>
          </div>
          <Input
            name="first_name"
            placeholder="John"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className={riskStyles.Error}>
            {formik.errors.first_name && formik.touched.first_name && (
              <span>{formik.errors.first_name}</span>
            )}
          </div>
        </div>

        <div className={riskStyles.Field}>
          <div className={riskStyles.Label}>
            <Label>Last Name</Label>
          </div>
          <Input
            name="last_name"
            placeholder="Smith"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className={riskStyles.Error}>
            {formik.errors.last_name && formik.touched.last_name && (
              <span>{formik.errors.last_name}</span>
            )}
          </div>
        </div>

        <div className={riskStyles.Field}>
          <div className={riskStyles.Label}>
            <Label>Email</Label>
          </div>
          <Input
            name="email"
            type="email"
            placeholder="jsmith@example.ca"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className={riskStyles.Error}>
            {formik.errors.email && formik.touched.email && (
              <span>{formik.errors.email}</span>
            )}
          </div>
        </div>

        <div className={riskStyles.ButtonWrapper}>
          <Button
            type="button"
            className={riskStyles.PreviousButton}
            onClick={(event) => {
              event.preventDefault();
              save(formik.values);
              previous();
            }}
          >
            Previous
          </Button>

          <Button
            type="submit"
            className={riskStyles.NextButton}
            onClick={(event) => {
              event.preventDefault();
              complete(formik.values);
            }}
            disabled={!formik.isValid}
          >
            Complete
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PersonalInfo;
