import * as AdminUserService from "Core/services/AdminUser.service";
import * as AdminNoteService from "Core/services/AdminNote.service";

import * as fromAdminUsers from "../types/AdminUsers.types";

export function adminListUsers(query = {}) {
  return async function (dispatch) {
    dispatch({ type: fromAdminUsers.ADMIN_LIST_USERS });

    try {
      const users = await AdminUserService.adminListUsers(query);

      dispatch({
        type: fromAdminUsers.ADMIN_LIST_USERS_SUCCESS,
        payload: users,
      });

      return users;
    } catch (e) {
      dispatch({ type: fromAdminUsers.ADMIN_LIST_USERS_FAIL });
      throw e;
    }
  };
}

export function adminGetUser(userId) {
  return async function (dispatch) {
    dispatch({ type: fromAdminUsers.ADMIN_GET_USER });

    try {
      const user = await AdminUserService.adminGetUser(userId);

      dispatch({
        type: fromAdminUsers.ADMIN_GET_USER_SUCCESS,
        payload: user,
      });

      return user;
    } catch (e) {
      dispatch({ type: fromAdminUsers.ADMIN_GET_USER_FAIL });
      throw e;
    }
  };
}

export function adminCreateUserNote(userId, content) {
  return async function (dispatch) {
    dispatch({ type: fromAdminUsers.ADMIN_CREATE_USER_NOTE });

    try {
      const note = await AdminNoteService.adminCreateNote(content, userId);

      dispatch({
        type: fromAdminUsers.ADMIN_CREATE_USER_NOTE_SUCCESS,
        payload: note,
      });

      return note;
    } catch (e) {
      dispatch({ type: fromAdminUsers.ADMIN_CREATE_USER_NOTE_FAIL });
      throw e;
    }
  };
}
