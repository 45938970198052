import { Switch, Route } from "react-router-dom";

import Nav from "./Nav";
import Dashboard from "./Dashboard";
import UsersRouter from "./Users";
import ApplicationsRouter from "./Applications";
import QuotesRouter from "./Quotes";

import styles from "./Admin.module.css";

const DashboardRouter = ({ match }) => {
  return (
    <>
      <main className={styles.AdminMain}>
        <Nav />

        <div className={styles.AdminPage}>
          <Switch>
            <Route exact path={`/admin`} component={Dashboard} />

            <Route path={`/admin/users`} component={UsersRouter} />

            <Route
              path={`/admin/applications`}
              component={ApplicationsRouter}
            />

            <Route path={`/admin/quotes`} component={QuotesRouter} />
          </Switch>
        </div>
      </main>
    </>
  );
};

export default DashboardRouter;
