import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import P from "Common/P";
import Label from "Common/Label";
import Select from "Common/Select";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";

import ApplicationsStyles from "../../Applications.module.css";
// import TempCoverageInfoStyles from "./TempCoverageInfo.module.css";

const TempCoverageInfoSchema = Yup.object().shape({
  applying_for_temp_coverage: Yup.boolean().required("This field is required"),

  had_disease: Yup.boolean().when("applying_for_temp_coverage", {
    is: true,
    then: Yup.boolean().required("This field is required"),
  }),
  was_hospitalized: Yup.boolean().when("applying_for_temp_coverage", {
    is: true,
    then: Yup.boolean().required("This field is required"),
  }),
  had_tests_or_surgery_advised: Yup.boolean().when(
    "applying_for_temp_coverage",
    {
      is: true,
      then: Yup.boolean().required("This field is required"),
    },
  ),
  had_life_insurance_declined_or_modified: Yup.boolean().when(
    "applying_for_temp_coverage",
    {
      is: true,
      then: Yup.boolean().required("This field is required"),
    },
  ),
  has_untreated_symptoms: Yup.boolean().when("applying_for_temp_coverage", {
    is: true,
    then: Yup.boolean().required("This field is required"),
  }),
});

const TempCoverageInfo = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Temporary Coverage Info</StylizedHeading>

      <Formik
        initialValues={{
          applying_for_temp_coverage:
            typeof data?.applying_for_temp_coverage === "boolean"
              ? data?.applying_for_temp_coverage
              : "",

          had_disease:
            typeof data?.had_disease === "boolean" ? data?.had_disease : "",
          was_hospitalized:
            typeof data?.was_hospitalized === "boolean"
              ? data?.was_hospitalized
              : "",
          had_tests_or_surgery_advised:
            typeof data?.had_tests_or_surgery_advised === "boolean"
              ? data?.had_tests_or_surgery_advised
              : "",
          had_life_insurance_declined_or_modified:
            typeof data?.had_life_insurance_declined_or_modified === "boolean"
              ? data?.had_life_insurance_declined_or_modified
              : "",
          has_untreated_symptoms:
            typeof data?.has_untreated_symptoms === "boolean"
              ? data?.has_untreated_symptoms
              : "",
        }}
        validateOnMount
        validationSchema={TempCoverageInfoSchema}
        onSubmit={(values) => {
          save(values);
          next();
        }}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  Are you applying for Temporary Life Insurance Coverage?
                </Label>
                <Tooltip message="Temporary insurance is short term coverage offered by RBC to cover the duration of the insurance application process. The coverage usually starts when your application, and initial premium payment, is submitted. It ends when your insurance policy is approved.">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <Field
                as={Select}
                name="applying_for_temp_coverage"
                onChange={(event) =>
                  setFieldValue(
                    "applying_for_temp_coverage",
                    JSON.parse(event.target.value),
                  )
                }
              >
                <option value="" disabled hidden></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="applying_for_temp_coverage"
              />
            </div>

            {values.applying_for_temp_coverage && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Have you ever been treated for or had any indication of
                    heart or circulatory disease, heart attack, high blood
                    pressure, chest pain, abnormal ECG, stroke, transient
                    ischemic attacks (TIAs), diabetes, chronic kidney, liver or
                    lung disease, cancer or tumour, multiple sclerosis,
                    paralysis, motor neuron disease, Alzheimer’s disease,
                    Huntington’s disease, Parkinson’s disease, AIDS, ARC or HIV
                    infection, loss of speech, blindness or deafness?
                  </Label>
                  <Field
                    as={Select}
                    name="had_disease"
                    onChange={(event) =>
                      setFieldValue(
                        "had_disease",
                        JSON.parse(event.target.value),
                      )
                    }
                  >
                    <option value="" disabled hidden></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="had_disease"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Within the past year, other than normal childbirth, have you
                    been admitted to a hospital or other medical facility or
                    been advised to do so?
                  </Label>
                  <Field
                    as={Select}
                    name="was_hospitalized"
                    onChange={(event) =>
                      setFieldValue(
                        "was_hospitalized",
                        JSON.parse(event.target.value),
                      )
                    }
                  >
                    <option value="" disabled hidden></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="was_hospitalized"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Have you been advised to have any tests, investigations or
                    surgery not yet done?
                  </Label>
                  <Field
                    as={Select}
                    name="had_tests_or_surgery_advised"
                    onChange={(event) =>
                      setFieldValue(
                        "had_tests_or_surgery_advised",
                        JSON.parse(event.target.value),
                      )
                    }
                  >
                    <option value="" disabled hidden></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="had_tests_or_surgery_advised"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    In the past year has any application for life insurance,
                    change or reinstatement declined, rated or modified in any
                    way?
                  </Label>
                  <Field
                    as={Select}
                    name="had_life_insurance_declined_or_modified"
                    onChange={(event) =>
                      setFieldValue(
                        "had_life_insurance_declined_or_modified",
                        JSON.parse(event.target.value),
                      )
                    }
                  >
                    <option value="" disabled hidden></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="had_life_insurance_declined_or_modified"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Are you aware of any symptoms for which they have not sought
                    treatment or for which treatment is planned or pending?
                  </Label>
                  <Field
                    as={Select}
                    name="has_untreated_symptoms"
                    onChange={(event) =>
                      setFieldValue(
                        "has_untreated_symptoms",
                        JSON.parse(event.target.value),
                      )
                    }
                  >
                    <option value="" disabled hidden></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="has_untreated_symptoms"
                  />
                </div>

                <P className={ApplicationsStyles.SmallText}>
                  An Initial Deposit amount is required for the payment of
                  Temporary Insurance. This amount will be debited/charged as
                  soon as the application has been submitted to RBC.
                </P>
              </>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                type="submit"
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TempCoverageInfo;
