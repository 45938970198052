import classNames from "classnames";

import styles from "./Input.module.css";

export default function Input({
  className,
  wrapperClassName,
  prefix,
  ...props
}) {
  return (
    <div className={classNames(styles.InputWrapper, wrapperClassName)}>
      <input
        className={classNames(
          styles.Input,
          className,
          prefix && styles.InputWithPrefix
        )}
        {...props}
      />
      {prefix && <span className={styles.InputPrefix}>{prefix}</span>}
    </div>
  );
}
