import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Helmet from "react-helmet";

import {
  adminGetUser,
  adminCreateUserNote
} from "store/actions/AdminUsers.actions";
import { adminSelectUser } from "store/selectors/AdminUsers.selectors";

import styles from "./UserView.module.css";

import { Card, CardBody } from "Common/Card";
import Textarea from "Common/Textarea";
import Label from "Common/Label";
import P from "Common/P";
import Breadcrumbs from "Common/Breadcrumbs";

const UserView = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const user = useSelector(adminSelectUser);

  useEffect(() => dispatch(adminGetUser(userId)).catch((e) => console.log(e)), [
    dispatch,
    userId
  ]);

  const handleNoteBlur = (event) => {
    dispatch(adminCreateUserNote(user.id, event.target.value)).catch((e) =>
      console.log(e)
    );
  };

  if (!user) return null;

  return (
    <div className={styles.Container}>
      <Helmet>
        <title>User {user.email} | Bounc3</title>
      </Helmet>

      <Breadcrumbs
        links={[
          { name: "Admin Home", path: "/admin" },
          { name: `Users`, path: "/admin/users" },
          { name: `${user.email}`, path: "#" }
        ]}
        className={styles.Subnav}
      />

      <Card className={styles.Card}>
        <CardBody>
          <div className={styles.LabelWrapper}>
            <Label>User Info</Label>
          </div>

          <P>
            {user.profile.first_name} {user.profile.last_name}
            <br />
            <small>{user.email}</small>
          </P>
        </CardBody>
      </Card>

      <Card className={styles.Card}>
        <CardBody>
          <div className={styles.LabelWrapper}>
            <Label>Applications</Label>
          </div>

          <div className={styles.ApplicationList}>
            {user.applications?.map((a, i) => {
              return (
                <div key={i} style={{ marginBottom: "1em" }}>
                  <Link key={i} to={`/admin/applications/${a.id}`}>
                    {a.product}
                  </Link>
                </div>
              );
            })}
          </div>
        </CardBody>
      </Card>

      <Card className={styles.Card}>
        <CardBody>
          <div className={styles.LabelWrapper}>
            <Label>Notes</Label>
          </div>
          <Textarea
            rows="5"
            defaultValue={user.note?.content}
            onBlur={handleNoteBlur}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default UserView;
