// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (window.gtag !== undefined) {
    window.gtag({
      event: "pageview"
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// Single event function
export const event = (action) => {
  if (window.gtag !== undefined) {
    window.gtag({ event: action });
  }
};

// Raw track function
export const track = (data) => {
  if (window.gtag !== undefined) {
    window.gtag(data);
  }
};
