import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Textarea from "Common/Textarea";
import Select from "Common/Select";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";

const BusinessFinancialInfoSchema = Yup.object().shape({
  gross_annual_income: Yup.number().required("This field is required"),
  gross_household_income: Yup.number().required("This field is required"),
  other_income: Yup.number().required("This field is required"),
  book_value_current_year: Yup.number().required("This year is required"),
  book_value_previous_year: Yup.number(),
  fair_market_value_current_year: Yup.number().required(
    "This year is required"
  ),
  fair_market_value_previous_year: Yup.number(),
  gross_annual_revenue_current_year: Yup.number().required(
    "This year is required"
  ),
  gross_annual_revenue_previous_year: Yup.number(),
  net_annual_after_tax_income_current_year: Yup.number().required(
    "This year is required"
  ),
  net_annual_after_tax_income_previous_year: Yup.number(),
  other_partners_insured: Yup.boolean().required("This field is required"),
  other_partners_not_insured_reason: Yup.string(),
  has_existing_agreement_for_other: Yup.string().required(
    "This field is required"
  )
});

const BusinessFinancialInfo = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Financial Info</StylizedHeading>

      <Formik
        initialValues={{
          gross_annual_income: data?.gross_annual_income || "",
          gross_household_income: data?.gross_household_income || "",
          other_income: data?.other_income || "",
          book_value_current_year: data?.book_value_current_year || "",
          book_value_previous_year: data?.book_value_previous_year || "",
          fair_market_value_current_year:
            data?.fair_market_value_current_year || "",
          fair_market_value_previous_year:
            data?.fair_market_value_previous_year || "",
          gross_annual_revenue_current_year:
            data?.gross_annual_revenue_current_year || "",
          gross_annual_revenue_previous_year:
            data?.gross_annual_revenue_previous_year || "",
          net_annual_after_tax_income_current_year:
            data?.net_annual_after_tax_income_current_year || "",
          net_annual_after_tax_income_previous_year:
            data?.net_annual_after_tax_income_previous_year || "",
          other_partners_insured:
            typeof data?.other_partners_insured === "boolean"
              ? data?.other_partners_insured
              : true,
          other_partners_not_insured_reason:
            data?.other_partners_not_insured_reason || "",
          has_existing_agreement_for_other:
            data?.has_existing_agreement_for_other || ""
        }}
        validateOnMount
        validationSchema={BusinessFinancialInfoSchema}
      >
        {({ values, isValid, errors, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What is your annual earned income (within $10,000), including
                  salary, commissions, dividends, bonuses and pension, within
                  Canada?
                </Label>
              </div>
              <NumberFormat
                customInput={Input}
                name="gross_annual_income"
                defaultValue={0}
                value={values.gross_annual_income}
                onValueChange={(event) => {
                  setFieldValue("gross_annual_income", event.value, false);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="gross_annual_income"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What is your annual income (within $10,000) from other
                  Canadian sources, including interest and income from real
                  estate, within Canada?
                </Label>
              </div>
              <NumberFormat
                customInput={Input}
                name="other_income"
                defaultValue={0}
                value={values.other_income}
                onValueChange={(event) => {
                  setFieldValue("other_income", event.value, false);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="other_income"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  If income is not generated from any of the above sources
                  within Canada, tell us the household income.
                </Label>
              </div>
              <NumberFormat
                customInput={Input}
                name="gross_household_income"
                defaultValue={0}
                value={values.gross_household_income}
                onValueChange={(event) => {
                  setFieldValue("gross_household_income", event.value, false);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="gross_household_income"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What is the book value of the business (net worth)?
                </Label>
              </div>

              <div className={ApplicationsStyles.Inline}>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>This Year</Label>
                  <NumberFormat
                    customInput={Input}
                    name="book_value_current_year"
                    defaultValue={0}
                    value={values.book_value_current_year}
                    onValueChange={(event) => {
                      setFieldValue(
                        "book_value_current_year",
                        event.value,
                        false
                      );
                    }}
                    prefix={"$ "}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>Last Year</Label>
                  <NumberFormat
                    customInput={Input}
                    name="book_value_previous_year"
                    defaultValue={0}
                    value={values.book_value_previous_year}
                    onValueChange={(event) => {
                      setFieldValue(
                        "book_value_previous_year",
                        event.value,
                        false
                      );
                    }}
                    prefix={"$ "}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="book_value_current_year"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="book_value_previous_year"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What is the fair market value of the business?
                </Label>
              </div>

              <div className={ApplicationsStyles.Inline}>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>This Year</Label>
                  <NumberFormat
                    customInput={Input}
                    name="fair_market_value_current_year"
                    defaultValue={0}
                    value={values.fair_market_value_current_year}
                    onValueChange={(event) => {
                      setFieldValue(
                        "fair_market_value_current_year",
                        event.value,
                        false
                      );
                    }}
                    prefix={"$ "}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>Last Year</Label>
                  <NumberFormat
                    customInput={Input}
                    name="fair_market_value_previous_year"
                    defaultValue={0}
                    value={values.fair_market_value_previous_year}
                    onValueChange={(event) => {
                      setFieldValue(
                        "fair_market_value_previous_year",
                        event.value,
                        false
                      );
                    }}
                    prefix={"$ "}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="fair_market_value_current_year"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="fair_market_value_previous_year"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What is the gross annual revenue?
                </Label>
              </div>

              <div className={ApplicationsStyles.Inline}>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>This Year</Label>
                  <NumberFormat
                    customInput={Input}
                    name="gross_annual_revenue_current_year"
                    defaultValue={0}
                    value={values.gross_annual_revenue_current_year}
                    onValueChange={(event) => {
                      setFieldValue(
                        "gross_annual_revenue_current_year",
                        event.value,
                        false
                      );
                    }}
                    prefix={"$ "}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>Last Year</Label>
                  <NumberFormat
                    customInput={Input}
                    name="gross_annual_revenue_previous_year"
                    defaultValue={0}
                    value={values.gross_annual_revenue_previous_year}
                    onValueChange={(event) => {
                      setFieldValue(
                        "gross_annual_revenue_previous_year",
                        event.value,
                        false
                      );
                    }}
                    prefix={"$ "}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="gross_annual_revenue_current_year"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="gross_annual_revenue_previous_year"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What is the net annual after-tax income?
                </Label>
              </div>

              <div className={ApplicationsStyles.Inline}>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>This Year</Label>
                  <NumberFormat
                    customInput={Input}
                    name="net_annual_after_tax_income_current_year"
                    defaultValue={0}
                    value={values.net_annual_after_tax_income_current_year}
                    onValueChange={(event) => {
                      setFieldValue(
                        "net_annual_after_tax_income_current_year",
                        event.value,
                        false
                      );
                    }}
                    prefix={"$ "}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>Last Year</Label>
                  <NumberFormat
                    customInput={Input}
                    name="net_annual_after_tax_income_previous_year"
                    defaultValue={0}
                    value={values.net_annual_after_tax_income_previous_year}
                    onValueChange={(event) => {
                      setFieldValue(
                        "net_annual_after_tax_income_previous_year",
                        event.value,
                        false
                      );
                    }}
                    prefix={"$ "}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="net_annual_after_tax_income_current_year"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="net_annual_after_tax_income_previous_year"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  Are other partners, owners and executives being insured?
                </Label>
              </div>
              <Field
                as={Select}
                name="other_partners_insured"
                onChange={(event) =>
                  setFieldValue(
                    "other_partners_insured",
                    JSON.parse(event.target.value),
                    true
                  )
                }
              >
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="other_partners_insured"
              />
            </div>

            {values.other_partners_insured === false && (
              <div className={ApplicationsStyles.FormGroup}>
                <Label className={ApplicationsStyles.Label}>
                  ** If no, provide details, including why not
                </Label>
                <Field as={Textarea} name="other_partners_not_insured_reason" />
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="other_partners_not_insured_reason"
                />
              </div>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  Is there an existing or planned agreement that provides for
                  anyone other than an owner identified in this application to
                  obtain any legal interest in any policy resulting from this
                  application?
                </Label>
              </div>
              <Field
                as={Select}
                name="has_existing_agreement_for_other"
                onChange={(event) =>
                  setFieldValue(
                    "has_existing_agreement_for_other",
                    JSON.parse(event.target.value),
                    true
                  )
                }
              >
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="has_existing_agreement_for_other"
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                className={ApplicationsStyles.NextButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  next();
                }}
                disabled={!isValid}
              >
                Next
              </Button>
            </div>

            <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{JSON.stringify(isValid, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BusinessFinancialInfo;
