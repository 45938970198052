export const ADMIN_LIST_APPLICATIONS =
  "[Admin Applications] Admin List Applications";
export const ADMIN_LIST_APPLICATIONS_SUCCESS =
  "[Admin Applications] Admin List Applications Success";
export const ADMIN_LIST_APPLICATIONS_FAIL =
  "[Admin Applications] Admin List Applications Fail";

export const ADMIN_GET_APPLICATION =
  "[Admin Applications] Admin get application";
export const ADMIN_GET_APPLICATION_SUCCESS =
  "[Admin Applications] Admin get application Success";
export const ADMIN_GET_APPLICATION_FAIL =
  "[Admin Applications] Admin get application Fail";

export const ADMIN_UPDATE_APPLICATION =
  "[Admin Applications] Admin Update Application";
export const ADMIN_UPDATE_APPLICATION_SUCCESS =
  "[Admin Applications] Admin Update Application Success";
export const ADMIN_UPDATE_APPLICATION_FAIL =
  "[Admin Applications] Admin Update Application Fail";

export const ADMIN_DELETE_APPLICATION =
  "[Admin Applications] Admin Delete Application";
export const ADMIN_DELETE_APPLICATION_SUCCESS =
  "[Admin Applications] Admin Delete Application Success";
export const ADMIN_DELETE_APPLICATION_FAIL =
  "[Admin Applications] Admin Delete Application Fail";

export const ADMIN_CREATE_APPLICATION_NOTE =
  "[Admin Applications] Admin Create Application Note";
export const ADMIN_CREATE_APPLICATION_NOTE_SUCCESS =
  "[Admin Applications] Admin Create Application Note Success";
export const ADMIN_CREATE_APPLICATION_NOTE_FAIL =
  "[Admin Applications] Admin Create Application Note Fail";
