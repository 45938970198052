import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Textarea from "Common/Textarea";
import Select from "Common/Select";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";

const EmploymentInfoSchema = Yup.object().shape({
  occupation: Yup.string().required("This field is required"),
  professional_designations_or_degress: Yup.string().required(
    "This field is required",
  ),
  weekly_hours_worked: Yup.number().required("This field is required"),
  work_from_home_percent: Yup.number().required("This field is required"),
  is_self_employed: Yup.boolean().required("This field is required"),

  self_employed_duration: Yup.number().when("is_self_employed", {
    is: true,
    then: Yup.number().required("This field is required"),
  }),
  non_owner_full_time_employees: Yup.number().when("is_self_employed", {
    is: true,
    then: Yup.number().required("This field is required"),
  }),
  business_type: Yup.string().when("is_self_employed", {
    is: true,
    then: Yup.string()
      .required("This field is required")
      .oneOf(["sole owner", "partnership", "corporation"]),
  }),
  ownership_percent: Yup.number().when("is_self_employed", {
    is: true,
    then: Yup.number().required("This field is required"),
  }),
  splits_income_for_taxes: Yup.boolean().when("is_self_employed", {
    is: true,
    then: Yup.boolean().required("This field is required"),
  }),
  split_income_amount: Yup.number().when("is_self_employed", {
    is: true,
    then: Yup.number().when("splits_income_for_taxes", {
      is: true,
      then: Yup.number().required("This field is required"),
    }),
  }),

  years_with_current_employer: Yup.number().when("is_self_employed", {
    is: false,
    then: Yup.number().required("This field is required"),
  }),
  years_in_business_type: Yup.number().when("is_self_employed", {
    is: false,
    then: Yup.number().required("This field is required"),
  }),
  is_commissioned_sales_person: Yup.boolean().when("is_self_employed", {
    is: false,
    then: Yup.boolean().required("This field is required"),
  }),
});

const EmploymentInfo = ({ data, save, previous, next }) => {
  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Employment Info</StylizedHeading>

      <Formik
        initialValues={{
          occupation: data?.occupation || "",
          professional_designations_or_degress:
            data?.professional_designations_or_degress || "",
          weekly_hours_worked: data?.weekly_hours_worked || "",
          work_from_home_percent: data?.work_from_home_percent || "",
          is_self_employed:
            typeof data?.is_self_employed === "boolean"
              ? data?.is_self_employed
              : undefined,

          self_employed_duration: data?.self_employed_duration || "",
          non_owner_full_time_employees:
            data?.non_owner_full_time_employees || "",
          business_type: data?.business_type || "",
          ownership_percent: data?.ownership_percent || "",
          splits_income_for_taxes:
            typeof data?.splits_income_for_taxes === "boolean"
              ? data?.splits_income_for_taxes
              : undefined,
          split_income_amount: data?.split_income_amount || "",

          years_with_current_employer: data?.years_with_current_employer || "",
          years_in_business_type: data?.years_in_business_type || "",
          is_commissioned_sales_person:
            typeof data?.is_commissioned_sales_person === "boolean"
              ? data?.is_commissioned_sales_person
              : undefined,
        }}
        onSubmit={(values) => {
          save(values);
          next();
        }}
        validateOnMount
        validationSchema={EmploymentInfoSchema}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>Occupation</Label>
              <Field as={Input} name="occupation" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="occupation"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Professional Designations or Degrees
              </Label>
              <Field
                as={Textarea}
                name="professional_designations_or_degress"
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="professional_designations_or_degress"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                How many hours per week do you spend working?
              </Label>
              <NumberFormat
                customInput={Input}
                name="weekly_hours_worked"
                defaultValue={0}
                value={values.weekly_hours_worked}
                onValueChange={(event) => {
                  setFieldValue("weekly_hours_worked", event.value);
                }}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="weekly_hours_worked"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                What percentage of your time spent working is at home?
              </Label>
              <NumberFormat
                customInput={Input}
                name="work_from_home_percent"
                defaultValue={0}
                value={values.work_from_home_percent}
                onValueChange={(event) => {
                  setFieldValue("work_from_home_percent", event.value);
                }}
                suffix={"%"}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="work_from_home_percent"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Are you self employed?
              </Label>
              <Field
                as={Select}
                name="is_self_employed"
                onChange={(event) =>
                  setFieldValue(
                    "is_self_employed",
                    JSON.parse(event.target.value),
                  )
                }
              >
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="is_self_employed"
              />
            </div>

            {values.is_self_employed && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    How many years have you been self employed?
                  </Label>
                  <NumberFormat
                    customInput={Input}
                    name="self_employed_duration"
                    defaultValue={0}
                    value={values.self_employed_duration}
                    onValueChange={(event) => {
                      setFieldValue("self_employed_duration", event.value);
                    }}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="self_employed_duration"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    How many full-time employees do you have, excluding owners?
                  </Label>
                  <NumberFormat
                    customInput={Input}
                    name="non_owner_full_time_employees"
                    defaultValue={0}
                    value={values.non_owner_full_time_employees}
                    onValueChange={(event) => {
                      setFieldValue(
                        "non_owner_full_time_employees",
                        event.value,
                      );
                    }}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="non_owner_full_time_employees"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Type of Business
                  </Label>
                  <Field
                    as={Select}
                    name="business_type"
                    onChange={(event) =>
                      setFieldValue("business_type", event.target.value)
                    }
                  >
                    <option></option>
                    <option value="sole owner">Sole Owner</option>
                    <option value="partnership">Partnership</option>
                    <option value="corporation">Corporation</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="business_type"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    What is your percent ownership?
                  </Label>
                  <NumberFormat
                    customInput={Input}
                    name="ownership_percent"
                    defaultValue={0}
                    value={values.ownership_percent}
                    onValueChange={(event) => {
                      setFieldValue("ownership_percent", event.value);
                    }}
                    suffix={"%"}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="ownership_percent"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Do you income split for tax purposes?
                  </Label>
                  <Field
                    as={Select}
                    name="splits_income_for_taxes"
                    onChange={(event) =>
                      setFieldValue(
                        "splits_income_for_taxes",
                        JSON.parse(event.target.value),
                      )
                    }
                  >
                    <option></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="splits_income_for_taxes"
                  />
                </div>

                {values.splits_income_for_taxes && (
                  <div className={ApplicationsStyles.FormGroup}>
                    <Label className={ApplicationsStyles.Label}>
                      What amount do you income split?
                    </Label>
                    <NumberFormat
                      customInput={Input}
                      name="split_income_amount"
                      defaultValue={0}
                      value={values.split_income_amount}
                      onValueChange={(event) => {
                        setFieldValue("split_income_amount", event.value);
                      }}
                      prefix={"$ "}
                      decimalScale={0}
                      allowNegative={false}
                      thousandSeparator={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={true}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      component="div"
                      className={ApplicationsStyles.Error}
                      name="split_income_amount"
                    />
                  </div>
                )}
              </>
            )}

            {!values.is_self_employed && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    How many years have you been with your current employer?
                  </Label>
                  <NumberFormat
                    customInput={Input}
                    name="years_with_current_employer"
                    defaultValue={0}
                    value={values.years_with_current_employer}
                    onValueChange={(event) => {
                      setFieldValue("years_with_current_employer", event.value);
                    }}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="years_with_current_employer"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    How many full-time employees do you have, excluding owners?
                  </Label>
                  <NumberFormat
                    customInput={Input}
                    name="years_in_business_type"
                    defaultValue={0}
                    value={values.years_in_business_type}
                    onValueChange={(event) => {
                      setFieldValue("years_in_business_type", event.value);
                    }}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="years_in_business_type"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Are you a commissioned sales person?
                  </Label>
                  <Field
                    as={Select}
                    name="is_commissioned_sales_person"
                    onChange={(event) =>
                      setFieldValue(
                        "is_commissioned_sales_person",
                        JSON.parse(event.target.value),
                      )
                    }
                  >
                    <option></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="is_commissioned_sales_person"
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                type="submit"
                fullWidth
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmploymentInfo;
