import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Input from "Common/Input";
import Textarea from "Common/Textarea";
import Select from "Common/Select";
import Button from "Common/Button";
import NetWorthCalculatorModal from "Common/NetWorthCalculatorModal";

import ApplicationsStyles from "../../Applications.module.css";
import FinancialInfoStyles from "./FinancialInfo.module.css";

const FinancialInfoSchema = Yup.object().shape({
  is_employed: Yup.boolean().required("This field is required"),

  employer_name: Yup.string().when("is_employed", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  employer_street_address: Yup.string().when("is_employed", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  employer_city: Yup.string().when("is_employed", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  employer_province: Yup.string().when("is_employed", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  employer_postal_code: Yup.string().when("is_employed", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  employer_phone: Yup.string().when("is_employed", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  nature_of_business: Yup.string().when("is_employed", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  occupation: Yup.string().when("is_employed", {
    is: true || false,
    then: Yup.string().required("This field is required"),
  }),
  occupation_description: Yup.string().when("is_employed", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  duties_description: Yup.string().when("is_employed", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  current_employment_duration: Yup.string().when("is_employed", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  earned_annual_income: Yup.number().when("is_employed", {
    is: (value) => typeof value === "boolean",
    then: Yup.number().required("This field is required"),
  }),
  personal_net_worth: Yup.number().when("is_employed", {
    is: true || false,
    then: Yup.number().required("This field is required"),
  }),

  has_outstanding_mortgage: Yup.boolean().required("This field is required"),
  outstanding_mortgage_amount: Yup.number().when("has_outstanding_mortgage", {
    is: true,
    then: Yup.number().required("This field is required"),
  }),

  is_self_supporting: Yup.boolean().required("This field is required"),
  family_earned_annual_income: Yup.number().when("is_self_supporting", {
    is: false,
    then: Yup.number().required("This field is required"),
  }),

  has_other_income: Yup.boolean().required("This field is required"),
  other_annual_income: Yup.number().when("has_other_income", {
    is: true,
    then: Yup.number().required("This field is required"),
  }),
  other_income_description: Yup.string().when("has_other_income", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),

  declared_bankruptcy: Yup.boolean().required("This field is required"),
  bankruptcy_type: Yup.string().when("declared_bankruptcy", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  bankruptcy_details: Yup.string().when("declared_bankruptcy", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
});

const FinancialInfo = ({ data, save, previous, next }) => {
  const [netWorthCalcVisible, setNetWorthCalcVisible] = useState(false);

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Financial Info</StylizedHeading>

      <Formik
        initialValues={{
          is_employed:
            typeof data?.is_employed === "boolean" ? data?.is_employed : "",

          employer_name: data?.employer_name || "",
          employer_street_address: data?.employer_street_address || "",
          employer_city: data?.employer_city || "",
          employer_province: data?.employer_province || "",
          employer_postal_code: data?.employer_postal_code || "",
          employer_phone: data?.employer_phone || "",
          nature_of_business: data?.nature_of_business || "",
          occupation: data?.occupation || "",
          occupation_description: data?.occupation_description || "",
          duties_description: data?.duties_description || "",
          current_employment_duration: data?.current_employment_duration || "",
          earned_annual_income: data?.earned_annual_income || "",
          personal_net_worth: data?.personal_net_worth || "",

          has_outstanding_mortgage:
            typeof data?.has_outstanding_mortgage === "boolean"
              ? data?.has_outstanding_mortgage
              : "",
          outstanding_mortgage_amount: data?.outstanding_mortgage_amount || "",

          is_self_supporting:
            typeof data?.is_self_supporting === "boolean"
              ? data?.is_self_supporting
              : "",
          family_earned_annual_income: data?.family_earned_annual_income || "",

          has_other_income:
            typeof data?.has_other_income === "boolean"
              ? data?.has_other_income
              : "",
          other_annual_income: data?.other_annual_income || "",
          other_income_description: data?.other_income_description || "",

          declared_bankruptcy:
            typeof data?.declared_bankruptcy === "boolean"
              ? data?.declared_bankruptcy
              : "",
          bankruptcy_type: data?.bankruptcy_type || "",
          bankruptcy_details: data?.bankruptcy_details || "",
        }}
        validateOnMount
        validationSchema={FinancialInfoSchema}
        onSubmit={(values) => {
          save(values);
          next();
        }}
      >
        {({ values, isValid, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Are you employed?
              </Label>
              <Field
                as={Select}
                name="is_employed"
                onChange={(event) =>
                  setFieldValue(
                    "is_employed",
                    JSON.parse(event.target.value),
                    true,
                  )
                }
              >
                <option value="" disabled hidden></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="is_employed"
              />
            </div>

            {values.is_employed && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Employer Name
                  </Label>
                  <Field as={Input} name="employer_name" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="employer_name"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Employer Street Address
                  </Label>
                  <Field as={Input} name="employer_street_address" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="employer_street_address"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Employer City
                  </Label>
                  <Field as={Input} name="employer_city" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="employer_city"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Employer Province
                  </Label>
                  <Field as={Input} name="employer_province" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="employer_province"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Employer Postal Code
                  </Label>
                  <Field as={Input} name="employer_postal_code" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="employer_postal_code"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Employer Phone
                  </Label>
                  <NumberFormat
                    customInput={Input}
                    name="employer_phone"
                    value={values.employer_phone}
                    onValueChange={(event) => {
                      setFieldValue("employer_phone", event.value, true);
                    }}
                    format="(###) ###-####"
                    decimalScale={0}
                    allowNegative={false}
                    allowEmptyFormatting={false}
                    type="tel"
                    placeholder="(123) 456-7890"
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="employer_phone"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Describe the nature of the business.
                  </Label>
                  <Field as={Textarea} name="nature_of_business" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="nature_of_business"
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                {values.is_employed ? `Occupation` : `What do you do?`}
              </Label>
              <Field as={Input} name="occupation" />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="occupation"
              />
            </div>

            {values.is_employed && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Describe your occupation.
                  </Label>
                  <Field as={Textarea} name="occupation_description" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="occupation_description"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Describe your duties.
                  </Label>
                  <Field as={Textarea} name="duties_description" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="duties_description"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    How long have you been with your current employer?
                  </Label>
                  <Field as={Input} name="current_employment_duration" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="current_employment_duration"
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Earned Annual Income
              </Label>
              <NumberFormat
                customInput={Input}
                name="earned_annual_income"
                defaultValue={0}
                value={values.earned_annual_income}
                onValueChange={(event) => {
                  setFieldValue("earned_annual_income", event.value, true);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="earned_annual_income"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={FinancialInfoStyles.LabelWrapper}>
                <Label className={ApplicationsStyles.Label}>
                  What is your personal net worth?
                </Label>
                <div
                  className={FinancialInfoStyles.ActionText}
                  onClick={() => setNetWorthCalcVisible(true)}
                >
                  Calculate Net Worth
                </div>
              </div>
              <NumberFormat
                customInput={Input}
                name="personal_net_worth"
                defaultValue={0}
                value={values.personal_net_worth}
                onValueChange={(event) => {
                  setFieldValue("personal_net_worth", event.value, true);
                }}
                prefix={"$ "}
                decimalScale={0}
                allowNegative={false}
                thousandSeparator={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
                onBlur={handleBlur}
              />
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="personal_net_worth"
              />
            </div>

            {netWorthCalcVisible && (
              <NetWorthCalculatorModal
                onSubmit={(netWorth) => {
                  setFieldValue("personal_net_worth", netWorth, true);
                  setNetWorthCalcVisible(false);
                }}
                onClose={() => setNetWorthCalcVisible(false)}
              />
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Do you have an outstanding mortgage?
              </Label>
              <Field
                as={Select}
                name="has_outstanding_mortgage"
                onChange={(event) =>
                  setFieldValue(
                    "has_outstanding_mortgage",
                    JSON.parse(event.target.value),
                    true,
                  )
                }
              >
                <option value="" disabled hidden></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="has_outstanding_mortgage"
              />
            </div>

            {values.has_outstanding_mortgage && (
              <div className={ApplicationsStyles.FormGroup}>
                <Label className={ApplicationsStyles.Label}>
                  Outstanding Mortgage Amount
                </Label>
                <NumberFormat
                  customInput={Input}
                  name="outstanding_mortgage_amount"
                  defaultValue={0}
                  value={values.outstanding_mortgage_amount}
                  onValueChange={(event) => {
                    setFieldValue(
                      "outstanding_mortgage_amount",
                      event.value,
                      true,
                    );
                  }}
                  prefix={"$ "}
                  decimalScale={0}
                  allowNegative={false}
                  thousandSeparator={true}
                  allowLeadingZeros={false}
                  allowEmptyFormatting={true}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="outstanding_mortgage_amount"
                />
              </div>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Are you self supporting?
              </Label>
              <Field
                as={Select}
                name="is_self_supporting"
                onChange={(event) =>
                  setFieldValue(
                    "is_self_supporting",
                    JSON.parse(event.target.value),
                    true,
                  )
                }
              >
                <option value="" disabled hidden></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="is_self_supporting"
              />
            </div>

            {values.is_self_supporting === false && (
              <div className={ApplicationsStyles.FormGroup}>
                <Label className={ApplicationsStyles.Label}>
                  Annual Gross Amount of Family Earned Income
                </Label>
                <NumberFormat
                  customInput={Input}
                  name="family_earned_annual_income"
                  defaultValue={0}
                  value={values.family_earned_annual_income}
                  onValueChange={(event) => {
                    setFieldValue(
                      "family_earned_annual_income",
                      event.value,
                      true,
                    );
                  }}
                  prefix={"$ "}
                  decimalScale={0}
                  allowNegative={false}
                  thousandSeparator={true}
                  allowLeadingZeros={false}
                  allowEmptyFormatting={true}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="family_earned_annual_income"
                />
              </div>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Do you have income from other sources?
              </Label>
              <Field
                as={Select}
                name="has_other_income"
                onChange={(event) =>
                  setFieldValue(
                    "has_other_income",
                    JSON.parse(event.target.value),
                    true,
                  )
                }
              >
                <option value="" disabled hidden></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="has_other_income"
              />
            </div>

            {values.has_other_income && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Annual Income from Other Sources
                  </Label>
                  <NumberFormat
                    customInput={Input}
                    name="other_annual_income"
                    defaultValue={0}
                    value={values.other_annual_income}
                    onValueChange={(event) => {
                      setFieldValue("other_annual_income", event.value, true);
                    }}
                    prefix={"$ "}
                    decimalScale={0}
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="other_annual_income"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Description of Other Sources of Income
                  </Label>
                  <Field as={Textarea} name="other_income_description" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="other_income_description"
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                In the past five years, have you declared personal or corporate
                bankruptcy?
              </Label>
              <Field
                as={Select}
                name="declared_bankruptcy"
                onChange={(event) =>
                  setFieldValue(
                    "declared_bankruptcy",
                    JSON.parse(event.target.value),
                    true,
                  )
                }
              >
                <option value="" disabled hidden></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="declared_bankruptcy"
              />
            </div>

            {values.declared_bankruptcy && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Was the bankruptcy personal or corporate?
                  </Label>
                  <Field as={Select} name="bankruptcy_type">
                    <option value="" disabled hidden></option>
                    <option value="personal">Personal</option>
                    <option value="corporate">Corporate</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="bankruptcy_type"
                  />
                </div>

                <div className={ApplicationsStyles.FormGroup}>
                  <Label className={ApplicationsStyles.Label}>
                    Please provide details on the year that bankruptcy was
                    declared, and the discharge date.
                  </Label>
                  <Field as={Textarea} name="bankruptcy_details" />
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="bankruptcy_details"
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                type="submit"
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FinancialInfo;
