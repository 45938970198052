import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import Label from "Common/Label";
import Select from "Common/Select";
import Button from "Common/Button";
import Tooltip from "Common/Tooltip";

import ApplicationsStyles from "../../Applications.module.css";
import { ApplicationType } from "Core/models/Application.model";

const PaymentInfoSchema = Yup.object().shape({
  has_preferred_payment_day: Yup.boolean().required("This field is required"),
  preferred_payment_day: Yup.number().when("has_preferred_payment_day", {
    is: true,
    then: Yup.number().required("This field is required")
  }),
  preferred_payment_option: Yup.string().required("This field is required"),
  payment_frequency: Yup.string().required("This field is required"),
  account_type: Yup.string()
});

const PaymentInfo = ({ data, applicationType, save, previous, next }) => {
  const mapDayOptions = () => {
    const mappedOptions = [];
    for (let i = 1; i <= 28; i++) {
      mappedOptions.push(<option value={i}>{i}</option>);
    }
    return mappedOptions;
  };

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Payment Info</StylizedHeading>

      <Formik
        initialValues={{
          has_preferred_payment_day:
            typeof data?.has_preferred_payment_day === "boolean"
              ? data?.has_preferred_payment_day
              : "",
          preferred_payment_day: data?.preferred_payment_day || "",
          preferred_payment_option: data?.preferred_payment_option || "",
          payment_frequency: data?.payment_frequency || "",
          account_type:
            data?.account_type || applicationType === ApplicationType.BUSINESS
              ? "business"
              : "personal"
        }}
        validateOnMount
        validationSchema={PaymentInfoSchema}
        onSubmit={(values) => {
          save(values);
          next();
        }}
      >
        {({ values, isValid, errors, setFieldValue, handleBlur }) => (
          <Form className={ApplicationsStyles.Form}>
            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                Is there a preferred day to make the monthly premium payments?
              </Label>
              <Field
                as={Select}
                name="has_preferred_payment_day"
                onChange={(event) =>
                  setFieldValue(
                    "has_preferred_payment_day",
                    JSON.parse(event.target.value)
                  )
                }
              >
                <option value="" disabled hidden></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="has_preferred_payment_day"
              />
            </div>

            {values.has_preferred_payment_day && (
              <div className={ApplicationsStyles.FormGroup}>
                <Label className={ApplicationsStyles.Label}>
                  Preferred Payment Day
                </Label>
                <Field
                  as={Select}
                  name="preferred_payment_day"
                  onChange={(event) =>
                    setFieldValue(
                      "preferred_payment_day",
                      JSON.parse(event.target.value)
                    )
                  }
                >
                  <option value="" disabled hidden></option>
                  {mapDayOptions()}
                </Field>
                <ErrorMessage
                  component="div"
                  className={ApplicationsStyles.Error}
                  name="preferred_payment_day"
                />
              </div>
            )}

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  What is your preferred payment option?
                </Label>
              </div>
              <Field
                as={Select}
                name="preferred_payment_option"
                onChange={(event) => {
                  let paymentFrequency = "";
                  switch (event.target.value) {
                    case "bank account": {
                      paymentFrequency = "monthly";
                      break;
                    }
                    case "direct bill": {
                      paymentFrequency = "annually";
                      break;
                    }
                    default: {
                      break;
                    }
                  }
                  setFieldValue("preferred_payment_option", event.target.value);
                  setFieldValue("payment_frequency", paymentFrequency);
                }}
              >
                <option value="" disabled hidden></option>
                <option value="bank account">Bank Account</option>
                <option value="direct bill">Direct Bill</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="preferred_payment_option"
              />
            </div>

            <div className={ApplicationsStyles.FormGroup}>
              <div className={ApplicationsStyles.Label}>
                <Label className={ApplicationsStyles.Label}>
                  Frequency of Payments
                </Label>
                <Tooltip message="For monthly payments, the only payment option is pre-authorized withdrawals from a Bank Account. For yearly payments, the only payment option is Direct Bill.">
                  <div className={ApplicationsStyles.InfoIcon}>i</div>
                </Tooltip>
              </div>
              <Field as={Select} name="payment_frequency" disabled>
                <option value="" disabled hidden></option>
                <option value="monthly">Monthly</option>
                <option value="annually">Annually</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="payment_frequency"
              />
            </div>

            {applicationType === ApplicationType.BUSINESS && (
              <>
                <div className={ApplicationsStyles.FormGroup}>
                  <div className={ApplicationsStyles.Label}>
                    <Label className={ApplicationsStyles.Label}>
                      From whose account will the premiums be withdrawn?
                    </Label>
                  </div>
                  <Field as={Select} name="account_type">
                    <option value="business">A business account</option>
                    <option value="personal">My personal account</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className={ApplicationsStyles.Error}
                    name="account_type"
                  />
                </div>
              </>
            )}

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                type="submit"
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>

            <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{JSON.stringify(isValid, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentInfo;
