import classNames from "classnames";

import Button from "Common/Button";

import { ReactComponent as Logo } from "../../Icons/logo.svg";

import styles from "./HelpBanner.module.css";

const HelpBanner = ({ className }) => {
  return (
    <div className={classNames(styles.banner, className)}>
      <div className={styles.logoWrapper}>
        <Logo />
      </div>
      <div className={styles.helpText}>
        <h4>Still have questions?</h4>
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          linkTo="https://calendly.com/olga-morawczynski/bounc3-consultation"
          target="_blank"
        >
          Book expert
        </Button>
      </div>
    </div>
  );
};

export default HelpBanner;
