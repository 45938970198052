import React from "react";
import classNames from "classnames";

import styles from "./Card.module.css";

export const Card = ({ className, children }) => {
  return <div className={classNames(styles.Card, className)}>{children}</div>;
};

export const CardBody = ({ className, children }) => {
  return (
    <div className={classNames(styles.CardBody, className)}>{children}</div>
  );
};
