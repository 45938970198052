import { Route } from "react-router-dom";

import UserList from "./UserList";
import UserView from "./UserView";

const UsersRouter = ({ match }) => {
  return (
    <>
      <Route exact path={`${match.url}/`} component={UserList} />

      <Route
        exact
        path={`${match.url}/:userId`}
        component={UserView}
      />
    </>
  );
};

export default UsersRouter;
