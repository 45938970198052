import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React from "react";

import { formatPhoneNumber } from "utils/phoneNumber";
import { humanText } from "utils/text";

import styles from "./ObjectViewer.module.css";

const currency = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD"
});

// Find the key and path to this field
const overrides = {
  annual_allocations: (value) =>
    ({
      1: "Annually",
      2: "Semi-annually",
      4: "Quarterly",
      12: "Monthly"
    }[value]),
  coverage_ratio: (value) => `${value * 100}%`
};

dayjs.extend(utc);

function ObjectViewer({ object, level = 0 }) {
  // Loop through each key and render the data, if the field
  const keys = Object.keys(object);

  // Is this current field an array of values?
  const isArray = Array.isArray(object);

  const nextLevel = level + 1;

  const renderValue = (value, field = "") => {
    // check overrides
    if (overrides[field]) {
      return overrides[field](value);
    }

    const humanField = humanText(field);

    if (value === true || value === "true") {
      return "Yes";
    } else if (value === false || value === "false") {
      return "No";
    }

    if (value === "") {
      return "--";
    }

    if (!humanField) return value;

    if (humanField.toLowerCase().includes("date")) {
      return dayjs(value).utc().format("MMMM D, YYYY");
    }

    if (humanField.toLowerCase().includes("percent")) {
      // Render as percent?
      return `${value}%`;
    }

    if (
      humanField.toLowerCase().includes("amount") ||
      humanField.toLowerCase().includes("income") ||
      humanField.toLowerCase().includes("worth") ||
      humanField.toLowerCase().includes("claim") ||
      humanField.toLowerCase().includes("revenue") ||
      (humanField.toLowerCase().includes("value") && !isNaN(parseFloat(value)))
    ) {
      return currency.format(value);
    }

    if (humanField.toLowerCase().includes("phone")) {
      return formatPhoneNumber(value);
    }

    if (Array.isArray(value)) {
      return value.join(", ");
    }

    return value;
  };

  return (
    <>
      {keys.map((key) => {
        if (typeof object[key] === "object") {
          return (
            <div key={key} className={styles.ObjectGroup}>
              <h3>{humanText(key)}</h3>
              <div className={styles.ObjectKeyGroup}>
                {!Array.isArray(object[key]) && object[key] && (
                  <ObjectViewer object={object[key]} level={nextLevel} />
                )}

                {Array.isArray(object[key]) &&
                  typeof object[key][0] === "object" && (
                    <div className={styles.ObjectKeyGroup}>
                      {object[key].map((item, index) => (
                        <div key={index}>
                          <ObjectViewer object={item} level={nextLevel} />
                        </div>
                      ))}
                    </div>
                  )}

                {Array.isArray(object[key]) &&
                  typeof object[key][0] !== "object" &&
                  renderValue(object[key], key)}

                {!object[key] && <p>No information provided.</p>}
              </div>
            </div>
          );
        } else if (isArray && typeof object[key] !== "object") {
          return <div>{renderValue(object[key])} </div>;
        }

        return (
          <div key={`${level}_${key}`} className={styles.ObjectKey}>
            <div>
              <b>{humanText(key)}</b>
            </div>{" "}
            <div>{renderValue(object[key], key)} </div>
          </div>
        );
      })}
    </>
  );
}

export default ObjectViewer;
