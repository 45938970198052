import React, { useMemo } from "react";
import classNames from "classnames";

import styles from "./StepCounter.module.css";

const StepCounter = ({ step, maxSteps }) => {
  const steps = useMemo(() => {
    const array = [];
    let i = 0;
    while (i < maxSteps) array[i++] = i;
    return array;
  }, [maxSteps]);

  return (
    <ul className={styles.StepCounter}>
      {steps.map((s) => (
        <li
          key={s}
          className={classNames({
            [styles.Active]: s === step,
            [styles.Visited]: s < step
          })}
        >
          <span>{s}</span>
        </li>
      ))}
    </ul>
  );
};

export default StepCounter;
