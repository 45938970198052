import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import StylizedHeading from "Common/StylizedHeading";
import P from "Common/P";
import Input from "Common/Input";
import Label from "Common/Label";
import Select from "Common/Select";
import Button from "Common/Button";

import ApplicationsStyles from "../../Applications.module.css";
import NextStepsStyles from "./NextSteps.module.css";

const NextSteps = ({ data, save, previous, next }) => {
  const NextStepsSchema = Yup.object().shape({
    info_disclosure_agreement: Yup.boolean()
      .required("This field is required")
      .oneOf([true]),
    contact_time: Yup.string().required("This field is required"),
  });

  return (
    <div className={ApplicationsStyles.Wrapper}>
      <StylizedHeading>Next Steps</StylizedHeading>

      <Formik
        initialValues={{
          info_disclosure_agreement: data?.info_disclosure_agreement || "",
          contact_time: data?.contact_time || "anytime",
        }}
        validationSchema={NextStepsSchema}
        validateOnMount
        onSubmit={(values) => {
          save(values);
          next();
        }}
      >
        {({ values, isValid, setFieldValue }) => (
          <Form className={ApplicationsStyles.Form}>
            <P
              htmlFor="info_disclosure_agreement"
              className={ApplicationsStyles.Label}
            >
              1. Agree to information disclosure.
            </P>

            <P className={NextStepsStyles.Agreement}>
              I authorize RBC Life Insurance Company (“RBC Life”) to disclose to
              my Servicing Advisor material information regarding my health and
              personal history solely for the purpose of explaining underwriting
              decisions. This disclosure could include history of mental
              illness, infectious disease, drug and alcohol use, record of
              criminal activity, or other facts that have a material effect on
              RBC Life's decision to insure me. This authorization to disclose
              information for this purpose is valid until 60 days after the
              later of the day: RBC Life issues a new or amends the existing
              policy; or RBC Life notifies me in writing that my application has
              been declined, withdrawn, or filed incomplete.
            </P>

            <div className={NextStepsStyles.Checkbox}>
              <Input
                id="info_disclosure_agreement"
                name="info_disclosure_agreement"
                type="checkbox"
                checked={values.info_disclosure_agreement}
                onChange={() => {
                  setFieldValue(
                    "info_disclosure_agreement",
                    !values.info_disclosure_agreement,
                  );
                }}
              />
            </div>

            <P className={ApplicationsStyles.Label}>
              2. A telephone interview will be scheduled with Manulife.
            </P>

            <div className={ApplicationsStyles.FormGroup}>
              <Label className={ApplicationsStyles.Label}>
                What is the best time to call?
              </Label>
              <Field as={Select} name="contact_time">
                <option value="anytime">Anytime</option>
                <option value="morning">Morning</option>
                <option value="afternoon">Afternoon</option>
                <option value="evening">Evening</option>
              </Field>
              <ErrorMessage
                component="div"
                className={ApplicationsStyles.Error}
                name="contact_time"
              />
            </div>

            <div className={ApplicationsStyles.ButtonWrapper}>
              <Button
                fullWidth
                className={ApplicationsStyles.PreviousButton}
                onClick={(event) => {
                  event.preventDefault();
                  save(values);
                  previous();
                }}
              >
                Previous
              </Button>

              <Button
                fullWidth
                type="submit"
                className={ApplicationsStyles.NextButton}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NextSteps;
