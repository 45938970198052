import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import DashboardRouter from "../Dashboard";
import AdminRouter from "../Admin";
import AuthRouter from "../Auth";
import QuoteForm from "../QuoteForm";
import Applications from "../Applications";
import RiskProfileRouter from "../RiskProfile";
import RiskProfileOverview from "../RiskProfileOverview";
import Nav from "../Common/Nav";

import { initApp } from "store/actions/App.actions";
import {
  selectIsAuthenticated,
  selectCurrentUser
} from "store/selectors/Auth.selectors";
import { selectAppReady } from "store/selectors/App.selectors";
import usePageTracking from "hooks/pageTracking";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  const user = useSelector(selectCurrentUser);

  if (!user) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return authed ? <Component {...props} /> : <Redirect to="/" />;
      }}
    ></Route>
  );
};

const AdminRoute = ({ component: Component, authed, ...rest }) => {
  const user = useSelector(selectCurrentUser);

  if (!user?.is_admin) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return authed === true ? <Component {...props} /> : <Redirect to="/" />;
      }}
    ></Route>
  );
};

const AppRouter = () => {
  const dispatch = useDispatch();
  const auth = useSelector(selectIsAuthenticated);
  const appReady = useSelector(selectAppReady);
  usePageTracking();

  useEffect(() => {
    dispatch(initApp());
  }, [dispatch]);

  if (!appReady) {
    return <></>;
  }

  return (
    <>
      <ScrollToTop />

      <Nav authed={auth} />

      <div className="page-wrapper">
        <Switch>
          <Route exact path="/quote" component={QuoteForm}></Route>

          <Route path="/applications" component={Applications}></Route>

          <Route
            exact
            path="/risk-profile"
            component={RiskProfileRouter}
          ></Route>
          <Route
            exact
            path="/risk-profile/complete"
            component={RiskProfileOverview}
          ></Route>

          <Route path="/auth" component={AuthRouter}></Route>

          <PrivateRoute
            path="/dashboard"
            authed={auth}
            component={DashboardRouter}
          ></PrivateRoute>

          <AdminRoute
            path="/admin"
            authed={auth}
            component={AdminRouter}
          ></AdminRoute>

          <Redirect from="/" to="/dashboard" />
        </Switch>
      </div>
    </>
  );
};

export default AppRouter;
